<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/digitalmarkting/seo/banner.jpg?updatedAt=1679569819841);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>SEO
    <br>
    <span>(search engine optimization)</span>
  </h1>
  <h4>Improved search visibility for more trust, more traffic, and more
    leads</h4><div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/seo/icon.png" alt="SEO">
        <h2>
          How Endurance gets you to <span>the top of the search engines
          </span>
        </h2>
      </div>
      <div class="content">
        <p>The right SEO strategy and implementation delivers more qualified
          traffic to your site, resulting in more leads and sales.</p>
        <p>Companies across Hampton Roads trust Endurance to get them to the top
          of the search engines for high-intent keywords. When our team of SEO
          experts gets you to page one of Google, we also ensure you stay there
          by implementing cutting-edge, white hat tactics and best practices.
        </p>
        <p>There are no cookie-cutter SEO strategies here. We know that your
          company is unique, so our winning SEO strategy will be custom to your
          business and your goals.
        </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/service/digitalmarkting/seo/Rightside.png?updatedAt=1679569821161"
        alt="Digital Marketing campaign solutions">
    </div>
  </div>
</section>
<section class="section2">
  <h3>
    <strong>An award-winning SEO formula</strong>
  </h3>
  <div class="serviceicons cols2">
    <div>
      <h4 class="organicseo">Organic SEO:</h4>
      <div>
        <p>Organic SEO refers to an Internet user finding your business when
          they type a keyword into Google.
        </p>
        <p>Prominent placement at the top of Google requires a strategic mix of
          content creation, site optimization, and robust backlink strategy.</p>
        <p>Successful organic SEO also requires having a finger on the pulse of
          Google’s latest algorithm changes and a relentless dedication to
          improvement. </p>
        <p> Endurance has the power to get you to the top and help you stay
          there.</p>
      </div>
    </div>
    <div class="noboder">

      <h4 class="localseo">Local SEO:</h4>
      <div>
        <p>Local SEO is one of the best ways to stand out from the competition
          and dominate your industry.</p>
        <p>Here’s why: when someone nearby is searching for a product or service
          you provide, you can win more business when you appear at the top of
          the search engines.</p>
        <p>Local SEO involves prominent placement in organic search results,
          including the Google Maps 3-Pack.</p>
        <p>Endurance Local SEO strategies include adding your business to
          prominent directories, optimizing your Google My Business profile,
          boosting your online reputation, and deploying proven proprietary
          tactics. </p>
      </div>
    </div>
    <!-- comment -->
  </div>
</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/digitalmarkting/seo/footercontent.jpg?updatedAt=1679569817069);">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 class="colorw">
        <strong>Boost your online presence with SEO</strong>
      </h3>
      <p>
        SEO done right does more than just deliver your website to the top of
        the search engine results page.
        As you move to the front of the line of the Google club, you’ll
        automatically be seen as trustworthy and credible. The results of SEO
        can accumulate, giving you a competitive position that no one else can
        match.</p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">SCHEDULE A FREE CONSULTATION</a>
      </div>
    </div>
  </div>
</section>
