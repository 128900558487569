<section class="Redboxlinewholesite">
    <h3 >Solutions for any size of business - Endurance IT will be with you as you grow</h3>
</section>
<footer><div id="footer-menu">
        <div class="row">
            <div class="col-2"><a href="/"><img alt="Endurance IT Services" src="https://ik.imagekit.io/b0cgaqv3j/logo-vertical.png?updatedAt=1677581119808"></a></div>

            <div class="col-1">
                <h6>About Us</h6>
                <ul>
                    
                    <li><a href="about-us/leadership">Leadership</a></li>
                    
                    <li><a href="about-us/giving-back">Giving Back</a></li>
                    <li ><a  target="_blank"   href="https://endurance-it.zohorecruit.com/jobs/Careers">Careers</a></li>
                </ul>
            </div>

            <div class="col-1">
                <h6>Solutions</h6>
                <ul>
                    <li><a href="solutions/small-business">Small  Business</a></li>
                    <li><a href="solutions/medium-business">Medium Business</a></li>
                    <li><a href="solutions/enterprise">Enterprise</a></li>
                    <li><a href="solutions/government-contractors">Government  Contractors</a></li>
                </ul>
            </div>
            <div class="col-2">
                <h6>Services</h6>
                <ul>
                  <li><a href="services/managed-services">Managed Services</a></li>
                  <li><a href="services/it-staffing">IT Staffing</a></li>
                    <li><a href="services/infrastructure-services">Infrastructure Services</a></li>
                    <li><a href="services/it-consulting">IT Consulting</a></li>
                    <li><a href="services/infrastructure-services/cloud">Cloud</a></li>
                    <li><a href="services/project-management">Project Management</a></li>
                    <li><a href="services/compliance-assessments">Compliance</a></li>
                    <li><a href="services/webdesign-and-development">Web Design and Development</a></li>
                    <li><a href="services/application-development">Application Development</a></li>
                    <li><a href="services/branding">Branding</a></li>
                    <li><a href="services/digital-marketing">Digital Marketing</a></li>
                    <li><a href="services/digital-recruitment">Digital Recruitment</a></li>
                </ul>
            </div>


            <div class="col-3">
                <h6>Contact</h6>
                <div>
                    <a href="tel:7573798682">757-379-8682</a><br>
                    <a href="mailto:saleseit@endurance-it.com">saleseit@endurance-it.com</a>
                    <address>
                        Convergence I, 295 Bendix Road,<br>Suite 300. Virginia Beach, VA 23452
                    </address>
                    <div id="social-icons">
                        <a href="https://www.facebook.com/profile.php/?id=100063618251696" target="_blank">
                          <i class="fa-brands fa-facebook-f"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/endurance-it-services/" target="_blank">
                          <i class="fa-brands fa-linkedin-in"></i>
                        </a>
                        <a href="https://www.instagram.com/enduranceitservicesllc/" target="_blank">
                          <i class="fa-brands fa-instagram fa"></i>
                        </a>
                        <a href="https://twitter.com/EnduranceITVA" class="twitterlogo" target="_blank">
                           <img style="width: 1rem !important; max-width: 1rem;    margin-top: -2px; " src="assets/images/twitter.png" alt="Folow Us on twitter">
                        </a>

                      </div>
                </div>
            </div>
        </div>
<style>
  .twitterlogo:hover img{
    filter: drop-shadow(0px 1000px 0 red);
                          transform: translateY(-1000px);

  }
</style>
    </div>
    <div class="row" id="reserved-section">
        <div class="col-4">
            <a href="terms-condition">Terms & conditions</a>
            <a href="privacy-policy">Privacy policy</a>
        </div>
        <div class="col-4">
            @ {{year}} Endurance IT, All Rights Reserved.
        </div>
    </div>
    <div class="col-4">
    </div>

</footer>
