import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.css']
})
export class MobileFooterComponent {
  year:any="";
  constructor() { }
  ngOnInit(): void {
    this.year=new Date().getFullYear();
  }

}
