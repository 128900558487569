<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/Staffing/banner.jpg?updatedAt=1677585983723);">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>IT <span>staffing</span></h1>
    <h4>Specialist resources to overcome <br>skills gaps and reduce hiring costs</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="callout-boxes">
    <div >
        <img alt="Save time" src="/assets/images/call-out/Staffing/Save-time.png">
        <h4>Save time</h4>
        <p>We do the recruitment so you can focus on your business</p>
    </div>
    <div >
        <img alt="Reduce costs" src="/assets/images/call-out/Staffing/reduce-cost.png">
        <h4>Reduce costs</h4>
        <p>Save on the cost of hiring, onboarding, and training new staff</p>
    </div>
    <div>
        <img alt="Fill skills gaps" src="/assets/images/call-out/Staffing/Fill-skils.png">
        <h4>Fill skills gaps</h4>
        <p>Complete your team with our network of specialist IT talent</p>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img alt="Long term and direct hire staffing" src="/assets/images/service/Staffing/icon.jpg">
                <h2>Long term and direct <br><span>hire staffing</span></h2>
            </div>
            <div class="content">
                <h3>Onsite and offsite personnel to complement your team</h3>
                <p>
                    If you’re ready to grow and thrive in the digital age, then let us show you how to bring staffing capabilities together to solve real business challenges.
                </p>
                <p>
                    From information technology (IT) support, web/app development, a full suite of digital marketing services and project management, and even fractional CIO staffing, we leverage digital transformation to produce unprecedented results.
                </p>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <img alt="IT Staffing" src="https://ik.imagekit.io/b0cgaqv3j/service/Staffing/Rightside.jpg?updatedAt=1677585986487">
        </div>
    </div>
</section>
<section class="section2 mainredbar">
    <h3>Endurance IT delivers dedicated onsite or offsite personnel to outsource services depending on your budget and need.</h3>
    <div class="serviceicons cols3">
        <div>
            <img alt="IT Services" src="/assets/images/service/Staffing/service1.png">
            <div>Match your company with just the right team member for the best cultural compatibility fit, proximity, and language.</div>
        </div>
        <div>
            <img alt="IT Support Services" src="/assets/images/service/Staffing/service2.png">
            <div>Take care of everything from payroll to taxes, such as health insurance, benefits, worker’s compensation, employee screening, background checks, and more.</div>
        </div>
        <!-- comment -->
        <div class="noborder">
            <img alt="IT Support Services" src="/assets/images/service/Staffing/service3.png">
            <div>Offer you the highest caliber technical and project expertise and assistance for your company.</div>
        </div>
        <!-- comment -->
    </div>
</section>
<section class="section4 graybar" id="ITsection4">
    <h3 class="coloro">Whether you need staffing for an in-house support team or  an outsourced staffing solution, we have the capabilities and pricing models guaranteed to fit all client and project requirements.</h3>
    <div class="cols3 threeservices">
        <div class="borders">
            <img class="bubbleicon" alt="Long Term" src="/assets/images/service/Staffing/1.png">
            <h4 class="bluetitle">Long Term</h4>
            <p>Long term, contract employees are committed to and invested in your business. This staffing model allows your company to get to know the employee over time. This fosters a relationship between the employee and your customers and clients, which increases your company’s customer loyalty.</p>
        </div>
        <div class="borders">
            <img  class="bubbleicon"  alt="Direct Hire" src="/assets/images/service/Staffing/2.png">
            <h4 class="bluetitle">Direct Hire</h4>
            <p>If you’re interested in quickly staffing-up and reducing time spent in the hiring process, then the direct hire staffing model is a great solution. These staffers bring a fresh perspective, are highly skilled, and quickly make a positive impact on your business.</p>
        </div>
        <div>
            <img  class="bubbleicon"   alt="Option To Hire" src="/assets/images/service/Staffing/3.png">
            <h4 class="bluetitle">Option To Hire</h4>
            <p>Also known as temp-to-hire, this short-term staffing solution allows both parties to try out a role before committing to full-time employment. Option to hire agreements typically set out the length of time the contractor will work for the employer, which gives the employer time to get to know the staffer’s skills and personality. At the end of this trial period, the employer can decide whether to hire the contractor as a full-time employee with benefits.</p>
        </div>
    </div>
</section>
<section class="section3" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/Staffing/footercontent.jpg?updatedAt=1677585985114);">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3 style="color:#fff">
                Want top-tier talent at your business?
            </h3>
            <p>Endurance IT can help guide you towards the staffing solution that’s right for your business. Whether that’s long term, direct hire, or option to hire staffing, we’ve got you covered.</p>
            <div class="bbtnsbox">
                <a href="/contact-us" class="btnsbox">let's talk about it</a>
            </div>
        </div>
    </div>
</section>
