<header>
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Giving <span>back <br>the community</span></h1>
    <h4>We value and support local Non-Profits</h4>
</header>
