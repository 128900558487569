import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-jot-contact-survey-form',
  templateUrl: './jot-contact-survey-form.component.html',
  styleUrls: ['./jot-contact-survey-form.component.css']
})
export class JotContactSurveyFormComponent {
  toDisplay = true;
  Results: any = [];
  NewResults: any = [];
  SEO: any = [];
  IsShowButton =false;
  ButtonText  ='';
  defaultChecked: string = 'option1';
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta,private MetaService: MetaServiceService) { }

  ngOnInit(): void {

    this.ButtonText='Get Started';
      this.MetaService.createCanonicalURL();
      const url = '/assets/seo/contact-us.json';
      this.http.get(url).subscribe(seodata => {

          this.SEO = seodata;
          console.log(this.SEO[0]);
          this.title.setTitle(this.SEO.meta_title);
          this.meta.updateTag({ name: 'description', content: this.SEO.meta_dec });
          this.meta.updateTag({ name: 'keyword', content: this.SEO.keyword });
      });
  }
  toggleData() {

      this.toDisplay = false;
  }
  logForm(value: any) {
      this.toDisplay = false;
      const surl = this.router.url;
      console.log(value);
      const url = '/wp/mail/contact.php';
      const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
      const body = { data: value, url: surl };
      this.http.post<any>(url, body, { headers }).subscribe(data => {
          this.Results = data;
          this.NewResults = data;
          this.toDisplay = true;
          console.log('Main Old Result',this.Results);
          console.log('Main Result',this.Results.result);

          if(this.Results.flag==1 && this.Results.result.data.usertype=='New_Customer'){

            this.router.navigate(['/survey']);
          }
          if(this.Results.flag==1 && this.Results.result.data.usertype=='Others'){

            this.router.navigate(['/thank-you']);
          }
          console.log('Main New Result One',this.Results.result.data.usertype);
          console.log('Main New Result',this.Results.result.usertype);

      });
  }
  showForm(value: Number){

    this.IsShowButton=false;
    if(value==1){
       this.Results.flag=2;
       this.IsShowButton=true;
       this.ButtonText='Click here to Get Support';

    }
    if(value==2)
    {
      this.ButtonText='Get Started';
    }
    if(value==3)
    {
      this.ButtonText='Submit';
    }
}
}
