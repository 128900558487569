<!-- Event snippet for Website traffic conversion page -->
<script>
  gtag('event', 'conversion', {'send_to': 'AW-10837831330/up-DCPP85NoYEKLd8K8o'});
</script>
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-10837831330"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', 'AW-10837831330');
</script>
  <header class="banner">
    <nav class="navbar navbar-expand-sm">
      <div class="container-fluid">
        <a _ngcontent-serverapp-c61="" href="/" class="navbar-brand"><img _ngcontent-serverapp-c61=""
            src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
            alt="Endurqance IT Services" class="mainlogo"></a>
        <div class="call"><img
            src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/phone_call.png?updatedAt=1686234842413">
          <a href="tel:(757) 571-8272">(757) 571-8272</a>
        </div>
      </div>
    </nav>

  </header>
  <section class="sections1">
    <div><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/thanks.png?updatedAt=1686667161176"></div>
    <h2>Thank You for Reaching Out! </h2>
    <p>We have received your request to schedule a call, and we’re excited to discuss how we can support your managed services needs.</p>
  </section>
  <section class="sections2">
    <h3>Here’s what you can expect next: </h3>
    <p><b>Our team of  managed services will review your request and prepare for the call.</b> We will reach out to
      you within the next 24 hours to schedule a convenient time for the consultation.<b> However, if you need to
        expedite, please call us at (755) 571-8272.</b> </p>
  </section>
  <section class="sections3">
    <div class="row">
      <div class="col-6"></div>
      <div class="col-6">
        <p><b>In the meantime, feel free to explore our website </b>for more information about our managed IT solutions.
        </p> <p>
          <b>We look forward to speaking with you soon</b> and helping you streamline your business with our managed services expertise.
      </p></div>
    </div>
  </section>
  <section class="section6"><img src="https://ik.imagekit.io/b0cgaqv3j/logo-vertical.png?updatedAt=1677581119808" alt="Endurance IT Services">
    <div><a href="tel:(757) 571-8272">(757) 571-8272</a> | <a
        href="mailto:saleseit@endurance-it.com">saleseit@endurance-it.com</a></div>
    <div>Convergence I, 295 Bendix Road, Suite 300. Virginia Beach, VA 23452</div>
    <div id="social-icons"><a href="https://www.facebook.com/profile.php/?id=100063618251696" target="_blank"><i
          class="fa-brands fa-facebook-f"></i></a><a href="https://www.linkedin.com/company/endurance-it-services/"
        target="_blank"><i class="fa-brands fa-linkedin-in"></i></a><a
        href="https://www.instagram.com/enduranceitservicesllc/" target="_blank"><i
          class="fa-brands fa-instagram fa"></i></a></div>

  </section>
  <section class="section7">
    <div>
      <div id="reserved-section" class="row">
        <div class="col-4 termss"><a href="terms-condition">Terms &amp;
            conditions</a><a href="privacy-policy">Privacy policy</a></div>
        <div class="col-4 copyright"> @ 2023 <a href="/">Endurance IT</a>, All Rights Reserved. </div>
      </div>
    </div>
  </section>
  <style>
    html body .modal-backdrop {
      display: none !important;
    }
  </style>
