<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Cloud managed
      <br>
      <span>services</span>
  </h1>
  <h4>Reduce IT costs while maximizing value</h4><div _ngcontent-psv-c86
    class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/cloud/cloudmain/3.png"
          alt="Cloud solutions">
        <h2>
          Flexible outsourced <span>cloud solutions</span>
        </h2>
      </div>
      <div class="content">
        <p>Maximize value to your internal stakeholders and the company overall
          without spiking IT costs. You can efficiently outsource one, some, or
          all of your infrastructure, technology, apps, and support with
          Endurance cloud managed services.</p>
        <h3>Flexible cloud architecture solutions</h3>
        <p>
          Whether you want private, public, or hybrid cloud hosting architecture
          configurations, the team at Endurance can design, implement, and
          manage an ideal solution for your organization’s desired outcomes.
        </p>
        <p>Our private cloud configuration, For example, our private cloud
          configuration guarantees a 99.99% uptime in a redundant, scalable
          configuration.</p>
        <p>With our flexible solutions, companies of all sizes can move nimbly
          and implement cloud technology at a pace and in an environment that
          ensures optimal security, performance, and control that’s compatible
          with the needs of your business. </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/service/cloud/CloudManaged/rightside.png?updatedAt=1679481111325"
        alt="Flexible outsourced Cloud solutions" class="shadow-lg">
    </div>
  </div>
</section>
<section class="section2 iconanimation mainredbar">
  <h3>
    <strong>Agnostic cloud tools</strong>
  </h3>
  <p>
    You’re never locked into a single solution when you work with Endurance to
    bring your company applications into a cloud setting.
  </p>
  <p>
    We also provide customer-facing dashboards and consoles for real-time data
    acquisition and control of your entire cloud technology footprint.
  </p>
</section>
<section class="section4">
  <div>
    <div><img src="/assets/images/service/cloud/CloudManaged/ser1.png"
        alt="Cloud Migration"><h3>Flexible Cloud Managed <br>Services
        Solutions</h3></div>
    <p>
      CMS solutions provide unprecedented access to data and control, and
      Endurance is at the forefront of providing innovative new ways of
      achieving a winning digital transformation strategy across virtually any
      industry or niche.
    </p>
    <p>
      For example, Endurance recognizes that cross-department functions can
      result in disparate sets of data. Our team unifies multi-department data
      sets into a single cloud provider interface for better decision-making and
      operations.
    </p>
  </div>
  <div class="bulletlistr">
    <div><img src="assets/images/service/cloud/CloudManaged/ser2.png"
        alt="Cloud Managed Services ">
      <h3>Cloud Managed Services that meet <br>you where you are in your digital
        <br>transformation journey</h3></div>
    <p>
      Not every business moves at the same speed, nor do businesses have the
      same goals. Endurance recognizes that there is no right or wrong place to
      be in your digital transformation journey. Instead, we meet you where we
      are and deliver an ongoing solution that drives your business forward.
    </p>
    <p>
      We work with Hampton Roads businesses to provide solutions ranging from
      modest to enterprise with the following aspects:
    </p>
    <ul>
      <li>
        Fully automated deployments
      </li>
      <li>Security governance that effectively coordinates security activities
        across your organization</li>
      <li>
        A FinOps mindset that balances consumption with cost effectiveness
      </li>
    </ul>
  </div>
</section>
<section class="section5 ">
  <h3>Why choose Endurance?</h3>
  <p style="font-size: 20px;">We know we’re not the only CMS provider in town, but we are confident that
    we are the best.

  </p>
  <h4>Endurance offers:</h4>
  <ul class="columns3box">
    <li>
      <img src="assets/images/service/cloud/CloudManaged/offer1.png"
        alt="Scalability">
      <p style="padding-top: 1rem;">
        Scalability
      </p>
    </li>
    <li>
      <img src="assets/images/service/cloud/CloudManaged/offer2.png"
        alt="Reliability">
      <p style="padding-top: 1rem;">
        Reliability
      </p>
    </li>
    <li>
      <img src="assets/images/service/cloud/CloudManaged/offer3.png"
        alt="Budget-friendly pricing">
      <p>
        Budget-friendly pricing
      </p>
    </li>
    <li>
      <img src="assets/images/service/cloud/CloudManaged/offer4.png"
        alt="Robust ecosystem of partners">
      <p>
        Robust ecosystem of partners
      </p>
    </li>
    <li>
      <img src="assets/images/service/cloud/CloudManaged/offer5.png"
        alt="Improved compliance">
      <p>
        Improved compliance
      </p>
    </li>
    <li>
      <img src="assets/images/service/cloud/CloudManaged/offer6.png"
        alt="Fully agile business operations">
      <p>
        Fully agile business operations
      </p>
    </li>
    <li>
      <img src="assets/images/service/cloud/CloudManaged/offer7.png"
        alt="Disaster recovery">
      <p>Disaster recovery support <span>(and more importantly,
          prevention)</span></p>
    </li>
    <li>
      <img src="assets/images/service/cloud/CloudManaged/offer8.png"
        alt=" Regional and global presence">
      <p>Regional and global presence</p>
    </li>

  </ul>
</section>
<section class="section3">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>Local Support at a Global Scale</h3>
      <p>
        At Endurance, we are 100% committed to helping Hampton Roads businesses
        with their digital transformation journeys.
      </p>
      <p>
        Let’s connect to build a solution that keeps your digital transformation
        initiatives moving forward.
      </p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">CONNECT WITH US</a>
      </div>
    </div>
  </div>
</section>
