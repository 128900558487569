<header class="banner MainHeaderforlanding"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/LandingPageBanner.png?updatedAt=1686234003726);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a _ngcontent-serverapp-c61="" href="/" class="navbar-brand"><img _ngcontent-serverapp-c61=""
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services" class="mainlogo"></a>
      <div class="call"><img
          src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/phone_call.png?updatedAt=1686234842413">
          <a href="tel:(757) 571-8272">(757) 571-8272</a>
      </div>
    </div>
  </nav>
  <div class="headercontent">
    <h2>Managed services</h2>
      <div class="borderorange"></div>
    <p>Improve service and reduce costs with our<br>
      managed alternatives to in-house IT resources</p>
    <button class="callform ">GET IN TOUCH</button>


  </div>
</header>

<section class="callout-boxes">

    <div>
        <img  src="/assets/images/call-out/ManagedServices/virtual-solution.png" alt="Virtual Solutions">
        <h4>Virtual Solutions</h4>
        <p>Expert virtual IT Manager and vCIO solutions for businesses with a skills gap or limited budget, to seamlessly integrate with your in-house IT team.</p>
    </div>
    <div>
        <img src="/assets/images/call-out/ManagedServices/service-desk.png" alt="Service Desk">
        <h4>Service Desk</h4>
        <p>A virtual extension or alternative to an in-house IT service desk to manage incidents and support requests, and ensuring continued productivity.</p>
    </div>
    <div>
        <img src="/assets/images/call-out/ManagedServices/security-services.png" alt="Managed Security Services">
        <h4>Security Services</h4>
        <p>Scalable SOCaaS solutions, including Intrusion Detection and Vulnerability Management, with packages to suit your requirements and budget.</p>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/ManageServices/icon.png" alt="Virtual Solutions">
                <h2>Virtual <span>Solutions</span></h2>

            </div>
            <div class="content">
                <p>Recruiting high quality IT specialists costs time and money, but many businesses have skills gaps within their IT team and management. Our virtual IT solutions give you access to experienced, knowledgeable senior IT leaders that provide leadership and direction to your in-house staff.</p>
                <p>A Virtual IT Manager will work with your finance director and IT staff, controlling spend, improving systems, dealing with malfunctions, managing staff and promoting a culture of learning.</p>
                <p>A vCIO (virtual Chief Information Officer) will collaborate with your IT team to plan strategy, budget and a business continuity plan - as well as facilitating hands-on technology upgrades.</p>
                <div _ngcontent-psv-c86="" class="bbtnsbox">
                    <button class="btnsbox callform">FIND OUT MORE</button>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/service/ManageServices/section1.jpg?updatedAt=1679461847216" alt="virtual IT solutions"></div>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/service/ManageServices/section2.jpg?updatedAt=1679461851663" alt=" IT support team"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/ManageServices/section2_icon.png" alt="Service Desk">
                <h2>Service <span>Desk</span></h2>

            </div>
            <div class="content">
                <p>IT systems can experience issues that negatively affect productivity at any time, both minor and major, so an IT Service Desk is an essential point of contact for any size of business. Our remote Service Desk solution works as either an extension or alternative to an internal IT support team, and offers the following standard and after-hours options:</p>
                <ul>
                    <li>On-Site Support</li>
                    <li>Remote Support</li>
                    <li>Remediation Services</li>
                    <li>Desktop / End User Support</li>
                    <li>Application/System Support</li>
                    <li>After Hours Emergency Support</li>
                </ul>
                <div _ngcontent-psv-c86="" class="bbtnsbox">
                    <button class="btnsbox callform">FIND OUT MORE</button>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/ManageServices/section3_icon.png" alt="Security Services">
                <h2>Security <span>Services</span></h2>

            </div>
            <div class="content">
                <p>A cyberattack can irreversibly harm the reputation of your company. With the rising cost of hiring specialised security staff, not to mention the costs of a malware or a ransomware attack, a managed solution can ensure that your business and reputation are safeguarded at an affordable price point.</p>
                <p>SOCaaS (SOC as a Service) is a managed alternative to hiring, developing and coordinating your own internal security team. Get access to expert cybersecurity knowledge and experience in an effective and affordable package.</p>
                <p>Includes Vulnerability Management (to detect potential security flaws across your network) and Intrusion Detection (to flag suspicious activity) so an attacker can be disarmed before any damage is done.</p>
                <div _ngcontent-psv-c86="" class="bbtnsbox">
                    <button class="btnsbox callform">FIND OUT MORE</button>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/service/ManageServices/section3.jpg?updatedAt=1679461853134" alt="managed solution "></div>
    </div>
</section>
<section class="section3">
    <div class="logoicon"><img src="assets/images/service/digitalmarkting/endurance_icon.png" alt="Endurance IT Services Favicon"></div>
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3 style="color:#fff">Get started with managed services</h3>
            <p>Determining your overall IT strategy, identifying skills gaps within your team and management, evaluating your Service Desk requirements and investing in the cybersecurity controls, tools and frameworks your company needs can be a minefield. It’s difficult to know where to begin. Endurance IT can provide managed solutions for all of this, so you can focus on growing your business.</p>
            <div _ngcontent-psv-c86="" class="bbtnsbox">
                <button class="btnsbox callform">LET’S TALK ABOUT IT</button>
            </div>
        </div>
    </div>
</section>
<section class="section6"><img src="https://ik.imagekit.io/b0cgaqv3j/logo-vertical.png?updatedAt=1677581119808" alt="Endurance IT Services">
    <div><a href="tel:(757) 571-8272">(757) 571-8272</a> | <a href="mailto:saleseit@endurance-it.com">saleseit@endurance-it.com</a></div>
    <div>Convergence I, 295 Bendix Road, Suite 300. Virginia Beach, VA 23452</div>
    <div _ngcontent-serverapp-c140="" id="social-icons"><a _ngcontent-serverapp-c140=""
        href="https://www.facebook.com/profile.php/?id=100063618251696" target="_blank"><i
          _ngcontent-serverapp-c140="" class="fa-brands fa-facebook-f"></i></a><a _ngcontent-serverapp-c140=""
        href="https://www.linkedin.com/company/endurance-it-services/" target="_blank"><i _ngcontent-serverapp-c140=""
          class="fa-brands fa-linkedin-in"></i></a><a _ngcontent-serverapp-c140=""
        href="https://www.instagram.com/enduranceitservicesllc/" target="_blank"><i _ngcontent-serverapp-c140=""
          class="fa-brands fa-instagram fa"></i></a></div>

  </section>
  <section class="section7">
    <div>
      <div _ngcontent-serverapp-c140="" id="reserved-section" class="row">
        <div _ngcontent-serverapp-c140="" class="col-4 termss"><a _ngcontent-serverapp-c140="" href="terms-condition">Terms &amp;
            conditions</a><a _ngcontent-serverapp-c140="" href="privacy-policy">Privacy policy</a></div>
        <div _ngcontent-serverapp-c140="" class="col-4 copyright"> @ 2023 <a href="/">Endurance IT</a>, All Rights Reserved. </div>
      </div>
    </div>
  </section>
<div class="modal fade" id="myModal" role="dialog" style="display: none;">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h3 ><span >Ready</span> to talk? </h3>



          <button type="button" class="close" data-dismiss="modal">&times;</button>

        </div>
        <div class="modal-body">
          <div *ngIf="Results.flag==0" class="alert alert-danger" [innerHTML]="Results.msg">{{Results}}</div>
          <div *ngIf="Results.flag==1" class="alert alert-success" [innerHTML]="Results.msg">{{Results}}</div>
          <form #form="ngForm" (submit)="logForm(form.value)">
            <!-- <form> -->
            <label>Name:*</label>
            <input type="text" ngModel name="Full_Name">
            <label>Company name:*</label>
            <input type="text" ngModel name="Company_Name">
            <label>Email:*</label>
            <input type="email" ngModel name="Email">
            <label>Phone:*</label>
            <input type="text" ngModel name="phone">
            <label>Additional Comments:</label>
            <textarea ngModel name="comment" cols="70" rows="5"></textarea>
            <br>
            <button type="submit" class="savebtn callform">Submit</button>
          </form>
        </div>

      </div>

    </div>
  </div>
