<header class="serviceinner">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>
        SMB
        <span>cloud</span>
    </h1>
    <h4>Flexible and personalized innovation <br>for faster growth</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/cloud/SMBCloud/icon.png" alt="Cost Containment">
                <h2>
                    Affordable, scalable cloud <span>solutions for SMBs</span>
                </h2>
            </div>
            <div class="content">
                <p>Endurance provides creative cloud solutions that are convenient, reliable, and adaptable. As a trusted IT solutions partner, we help SMBs get ahead, and stay ahead.</p>
                <h3>Cloud as a competitive advantage</h3>
                <p>
                    Small businesses face new challenges daily, but your IT infrastructure shouldn’t be one of them.</p><p>Avoid the vicious cycle of buying and maintaining expensive equipment and infrastructure that becomes outdated, labor-intensive, and inflexible.</p><p>Endurance helps businesses create better experiences that facilitate scalability and innovation so that you can grow without technological obstacles.
                </p>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <img src="https://ik.imagekit.io/b0cgaqv3j/service/cloud/SMBCloud/rightside.png?updatedAt=1679470529554" alt="Affordable, scalable Cloud solutions for SMBs" class="shadow-lg">
        </div>
    </div>
</section>
<section class="section2">
    <h3 style="font-family: MontserratSB!important;">Comprehensive Cloud Support Services</h3>
    <p  style="font-size: 1.75rem!important;   font-family: 'Montserrat' !important;  font-weight: 400!important;">Wherever you are in your cloud migration journey, Endurance can help. We provide the following services to keep your business running smoothly:</p>
    <div class="serviceicons">
        <div>
            <img src="/assets/images/service/cloud/SMBCloud/1.png" alt="Infrastructure">

            <div>Infrastructure</div>
        </div>
        <div>
            <img src="/assets/images/service/cloud/SMBCloud/2.png" alt="Third-party maintenance">

            <div>Third-party maintenance</div>
        </div>
        <!-- comment -->
        <div>
            <img src="/assets/images/service/cloud/SMBCloud/3.png" alt="Cloud and data center">

            <div>Cloud and data center</div>
        </div>
        <div>
            <img src="/assets/images/service/cloud/SMBCloud/4.png" alt="Cyber security and physical security">

            <div>Cyber security and physical security</div>
        </div><div class="noborder">
            <img src="/assets/images/service/cloud/SMBCloud/5.png" alt="NOC/SOC (network and security operations center)">

            <div>NOC/SOC (network and security operations center)</div>
        </div>
        <!-- comment -->
    </div>
</section>
<section class="section5">
    <h3  style="font-family: MontserratSB!important;">Endurance is a Trusted Strategic Partner</h3>
    <p style="font-size: 20px;">Small and medium businesses can face cloud adoption challenges that larger organizations with more technical teams and advanced resources never encounter. </p>
    <h4 align="center" style="font-family:MontserratSB !important;">With over 15 years of experience, our expert team can help you with the following:</h4>
    <ul class="columns3box">
        <li>
            <div style="text-align: center;">
                <img src="assets/images/service/cloud/SMBCloud/noun-planning-1947513.png" alt="Planning">
                <span style="font-family:MontserratSB !important; font-size: 1rem !important;">Planning</span>
            </div>
            <p  style="font-weight: 400;   align-self: initial !important;">
                Determine how cloud technology best fits your organization and how to deploy it.
            </p>
        </li>
        <li >
            <div style="text-align: center;">
                <img src="assets/images/service/cloud/SMBCloud/Riskmitigation.png" alt="Risk Mitigation">
                <span style="font-family:MontserratSB !important; font-size: 1rem !important;">Risk<br>Mitigation</span>
            </div>
            <p style="font-weight: 400;   align-self: initial !important;">
                Prepare for the unexpected and take a data-centric, proactive approach to security.
            </p>
        </li>
        <li>
            <div style="text-align: center;">
                <img src="assets/images/service/cloud/SMBCloud/Costcontainment.png" alt="Planning">
                <span style="font-family:MontserratSB !important; font-size: 1rem !important; ">Cost
                    <br>Containment
                </span>
            </div>
            <p >
                Avoid cost overruns often associated with metered service with our strict governance standards.            </p>
        </li>

    </ul>
</section>
<section class="section4 graybar">
    <h3 >How does Endurance ensure successful cloud migration?</h3>
    <p style="font-size: 20px !important;">We take a step-by-step approach that is exceptionally organized to poise your business for success in the cloud.</p>

<ul class="listofservice">
    <li><h4>Discovery:</h4><p >We start by taking inventory of all of your applications and supporting infrastructure, taking particular note of your security posture.</p></li>

    <li><h4>Assessment:</h4><p >Determine the best next steps for your applications by identifying what needs lift and shift, remediation, re-platforming, decommissioning, and/or deployment. We also help identify target cloud vendors that are the right fit for your business based on their services, cost, support, data offerings, data center locations, and more.</p></li>

    <li><h4>Migration Planning:</h4><p>After gathering all necessary data in the context of your business needs and goals, we will put together a plan that prioritizes the order in which services are moved. At this point, you’ll receive price and time estimates that include monthly costs for cloud operations and support. We will also create a complete and detailed roadmap for moving forward.</p></li>

    <li><h4>Migration and Testing:</h4><p >During this phase, we will begin migrating applications, databases, and network infrastructures. Once everything is moved, we run tests to validate that everything is operating according to our migration plan.</p></li>
    <li><h4>Go Live:</h4><p >The final phase is cutting the cord on the internal systems and running completely in the cloud (for the relevant applications). Each service is subject to a post-migration review to mitigate any issues and update documentation.</p></li>

</ul>
</section>


<section class="section3">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3>Take your SMB to the next level</h3>
            <p>              Explore the seamless collaboration and cost-efficiency that our SMB Cloud solutions offer.</p>
            <p>Our cutting-edge cloud services are tailored to fit the unique needs of your business, providing scalability, flexibility, and security like never before.</p>
            <p>Let’s embark on your journey to success together!</p>
            <div class="bbtnsbox">
                <a href="/contact-us" class="btnsbox">CONNECT WITH US</a>
            </div>
        </div>
    </div>
</section>
