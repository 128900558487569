
<header class="banner MainHeaderforlanding"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/LandingPageBanner.png?updatedAt=1686234003726);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a _ngcontent-serverapp-c61="" href="/" class="navbar-brand"><img _ngcontent-serverapp-c61=""
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services" class="mainlogo"></a>
      <div class="call"><img
          src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/phone_call.png?updatedAt=1686234842413">
        <a href="tel:(757) 571-8272">(757) 571-8272</a>
      </div>
    </div>
  </nav>
  <div class="headercontent">
    <h2 [innerHTML]="SEO.NewMeta['wpcf_header_main_heading']">Leverage Our 15 Years of Expertise <br>for Our Cloud
      Engineering Advantage</h2>
    <div class="borderorange"></div>
    <p [innerHTML]="SEO.NewMeta['wpcf_header_main_sub_heading']">We attract, engage, and prequalify top cloud engineer
      candidates, <br>empowering your hiring managers to find top cloud engineers for hire,<br> complete projects on
      time, within budget, and drive maximum business value.</p>
    <button class="callform " [innerHTML]="SEO.NewMeta['wpcf_hire_cloud_engineers_now']">Hire Cloud Engineers
      Now</button>
    <div class=" monly Companylogosslider">
      <h3>Our Clients:</h3>
      <ul class="Companylogos">
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/1.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/2.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/3.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/4.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/5.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/6.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/7.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/8.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/9.png?updatedAt=12">
        </li>
      </ul>

    </div>

  </div>
</header>
<div class="section1 donly">
  <div class="contents"><span> Our Clients:</span>
    <div class="">
      <ul class="companylogo">
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/1.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/2.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/3.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/4.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/5.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/6.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/7.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/8.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/9.png?updatedAt=1">
        </li>
      </ul>
    </div>
    <div style="clear: both;"></div>
  </div>

</div>
<div class="section2">
  <h3 [innerHTML]="SEO.NewMeta['wpcf_section_one_heading']">Streamline Your Business with Our Cloud Engineer Staffing
    Solutions</h3>
</div>
<section class="callout-boxes">
  <div>
    <img alt="Nimble" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/Save_time.png?updatedAt=1686242180555">
    <h4 [innerHTML]="SEO.NewMeta['wpcf_section_one_first_box_heading']">Save time</h4>
    <p [innerHTML]="SEO.NewMeta['wpcf_section_one_first_box_content']">We handle the recruitment process, allowing you
      to focus on your core business. Our streamlined approach attracts, engages, and prequalifies top cloud engineers,
      saving you valuable time and resources.</p>
  </div>
  <div><img alt="Flexible"
      src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/reduce_cost.png?updatedAt=1686242181192">
    <h4 [innerHTML]="SEO.NewMeta['wpcf_section_one_second_box_heading']">Save time</h4>
    <p [innerHTML]="SEO.NewMeta['wpcf_section_one_second_box_content']">We handle the recruitment process, allowing you
      to focus on your core business. Our streamlined approach attracts, engages, and prequalifies top cloud engineers,
      saving you valuable time and resources.</p>
  </div>
  <div>
    <img alt="Experienced"
      src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/Fill_skils.png?updatedAt=1686242180501">
    <h4 [innerHTML]="SEO.NewMeta['wpcf_section_one_third_box_heading']">Save time</h4>
    <p [innerHTML]="SEO.NewMeta['wpcf_section_one_third_box_content']">We handle the recruitment process, allowing you
      to focus on your core business. Our streamlined approach attracts, engages, and prequalifies top cloud engineers,
      saving you valuable time and resources.</p>
  </div>
</section>
<section class="section3">
  <h4 [innerHTML]="SEO.NewMeta['wpcf_section_two_heading']">We offer a range of services to help you find the right
    cloud engineering talent. These include:</h4>
  <div class="threeboxes">
    <div>
      <h5><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/1.png?updatedAt=1686316221332">
        <div [innerHTML]="SEO.NewMeta['wpcf_section_two_first_box_heading']">Contract<br> Staffing</div>
      </h5>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_two_first_box_content']">Hire cloud engineers on a contract basis to meet your
        short-term needs.</p>
    </div>
    <div>
      <h5><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/2.png?updatedAt=1686316221332">
        <div [innerHTML]="SEO.NewMeta['wpcf_section_two_second_box_heading']">Direct Hire</div>
      </h5>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_two_second_box_content']">Find the perfect permanent addition to your cloud
        engineering team.</p>
    </div>
    <div>
      <h5><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/3.png?updatedAt=1686316221332">
        <div [innerHTML]="SEO.NewMeta['wpcf_section_two_third_box_heading']">Contract-to-Hire: </div>
      </h5>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_two_third_box_content']">Try out a cloud engineer on a contract basis before
        making a permanent hiring decision.</p>
    </div>
  </div>
  <div class="afterthreebox" [innerHTML]="SEO.NewMeta['wpcf_section_two_footer']"><span>
      Ready to find the top cloud engineering talent for your team?</span> Contact us today to learn more about our hire
    cloud engineer services. Let Endurance IT Services help you build a team that can take your business to new heights
    in the cloud.
    <br><button class="callform" [innerHTML]="SEO.NewMeta['wpcf_section_two_button_text']">Get Started Today</button>
  </div>
</section>
<section class="section4">
  <h3 [innerHTML]="SEO.NewMeta['wpcf_section_three']">Our Cloud Engineer Hiring Process:</h3>
  <ul class="boxes3">
    <li>
      <img class="donly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/1.png?updatedAt=1686317874614">
      <h3><img class="monly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/1.png?updatedAt=1686317874614"> <span
          [innerHTML]="SEO.NewMeta['wpcf_section_three_first_box_heading']">Understanding your Needs</span></h3>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_three_first_box_content']">We first understand your business goals, technical
        requirements, and the type of professionals you need.</p>
    </li>
    <li>
      <img class="donly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/2.png?updatedAt=1686317874614">
      <h3><img class="monly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/2.png?updatedAt=1686317874614"><span
          [innerHTML]="SEO.NewMeta['wpcf_section_three_second_box_heading']">Talent Sourcing and Screening</span></h3>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_three_second_box_content']">We source and prequalify top cloud engineers from our
        extensive network, ensuring they match your requirements.</p>
    </li>
    <li>
      <img class="donly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/3.png?updatedAt=1686317874614">
      <h3><img class="monly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/3.png?updatedAt=1686317874614"><span
          [innerHTML]="SEO.NewMeta['wpcf_section_three_third_box_heading']">Talent Sourcing and Screening</span></h3>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_three_third_box_content']">We source and prequalify top cloud engineers from our
        extensive network, ensuring they match your requirements.</p>
    </li>
    <li>
      <img class="donly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/4.png?updatedAt=1686317874614">
      <h3><img class="monly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/4.png?updatedAt=1686317874614"><span
          [innerHTML]="SEO.NewMeta['wpcf_section_three_forth_box_heading']">Talent Sourcing and Screening</span></h3>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_three_forth_box_content']">We source and prequalify top cloud engineers from our
        extensive network, ensuring they match your requirements.</p>
    </li>
    <li>
      <img class="donly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/5.png?updatedAt=1686317874614">
      <h3><img class="monly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/5.png?updatedAt=1686317874614"><span
          [innerHTML]="SEO.NewMeta['wpcf_section_three_fifth_box_heading']">Talent Sourcing and Screening</span></h3>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_three_fifth_box_content']">We source and prequalify top cloud engineers from our
        extensive network, ensuring they match your requirements.</p>
    </li>
    <li>
      <img class="donly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/6.png?updatedAt=1686317874614">
      <h3><img class="monly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/6.png?updatedAt=1686317874614"><span
          [innerHTML]="SEO.NewMeta['wpcf_section_three_sixth_box_heading']">Talent Sourcing and Screening</span></h3>
      <p [innerHTML]="SEO.NewMeta['wpcf_section_three_sixth_box_content']">We source and prequalify top cloud engineers from our
        extensive network, ensuring they match your requirements.</p>
    </li>
  </ul>
</section>
<section class="section5">
  <img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/footerbanner.png?updatedAt=1686318619394">
  <div>
    <h3 [innerHTML]="SEO.NewMeta['wpcf_section_fourth_heading']">Accelerate your business growth with efficient,<br><span>
        reliable, and affordable cloud engineer<br> hiring solutions.</span>
    </h3><button class="callform" [innerHTML]="SEO.NewMeta['wpcf_section_fourth_button_text']">Find Top Cloud
      Engineers</button>
  </div>
</section>
<section class="section6"><img src="https://ik.imagekit.io/b0cgaqv3j/logo-vertical.png?updatedAt=1677581119808" alt="Endurance IT Services">
  <div><a href="tel:(757) 571-8272">(757) 571-8272</a> | <a
      href="mailto:saleseit@endurance-it.com">saleseit@endurance-it.com</a></div>
  <div>Convergence I, 295 Bendix Road, Suite 300. Virginia Beach, VA 23452</div>
  <div id="social-icons"><a href="https://www.facebook.com/profile.php/?id=100063618251696" target="_blank"><i
        class="fa-brands fa-facebook-f"></i></a><a href="https://www.linkedin.com/company/endurance-it-services/"
      target="_blank"><i class="fa-brands fa-linkedin-in"></i></a><a
      href="https://www.instagram.com/enduranceitservicesllc/" target="_blank"><i
        class="fa-brands fa-instagram fa"></i></a></div>

</section>
<section class="section7">
  <div>
    <div id="reserved-section" class="row">
      <div class="col-4 termss"><a href="terms-condition">Terms &amp;  conditions</a><a href="privacy-policy">Privacy policy</a></div>
      <div class="col-4 copyright"> @ 2023 <a href="/">Endurance IT</a>, All Rights
        Reserved. </div>
    </div>
  </div>
</section>
<button class="callform Stickybuts" style="display: none !important;">schedule a call</button>


<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h3><span>Ready</span> to talk? </h3>



        <button type="button" class="close" data-dismiss="modal">&times;</button>

      </div>
      <div class="modal-body">
        <div *ngIf="Results.flag==0" class="alert alert-danger" [innerHTML]="Results.msg">{{Results}}</div>
        <div *ngIf="Results.flag==1" class="alert alert-success" [innerHTML]="Results.msg">{{Results}}</div>
        <form #form="ngForm" (submit)="logForm(form.value)">
          <label>Name:*</label>
          <input type="text" ngModel name="Full_Name">
          <label>Company name:*</label>
          <input type="text" ngModel name="Company_Name">
          <label>Email:*</label>
          <input type="email" ngModel name="Email">
          <label>Phone:*</label>
          <input type="text" ngModel name="phone">
          <br>
          <button type="submit" class="savebtn callform">Submit</button>
        </form>
      </div>

    </div>

  </div>
</div>
