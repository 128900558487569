<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/it-consulting/banner.png?updatedAt=1679471778118) !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>IT
        <span>consulting</span>
    </h1>
    <h4>Achieve your business goals <br>through digital transformation</h4>
    <div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT</a>
    </div>
</header>
<section class="newheadingbox">
    <h2>
        Experienced IT
        <span>Consultants</span>
    </h2>
    <p class="p-beforecallouts">Any size, any industry.</p>
</section>
<section class="callout-boxes">
    <div>
        <img alt="Experience" src="/assets/images/call-out/ITConsulting/experience.png">
        <h4>Experience</h4>
        <p>We bring years of IT consulting experience to the table</p>
    </div>
    <div>
        <img alt="Expertise" src="/assets/images/call-out/ITConsulting/expertise.png">
        <h4>Expertise</h4>
        <p>We have multi-industry knowledge in all sizes of business</p>
    </div>
    <div>
        <img alt="Evolution" src="/assets/images/call-out/ITConsulting/evolution.png">
        <h4>Evolution</h4>
        <p>We partner with you to help you adapt as your business grows</p>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="gridboxed">
            <div class="borderR">
                <div class="icon">
                    <img alt="IT Assessment" src="assets/images/service/it-consulting/ITAssessment.png">
                </div>
                <div class="heading">
                    <h3>IT
                        <span>Assessment</span>
                    </h3>
                </div>
                <div class="peragraph" style="font-size: 19px;">A process to improve efficiency and reduce costs
                </div>
                <div class="buttons">
                    <a href="services/it-consulting/it-assessments">Learn More</a>
                </div>
            </div>
            <div class="borderR">
                <div class="icon">
                    <img alt="IT Leadership" src="assets/images/service/it-consulting/ITLeadership.png">
                </div>
                <div class="heading">
                    <h3>IT
                        <span>Leadership</span>
                    </h3>
                </div>
                <div class="peragraph" style="font-size: 19px;">Bridge knowledge gaps and achieve technology best practices
                </div>
                <div class="buttons">
                    <a href="services/it-consulting/it-leadership">Learn More</a>
                </div>
            </div>
            <div>
                <div class="icon">
                    <img alt="Outsourcing" src="assets/images/service/it-consulting/Outsourcing.png">
                </div>
                <div class="heading">
                    <h3>Outsourcing</h3>
                </div>
                <div class="peragraph" style="font-size: 19px;">An ongoing partnership to accelerate digital transformation

                </div>
                <div class="buttons">
                    <a href="services/it-consulting/outsourcing">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section3" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/it-consulting/footer-banner.png?updatedAt=1679471777475) !important;">
    <div class="logoicon">
        <img src="assets/images/service/digitalmarkting/endurance_icon.png" alt="Endurance IT Services Favicon">
    </div>
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6" style="text-align: left !important;">
            <h3>Align your IT solutions with your business goals</h3>
            <p  style="text-align: left !important;">Success in achieving business results through digital transformation is founded and dependent upon hiring the right consultant. At Endurance IT, we bring years of experience in small, medium and enterprise business consulting across a wide variety of industries. On every project, we examine your business objectives and then identify how digital transformation technology can align with your priorities and goals to achieve results. Our goal is to take the weight of technology and project management off your shoulders. After our initial conversations, we present all options with costs and timelines to determine the best path forward.</p>
            <p  style="text-align: left !important;">We don’t want to just be a technology vendor - we want to be your most valued partner and consultant. We treat your business as if it were our own. That’s because our business success depends on your success.</p>
            <div _ngcontent-yuc-c94="" class="bbtnsbox">
                <a _ngcontent-yuc-c94="" href="/contact-us" class="btnsbox">let's talk about IT</a>
            </div>
        </div>
    </div>
</section>
