<i class="fa fa-close menuright closebuttoninservice"></i>
<h3 class="serviceheading"><a href="/services" style="    color: #D74B34 !Important;
    font-size: 1.8rem !important;
    font-weight: 700;">Services</a></h3>
<div class="servicesubmenu">
    <div>
        <h4>
            <a href="services/managed-services">Managed Services</a>
        </h4>
        <h4>
            <a href="services/it-staffing">IT Staffing</a>
        </h4>
        <ul style="display: none;">
            <li>
                <a href="services/it-staffing#ITsection4">Long Term</a>
            </li>
            <li>
                <a href="services/it-staffing#ITsection4">Direct Hire</a>
            </li>
            <li>
                <a href="services/it-staffing#ITsection4">Option To Hire</a>
            </li>
        </ul>
        <h4>
            <a href="services/infrastructure-services">Infrastructure Services</a>
        </h4>
        <ul>
            <li>
                <a href="services/infrastructure-services/computeserver">Compute/Server</a>
            </li>
            <li>
                <a href="services/infrastructure-services/data-storage">Data Storage</a>
            </li>
            <li>
                <a href="services/infrastructure-services/networking">Networking Solutions</a>
            </li>
            <li>
                <a href="services/infrastructure-services/virtualization">Data Virtualization</a>
            </li>
            <li>
                <a href="services/infrastructure-services/data-center">Data Center</a>
            </li>
            <li>
                <a href="services/infrastructure-services/security">Cybersecurity</a>
            </li>
            <li>
                <a href="services/infrastructure-services/disaster-recovery">Disaster Recovery</a>
            </li>
            <li>
                <a href="services/infrastructure-services/technology-assessments">Technology Assessments</a>
            </li>
        </ul>
        <h4>
            <a href="services/it-consulting">IT Consulting</a>
        </h4>
        <ul>
            <li>
                <a href="services/it-consulting/it-assessments">IT Assessment</a>
            </li>
            <li>
                <a href="services/it-consulting/it-leadership">IT Leadership</a>
            </li>
            <li>
                <a href="services/it-consulting/outsourcing">Outsourcing</a>
            </li>
        </ul>

    </div>
    <div>
        <h4>
            <a href="services/infrastructure-services/cloud">Cloud</a>
        </h4>
        <ul>
            <li>
                <a href="/services/cloud/public-cloud">Public Cloud</a>
            </li>
            <li>
                <a href="/services/cloud/private-cloud">
                    Private Cloud
                </a>
            </li>
            <li>
                <a href="/services/cloud/hybrid-cloud">
                    Hybrid Cloud
                </a>
            </li>
            <li>
                <a href="/services/cloud/cloud-consulting">
                    Cloud Consulting Services
                </a>
            </li>
            <li>
                <a href="/services/cloud/cloud-managed">
                    Cloud Managed Services
                </a>
            </li>
            <li>
                <a href="/services/cloud/cloud-hosted">
                    Cloud Hosted Services
                </a>
            </li>
            <li>
                <a href="/services/cloud/enterprise">
                    Enterprise Cloud
                </a>
            </li>
            <li>
                <a href="/services/cloud/smb-cloud">
                    SMB Cloud
                </a>
            </li>
        </ul>
        <h4>
            <a href="/services/project-management">Project Management</a>
        </h4>
        <ul>
            <li>
                <a href="services/project-management/project-recovery-solutions">Project Recovery Solutions</a>
            </li>
            <li>
                <a href="services/project-management/data-center-project-management">Data Center Project Management</a>
            </li>
        </ul>

        <h4>
            <a href="services/compliance-assessments">Compliance</a>
        </h4>
        <ul>
            <li>
                <a href="services/compliance-assessments/cmmc">CMMC</a>
            </li>
            <li>
                <a href="services/compliance-assessments/gdpr">GDPR</a>
            </li>
            <li>
                <a href="services/compliance-assessments/hipaa">HIPAA</a>
            </li>
            <li>
                <a href="services/compliance-assessments/pci">PCI</a>
            </li>
        </ul>
    </div>
    <div>
        <h4>
            <a href="/services/webdesign-and-development">Web Design and Development</a>
        </h4>
        <ul>
            <li>
                <a href="services/webdesign-and-development/ux-ui-design">UX/UI Design</a>
            </li>
            <li>
                <a href="services/webdesign-and-development/website-development">Website Development</a>
            </li>
            <li>
                <a href="services/webdesign-and-development/website-maintenance-and-security">Website Maintenance and Security</a>
            </li>
            <li style="display: none;" class="hide">
                <a href="services/webdesign-and-development/cloud-hosting">Cloud Hosting</a>
            </li>
        </ul>

        <h4>
            <a href="services/application-development">Application Development</a>
        </h4>
        <h4>
            <a href="services/branding">Branding</a>
        </h4>
        <h4>
            <a href="services/digital-marketing">Digital Marketing</a>
        </h4>
        <ul>
            <li>
                <a href="services/digital-marketing/seo">SEO (Search Engine Optimization)</a>
            </li>
            <li>
                <a href="services/digital-marketing/paid-advertisement">Paid Advertisement</a>
            </li>
            <li>
                <a href="services/digital-marketing/social-media-marketing">Social Media Marketing</a>
            </li>
            <li>
                <a href="services/digital-marketing/programmatic-ott-advertisement">Programmatic/OTT Advertisement</a>
            </li>
            <li>
                <a href="services/digital-marketing/email-marketing">Email Marketing</a>
            </li>
            <li>
                <a href="services/digital-marketing/analyticsreporting">Analytics</a>
            </li>
            <li>
                <a href="services/digital-marketing/online-reputation-management"> Online Brand Reputation Management</a>
            </li>
        </ul>
        <h4>
            <a href="services/digital-recruitment">Digital Recruitment</a>
        </h4>

    </div>
</div>
