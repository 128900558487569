<div class="row home-page-slider">
    <div class="col-md-4">
        <img alt="Small Business Solutions" class="homesliderMainIMG" src="https://ik.imagekit.io/b0cgaqv3j/1.png?updatedAt=1290804594415" style="content: url(https://ik.imagekit.io/b0cgaqv3j/1.png?updatedAt=12908045944151);">
    </div>
    <div class="col-md-8">
        <div class="container-fluid">
            <div class="row">
                <div class="icon-tab col-xs-12 col-sm-2 col-sm-offset-3 active col-md-3"  data-img="https://ik.imagekit.io/b0cgaqv3j/1.png?updatedAt=1290804594416" data-imgmobile="https://ik.imagekit.io/b0cgaqv3j/11.png?updatedAt=1690920909460">
                    <img alt="Small Business" src="https://ik.imagekit.io/b0cgaqv3j/Small%20B_2.png?updatedAt=1690825836489"> 
                    <span class="icon-label">Small Business</span>
                </div>
                <div class="icon-tab col-xs-12 col-sm-2 col-md-3" data-img="https://ik.imagekit.io/b0cgaqv3j/solutions/2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613788696" data-imgmobile="https://ik.imagekit.io/b0cgaqv3j/22.png?updatedAt=1690920909460">
                    <img alt="Medium Business" src="https://ik.imagekit.io/b0cgaqv3j/medium business_2.png?updatedAt=1690825836489">
                    <span class="icon-label">Medium Business</span>
                </div>
                <div class="icon-tab col-xs-12 col-sm-2 col-md-3" data-img="https://ik.imagekit.io/b0cgaqv3j/solutions/1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613788695" data-imgmobile="https://ik.imagekit.io/b0cgaqv3j/33.png?updatedAt=1690920909460">
                    <img alt="Enterprise" src="https://ik.imagekit.io/b0cgaqv3j/enterprise_2.png?updatedAt=1690825836489">
                    <span class="icon-label">Enterprise</span>
                </div>
                <div class="icon-tab col-xs-12 col-sm-2 col-md-3" data-img="https://ik.imagekit.io/b0cgaqv3j/solutions/4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613788811" data-imgmobile="https://ik.imagekit.io/b0cgaqv3j/44.png?updatedAt=1690920909460">
                    <img alt="Government Contractors" src="https://ik.imagekit.io/b0cgaqv3j/Government Contractors_2.png?updatedAt=1690825836489"> 
                    <span class="icon-label">Government Contractors</span>
                </div>
            </div>
        </div>
        <!-- Your elements -->
        <div class="item col-sm-10 col-sm-offset-1">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3>The Right Fit For
                        <span>Any Size
                            <br>Business
                        </span>
                    </h3>
                </div>
                <div class="panel-body">
                    <h3>Small Business</h3>
                    <p>World class IT and marketing support for even the smallest business. We treat you as a partner and deliver excellence as your trusted service provider.</p>
                    <a _ngcontent-hhf-c23="" href="/solutions/small-business" class="redbtn">learn more</a>
                </div>
            </div>
        </div>
        <div class="item col-sm-10 col-sm-offset-1">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3>The Right Fit For
                        <span>Any Size
                            <br>Business
                        </span>
                    </h3>
                </div>
                <div class="panel-body">
                    <h3>Medium Business</h3>
                    <p>Expert managed services and staffing solutions to solve your IT and marketing challenges. We work with you to grow your business to the next level.</p>
                    <a _ngcontent-hhf-c23="" href="/solutions/medium-business" class="redbtn">learn more</a>
                </div>
            </div>
        </div>
        <div class="item col-sm-10 col-sm-offset-1">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3>The Right Fit For
                        <span>Any Size
                            <br>Business
                        </span>
                    </h3>
                </div>
                <div class="panel-body">
                    <h3>Enterprise</h3>
                    <p>Enterprise-level IT infrastructure and marketing services. We integrate with your in-house teams to implement or advise on the latest digital solutions.</p>
                    <a _ngcontent-hhf-c23="" href="/solutions/enterprise" class="redbtn">learn more</a>
                </div>
            </div>
        </div>
        <div class="item col-sm-10 col-sm-offset-1">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <h3>The Right Fit For
                        <span>Any Size
                            <br>Business
                        </span>
                    </h3>
                </div>
                <div class="panel-body">
                    <h3>Government Contractors</h3>
                    <p>Specialist guidance and support with compliance regulations. We ensure you remain within the law and increase your prospects when bidding on contracts.</p>
                    <a _ngcontent-hhf-c23="" href="/solutions/government-contractors" class="redbtn">learn more</a>
                </div>
            </div>
        </div>
    </div>
</div>
