<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services"></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>Online <span>brand reputation <br>management</span></h1>
  <h4>Build trust with positive reviews and social media referrals</h4><div
    _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img alt="Increase trust, improve talent, reduce risk"
          src="/assets/images/service/onlinereputationmanagement/icon.jpg">
        <h2>Increase trust, improve <br><span>talent, & reduce risk</span></h2>

      </div>
      <div class="content">
        <p>
          Before the digital revolution, both satisfied and dissatisfied
          customers had a limited scope to influence your business reputation.
          But with the power of Google My Business, local business directory
          listings, social media, and online review sites, your past or present
          customers can significantly impact the buying decisions of new or
          potential customers. </p>
        <p>Research shows that 80% of customers put as much stock
          in an
          anonymous online review as they do in a referral from friends or
          family.</p>
        <p>
          Customer responses and reviews are everywhere. Besides storefronts or
          product pages, reviews also appear as star ratings alongside Google
          search results and PPC display ads. You can’t run from bad reviews,
          but with brand reputation management, you can confront and repair even
          the most sullied online reputations.
        </p>

      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6"><img alt="business reputation"
        src="https://ik.imagekit.io/b0cgaqv3j/service/onlinereputationmanagement/Rightside.jpg?updatedAt=1123456"></div>
  </div>
</section>
<section class="section2 mainredbar">
  <h3><strong>Online reputation: a ranking factor</strong></h3>

  <p>
    There is a strong correlation between local search results and customer
    reviews. The quantity and quality of both positive and negative reviews
    significantly impacts your ranking! If you are unable to manage your online
    brand reputation, your search engine rankings will take a major hit.
  </p>

</section>
<section class="section3" style="background: url(https://ik.imagekit.io/b0cgaqv3j/service/onlinereputationmanagement/footercontent.jpg?updatedAt=1679571s5) !important;background-repeat: no-repeat;padding: 8vw 10vw!important;background-size: cover !important;">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 style="color: #fff;">Reputation matters</h3>
      <p> Online reviews, star ratings, and social media recommendations
        serve as strong indicators that your brand is worthy of engagement.
        Professional-level brand reputation management ensures customers trust
        your business more than your competitors.</p>
      <p>Maintaining a successful brand requires knowing and managing your
        customers’ opinions of your business and its products/services. To grow
        your brand, you need an ever-vigilant agency partner who is committed to
        addressing issues quickly and professionally.</p>
      <p>Do you want a 5-star reputation that outshines the competition?</p>

      <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">GET STARTED
          NOW</a></div>
    </div></div>
</section>
