<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services"></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>Social <span>media <br>marketing</span></h1>
  <h4>Target potential customers and increase brand awareness</h4><div
    _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img
          src="/assets/images/service/socialmediamarkting/socialmedia-icon.jpg"
          alt="SMM">
        <h2> Social media ad management</h2>

      </div>
      <div class="content">
        <p>
          Facebook, Instagram, TikTok, YouTube, LinkedIn, and X provide a new level
          of granular targeting for your company. By taking advantage of social
          advertising (or social media marketing), your company can quickly
          benefit from hyper-local targeting.
        </p><h3>SMM offers a long list of benefits, such as:</h3>
        <ul>
          <li style="font-size: 16px !important;">Increased brand awareness</li>
          <li style="font-size: 16px !important;">Additional inbound
            traffic</li>
          <li style="font-size: 16px !important;">Enhanced search engine
            rankings</li>
          <li style="font-size: 16px !important;">Higher conversion rates</li>
          <li style="font-size: 16px !important;">Better customer
            satisfaction</li>
          <li style="font-size: 16px !important;">Improved brand loyalty</li>
          <li style="font-size: 16px !important;">More brand authority</li>
          <li style="font-size: 16px !important;">Greater
            cost-effectiveness</li>
          <li style="font-size: 16px !important;">Acquired marketplace
            insights</li>
          <li style="font-size: 16px !important;">Established thought
            leadership</li>
        </ul>

      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6"><img
        src="https://ik.imagekit.io/b0cgaqv3j/service/socialmediamarkting/Rightside.jpg?updatedAt=1679570601929"
        alt="Social media ad management"></div>
  </div>
</section>
<section class="section2">
  <h3><strong>Meta Business Suite advertising capabilities</strong></h3><br>
  <p>
    The average American spends 144 minutes, or 2 hours and 24 minutes, every
    day on social media platforms like Facebook and Instagram. This presents you
    with a perfectly matched, captive audience that is eager to receive your
    message. The granular nature of user data allows our agency to create
    audiences and target segments that are likely to be your ideal client base.<br></p>

    <h4>Below is a sample of the targeting capabilities available via
    Facebook/Instagram:</h4><br>
  <div class="bulletlist">
    <div class="borderw">
      <img src="/assets/images/service/socialmediamarkting/1.png"
        alt="Age, Gender (Male/Female/Other)">
      <h4>Age & Gender</h4>

    </div>
    <div class="borderw"><img
        src="/assets/images/service/socialmediamarkting/2.png"
        alt="Zip Code, City, & State">
      <h4>Zip Code, City, & State</h4>
    </div><div class="borderw"><img
        src="/assets/images/service/socialmediamarkting/3.png"
        alt="Industry & Title">
      <h4>Industry & Title</h4>
    </div><div class="borderw"><img
        src="/assets/images/service/socialmediamarkting/4.png" alt="Income">
      <h4>Income</h4>
    </div>
    <div><img src="/assets/images/service/socialmediamarkting/5.png"
        alt="Interests & Behavior">
      <h4>Interests & Behavior</h4>
    </div>
  </div>
  <p>Using data gathered from your website visitors, previous online customers,
    or converted leads, we can create a lookalike audience for your social media
    marketing campaign.</p>
</section>
<section class="section3">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>Level-up your marketing
        game</h3>
      <p>According to Social Media Examiner, a strategic leading SMM site, about
        96% of marketers are currently participating in social media marketing.
        However, 85% of those participants aren’t sure which tools to use, or
        how to use them.
      </p>
         <p>We can diminish that confusion by meticulously outlining the advantages and best practices of social media marketing to you and your team.
         </p><p>
          Ready to enhance your marketing results?
          </p>
      <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">GET IN TOUCH</a></div>
    </div></div>
</section>
