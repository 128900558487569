<header style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/mainbanner.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677581120393);">
    <div class="bgdhomheader monly">ss</div>
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" routerLink="/" ariaCurrentWhenActive="page" ><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>
            <app-nav></app-nav>

        </div>
    </nav>
    <div class="HeaderMainContents">
    <h3>Secure Your Assets, Boost Operational Efficiency &</h3>
    <h1>Elevate Your IT
        <br>Performance</h1>
    <h4>With our Managed IT Services, backed by an expert team, advanced help desk, predictable billing, cost control, and robust cybersecurity protocols.</h4>
    <a class="buttonboxes" (click)="openPopup()" >Let's Talk About IT</a>
  </div>
    <div class="casestudybgs"></div>
    <div class="casestudy-form">
     <p>Discover how we tranformed</p>
     <h3>The Kerner Group</h3>
     <p class="small">With Managed IT Services and Enhanced Cybersecurity</p>
     <app-case-study-form></app-case-study-form>
     <button class="closebtn">X</button>
    </div>
</header>
<app-popup *ngIf="isPopupOpen" (closePopup)="closePopup()"></app-popup>
