import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-new-service-request',
  templateUrl: './new-service-request.component.html',
  styleUrls: ['./new-service-request.component.css']
})
export class NewServiceRequestComponent implements OnInit {
    Results: any = [];


    constructor(private http: HttpClient,private MetaService: MetaServiceService) { }

    ngOnInit(): void {
        this.MetaService.createCanonicalURL();
    }
    logForm(value: any) {
        console.log(value);
        const url = '/wp/mail/contact.php';
        const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
        const body = { data: value };
        this.http.post<any>(url, body, { headers }).subscribe(data => {
            this.Results = data;
        });
    }

}
