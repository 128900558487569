<header class="serviceinner fixedheader"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/contact/banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677617746710);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1679916736866"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <div class="container">
    <h1>
      Thank You for Contacting<br> Endurance IT Services!
    </h1>
    <p><strong>We've received your message and are eager to explore how we can
        support your business needs.</strong><br>
      Expect to hear from us soon!
    </p>
    <h2>Need Immediate Assistance?</h2>
    <p>If you have urgent questions or wish to delve deeper, our sales team is
      ready to assist.<br> <strong>Call us directly at <a
          href="tel:(757) 720-0244">(757) 720-0244</a>.</strong>
    </p>
  </div>

</header>

<section class="section1">
  <div class="container">
    <div class="row">
      <div class="col-5 displaytable">
        <div class="middle">
          <h3>Why Choose Us?</h3>
          <p><strong>Discover now by watching this short video.</strong> Uncover
            the values driving
            us, our exceptional services,
            and the significant impact we make in our community-all while you
            wait.</p></div>
      </div>
      <div class="col-7 videoshow"> <iframe
          src="https://fast.wistia.net/embed/iframe/inkhus2cor?seo=false&videoFoam=false"
          title="Endurance IT Managed Service Provider in VA Video"
          allow="autoplay; fullscreen" allowtransparency="true" frameborder="0"
          scrolling="no" class="wistia_embed" name="wistia_embed"
          msallowfullscreen width="640" height="500"></iframe>
        <script src="https://fast.wistia.net/assets/external/E-v1.js"
          async></script></div>
    </div>
    <div class="row Accolades">
      <div class="col-5 displaytable">
        <h4>Recent Accolades:</h4>
      </div>
      <div class="col-7">

        <div class="row">
          <div class="col-3 icons">
            <img alt="SSAE19" src="/assets/images/icn/SSAE19.png">
          </div>
          <div class="col-3 icons">
            <img alt="Best place to work" src="/assets/images/icn/best.png">
          </div>
          <div class="col-3 icons">
            <img alt="Listed in 5000 INC" src="/assets/images/icn/inc.png">
          </div>
          <div class="col-3 icons">
            <img alt="Inside Business"
              src="/assets/images/icn/insidebusiness.png">
          </div>
        </div>

      </div>
    </div>
  </div>

</section>
<section class="section2">
  <div class="container">
    <div class="row">
      <div class="col-4 boxesstats br">
        <h3><span>16</span>
          Years of Excellence</h3>
      </div>
      <div class="col-4 boxesstats br">
        <h3><span>300+</span>
          Clients</h3>
      </div>
      <div class="col-4 boxesstats">
        <h3><span>2500+</span>
          Successful Projects</h3>
      </div>
    </div>
  </div>
</section>
<section class="section3">
  <div class="container">
    <div class="row ">
      <div class="col icons">
        <img alt="Inside Business"
          src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/CBN.png?updatedAt=1712692024658">
      </div>
      <div class="col icons">
        <img alt="Inside Business"
          src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/Newport.png?updatedAt=1712692024777">
      </div>
      <div class="col icons">
        <img alt="Inside Business"
          src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/Stihl.png?updatedAt=1712692024553">
      </div>
      <div class="col icons">
        <img alt="Inside Business"
          src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/ABNB.png?updatedAt=1712692024688">
      </div>
      <div class="col icons">
        <img alt="Inside Business"
          src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/Towne.png?updatedAt=1712693289288">
      </div>

    </div>
  </div>
</section>
<section class="section4">
  <div class="container">
    <h3>Hear From Our Clients</h3>
    <h4>See what our valued clients have to say about partnering with us.</h4>
    <div class="row commentsboxes">


      <div class="col-4" *ngFor="let post of clientTesttimonial" >
        <div class="commentsbox">
          <div class="rating">
            <img     alt="star"
              src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/Star.svg?updatedAt=1712695938200">
            <img alt="star"
              src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/Star.svg?updatedAt=1712695938200">
            <img alt="star"
              src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/Star.svg?updatedAt=1712695938200">
            <img alt="star"
              src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/Star.svg?updatedAt=1712695938200">
            <img alt="star"
              src="https://ik.imagekit.io/b0cgaqv3j/survey-thankyou/Star.svg?updatedAt=1712695938200">
          </div>
          <div class="comments"  [innerHTML]="post.testimonial.description">
            SL Nusbaum credits Endurance with providing stability and insightful IT management during our leadership transition.
          </div>
          <div class="auther" [innerHTML]="post.testimonial.name">
            {{post}}
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
