<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/contact/banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677617746710);">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1679916736866" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>
        Contact Endurance
    </h1>
    <h4>We appreciate your interest in Endurance. <br>Please select from the options on this page.</h4>
</header>
<section class="section1">
    <form #form="ngForm" (submit)="logForm(form.value)" id="contactform">
        <div class="loader"></div>
        <div class="row">
            <div class="col-sm-8 col-lg-8 col-md-8 bigbox">
                <h2 style="color: #d74b34;">How Can We Help You Today? </h2>
                <p style="font-size: 1rem; font-weight: 400;margin-top: 1.5rem;">Please select your inquiry type below and provide us with your contact details so we can tailor our response to fit your needs. </p>
                <h6 style="font-weight: 800;margin-top: 2rem;font-size: 1.1rem; display: none;">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h6>
                <div *ngIf="Results.flag==0" class="alert alert-danger" [innerHTML]="Results.msg">{{Results}}</div>
                <div *ngIf="Results.flag==1" class="alert alert-success" [innerHTML]="Results.msg">{{Results}}</div>
                <div class="colsbox1">
                    <div class="contactradio">
                      <label (click)="showForm(2)">
                        <input type="radio" name="usertype" ngModel value="New_Customer" [checked]="defaultChecked === 'option1'"> New Client Request
                    </label>
                    <label (click)="showForm(1)">
                        <input  type="radio" name="usertype" ngModel value="Existing_Client" > Current Client
                        </label>

                        <label (click)="showForm(3)">
                            <input type="radio" name="usertype" ngModel value="Others" > Other Requests
                        </label>
                    </div>
                    <div class="colsbox1" *ngIf="IsShowButton" style="padding-bottom: 2rem;">
                      <p style="font-size: 1rem; font-weight: 400;margin-top: 1.5rem;">Thank you for trusting Endurance. For immediate support, please click the button below to visit our support page, or call our help desk at <a style="color: #d74b34;" href="tel:+1-757-457-4800">(757) 457-4800</a> for direct assistance. Your satisfaction is our top priority.</p>
                      <div class="currentclientSupportButton">
                        <a href="/get-support">Help Desk Support</a>
                        <a href="/get-support/new-service-request">New Request</a></div>
                        <div style="clear: both;">
                        </div>
                    </div>
                </div>
                <div   *ngIf="!IsShowButton">
                  <div style="display: grid;grid-template-columns: 1fr 1fr;" class="colsbox2">
                    <div style="display: grid;grid-template-columns: 1fr;">
                        First Name*
                        <input name="firstname" ngModel type="text">
                    </div>
                    <div style="display: grid;grid-template-columns: 1fr;">
                        Last Name*
                        <input  name="lastname" ngModel >
                    </div>
                    <div style="display: grid;grid-template-columns: 1fr;">
                        Email*
                        <input  name="email" ngModel  type="email">
                    </div>
                    <div style="display: grid;grid-template-columns: 1fr;">
                        Callback number*
                        <input  name="callback_number" ngModel >
                    </div>
                 </div>
                 <div class="colsbox1" >
                    Company Name*
                    <input  type="text" name="company_name" ngModel />
                </div>
                <div class="colsbox1" >
                    Message*
                    <textarea  name="Message" ngModel ></textarea>
                </div>
                </div>

                <div *ngIf="Results.flag==0" class="alert alert-danger" [innerHTML]="Results.msg">{{Results}}</div>
                <div *ngIf="Results.flag==1" class="alert alert-success" [innerHTML]="Results.msg">{{Results}}</div>
                <div *ngIf="toDisplay==false" style="text-align:center;"  ><i style="font-size:2rem;  color: #000;" class="fa fa-spinner fa-spin"></i></div>
                <div class="colsbox2" *ngIf="!IsShowButton">
                    <div><input  name="Send_me_the_Endurance_IT_Newsletter" type="checkbox"  style="display: none;"><label  style="display: none;">Send me the Endurance IT Newsletter</label></div>
                    <button type="submit" style="width: max-content;">{{ButtonText}}</button>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4 col-md-4 smallboxes">
                <div class="smalls">
                    <h3>
                        <span>Ready</span> to talk?
                    </h3>
                    <div class="call">
                        <a href="tel:+1-757-379-8682">
                            <img src="assets/images/contact/phone-call-svgrepo-com.png" alt="Call At">
                        </a>
                        <br>
                        Make a call
                        <div>
                            <a href="tel:+1-757-379-8682">757-379-8682</a>
                        </div>
                    </div>
                    <div class="email">
                        <a href="mailto:saleseit@endurance-it.com">
                            <img alt="Our Email" src="assets/images/contact/noun-email-1012747.png">
                        </a>
                        <br>
                        Send an E-mail
                        <div>
                            <a href="mailto:saleseit@endurance-it.com">saleseit@endurance-it.com</a>
                        </div>
                    </div>
                    <div class="address">
                        <a target="_blank" href="https://goo.gl/maps/oHB9eU9qEGZ345Wq9">
                            <img src="assets/images/contact/noun-location-1138067.png" alt="Our Location">
                        </a>
                        <br>
                        Convergence I, 295 Bendix Road, Suite 300. Virginia Beach, VA 23452
                    </div>
                    <div class="socialnetwork">
                        <a href="https://www.facebook.com/enduranceitvirginia" target="_blank">
                            <img alt="Follow us on Facebook" src="assets/images/contact/fb.png">
                        </a>
                        <a href="https://www.linkedin.com/company/endurance-it-services/" target="_blank">
                            <img alt="Follow us on Linkedin" src="assets/images/contact/linked.png">
                        </a>
                        <a href="https://www.instagram.com/enduranceitservicesllc/" target="_blank">
                            <img src="assets/images/Instagram.png" alt="Folow Us on instagram">
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </form>
</section>
<section class="section3">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1081.1565866562044!2d-76.12629559290832!3d36.83384145097664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89babe32269c9f01%3A0xfe77e16f897c11b7!2sEndurance%20IT%20Services!5e0!3m2!1sen!2sin!4v1670727303162!5m2!1sen!2sin"
        width="600"
        height="450"
        style="border:0;"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
    </div>
</section>
