<header style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/error.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677581109324);">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <div class="serviceresponce">
        <h1>Service Desk</h1>
        <div class="row boxess">
            <div class="box">
                <a  target="_blank" href="https://endurance-it.hostedrmm.com:8040/"><div>
                    <img alt="Remote  Assistance" src="assets/images/support/1.png">
                </div></a><h3><a target="_blank"  href="https://endurance-it.hostedrmm.com:8040/">Remote <span>Assistance</span></a></h3>
            </div>
            <div class=" box">
                <a   href="/get-support/new-service-request"><div><img  alt="New Service Request"  src="assets/images/support/2.png">
                </div></a><h3><a target="_blank"  href="/get-support/new-service-request">New Service <span>Request</span></a></h3>
            </div>
            <div class="box">
                <a target="_blank"  href="https://tinyurl.com/4daef96k/"><div><img  alt="Service Board Sign In"  src="assets/images/support/3.png">
                </div></a><h3><a  target="_blank" href="https://tinyurl.com/4daef96k/">Service Board <span>Sign In</span></a></h3>
            </div>
            <div class=" box">
                <a  target="_blank" href="https://tinyurl.com/yw93mpmu"><div><img  alt="Network Dashboard"  src="assets/images/support/4.png">
                </div></a><h3><a target="_blank" href="https://tinyurl.com/yw93mpmu">Network <span>Dashboard</span></a></h3>
            </div>
            <div class=" box">
                <a href="tel:(757)457-4800"><div><img alt="Call us" class="Call us" src="assets/images/support/5.png"></div></a>
                <h3>Call <span><a href="tel:(757)457-4800">(757)457-4800</a></span></h3>
            </div>
        </div>
    </div>


</header>
