<app-solutions-header></app-solutions-header>
<h2 class="h2-beforecallouts">The Right Fit <span>For Any Size Business</span></h2>
<p class="p-beforecallouts">Endurance IT is your go-to partner for IT Staffing, Managed Services, IT Consulting and Digital Marketing. We work with you to identify and solve your unique business challenges. All of our IT and Digital Marketing solutions are scalable for whichever stage your business is at, and we’ll be with you on the journey as your business grows.</p>
<section class="callout-boxes">
    <div>
        <img alt="Nimble" src="/assets/images/call-out/Solutions/Nimble.png">
        <h4>Nimble</h4>
        <p>Quick and easy implementation to get you started fast</p>
    </div>
    <div>
        <img alt="Flexible" src="/assets/images/call-out/Solutions/Flexible.png">
        <h4>Flexible</h4>
        <p>Scalable, customized solutions for any size business</p>
    </div>
    <div>
        <img alt="Experienced" src="/assets/images/call-out/Solutions/Experience.png">
        <h4>Experienced</h4>
        <p>We provide the expertise and knowledge you need</p>
    </div>
</section>
<section class="solution-first">
    <div class="solutionboxs">
        <div class="solutionboxs1 solutionboxsxx">
            <a href="/solutions/small-business">
                <div class="hover">
                    <div class="withover">
                        <div>
                            <img alt="Small Business" src="/assets/images/solutions/SmallB.png">
                        </div>
                        <div class="heading">
                            Small
                            <br>
                            Business
                        </div>
                        <div class="headingc">World class support for even the smallest business</div>
                    </div>
                </div>
                <img alt="Small Business Solutions" src="https://ik.imagekit.io/b0cgaqv3j/1.png?updatedAt=169080459444">
                <div class="withoutover">
                    <div>
                        <img  alt="Small Business" src="/assets/images/solutions/SmallB.png">
                    </div>
                    <div>
                        Small
                        <br>
                        Business
                    </div>
                </div>
            </a>
        </div>
        <div class="solutionboxs2 solutionboxsxx">
            <a href="/solutions/medium-business">
                <div class="hover">
                    <div class="withover">
                        <div>
                            <img  alt="Medium Business"  src="/assets/images/solutions/Medium.png">
                        </div>
                        <div class="heading">
                            Medium
                            <br>
                            Business
                        </div>
                        <div class="headingc">Expert solutions to help you grow to the next level</div>
                    </div>
                </div>
                <img alt="Medium Business Solutions" src="https://ik.imagekit.io/b0cgaqv3j/solutions/2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613788696">
                <div class="withoutover">
                    <div>
                        <img  alt="Medium Business"  src="/assets/images/solutions/Medium.png">
                    </div>
                    <div>
                        Medium
                        <br>
                        Business
                    </div>
                </div>
            </a>
        </div>
        <div class="solutionboxs3 solutionboxsxx">
            <a href="/solutions/enterprise">
                <div class="hover">
                    <div class="withover">
                        <div>
                            <img  alt="Enterprise"  src="/assets/images/solutions/enterprise.png">
                        </div>
                        <div class="heading">Enterprise</div>
                        <div class="headingc">The latest digital solutions for enterprise operations</div>
                    </div>
                </div>
                <img alt="Enterprise Business Solution" src="https://ik.imagekit.io/b0cgaqv3j/solutions/1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613788695">
                <div class="withoutover">
                    <div>
                        <img  alt="Enterprise"  src="/assets/images/solutions/enterprise.png">
                    </div>
                    <div>Enterprise</div>
                </div>
            </a>
        </div>
        <div class="solutionboxs4 solutionboxsxx">
            <a href="/solutions/government-contractors">
                <div class="hover">
                    <div class="withover">
                        <div>
                            <img alt="Government Contractors"   src="/assets/images/solutions/government.png">
                        </div>
                        <div class="heading">
                            Government
                            <br>
                            Contractors
                        </div>
                        <div class="headingc">Specialist guidance and support with compliance</div>
                    </div>
                </div>
                <img alt="government contractor" src="https://ik.imagekit.io/b0cgaqv3j/solutions/4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613788811">
                <div class="withoutover">
                    <div>
                        <img alt="Government Contractors" src="/assets/images/solutions/government.png">
                    </div>
                    <div>
                        Government
                        <br>
                        Contractors
                    </div>
                </div>
            </a>
        </div>
    </div>
</section>
<section class="solution-second">
    <div class="logos">
        <img src="/assets/images/givenback/smalllogo2x.png" alt="Giving Back">
    </div>
    <div class="contentwithbutton">
        <div class="innercontent row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <h3>
                    Get 
                    started now
                </h3>
                <p>
                    If you want to learn more about our IT solutions for your business, or if you are ready to get started now,  get in touch with us today!
                    <br>
                    <br>
                    We look forward to speaking with you!
                </p>
                <div class="contactbbtun">
                    <a href="/contact-us" class="contactbtn">CONTACT US</a>
                </div>
            </div>
        </div>
    </div>
    <img alt="IT solutions for your business" src="https://ik.imagekit.io/b0cgaqv3j/solutions/solution2x.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613793376" class="footerbanner">
</section>
<app-form></app-form>
