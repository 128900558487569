import { Component } from '@angular/core';

@Component({
  selector: 'app-hire-cloud-engineers-thankyou',
  templateUrl: './hire-cloud-engineers-thankyou.component.html',
  styleUrls: ['./hire-cloud-engineers-thankyou.component.css']
})
export class HireCloudEngineersThankyouComponent {

}
