<header style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/mainbanner.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677581120393);">
    <div class="bgdhomheader monly">ss</div>
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" routerLink="/" ariaCurrentWhenActive="page" ><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>
            <app-nav></app-nav>

        </div>
    </nav>
    <h1>Evolve and stay competitive <span>through Digital Transformation</span></h1>
    <h4>Endurance IT focuses on your business, goals, and results. By offering IT Staffing, Managed Services, IT Consulting, Software Development, and Digital Marketing</h4>
    <a class="buttonboxes" routerLink="/solutions" ariaCurrentWhenActive="page"  style="color: #fff !important;">TRANSFORM IT NOW</a>
    <div class="bannerbottom">
        <div class="innerbannerbottom">
            <div class="columns1">
                <p>Recent Accolades</p>
            </div>
            <div class="columns2"><img alt="Botton Arrow" src="/assets/images/bottom-arrow.png"><br>Scroll</div>
            <div class="columns3">
                <div class="noicon"></div>
                <div class="icon icon1"><img alt="SSAE19" src="/assets/images/icn/SSAE19.png"></div>
                <div class="icon icon2"><img alt="Best place to work" src="/assets/images/icn/best.png"></div>
                <div class="icon icon3"><img alt="Listed in 5000 INC" src="/assets/images/icn/inc.png"></div>
                <div class="icon icon4"><img alt="Inside Business" src="/assets/images/icn/insidebusiness.png"></div>

            </div>
        </div>
    </div>
</header>
