<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/error.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677581109324);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Thank You for Reaching Out!
  </h1>
  <h4 style="width: 100% !important;margin-bottom: 2rem;">Your inquiry has been
    received, and we're grateful for your interest. Access the complete case
    study by clicking the button below. Should you have any further questions,
    don't hesitate to reach out. Thank you for considering Endurance IT Services.
  </h4>
  <a href="../../../assets/images/The Kerner Group Case Study_Download.pdf"
    download="casestudy.pdf" class="orangebtn"
    style="color:white;background-color:#d74b34;padding: 0.8rem 2.5rem;border-radius: 3rem;">Download
    Your Full Case Study Now</a><br>
    <a href="/contact-us"
     class="orangebtnlearnmore"
    style="color:white;background-color:#d74b34;border-radius: 3rem;">Learn More</a>
</header>
