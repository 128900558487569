import { Component, OnInit } from '@angular/core'; import { HttpClient } from '@angular/common/http';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
    toDisplay = true;
    slug: any = {};
    Results: any = [];
    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta) { }

    ngOnInit(): void {
    }
    toggleData() {
       
        this.toDisplay = false;
      }
    logForm(value: any) {
        this.toDisplay = false;
        const surl = this.router.url;
        // $('#detail-submit').hide();
            const url = '/wp/mail/project_Work_Requirement.php';
        const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
        const body = { data: value,url:surl };
        this.http.post<any>(url, body, { headers }).subscribe(data => {
            this.Results = data;
            this.toDisplay = true; 
            if(this.Results.flag==1){
                this.router.navigate(['/thank-you'])
            }
        });
    }

}
