<form>
    <div class="col2">
        <div>
            <label>First Name</label>
            <input type="text" name="firstname" />
        </div>
        <div>
            <label>Last Name</label>
            <input type="text" name="lastname" />
        </div>
        <div>
            <label>Email</label>
            <input type="mail" name="email"/>
        </div>
        <div>
            <label>Callback Number</label>
            <input type="tel" name="number"/>
        </div>
    </div>
    <div>
        <label>Company Name</label>
        <input type="text" name="company" />
    </div>
    <div>
        <label>Message</label>
        <textarea name="message"></textarea>
    </div>
    <input type="submit" value="Get Started" />
</form>