import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  newsRoom: any = [];
  SEO: any = [];
  p: number = 1;
  constructor(private http: HttpClient, private title: Title, private meta: Meta,private MetaService: MetaServiceService) { }
  ngOnInit(): void {
     
    const url = '/assets/seo/home.json?h=2';
    this.http.get(url).subscribe(seodata => {
      this.createLinkForCanonicalURL();
      this.SEO = seodata;
      console.log(this.SEO[0]);
      this.title.setTitle(this.SEO.meta_title);
      this.meta.updateTag({ name: 'description', content: this.SEO.meta_dec });
      this.meta.updateTag({ name: 'keyword', content: this.SEO.keyword });

      



    });

    // // this.title.setTitle('IT Support Company | Endurance IT Managed Service Provider in VA');
    // this.meta.updateTag({ name: 'og:title', content: 'Endurance IT Services' });
    // this.meta.updateTag({ name: 'og:image', content: '/assets/images/logo-sign.png' });
    // this.meta.updateTag({ name: 'og:description', content: 'Endurance IT Services is a locally owned IT Network Services firm specializing in serving businesses in Hampton Roads and the Commonwealth of Virginia.' });
    // this.meta.updateTag({ name: 'description', content: 'Endurance IT is one of the best managed service providers and IT support companies in Virginia. For a free consultation call us today at 757-379-8682.' });



    this.getNews();
  }
  createLinkForCanonicalURL() {
    this.MetaService.createCanonicalURL();
  }
  getNews() {
    const url = '/assets/news/newsdata.json?h=2';
    this.http.get(url).subscribe(res => {
      console.log(res, 'HI');
      this.newsRoom = res;
    });

  }
  onPageChange(p: number) {
    this.p = p;
  }

}
