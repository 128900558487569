import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';


@Component({
  selector: 'app-ed-ads-serveythankyou',
  templateUrl: './ed-ads-serveythankyou.component.html',
  styleUrls: ['./ed-ads-serveythankyou.component.css']
})
export class EdAdsServeythankyouComponent implements OnInit {
  clientTesttimonial: any = [];
  rand: number = 0;

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private MetaService: MetaServiceService) { }
  ngOnInit(): void {


    this.MetaService.createCanonicalURL();

    this.title.setTitle('Thank You for Contacting Endurance IT Services!');
    this.meta.updateTag({ name: 'description', content: "We've received your message and are eager to explore how we can support your business needs. Expect to hear from us soon!" });
    this.meta.updateTag({ name: 'author', content: 'Editorial Team' });
    this.meta.updateTag({ name: 'publisher', content: 'Endurance IT Services' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ property: 'og:title', content: 'Thank You for Contacting Endurance IT Services!' });
    this.meta.updateTag({ property: 'og:description', content: "We've received your message and are eager to explore how we can support your business needs. Expect to hear from us soon!" });






    this.rand = (Math.random() + 1);
    const url = '/assets/blog/ClientsTestimonial.json?v=' + this.rand;
    this.http.get(url).subscribe(res => {
      console.log(res, 'HI');
      this.clientTesttimonial = res;

    });
  }

}
