import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-lmanaged-services',
  templateUrl: './lmanaged-services.component.html',
  styleUrls: ['./lmanaged-services.component.css']
})
export class LManagedServicesComponent {
 
  SEO: any = [];
  constructor(private http: HttpClient, private title: Title, private meta: Meta, private MetaService: MetaServiceService) { }

  ngOnInit(): void {
    this.MetaService.createCanonicalURL();
    const url = '/assets/seo/managed-services-thankyou.json';
    this.http.get(url).subscribe(seodata => {

      this.SEO = seodata;
      console.log(this.SEO);
      this.title.setTitle(this.SEO.metadata['yoast_wpseo_title']);
      this.meta.updateTag({ name: 'description', content: this.SEO.metadata['yoast_wpseo_metadesc']  });
      this.meta.updateTag({ name: 'keyword', content: this.SEO.keyword });
    });
  }

}
