<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/hipaa/header-banner.png?updatedAt=1679565586220) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services"></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>HIPAA</h1>
  <h4>Adhere to national standards concerning <br>sensitive patient health
    information</h4><div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">Get in touch</a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img alt="What is HIPAA?"
          src="/assets/images/service/compliace-assessments/hipaa/icon.png">
        <h2>What is HIPAA?</h2>

      </div><div class="content">

        <p>The Health Insurance Portability and Accountability Act (HIPAA) is
          legislation that is firstly concerned with making it simpler for U.S.
          workers to retain health insurance coverage when they change or lose
          their jobs. Secondly, it aims to improve the efficiency of the U.S.
          healthcare system through improved electronic health records.</p>
        <p>With that comes a set of national guidelines to prevent the release
          of private patient health information without the patient’s knowledge
          or consent.
        </p>
        <p>Hospitals, suppliers of medical services, employer-sponsored health
          plans, research centers, and insurance firms that work directly with
          patients and their personal information are all covered by these
          rules.
        </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg"
        alt="HIPAA Compliance" style="height: auto !IMPORTANT;"
        src="https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/hipaa/Rightside.png?updatedAt=1679565586282"></div>
  </div>
</section>
<section class="section2 mainredbar" >

  <h3><strong>Do I need to be HIPAA compliant?</strong></h3>
    <p>Any company that has access to protected health information (PHI) needs
    to implement and follow the physical, network, and process security measures
    covered by HIPAA.</p>

</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/hipaa/footer-banner.png?updatedAt=1679565587936) !important;">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>Are you handling sensitive healthcare data?</h3>
      <p>Secure your organization’s future by taking the necessary steps to
        safeguard patient information.</p>
      <p>Let our experts guide you to compliance! Our tailored solutions will
        protect your patients’ privacy and your organization’s reputation by
        ensuring you meet all regulatory requirements.</p>
      <p>Protect your patients, protect your business – act now!</p>
      <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">GET
          COMPLIANT</a></div>
    </div></div>
</section>
