<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/banner%20paid.png?updatedAt=1706647623101) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="mainlogo"
          src="assets/images/logo.png"></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>Paid <span>advertisement</span></h1>
  <h4>Reach new customers and increase conversions quickly</h4>
  <div class="bbtnsbox">
    <a href="/contact-us" class="btnsbox">let's talk about IT </a></div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img
          src="/assets/images/service/paidadvertisement/PaidAdvertisement.jpg">
        <h2>Paid Advertisement:<br><span>How does it work?</span></h2>

      </div><div class="content">
        <p>
          After a thorough, in-depth audit, we’ll begin an account restructure
          and full mapping game plan that your internal team, existing digital
          agency, or our agency can implement.
        </p>
        <h3>
          Here’s a look at our process:
        </h3>
        <ul>
          <li style="font-size: 16px !important;">Immediate short-term
            recommendations from
            prior in-depth audit</li>
          <li style="font-size: 16px !important;">Long term recommendations from
            prior
            in-depth audit</li>
          <li style="font-size: 16px !important;">Campaign/category restructure
            map</li>
          <li style="font-size: 16px !important;">Ad group/sub-category
            restructure
            map</li>
          <li style="font-size: 16px !important;">Keyword build</li>
          <li style="font-size: 16px !important;">Ad creation</li>
          <li style="font-size: 16px !important;">Ad extension creation</li>
        </ul>
        <p>
          This process serves as your roadmap to building a cohesive Google Ads
          Campaign based on logic, extensive experience, and understanding of
          how to align your business cash flow and ROI needs with your Google
          Ads and business strategy.</p>

      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6"><img
        src="/assets/images/service/paidadvertisement/Rightside.jpg"></div>
  </div>
</section>
<section class="section2 mainredbar">
  <h3><strong>Google Ads Implementation</strong></h3>
  <p> Post-audit, we’ll provide a restructuring strategy map to show you
    exactly
    how we’ll redo your existing Google Ads campaigns. Here’s how we begin the
    implementation phase of the account restructures:
  </p>
  <div class="bulletlist">
    <div class="borderw">
      <ul>
        <li>Upload new campaigns, ad groups, ads, keywords, and urls</li>
        <li>Upload and confirm campaign settings</li>
        <li>Tailor to device types</li>
        <li>Identify and schedule campaign types (call only, standard,
          display)</li>
      </ul>
    </div>
    <div>
      <ul>
        <li>Create and implement ad extensions</li><!-- comment -->
        <li>Monitor performance of new structure </li>
        <li>Post-launch campaign keyword adjustments</li><!-- comment -->
        <li>Customized documentation & management
          guidelines</li><!-- comment -->

      </ul>
    </div>
  </div>
</section>
<section class="section3">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 style="color: #fff; font-size: 30.08px;">Improve your online
        campaign</h3>
      <p>We advocate for our clients – even the “potential” ones! Whether your
        internal team or our Endurance team implements your campaign
        restructuring, we still provide you with your customized management
        guidelines.</p>
      <p>Not sure where you stand? We’ll conduct a FREE Google Ad Account audit
        to help you figure that out. Request yours today!</p>

      <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">CLAIM MY
          AUDIT</a></div>
    </div></div>
</section>
