import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {
  SEO: any = [];
  Teams: any = [];
  constructor(private http: HttpClient, private title: Title, private meta: Meta,private MetaService: MetaServiceService) { }

  ngOnInit(): void {
    this.MetaService.createCanonicalURL();
    const url = '/assets/seo/our-team.json';
    this.http.get(url).subscribe(seodata => {

      this.SEO = seodata;
      console.log(this.SEO[0]);
      this.title.setTitle(this.SEO.meta_title);
      this.meta.updateTag({ name: 'description', content: this.SEO.meta_dec });
      this.meta.updateTag({ name: 'keyword', content: this.SEO.keyword });
    });

    const urls = '/assets/pages/liveourteam.json?v='+Math.floor(Math.random()*100000);
    this.http.get(urls).subscribe(teammebers => {

      this.Teams = teammebers;

    });

  }

}
