<header class="banner MainHeaderforlanding"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/LandingPageBanner.png?updatedAt=1686234003726);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a _ngcontent-serverapp-c61="" href="/" class="navbar-brand"><img _ngcontent-serverapp-c61=""
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services" class="mainlogo"></a>
      <div class="call"><img
          src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/phone_call.png?updatedAt=1686234842413">
          <a href="tel:(757) 571-8272">(757) 571-8272</a>
      </div>
    </div>
  </nav>
  <div class="headercontent">
    <h2>Leverage Our 15 Years of Expertise<br>
      for Your IT Staffing Advantage</h2>
      <div class="borderorange"></div>
    <p>We attract, engage, and prequalify top IT talent, empowering your hiring<br> managers to complete projects on
      time, within budget, and drive maximum <br>business value</p>
    <button class="callform ">schedule a call</button>
    <div class=" monly Companylogosslider">
      <h3>Our Clients:</h3>
      <ul class="Companylogos">
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/1.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/2.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/3.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/4.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/5.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/6.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/7.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/8.png?updatedAt=12">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/9.png?updatedAt=12">
        </li>
      </ul>

    </div>

  </div>
</header>
<div class="section1 donly">
  <div class="contents"><span> Our Clients:</span>
    <div class="">
      <ul class="companylogo">
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/1.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/2.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/3.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/4.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/5.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/6.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/7.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/8.png?updatedAt=1">
        </li>
        <li><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/CompanyLogo/9.png?updatedAt=1">
        </li>
      </ul>
    </div>
    <div style="clear: both;"></div>
  </div>

</div>
<div class="section2">
  <h3>Streamline Your Business with Our IT Staffing Solutions</h3>
</div>
<section class="callout-boxes">
  <div>
    <img alt="Nimble" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/Save_time.png?updatedAt=1686242180555">
    <h4>Save time</h4>
    <p>We handle the recruitment process, allowing you to focus on your core business. Our streamlined approach
      attracts, engages, and prequalifies top IT talent, saving you valuable time and resources.

    </p>
  </div>
  <div><img alt="Flexible"
      src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/reduce_cost.png?updatedAt=1686242181192">
    <h4>Reduce costs</h4>
    <p>Say goodbye to the high costs associated with hiring, onboarding, and training new staff. Our IT staffing
      solutions help you save on expenses while ensuring access to skilled professionals.
    </p>
  </div>
  <div>
    <img alt="Experienced"
      src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/Fill_skils.png?updatedAt=1686242180501">
    <h4>Fill skills gaps</h4>
    <p>Complete your team with our extensive network of specialist IT talent. Whether you need niche expertise or
      additional resources, we connect you with professionals who can fill the skills gaps in your organization. </p>
  </div>
</section>
<section class="section3">
  <h4>Your staffing needs, our expertise. From in-house teams to outsourced<br> solutions, we have the perfect fit for
    every project. </h4>
  <div class="threeboxes">
    <div>
      <h5><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/1.png?updatedAt=1686316221332">
        <div>Long-Term <br> Commitment:</div>
      </h5>
      <p>Our long-term staffing model ensures dedicated and committed employees who become an integral part of your
        business. Building lasting relationships, they gain a deep understanding of your customers and clients,
        fostering loyalty and delivering exceptional service. </p>
    </div>
    <div>
      <h5><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/2.png?updatedAt=1686316221332">
        <div>Direct Hire <br> Excellence:</div>
      </h5>
      <p>For rapid staffing solutions and efficient hiring processes, our direct hire model is the perfect choice. Our
        highly skilled staffers bring fresh perspectives and quickly make a positive impact on your business, helping
        you meet your goals effectively. </p>
    </div>
    <div>
      <h5><img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/3.png?updatedAt=1686316221332">
        <div>Try Before You <br> Hire:</div>
      </h5>
      <p>Our option-to-hire solution, also known as temp-to-hire, allows both parties to evaluate the fit before
        committing to full-time employment. This short-term staffing arrangement provides an opportunity for the
        employer to assess the staffer’s skills, personality, and compatibility with the team. At the end of the trial
        period, you have the flexibility to make an informed decision about permanent employment with benefits. </p>
    </div>
  </div>
  <div class="afterthreebox"><span>
      Choose the staffing model from above that aligns with your goals</span> and preferences and let us provide you
    with top-tier talent to drive your business forward
    <button class="callform">schedule a call</button>
  </div>
</section>
<section class="section4">
  <h3>Our IT Staffing and Staff Augmentation Process:</h3>
  <ul class="boxes3">
    <li>
      <img  class="donly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/1.png?updatedAt=1686317874614">
      <h3><img class="monly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/1.png?updatedAt=1686317874614"> Understanding your Needs</h3>
      <p>We first understand your business goals, technical requirements, and the type of professionals you need.</p>
    </li>
    <li>
      <img class="donly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/2.png?updatedAt=1686317874614">
      <h3><img class="monly" src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/2.png?updatedAt=1686317874614">Talent Sourcing and Screening</h3>
      <p>We source and prequalify top IT talents from our extensive network, ensuring they match your requirements.</p>
    </li>
    <li>
      <img class="donly"  src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/3.png?updatedAt=1686317874614">
      <h3><img class="monly"  src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/3.png?updatedAt=1686317874614">Technical Interview</h3>
      <p>Our inhouse team of IT experts will customize and test your candidates skill level to find the perfect match.
      </p>
    </li>
    <li>
      <img class="donly"  src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/4.png?updatedAt=1686317874614">
      <h3><img class="monly"  src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/4.png?updatedAt=1686317874614">Personal Interviews</h3>
      <p>Senior IT staffing specialists will facilitate face to face interviews and coordinate onsite discussions to
        ensure a seamless process for your team.</p>
    </li>
    <li>
      <img class="donly"  src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/5.png?updatedAt=1686317874614">
      <h3><img class="monly"  src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/5.png?updatedAt=1686317874614">Seamless Onboarding</h3>
      <p>We take care of the administrative tasks associated with onboarding new hires, ensuring a smooth transition.
      </p>
    </li>
    <li>
      <img class="donly"  src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/6.png?updatedAt=1686317874614">
      <h3><img class="monly"  src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/6.png?updatedAt=1686317874614">Continuous Support</h3>
      <p>A dedicated client success manager will be assigned to your account, providing continuous support and ensuring
        your complete satisfaction.</p>
    </li>
  </ul>
</section>
<section class="section5">
  <img src="https://ik.imagekit.io/b0cgaqv3j/LandingPage/Staffing/footerbanner.png?updatedAt=1686318619394">
  <div>
    <h3>Accelerate your business growth<br><span>with efficient, reliable, and affordable<br> staffing solutions.</span>
    </h3><button  class="callform">schedule a call</button>
  </div>
</section>
<section class="section6"><img src="https://ik.imagekit.io/b0cgaqv3j/logo-vertical.png?updatedAt=1677581119808" alt="Endurance IT Services">
  <div><a href="tel:(757) 571-8272">(757) 571-8272</a> | <a href="mailto:saleseit@endurance-it.com">saleseit@endurance-it.com</a></div>
  <div>Convergence I, 295 Bendix Road, Suite 300. Virginia Beach, VA 23452</div>
  <div _ngcontent-serverapp-c140="" id="social-icons"><a _ngcontent-serverapp-c140=""
      href="https://www.facebook.com/profile.php/?id=100063618251696" target="_blank"><i
        _ngcontent-serverapp-c140="" class="fa-brands fa-facebook-f"></i></a><a _ngcontent-serverapp-c140=""
      href="https://www.linkedin.com/company/endurance-it-services/" target="_blank"><i _ngcontent-serverapp-c140=""
        class="fa-brands fa-linkedin-in"></i></a><a _ngcontent-serverapp-c140=""
      href="https://www.instagram.com/enduranceitservicesllc/" target="_blank"><i _ngcontent-serverapp-c140=""
        class="fa-brands fa-instagram fa"></i></a></div>

</section>
<section class="section7">
  <div>
    <div _ngcontent-serverapp-c140="" id="reserved-section" class="row">
      <div _ngcontent-serverapp-c140="" class="col-4 termss"><a _ngcontent-serverapp-c140="" href="terms-condition">Terms &amp;
          conditions</a><a _ngcontent-serverapp-c140="" href="privacy-policy">Privacy policy</a></div>
      <div _ngcontent-serverapp-c140="" class="col-4 copyright"> @ 2023 <a href="/">Endurance IT</a>, All Rights Reserved. </div>
    </div>
  </div>
</section>
<button class="callform Stickybuts" style="display: none !important;">schedule a call</button>


<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h3 ><span >Ready</span> to talk? </h3>



        <button type="button" class="close" data-dismiss="modal">&times;</button>

      </div>
      <div class="modal-body">
        <div *ngIf="Results.flag==0" class="alert alert-danger" [innerHTML]="Results.msg">{{Results}}</div>
        <div *ngIf="Results.flag==1" class="alert alert-success" [innerHTML]="Results.msg">{{Results}}</div>
        <form #form="ngForm" (submit)="logForm(form.value)">
          <label>Name:*</label>
          <input type="text" ngModel name="Full_Name">
          <label>Company name:*</label>
          <input type="text" ngModel name="Company_Name">
          <label>Email:*</label>
          <input type="email" ngModel name="Email">
          <label>Phone:*</label>
          <input type="text" ngModel name="phone">
          <br>
          <button type="submit" class="savebtn callform">Submit</button>
        </form>
      </div>

    </div>

  </div>
</div>
