
<div class="timlinegrid">
    <div class="TimelineSHow">
        <div class="main1">
            <ul class="slider donly">
                <li>
                    <a>
                        <span>2008</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2009</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2010</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2011</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2012</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2013</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2014</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2015</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2016</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2017</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2018</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2019</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2020</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2021</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2022</span>
                    </a>
                </li>
            </ul>
            <ul class="slider1 monly" style="width: 100vw !important;">
                <li>
                    <a>
                        <span>2008</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2009</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2010</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2011</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2012</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2013</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2014</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2015</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2016</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2017</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2018</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2019</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2020</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2021</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span>2022</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="timelinedata">
        <ul>
            <li id="2008" class="selected">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>2</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Garage</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2" style="display: none !important;">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span></span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span>Core Values and Mission Statement established. </span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2009">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>5</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2" style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Garage</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>Hampton Road's Major IT Network Design Companies</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span></span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2010">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2" >
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>7</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2" style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Garage</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>Hampton Road's Top 10 Companies to Watch</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span></span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2011">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>9</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>
                                Rented office space at Office Space Solution,
                                    Corporation Lane, VB
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span></span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span></span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2012">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>16</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Purchased original office 4646 Princess Anne Road, VB 2500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>
                                Blake White HR's
                                    Entrepreneur of the Year
                                    Inc. 5000
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span>Acquired New IT Inc. - 2012</span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2013">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>32</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Purchased original office 4646 Princess Anne Road, VB 2500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>Best Places to Work in Virginia Inc. 5000</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span></span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2014">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>33</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Purchased original office 4646 Princess Anne Road, VB 2500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>
                                HR's Roaring 20 Companies
                                <br>
                                HR's Small Business of the Year
                                <br>
                                Best Places to Work in Virginia
                                <br>
                                Best Places to Work in Hampton Roads
                            Inc. 5000
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span>Acquired LeftBrain Systems - 2014</span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2015">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>36</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Purchased original office 4646 Princess Anne Road, VB 2500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>
                                Best Places to Work in Virginia
                                <br>
                                Best Places to Work in Hampton Roads
                            Inc. 5000
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span></span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2016">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2" style="display: none !important;">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left" >
                            <h3>36</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Purchased original office 4646 Princess Anne Road, VB 2500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>
                                HR's Roaring 20 Companies
                                <br>
                                Best Places to Work in Virginia
                                <br>
                                Best Places to Work in Hampton Roads
                            Inc. 5000
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span>Endurance IT Acquired Doran Consulting</span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2017">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>46</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Purchased original office 4646 Princess Anne Road, VB 2500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>
                                Best Places to Work in Virginia
                                <br>
                                Best Places to Work in Hampton Roads
                            Inc. 5000
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span></span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2018">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>45</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Moved into 295 Bendix Road, VB 8500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>
                                Best Places to Work in Virginia
                                <br>
                                Best Places to Work in Hampton Roads
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2" style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span>Have pictures of old office on award wall.</span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2019">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>38</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Moved into 295 Bendix Road, VB 8500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>
                                Best Places to Work in Virginia
                                <br>
                                Best Places to Work in Hampton Roads
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span>
                                Cetan Corporation's MS Practice - 2019
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2020">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>46</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Moved into 295 Bendix Road, VB 8500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span>Best Places to Work in Virginia</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span></span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2021">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>55</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Moved into 295 Bendix Road, VB 8500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span></span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span></span>
                        </div>
                    </div>
                </div>
            </li>
            <li id="2022">
                <div class="row">
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/employees.png" alt="Employees" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>75</h3>
                            <span>Employees</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/location.png" alt="Location" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Location</h3>
                            <span>Moved into 295 Bendix Road, VB 8500 sq ft</span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2"  style="display: none !important;">
                        <div>
                            <img src="/assets/images/awards.png" alt="Awards" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Awards</h3>
                            <span></span>
                        </div>
                    </div>
                    <div class="col-md-6 d-inline-flex p2">
                        <div>
                            <img src="/assets/images/Achievements.png"  alt="Achievements" class="p1">
                        </div>
                        <div class="p1" align="left">
                            <h3>Achievements</h3>
                            <span>SSAE 19 CIS Security Maturity Level 3.17<br>Listed on INC 5000</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
