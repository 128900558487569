<header class="serviceinner">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>
        Cloud
    </h1>
    <h4>Faster innovation and flexible <br>resources with the cloud</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="newheadingbox">
    <h2 class="h2-beforecallouts">Public, Private,<span> & Hybrid Cloud Solutions</span>
    </h2>
    <p class="p-beforecallouts" >On-demand delivery of IT resources </p>
</section>
<section class="callout-boxes">
    <div>
        <img  src="/assets/images/call-out/Cloud/effective.png" alt="Effective Cloud Solution">
        <h4>Effective</h4>
        <p>Cutting-edge cloud technology that’s right for your business</p>
    </div>
    <div>
        <img src="/assets/images/call-out/Cloud/affordable.png" alt="Affordable Cloud Solution">
        <h4>Affordable</h4>
        <p>Enterprise-level cloud infrastructure without the associated cost</p>
    </div>
    <div>
        <img src="/assets/images/call-out/Cloud/secure.png" alt="Secure Cloud Solution">
        <h4>Secure</h4>
        <p>Network security assessments based on industry best practices</p>
    </div>
</section>
<style>
  .NewgridBoxesCSS>div{
    position: relative;
  }
  .NewgridBoxesCSS>div .buttons{
    position: absolute;
    bottom: 0;
    width: 90%;
    text-align: center;
  }
</style>
<section class="section1">
    <div class="row">
        <div class="gridboxed NewgridBoxesCSS">
            <div class="borderR">
                <div class="icon">
                    <img src="assets/images/service/cloud/cloudmain/1.png" alt="Public Cloud Solutions">
                </div>
                <div class="heading">
                    <h3>Public <br><span>cloud</span>
                        </h3>

                </div>
                <div class="peragraph">On-demand resources to reduce IT costs</div>
                <div class="buttons">
                    <a href="services/cloud/public-cloud">Learn More</a>
                </div>
            </div>
            <div class="borderR">
                <div class="icon">
                    <img src="assets/images/service/cloud/cloudmain/5.png" alt="Private Cloud">
                </div>
                <div class="heading">
                    <h3>Private<br><span>cloud</span></h3>

                </div>
                <div class="peragraph">A secure environment to reduce the impact of human error
                </div>
                <div class="buttons">
                    <a href="services/cloud/private-cloud">Learn More</a>
                </div>
            </div>
            <div>
                <div class="icon">
                    <img src="assets/images/service/cloud/cloudmain/6.png" alt="Hybrid Cloud solutions for any size business">
                </div>
                <div class="heading">
                    <h3>Hybrid<br><span> cloud</span></h3>

                </div>
                <div class="peragraph">A mix of public and private cloud for improved operations
                </div>
                <div class="buttons">
                    <a href="services/cloud/hybrid-cloud">Learn More</a>
                </div>
            </div>
            <div class="borderR">
                <div class="icon">
                    <img src="assets/images/service/cloud/cloudmain/2.png" alt="Cloud Migration">
                </div>
                <div class="heading">
                    <h3>Cloud <br><span>Consulting Services</span></h3>

                </div>
                <div class="peragraph">Digital transformation expertise to accelerate growth
                </div>
                <div class="buttons">
                    <a href="services/cloud/cloud-consulting">Learn More</a>
                </div>
            </div>
            <div class="borderR">
                <div class="icon">
                    <img src="assets/images/service/cloud/cloudmain/3.png"  alt="Cloud solutions">
                </div>
                <div class="heading">
                    <h3>Cloud <br><span>Managed Services</span></h3>

                </div>
                <div class="peragraph">Reduce IT costs while maximizing value
                </div>
                <div class="buttons">
                    <a href="services/cloud/cloud-managed">Learn More</a>
                </div>
            </div>
            <div >
                <div class="icon">
                    <img src="assets/images/service/cloud/cloudmain/4.png" alt="Cloud Hosted Services">
                </div>
                <div class="heading">
                    <h3>Cloud <br><span>Hosted Services</span></h3>

                </div>
                <div class="peragraph">Reduce downtime and eliminate data loss
                </div>
                <div class="buttons">
                    <a href="services/cloud/cloud-hosted">Learn More</a>
                </div>
            </div>

            <div class="borderR">
                <div class="icon">
                    <img src="assets/images/service/cloud/cloudmain/7.png" alt="Enterprise Cloud">
                </div>
                <div class="heading">
                    <h3>Enterprise<br><span>cloud</span></h3>

                </div>
                <div class="peragraph">Expert strategy to modernize your technology stack
                </div>
                <div class="buttons">
                    <a href="services/cloud/enterprise">Learn More</a>
                </div>
            </div>
            <div>
                <div class="icon">
                    <img src="assets/images/service/cloud/cloudmain/8.png" alt="SMB Cloud">
                </div>
                <div class="heading">
                    <h3>SMB <br><span>cloud</span></h3>

                </div>
                <div class="peragraph">Flexible and personalized innovation for faster growth
                </div>
                <div class="buttons">
                    <a href="services/cloud/smb-cloud">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section3">
    <div class="logoicon">
        <img src="assets/images/service/cloud/cloudmain/endurance_icon.png" alt="Endurance IT Services Favicon">
    </div>
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6 bulletlist">
            <h3>Why Endurance?</h3>
            <p>
                With a fanatical approach to customer satisfaction, Endurance is a trusted expert in setting up and maintaining IT infrastructure for businesses in the Hampton Roads area.
                <br>
                Our team of network architects and security experts analyze the needs of your business to provide on-demand and ongoing IT infrastructure via the cloud, configuring resources to optimize your cloud computing and technology budget.
            </p>
            <p>
                We address issues and create opportunities for the following applications:
            </p>
            <ul class="cols22">
                <li>Security</li>
                <li>Web traffic patterns</li>
                <li>Data storage</li>
                <li>Automation</li>
                <li>Disaster recovery</li>
                <li>Virtual desktops</li>
            </ul>
            <p>No matter where your business is in the digital transformation journey, Endurance can provide a model that is robust, secure, and easy to manage.</p>
            <div class="bbtnsbox">
                <a href="/contact-us" class="btnsbox">LEARN MORE</a>
            </div>
        </div>
    </div>
</section>
