import { Component } from '@angular/core';

@Component({
  selector: 'app-kerner-group',
  templateUrl: './kerner-group.component.html',
  styleUrls: ['./kerner-group.component.css']
})
export class KernerGroupComponent {

}
