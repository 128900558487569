<app-giving-back-header></app-giving-back-header>
<section class="givingback-first">
    <h2>We value and support <span> Non-Profits</span></h2>
    <p>It’s our pleasure and honor to sponsor and participate in special events and sponsorships for non-profit organizations that selflessly serve our community. These organizations support members of our community such as disabled kids, adults and veterans through meaningful and therapeutic programs that make a significantly positive difference in their lives.</p>

    <p>Endurance IT President and Co-Founder, Blake White, is a Hampton Roads native (Norview High School & Old Dominion University) and is committed to our local community.</p>
</section>
<section class="givingback-second">
    <img class="smallthumb shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/givenback/Seria2x.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681820120" alt="Seria">
    <div>
        <img class="sitelogos" src="https://ik.imagekit.io/b0cgaqv3j/givenback/logoequikids.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681818144" alt="Equi-Kids">
        <h3>Equi-Kids <span>Therapeutic Riding Program</span></h3>
    <p>EQUI-KIDS is an equine therapy center founded in 1989. They are located on 92 beautiful acres off Sandbridge Road in the Heritage Park neighborhood of Virginia Beach. A herd of specialized horses of varying backgrounds and breeds brings proven healing to disabled children and adults from across the Hampton Roads region.</p>
</div>
</section>
<section class="givingback-third">
    <img class="smallthumb shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/givenback/Equi-vets2x.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681816322" alt="EquiVets">
    <div>
        <img class="sitelogos" src="https://ik.imagekit.io/b0cgaqv3j/givenback/Logoequivets.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681818731" alt="Equi Vets Logo">
        <h3>Equi-Vets</h3>
    <p>EQUI-VETS offers physical and mental healing through equine activities for armed service members wounded in the line of duty. Offering therapeutic horseback riding, horse care activities, and outdoor experiences, this program is offered to active duty and retired veterans at no cost in honor of their dedicated service to our country.</p>
</div>
</section>
<section class="givingback-fourth">
    <img class="smallthumb shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/givenback/buddy2x.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681816049" alt="Buddy">
    <div>
        <img class="sitelogos" src="https://ik.imagekit.io/b0cgaqv3j/givenback/buddylogo.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681815022" alt="Buddy Logo">
        <h3>Buddy BBall <span>League</span></h3>
    <p>The White family founded Buddy BBall, a program to provide children with special needs the opportunity to interact with a volunteer buddy, while working on basketball skills and having fun. Inspired by their oldest daughter, Jordyn, Buddy BBall is held on Sunday mornings at Landstown Middle School. There, you will find the White family along with volunteers and roughly 25 awesome kids ready to play!</p>
</div>
</section>
<section class="givingback-fifth">
    <h2>Here’s a peek at local Non-Profit organizations<span> and events that we support:</span></h2>
    <div class="givebackcols6">
    <ul>
        <li><a target="_blank"  href="https://www.alz.org/"><img style="background: url(https://ik.imagekit.io/b0cgaqv3j/givenback/events/1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681819868);" src="https://ik.imagekit.io/b0cgaqv3j/givenback/events/light.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681821918" alt="Light">Alzheimer’s<br> Association </a></li>
        <li><a target="_blank"  href="https://www.cancer.org/involved/fundraise/relay-for-life.html"><img style="background: url(https://ik.imagekit.io/b0cgaqv3j/givenback/events/2.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681819899);"  src="https://ik.imagekit.io/b0cgaqv3j/givenback/events/light.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681821918" alt="Light">Relay For Life<br> (Cancer Benefit)</a></li>
        <li><a target="_blank"  href="https://www.townebank.com/about-towne/community-involvement/"><img style="background: url(https://ik.imagekit.io/b0cgaqv3j/givenback/events/3.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681819802);"  src="https://ik.imagekit.io/b0cgaqv3j/givenback/events/light.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681821918" alt="Light">Towne Bank<br> Foundation</a></li>
        <li><a target="_blank"  href="https://www.unitedway.org/"><img style="background: url(https://ik.imagekit.io/b0cgaqv3j/givenback/events/4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681820042);"  src="https://ik.imagekit.io/b0cgaqv3j/givenback/events/light.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681821918" alt="Light">United Way</a></li>
        <li><a target="_blank"  href="https://chartwaypromisefoundation.org/chartway-promise-foundation.html"><img style="background: url(https://ik.imagekit.io/b0cgaqv3j/givenback/events/5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681820220);"  src="https://ik.imagekit.io/b0cgaqv3j/givenback/events/light.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681821918" alt="Light">We Promise</a></li>
        <li><a target="_blank" href="https://www.abnbfcu.org/"><img style="background: url(/assets/images/givenback/events/6.png);"  src="https://ik.imagekit.io/b0cgaqv3j/givenback/events/light.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677681821918" alt="Light">ABNB Community<br> Foundation</a></li>
    </ul>

</div>
</section>
<section class="givingback-six">
    <div class="logos"><img src="/assets/images/givenback/smalllogo2x.png" alt="Giving Back"></div>
    <div class="contentwithbutton">
       <div class="innercontent">
        <h2>If you’re interested in learning more about any <span>of these great organizations</span></h2>
        <div class="contactbbtun"><a href="/contact-us" class="contactbtn">CONTACT US TODAY</a></div>
    </div>
    </div>
    <!--<img src="/assets/images/givenback/footerBanner2x.png" class="footerbanner">-->

</section>
