<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>Web 
      <span>design 
        <br>and development</span>
  </h1>
  <h4>Improved online presence to increase engagement and sales</h4>
  <div class="bbtnsbox">
    <a href="/contact-us" class="btnsbox">let's talk about IT</a>
  </div>
</header>
<section class="newheadingbox">
  <h2>World Class
    <span> Web Design and Development</span>
  </h2>
  <p class="p-beforecallouts" style="display: none;">More engagement, more
    conversions, more sales </p>
</section>
<section class="callout-boxes">
  <div>
    <img src="/assets/images/call-out/WebDesignAndDevelopment/Engagement.png"
      alt="More Engagement">
    <h4>More Engagement</h4>
    <p>Keep visitors on your website with exceptional UI design.</p>
  </div>
  <div>
    <img src="/assets/images/call-out/WebDesignAndDevelopment/conversions.png"
      alt="More Conversions">
    <h4>More Conversions</h4>
    <p>Guide users toward your calls to action with clever UX.</p>
  </div>
  <div>
    <img src="/assets/images/call-out/WebDesignAndDevelopment/sales.png"
      alt="More Sales">
    <h4>More Sales</h4>
    <p>Generate leads with the content people are searching for.</p>
  </div>
</section>
<section class="section1">
  <div class="row">
    <div class="gridboxed">
      <div class="borderR">
        <div class="icon">
          <img src="assets/images/service/Web_Design_and_Development/1.png"
            alt="UX/UI Design Services">
        </div>
        <div class="heading">
          <h3>UX/UI
            <br>
            <span>Design</span>
          </h3>
        </div>
        <div class="peragraph" >Enhanced user
          experience to increase website conversions</div>
        <div class="buttons">
          <a href="/services/webdesign-and-development/ux-ui-design">Learn
            More</a>
        </div>
      </div>
      <div class="borderR">
        <div class="icon">
          <img src="assets/images/service/Web_Design_and_Development/2.png"
            alt="Website Maintenance and Security">
        </div>
        <div class="heading">
          <h3>Website
            <br>
            <span> Development</span>
          </h3>
        </div>
        <div class="peragraph" > Digital
          experiences that project authority, credibility and expertise
        </div>
        <div class="buttons">
          <a
            href="/services/webdesign-and-development/website-development">Learn
            More</a>
        </div>
      </div>
      <div>
        <div class="icon">
          <img src="assets/images/service/Web_Design_and_Development/4.png"
            alt="Expert UX/UI design">
        </div>
        <div class="heading">
          <h3>Website 
            <br>
            <span>Maintenance and Security</span>
          </h3>
        </div>
        <div class="peragraph" >Security
          management to eliminate risk of cyber attacks
        </div>
        <div class="buttons">
          <a
            href="/services/webdesign-and-development/website-maintenance-and-security">Learn
            More</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section2">
  <h3>Why choose Endurance for web design and development?</h3>
  <p>During the time when websites are competing in the race for visibility and
    engagement,<br> we help our clients stand out with show-stopping websites.
    We focus on the user experience to<br> create a website that beats your
    competition, hands-down. From color choice and<br> layout to iconography and UX,
    you’re going to love every aspect of your new website. </p>
</section>
<section class="section3">
  <div class="logoicon">
    <img src="assets/images/service/digitalmarkting/endurance_icon.png"
      alt="Endurance IT Services Favicon">
  </div>
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>Show
        off your website with confidence</h3>
      <p>The goal of any website is to promote business growth. As you drive
        traffic to your site, you want it to connect with your audience,
        generate leads, and produce sales.</p>
      <p>This is where Endurance comes in. We leverage the latest techniques in
        Conversion Rate Optimization (CRO) to generate leads and sales at a
        massive scale.</p><p>
        If you’ve ever felt embarrassed to have someone visit your website or
        felt the need to explain its “glitches,” it’s time for an upgrade.
      </p><p>
        Ready for a new website that reflects the quality of your business?
      </p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">let's talk about it</a>
      </div>
    </div>
  </div>
</section>
