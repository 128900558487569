import { Component, OnInit } from '@angular/core';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})
export class NewsDetailComponent implements OnInit {
  slug: any = {};

  // postData = {
  //   test:'my newsdetail page',
  // };


  detaildata: any = [];
  url: string | undefined;
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta,private MetaService: MetaServiceService) {
    //url='http://httpbin.org/post';


  }
  ngOnInit(): void {
    this.MetaService.createCanonicalURL();
   const surl = this.router.url.split('/');
    let slugname = surl[2];
    this.slug = slugname;
    this.url = '/assets/news/' + slugname+'.json';
    this.http.get(this.url).subscribe(res => {
      console.log(res, 'HI');
      this.detaildata = res;
      console.log(this.detaildata.result[0]);
      this.title.setTitle(this.detaildata.result[0].post_title+' | Endurance IT Services');
      this.meta.updateTag({ name: 'description', content: 'Checkout our latest post On '+this.detaildata.result[0].post_title+' from Endurance IT Services'});
      this.meta.updateTag({ name: 'keyword', content: this.detaildata.result[0].Seo._yoast_wpseo_focuskw });
    });

  }
  
}