import { Component } from '@angular/core';

@Component({
  selector: 'app-optin-form',
  templateUrl: './optin-form.component.html',
  styleUrls: ['./optin-form.component.css']
})
export class OptinFormComponent {

}
