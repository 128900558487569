<header class="serviceinner fixedheader" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/contact/banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677617746710);">
  <nav class="navbar navbar-expand-sm">
      <div class="container-fluid">
          <a class="navbar-brand" href="/">
              <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1679916736866" alt="Endurqance IT Services">
          </a>
          <app-nav></app-nav>
      </div>
  </nav>
  <h1>
      Survey Endurance
  </h1>
  <h4></h4>
</header>


<section class="section1">
  <div class="Headercontent" >
    <h2>Quick 30-Second Survey: <br>Help Us Help You Better!</h2>
    <p><strong>Thank you for reaching out!</strong> Please take a moment to answer the four quick multiple-choice questions below. Your responses will help us route and process your request accordingly.</p>
  </div>
  <form #form="ngForm" (submit)="logForm(form.value)" id="contactform">
     <div class="loader"></div>
      <div class="row">
          <div class="WizartForm">
              <div>
                <div class="colsbox1">
                  <div *ngIf="NewResults.flag==0" class="alert alert-danger" [innerHTML]="NewResults.msg">{{NewResults}}</div>
                  <div *ngIf="toDisplay==false" style="text-align:center;"  ><i style="font-size:2rem;  color: #000;" class="fa fa-spinner fa-spin"></i></div>
                  <div class="step1" *ngIf="Results.current_step==1">
                    <h3>What services are you interested in? (Select all that apply)*</h3>
                      <ul>
                        <li><label><input name="serviceinterested" (click)="StepSet('step1','Managed IT Services','checkbox')"  ngModel type="checkbox">Managed IT Services</label></li>
                        <li><label><input name="serviceinterested" (click)="StepSet('step1','IT Staffing','checkbox')" value="IT Staffing" ngModel type="checkbox">IT Staffing</label></li>
                        <li><label><input name="serviceinterested" (click)="StepSet('step1','IT Consulting','checkbox')"  value="IT Consulting" ngModel type="checkbox">IT Consulting</label></li>
                        <li><label><input name="serviceinterested" (click)="StepSet('step1','Compliance Assessment','checkbox')"   value="Compliance Assessment" ngModel type="checkbox">Compliance Assessment</label></li>
                        <li><label><input name="serviceinterested" (click)="StepSet('step1','Infrastructure Services','checkbox')" value="Infrastructure Services" ngModel type="checkbox">Infrastructure Services</label></li>
                        <li><label><input name="serviceinterested"  (click)="StepSet('step1','App Development','checkbox')"  value="App Development" ngModel type="checkbox">App Development</label></li>
                        <li><label><input name="serviceinterested" (click)="StepSet('step1','Web Development','checkbox')"  value="Web Development" ngModel type="checkbox">Web Development</label></li>
                        <li><label><input name="serviceinterested" (click)="StepSet('step1','Digital Marketing','checkbox')"  value="Digital Marketing" ngModel type="checkbox">Digital Marketing</label></li>
                      </ul>
                  </div>
                  <div class="step2" *ngIf="Results.current_step==2">
                    <h3>How soon are you looking to get started?</h3>
                      <ul>
                        <li><label><input name="getstarted" (click)="StepSet('step2','Immediately','radio')" value="Immediately" ngModel type="radio">Immediately</label></li>
                        <li><label><input name="getstarted" (click)="StepSet('step2','Within the next month','radio')" value="Within the next month" ngModel type="radio">Within the next month</label></li>
                        <li><label><input name="getstarted" (click)="StepSet('step2','Just researching options','radio')" value="Just researching options"  ngModel type="radio">Just researching options</label></li>
                      </ul>
                  </div>
                  <div class="step3" *ngIf="Results.current_step==3">
                    <h3>Are you the decision-maker?</h3>
                      <ul>
                        <li><label><input name="decisionmaker" (click)="StepSet('step3','Yes','radio')" value="Yes" ngModel type="radio">Yes</label></li>
                        <li><label><input name="decisionmaker" (click)="StepSet('step3','No, but I influence decisions','radio')"   value="No, but I influence decisions"  ngModel type="radio">No, but I influence decisions</label></li>
                        <li><label><input name="decisionmaker" (click)="StepSet('step3','No, I am gathering information for someone else','radio')"  value="No, I am gathering information for someone else" ngModel type="radio">No, I am gathering information for someone else</label></li>
                      </ul>
                  </div>
               </div>
              </div>
          </div>
          <div class="WizartButtons">
            <div class="Wizloader">
              Showing {{Results.current_step}} of {{Results.totalsteps}}
              <div class="loaderblock donly">
                <div style="min-width: {{Results.totalLoading}}% !important;" class="loaderfill">{{Results.totalLoading}}%</div>
              </div>
            </div>
            <button>{{ButtonText}}</button>
          </div>
          <div class="loaderblock monly">
            <div style="min-width: {{Results.totalLoading}}% !important; max-width: {{Results.totalLoading}}% !important;" class="loaderfill">{{Results.totalLoading}}%</div>
          </div>
      </div>
  </form>
  <div style="text-align: center; margin-top: 2rem;"><p style="color:#1c345c!important; font-family: MontserratSB;">We appreciate your cooperation and look forward to assisting you!</p></div>
</section>



