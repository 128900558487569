import { Component, OnInit } from '@angular/core';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-option-to-hire',
  templateUrl: './option-to-hire.component.html',
  styleUrls: ['./option-to-hire.component.css']
})
export class OptionToHireComponent implements OnInit {

  constructor(private MetaService: MetaServiceService) { }

  ngOnInit(): void {this.MetaService.createCanonicalURL();
  }

}
