<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/analyticsreporting/banner.jpg?updatedAt=1679571191549);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Analytics

  </h1>
  <h4>
    In-depth campaign data to optimize your marketing investment
  </h4>
  <div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about
      IT</a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/analyticsreporting/icon.jpg"
          alt="Analytics and reporting services Solutions">
        <h2>
          Analytics and reporting
          <span>services</span>
        </h2>
      </div>
      <div class="content">
        <div class="cols22">
          <img src="/assets/images/service/analyticsreporting/84.png"
            alt="84% of marketers">
          <H3>84% of marketers can’t measure and report on the contribution of
            their programs to the business</H3>
        </div>
        <div class="cols22">
          <img src="/assets/images/service/analyticsreporting/75.png"
            alt="22% of marketers">
          <H3>Only 22% of marketers say they have data-driven marketing
            initiatives that are achieving significant results</H3>
        </div>
        <p>
          When you have better data, you can make better decisions. Knowing your
          customers and understanding what drives the success of your marketing
          campaigns requires collecting and analyzing data from multiple, often
          disconnected sources. At Endurance, we identify the right combination
          of data to accurately target your clients and report on the results in
          a way that drives your business forward.
        </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/service/analyticsreporting/Rightside.jpg?updatedAt=1679571192247"
        alt="Analytics and reporting services">
    </div>
  </div>
</section>
<section class="section2 mainredbar">
  <h3><strong>Did you know…</strong></h3><br>

    <p>Companies that embrace data-backed insights are in a better position to
      outperform their competitors! At Endurance, our skilled analysts dissect
      the data to simplify campaigns and make every dollar of your marketing
      budget count.</p>

</section>
<section class="accordian-section">
  <h3>Endurance provides next-level analytics and reporting</h3>
  <div class="accordion" id="accordionPanelsStayOpenExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
        <button class="accordion-button collapsed" type="button"
          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne"
          aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
          <img src="/assets/images/service/analyticsreporting/point1.jpg"
            alt="Geofencing Solution">
          <div>
            <h4>Custom Reports and Data Points</h4>
            <!--<span>Deliver your message everywhere your customers go
                      </span>-->
          </div>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse "
        aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body">
          <div>
            <p>
              Our custom reports and dashboards provide real-time updates on
              your website analytics and campaign KPIs through
              easy-to-understand data visualization. Bringing in multiple data
              points via API, you’ll be able to see at a glance how your digital
              marketing strategies are performing so that you get your most
              critical data the moment you need it. No more waiting until the
              beginning of the next month or quarter!
            </p>
            <p>
              We can track most standard marketing channels via a single,
              convenient dashboard.
            </p>
            <p>Those channels include, but are not limited to:</p>
            <div class="bulletlistr">
              <ul>
                <li>Google Analytics</li>
                <li>Bing Ads</li>
                <li>SEO Channels</li>

                <li>Facebook / Instagram / TikTok</li>
                <li>Programmatic Display (Geofencing, Site Retargeting)</li>
                <li>Google My Business</li>
                <li>Google Ads / Display / YouTube</li>
              </ul>
            </div><br>
            <p>
              Custom reports help you move from reactive mode to proactive mode
              by providing relevant, real-time insights.
            </p>
            <p>
              If you’re ready to gain a deeper understanding of your customers
              through reports and data points, let’s chat!
            </p>
            <a class="buttonsbox" href="/contact-us">let's talk about it</a>
          </div>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
        <button class="accordion-button collapsed" type="button"
          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo"
          aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
          <img src="/assets/images/service/analyticsreporting/point2.jpg"
            alt="Programatic Audio">
          <div>
            <h4>Conversion Rate Optimization</h4>
            <!--<span>Highly targeted ads powered by automation</span>-->
          </div>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
        aria-labelledby="panelsStayOpen-headingTwo">
        <div class="accordion-body">
          <div>
            <p>
              The systematic process of increasing your online conversion rates
              through rigorous and scientific testing is referred to as
              Conversion
              Rate Optimization. Done right, CRO drives more sales and produces
              more leads, even without spending additional money on advertising.
            </p>
            <p>
              Advertising costs are on the rise, increasing by 30% annually.
              These rising costs can erode your return on investment. While it
              may be tempting to increase advertising budgets to strongarm
              results, there is a better, more sustainable way.
            </p>
            <p>
              Our approach encompasses the full view of the customer journey,
              from
              awareness to action. Unlike other digital marketing agencies, we
              don’t rely solely on best practices. Instead, we fix the root
              cause of low conversion rates, leveraging behavioral science
              techniques to nudge customers into action. The result? A website
              that’s a conversion machine.
            </p>
            <p>Do you have questions about how Endurance can improve your
              conversion
              rates?</p>
            <a class="buttonsbox" href="/contact-us">let's talk about it</a>
          </div>

        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingThree">
        <button class="accordion-button collapsed" type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
          aria-controls="panelsStayOpen-collapseThree">
          <img src="/assets/images/service/analyticsreporting/point3.jpg"
            alt="Programmatic Display Advertisement">
          <div>
            <h4>Heatmap Analytics
            </h4>
            <!--<span>Harness the power of real-time bidding to stretch your ad </span>-->
          </div>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
        aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body">
          <div>
            <p>
              Heatmaps are 2D data visualizations that use color to represent
              various values and actions. They provide key insights into user
            behavior on specific web pages and sections. Heatmaps can be
            used to show where users have clicked on a page, how far they have
            scrolled, and even where their eyes have traveled.
            </p>
            <p>
              There are three key benefits to leveraging heatmap analytics to
              improve
              your website’s conversion rate:
            </p>
            <div class="bulletlistr">
              <ul>
                <li>Provide an instant overview of high-priority KPIs</li>
                <li>Provide a visual approach to understanding numeric
                  values</li>
                <li>
                  Provide user insights to drive improved web design
                </li>
              </ul>
            </div><br>
            <p>
              Making informed web design choices will boost engagement and
              conversion rates, leading to more sales. This is possible through
              the advanced insights that heatmaps provide.
            </p>
            <p>Ready to utilize the power of heatmap analytics to drive business
              results?</p><a
              class="buttonsbox" href="/contact-us">let's talk about it</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/analyticsreporting/footercontent.jpg?updatedAt=1679571190857);">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6 bulletlist">
      <h3 style="color: #fff;">Harness the full potential of your data</h3>
      <p>
        From real-time dashboards to comprehensive reports, Endurance will
        revolutionize the way you understand and act on your insights. Turn your
        data into a strategic advantage – so you can navigate the complexities
        of your business landscape with confidence.

      </p>
      <p>
        Let’s embark on this data-driven journey together!
      </p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">GET STARTED</a>
      </div>
    </div>
  </div>
</section>
