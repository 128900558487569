<div class="gsh-section">
    <div class="row box1">
        <div class="col-md-4" align="center"><img src="/assets/images/growth.png" alt="Growth"></div>
        <div class="col-md-8 ">
            <h3>Growth</h3>
            <p>Endurance's leaders will manage their growth well to ensure all clients are serviced at the highest level possible.</p>
        </div>
    </div>
    <div class="row box2">
        <div class="col-md-4" align="center"><img src="/assets/images/strength.png" alt="Strength"></div>
        <div class="col-md-8">
            <h3>Strength</h3>
            <p>Endurance's company strength will come from its careful selection of IT Consultants. Expert technical skills and customer service skills are equally important to provide an exceptional customer experience. We ensure our consultants have both
                of these essential attributes.</p><span></span>
        </div>
    </div>
    <div class="row box3">
        <div class="col-md-4" align="center"><img src="/assets/images/harmony.png" alt="Harmony"></div>
        <div class="col-md-8">
            <h3>Harmony</h3>
            <p>Endurance's internal motto is "Always do what's right for the Client and for the Staff." When you combine these core beliefs, the end result is a supportive work environment and a satisfied client base.</p>
            <span></span>
        </div>
    </div>
</div>