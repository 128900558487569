<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/OurClient/assets-images-OurClients-banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677617372059)">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>
        Our <span>clients</span>
    </h1>
    <h4>How we help the businesses we partner with    </h4><div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">FIND OUT MORE</a></div>
</header>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6 leftpadding">
            <div class="heading">
                <img alt="What our clients have to say" src="/assets/images/OurClients/service-icon.png">
                <h2>
                    What our clients <br><span>
                        have to say</span>
                </h2>
            </div>
            <div class="content">
                <p>At Endurance IT, we deeply value the voices of our clients. Their feedback is the cornerstone of our continuous growth and dedication to excellence. We invite you to explore the authentic testimonials that showcase how our collaboration has fostered innovation, efficiency, and success in diverse industries.</p></div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <img alt="What our clients have to say" src="https://ik.imagekit.io/b0cgaqv3j/OurClient/rightside.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677617376561">
        </div>
    </div>
</section>
<section class="section2">
    <div class="iconsshowbox"><img alt="What Our Client Says" src="/assets/images/OurClients/icons.png"></div>
    <ul class="clientsTestmonial">
        <li><div>
            <h3>For our first major systems upgrade in 10 years we set out to find the right business partner to guarantee our success. We found the answer in Endurance. Through design, acquisition, implementation and training, the professionals at ENDURANCE were there every step of the way. Thank you for your part in ensuring our success!  </h3>
            <h4>Law Firm  (Virginia Beach, Chesapeake and Suffolk)</h4></div>
        </li><!-- comment -->
        <li> <div>
            <h3>Endurance’s Network Team designed and built a reliable and robust Cisco network that has met all of our needs.</h3>
            <h4>Government Contractor (Newport News)</h4></div>
        </li><!-- comment -->
        <li> <div>
            <h3>Thanks for sending a consultant to bridge the gap between the engineering and development skillsets. His ability to understand the technical side and then develop a way to present the data in a format that can be easily understood is unique. We hope to have him for a long time.</h3>
            <h4>Government Contractor (Virginia Beach)</h4></div>
        </li><!-- comment -->
        <li> <div>
            <h3>Endurance helped us over a really difficult time with our network issues with professional skills and expertise we had been unable to locate in the Hampton Roads area. We plan to work with Endurance for many years!</h3>
            <h4>Architecture Firm (Norfolk)</h4></div>
        </li>
        <li> <div>
            <h3>Quality of service and personalized attention is what we expect from a consultant. That’s exactly what we get from Endurance and more!</h3>
            <h4>Accounting Firm (Norfolk)</h4></div>
        </li>
        <li> <div>
            <h3>Endurance’s virtual server and storage solution has allowed us to run our company more efficiently and with less hardware costs!</h3>
            <h4>Transportation Company (Chesapeake)</h4></div>
        </li><!-- comment -->
        <li> <div>
            <h3>When switching IT firms, it was important that the transition be smooth with little to no downtime. Endurance was able to handle our transition with no disruption to our operations and was results oriented.</h3>
            <h4>Construction Company (Chesapeake)</h4></div>
        </li>
        <li> <div>
            <h3>Endurance’s legacy knowledge of our network allowed SL Nusbaum to make smart choices for the future, while remaining healthy and stable for the daily demands of our clients and employees. We offer a high recommendation for Endurance as they are a trusted partner to our firm.</h3>
            <h4>Real Estate Company  (Norfolk)</h4></div>
        </li>
    </ul>


</section>

<section class="section3" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/OurClient/footerbanner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677617373215) !Important;">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3 class="colorw">Time for a new IT partner?
            </h3>
            <p>Get in touch with Endurance for an overview of<br><!-- comment -->
                how we can help you transform your IT and <br>grow your business.</p>
            <div class="bbtnsbox">
                <a href="/contact-us" class="btnsbox">LET’S TALK ABOUT IT</a>
            </div>
        </div>
    </div>
</section>

