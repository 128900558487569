<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/error.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677581109324);">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>
        Thank you
    </h1>
    <h4 style="width: 100% !important;">Your message has been sent.<br>
        One of our representatives will get back to you in next 24-48 hours.</h4>
</header>
