<header style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/solutions/inner/Enterprise/Banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677613806585) !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Enterprise</h1>
    <h4>Stay ahead of your competitors <br>with the latest digital capabilities</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header><section class="firstsection row">
    <div class="col-md-6"><img alt="IT and Digital Marketing for enterprise organizations" src="https://ik.imagekit.io/b0cgaqv3j/solutions/inner/Enterprise/leftside.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677613806615"></div>
    <div class="col-md-6"><br><br>
        <div class="row">
            <div class="col-md-2"><img alt="Enterprise" src="assets/images/solutions/inner/Enterprise/Enterprise.png"></div>
            <div class="col-md-10 heading">
                <h2> IT and Digital Marketing for enterprise organizations</h2>
                <h3>The right fit for any size business</h3>
            </div>
        </div>
        <p>We are ready to take on your IT and Digital Marketing challenges. We know you need a partner who understands the newest digital capabilities, enterprise infrastructure solutions and who can work with existing in-house teams.</p>
    </div>
</section>
<section class="secondsection" style="padding: 5vw 15vw;">
    <div class="innericons" style="grid-template-columns: 1fr 1fr 1fr 1fr 1fr;">
        <div><div class="icons"><img  alt="consulting" src="assets/images/solutions/inner/Enterprise/consulting.png"></div><h4>IT  <br> Consulting</h4><span><a href="/services/it-consulting">More</a> </span></div>
        <div><div class="icons"><img  alt="staffing" src="assets/images/solutions/inner/Enterprise/staffing.png"></div><h4>IT <br>  Staffing</h4><span><a href="/services/it-staffing">More</a> </span></div>
        <div><div class="icons"><img  alt="it projects" src="assets/images/solutions/inner/Enterprise/it projects.png"></div><h4>IT  <br> Projects</h4><span><a href="/services/project-management">More</a> </span></div>
        <div><div class="icons"><img  alt="digital marketing" src="assets/images/solutions/inner/Enterprise/digital marketing.png"></div><h4>Digital  <br> Marketing</h4><span><a href="/services/digital-marketing">More</a> </span></div>
        <div><div class="icons"><img  alt="web development" src="assets/images/solutions/inner/Enterprise/web development.png"></div><h4>Web/App  <br> Development</h4><span><a href="/services/application-development">More</a> </span></div>
    </div>
</section>
<section class="thiredsection ">
    <div class="contentwithbutton">
        <div class="innercontent"><div></div>
            <div>
                <h3>Choose the right partner</h3>
                <p>At Endurance IT, we have experience with every aspect of enterprise-level IT operations in the private sector as well as within government and DoD contractors.</p>
                <p>We understand the pieces that comprise a holistic system. From consulting to implementation, we are ready to be your most valued IT and Digital Marketing service provider.</p><br>
                <div class="contactbbtun"><a _ngcontent-lvn-c33="" href="/contact-us" class="contactbtn">CONTACT US
                        TODAY</a>
                    </div>
            </div>
        </div>
    </div>
    <img alt="Digital Marketing service provider" src="https://ik.imagekit.io/b0cgaqv3j/solutions/inner/Enterprise/footerbanner.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613811553">
</section>
