import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-ed-ads-main',
  templateUrl: './ed-ads-main.component.html',
  styleUrls: ['./ed-ads-main.component.css']
})
export class EdAdsMainComponent {
  isPopupOpen = false;
  rand=0;
  clientTesttimonial: any=[];
  public innerWidth: any;
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private MetaService: MetaServiceService) { }
ngOnInit() {

    this.innerWidth = window.innerWidth;

    this.MetaService.createCanonicalURL();

    this.title.setTitle('Streamlined Managed Services | Endurance IT Solutions');
    this.meta.updateTag({ name: 'description', content: 'Explore comprehensive managed services for enhanced performance. Partner with Endurance IT Solutions for expert assistance & reliable support.' });
    this.meta.updateTag({ name: 'author', content: 'Editorial Team' });
    this.meta.updateTag({ name: 'publisher', content: 'Endurance IT Services' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ property: 'og:title', content: 'Streamlined Managed Services | Endurance IT Solutions' });
    this.meta.updateTag({ property: 'og:description', content: 'Explore comprehensive managed services for enhanced performance. Partner with Endurance IT Solutions for expert assistance & reliable support.' });

    this.rand = (Math.random() + 1);
    const url = '/assets/blog/ClientsTestimonial.json?v=' + this.rand;
    this.http.get(url).subscribe(res => {
      console.log(res, 'HI');
      this.clientTesttimonial = res;
    });

}
  openPopup(): void {
    this.isPopupOpen = true;
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }
}
