import { Component, OnInit } from '@angular/core';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {
  slug: any = {};

  // postData = {
  //   test:'my newsdetail page',
  // };
  newsRoom: any = [];
  dynamicTitle: string = 'Default Title';
  detaildata: any = [];
  url: string | undefined;
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private MetaService: MetaServiceService) {
    //url='http://httpbin.org/post';


  }
  ngOnInit(): void {

    this.MetaService.createCanonicalURL();
    const surl = this.router.url.split('/');
    let slugname = surl[2];

    this.slug = slugname;
    if (slugname.split('?').length > 1) {
      slugname = slugname.split('?')[0];
    }
    console.log('New SLug',slugname);
    this.url = '/assets/blog/' + slugname + '.json';
    this.http.get(this.url).subscribe(res => {
      console.log(res, 'HI1');
      this.detaildata = res;
      console.log(this.detaildata.result);
      this.title.setTitle(this.detaildata.result.yoast_title);
      this.dynamicTitle = this.detaildata.result.yoast_title;
      this.meta.updateTag({ name: 'description', content: this.detaildata.result.yoast_desc });
      this.meta.updateTag({ name: 'keyword', content: this.detaildata.result.yoast_keywords });
      this.meta.updateTag({ name: 'author', content: this.detaildata.result.authorDetails.display_name });
      this.meta.updateTag({ name: 'publisher', content: 'Endurance IT Services' });
      this.meta.updateTag({ name: 'robots', content: 'index, follow' });

      this.meta.updateTag({ property: 'og:title', content: this.detaildata.result.yoast_title });
      this.meta.updateTag({ property: 'og:description', content: this.detaildata.result.yoast_desc });



    });
    const url = '/assets/blog/blogdetail.json?v=' + Math.random();
    this.http.get(url).subscribe(res => {
      console.log(res, 'HI2');
      this.newsRoom = res;
    });



  }

  twitter(tweetText: any, urlToShare: any) {
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(urlToShare)}`, 'twitter-share-dialog', 'width=626,height=436');

  }
  facebook(tweetText: any, urlToShare: any) {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}&quote=${encodeURIComponent(tweetText)}`, 'facebook-share-dialog', 'width=626,height=436');

  }
  linkedin(tweetText: any, urlToShare: any) {
    window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(urlToShare)}&quote=${encodeURIComponent(tweetText)}`, 'linkedin-share-dialog', 'width=626,height=436');

  }
  mail(tweetText: any, urlToShare: any) {
    window.open(`mailto:?subject=Check%20out%20this%20blog%20post&body=I%20thought%20you%20might%20be%20interested%20in%20this%20blog%20post:%20${encodeURIComponent(urlToShare)}&quote=${encodeURIComponent(tweetText)}`, 'linkedin-share-dialog', 'width=626,height=436');

  }

}

