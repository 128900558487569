import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-case-study-form',
  templateUrl: './case-study-form.component.html',
  styleUrls: ['./case-study-form.component.css']
})
export class CaseStudyFormComponent implements OnInit {
  showloader=false;
  toDisplay = false;
  Results: any = [];
  SEO: any = [];

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta,private MetaService: MetaServiceService) { }
  ngOnInit(): void {
    this.toDisplay = false;
  }
  toggleData() {

    this.toDisplay = false;
  }
  logForm(value: any) {
    this.showloader=true;
    const surl = this.router.url;
    console.log(value);
    const url = '/wp/mail/ed-ads/download_the_case_study.php';
    const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
    const body = { data: value, url: surl };
    this.http.post<any>(url, body, { headers }).subscribe(data => {
      this.Results = data;
      this.toDisplay = true;
      this.showloader=false;
      setTimeout(() => {
        this.toDisplay = false;
      }, 8000);
      if (this.Results.flag == 1) {

        this.router.navigate(['/ed-ads-thankyou'], { queryParams: { refresh: true } });
      }
    });
  }
}
