import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-ed-ads-thankyoupage',
  templateUrl: './ed-ads-thankyoupage.component.html',
  styleUrls: ['./ed-ads-thankyoupage.component.css']
})
export class EdAdsThankyoupageComponent {
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private MetaService: MetaServiceService) { }
  ngOnInit() {

    document.addEventListener('DOMContentLoaded', function() {
      const body = document.querySelector('body');
      // if (body) {
      //     body.classList.add('yourClassName');
      // }
  });

    this.MetaService.createCanonicalURL();

    this.title.setTitle('Thank You For Your Request | Endurance IT Services');
    this.meta.updateTag({ name: 'Thank you for your interest. Need Immediate Assistance? Call us directly at (757) 457-4793 Our sales team is ready to assist.    ' });
    this.meta.updateTag({ name: 'author', content: 'Editorial Team' });
    this.meta.updateTag({ name: 'publisher', content: 'Endurance IT Services' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ property: 'og:title', content: 'Thank You For Your Request | Endurance IT Services' });
    this.meta.updateTag({ property: 'og:description', content: 'Thank you for your interest. Need Immediate Assistance? Call us directly at (757) 457-4793 Our sales team is ready to assist.    ' });



}
}
