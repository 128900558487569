<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/cmmc/header-banner.png?updatedAt=1679565595159) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services"></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>CMMC</h1>
  <h4>Increase your prospects when <br>bidding on government contracts</h4><div
    _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">Get in touch</a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img alt="What is CMMC?"
          src="/assets/images/service/compliace-assessments/cmmc/icon.png">
        <h2>What is CMMC?</h2>

      </div><div class="content">

        <p>The Cybersecurity Maturity Model Certification (CMMC) is a security
          requirement for any U.S. government contractor. It demonstrates your
          ability to safely deal with Controlled Unclassified Information (CUI)
          and Federal Contract Information (FCI) within the supply chain, as
          required by the Department of Defense (DoD).</p>
        <p>There are various levels of security maturity within CMMC, and
          suppliers who bid on DoD contracts need to comply with at least one of
          these - depending on the level of data shared with the winning
          contractor.</p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg"
        alt="DoD contracts" style="height: auto !important;"
        src="https://ik.imagekit.io/b0cgaqv3j/CMMC.png?updatedAt=1706638206650"></div>
  </div>
</section>
<section class="section2 mainredbar">

  <h3><strong>Do i need to be CMMC compliant?</strong></h3><p>If you bid on U.S.
    government contracts, then yes. It’s a must-have for all DoD
    contractors.</p>

</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/cmmc/footer-banner.png?updatedAt=1679565594219d) !important;">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>What next?</h3>
      <p>Protect your sensitive data and thrive in the digital age.</p>
      <p>Endurance will guide you through the compliance process, offering
        tailored solutions to meet your unique needs. With our support, you can
        confidently navigate the CMMC requirements, secure your business, and
        win government contracts.</p>
      <p>Don’t wait until it’s too late! Take the first step towards a more
        secure future today.</p>
      <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">GET COMPLIANT
        </a></div>
    </div></div>
</section>
