<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/Technology/banner.jpg?updatedAt=1677585982533) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1 >
    Technology
    <span>
      assessments
    </span>
  </h1>
  <h4>A complete technology evaluation<br>to identify any IT issues</h4><div
    class="bbtnsbox"><a href="/contact-us" class="btnsbox">let's talk about IT
    </a></div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/Technology/icon.jpg"
          alt="IT risk assessment">
        <h2 style="align-self:end;">
          Comprehensive technology
          <span>and IT risk assessment</span>
        </h2>
      </div>
      <div class="content">
        <p>
          Successful digital transformation requires periodic review and
          evaluation of the IT department and how it operates. Our consulting
          team can provide a professional technology assessment as well as
          recommendations to improve, modify, or replace the current plan.
        </p>
        <p>
          We’ll also examine the capabilities of your organization’s current
          digital technologies and how these capabilities compare with
          best-in-class organizations and determine whether new functionality or
          assets should be added.
        </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img style="height: auto !important;"
        src="assets/images/service/Technology/Rightside.jpg"
        alt="disaster recovery companies" class="shadow-lg">
    </div>
  </div>
</section>
<section class="section2" style="    margin-top: 0vw !important;">
  <h3>
    <strong>
      With our comprehensive Technology and IT Risk Assessment,
      <br>
      we evaluate:
    </strong>
  </h3>
  <div class="serviceicons cols2 bulletlist">
    <div>
      <ul>
        <li>
          Inventory of all hardware and software in use
        </li>
        <li>
          Current operational policies and staff competencies
        </li>
        <li>
          Software patches and updates
        </li>
        <li>
          Hardware to include CPU utilization, memory utilization, disk
          utilization, hardware age, support contracts, and efficiency
        </li>
        <li>LAN/WAN evaluation</li>
      </ul>
    </div>
    <div class="noborder">
      <ul>
        <li>
          Security controls
        </li>
        <li>
          A backup strategy so valuable data is protected and available for
          recovery
        </li>
        <li>
          Malware protection so your systems are protected from outside threats
        </li>
        <li>
          Directory, email, and other technologies in use
        </li>
        <li>A plan of action for addressing problems discovered in the
          assessment</li>
      </ul>
    </div>
  </div>
</section>
<section class="section4 graybar">
  <h3> Our Technology and IT Risk Assessment Process</h3>

  <div>
    <ul class="cols2 box">
      <li>
        <h4 class="bluetitle"><img class="bubbleicon" alt="Long Term" src="/assets/images/service/Staffing/1.png">
          Determine the purpose:</h4>
        <div>We identify all key participants, roles, and responsibilities. This
          helps set the expectation of what you will receive at the end of the
          process.</div>
      </li>
      <li>
        <h4 class="bluetitle">
          <img class="bubbleicon" alt="Long Term" src="/assets/images/service/Staffing/2.png">
          Gather data:</h4>
        <div class="bulletlistr">We obtain information from multiple sources,
          including, but not limited to:
          <ul class="cols2 ">
            <li>Existing documentation</li><li>Log files</li>
            <li>Internal resources</li><li>Vendors</li>
            <li>Visual inspection</li><li>Tool usage</li>
          </ul>
        </div>
      </li>
      <li>
        <h4 class="bluetitle">
          <img class="bubbleicon" alt="Long Term" src="/assets/images/service/Staffing/3.png">
          Analyze data:</h4>
        <div>We look for trends, issues, and concerns, then compare our findings
          to industry standards and best practices. We also identify gaps where
          technology fails to meet business needs.</div>
      </li>
      <li>
        <h4 class="bluetitle">
          <img class="bubbleicon" alt="Long Term" src="/assets/images/icon/4.png">
          Make recommendations:</h4>
        <div>Our first focus is on problems or issues, followed by concerns and
          trends. We help you prioritize our recommendations and build a budget
          and timeline to address them.</div>
      </li>
    </ul>
  </div>
</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/Technology/footercontent.jpg?updatedAt=1677585982695);">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3
        style="color: #fff;font-weight: 500;font-family: Montserrat !important;">
        <strong style="font-weight:900"> To request an IT Assessment</strong>
        <br>
        for your company, reach out today.
      </h3>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">REQUEST AN ASSESSMENT</a>
      </div>
    </div>
  </div>
</section>
