<div class="form">
    <span><strong>We'll take care of</strong> every detail</span>
    <p>Even if you don't know exactly what you need, our experts make it easy to talk about your project and work out the requirements. We'll quickly help frame it up and add some structure so it can be properly estimated and ultimately developed and delivered.</p>
    <form #form="ngForm" (submit)="logForm(form.value)" id="detail-form">
        <div class="loader"></div>
        <div *ngIf="Results.flag==0" class="alert alert-danger" [innerHTML]="Results.msg">{{Results}}</div>
        <div *ngIf="Results.flag==1" class="alert alert-success" [innerHTML]="Results.msg">{{Results}}</div>

        <div class="row">
            <div class="col-3">
                <label>First Name*</label><br>
                <input type="text" ngModel name="first_name">
            </div>
            <div class="col-3">
                <label>Last Name*</label><br>
                <input type="text" ngModel name="last_name">
            </div>
            <div class="col-3">
                <label>Email*</label><br>
                <input type="text" ngModel name="email">
            </div>
            <div class="col-3">
                <label>Phone Number*</label><br>
                <input type="text" ngModel name="number">
            </div>
        </div>
        <label>Company</label><br>
        <div class="row">
            <div class="col-3"><input type="text" ngModel name="company"></div>
            <label class="col-3">What are you looking for?</label>
            <div class=" col-3">
                <select ngModel name="What_are_you_looking_for" required="true" class="form-control" aria-label="Default select example">
                    <option value="" >What are you looking for?</option>
                    <option value="IT Staffing" >IT Staffing</option>
                    <option value="IT Consulting">IT Consulting</option>
                    <option value="Compliance Assessment">Compliance Assessment</option>
                    <option value="Infrastructure Services">Infrastructure Services</option>
                    <option value="Application Development">Application Development</option>
                    <option value="Web Design and Development">Web Design and Development</option>
                    <option value="Digital Marketing" selected>Digital Marketing</option>
                </select></div>
            <div class=" col-3"> <select  ngModel class="form-control" required="true" name="Expected Budget" aria-label="Default select example">
                    <option value="">Expected Budget</option>
                    <option value="< $10000">< $10000</option>
                    <option value="$10001 - $20000">$10001 - $20000</option>
                    <option value="$20001 - $50000">$20001 - $50000</option>

                </select></div>
        </div>
        <hr>
        <label>How can we help?</label><br>
        <textarea class="form-control" ngModel name="How_can_we_help" id="exampleFormControlTextarea1" rows="5"></textarea>

        <div *ngIf="toDisplay==false"  style="text-align:center;"    ><i style="font-size:2rem; color: #000;" class="fa fa-spinner fa-spin"></i></div>
        <div style="margin-top:40px;" >
            <input style=" display: none;" class="form-check-input"  name="Yes_I_want_to_subscribe_to_Endurance_IT_Newsletter" type="checkbox" >
            <label style="margin-top:5px; display: none;">
                Yes, I want to subscribe to Endurance IT Newsletter
            </label>
            <input name="Page_url" type="hidden" value="">
            <input (click)="toggleData()"  type="submit" value="SUBMIT" id="detail-submit">
        </div>

    </form><div><div *ngIf="Results.flag==0" class="alert alert-danger" [innerHTML]="Results.msg">{{Results}}</div>
    <div *ngIf="Results.flag==1" class="alert alert-success" [innerHTML]="Results.msg">{{Results}}</div></div>
</div>
