import { Component, OnInit, Renderer2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import {
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-default-langing-thankyou',
  templateUrl: './default-langing-thankyou.component.html',
  styleUrls: ['./default-langing-thankyou.component.css']
})
export class DefaultLangingThankyouComponent implements OnInit {
  toDisplay = true;
  Results: any = [];
  SEO: any = [];
  Paths: any = [];
  slug: String = '';
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,private renderer: Renderer2,
    private MetaService: MetaServiceService
  ) {}
  ngOnInit(): void {
    this.Paths=(this.router.url.split('/'));
    this.slug=this.Paths[this.Paths.length-1];


    this.MetaService.createCanonicalURL();
    const url = '/assets/pages/'+this.slug+'.json';
    this.http.get(url).subscribe((seodata) => {
      this.SEO = seodata;

      this.title.setTitle(this.SEO.NewMeta['yoast_wpseo_title']);
      this.meta.updateTag({ name: 'description', content: this.SEO.NewMeta['yoast_wpseo_metadesc'] });
      this.meta.updateTag({ name: 'keyword', content: this.SEO.NewMeta['yoast_wpseo_focuskw'] });


      const script = this.renderer.createElement('script');
      script.innerHTML = this.SEO.NewMeta['wpcf_header_script_event'];
      this.renderer.appendChild(document.head, script);

      const script1 = this.renderer.createElement('script');
      script1.src = this.SEO.NewMeta['wpcf_header_script_g_tag_js_url'];
      this.renderer.appendChild(document.head, script1);

      const script2 = this.renderer.createElement('script');
      script2.innerHTML = this.SEO.NewMeta['wpcf_header_script_gtag_data_layer'];
      this.renderer.appendChild(document.head, script2);


    });
  }

}
