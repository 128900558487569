<header class="serviceinner">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img alt="{{detaildata.result[0].post_title}}" class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <div style="    margin-top: 4rem ; color:#fff;" [innerHTML]="detaildata.result[0].Ddate"></div>
    <h1 class="mainheading" style="margin-top:0px !important;" [innerHTML]="detaildata.result[0].post_title">

    </h1>

</header>
<section class="section1">
    <div class="contentimg" style="background: url({{detaildata.result[0].Imagess}});">ss</div>

    <div style="text-align: justify;" [innerHTML]="detaildata.result[0].Maincontent"></div>

</section>
<!-- <section class="section1"><img *ngFor="let im of detaildata.result[0].MoreImage" src="{{ im }}"></section> -->
<section class="section2">
    <h3>Related <span>Stories</span></h3>
    <div class="newsbox4">
        <div class="newsscontent">
            <a href="newsroom/{{detaildata.result.fourimages[0].post_name}}">
                <div style="background:url({{detaildata.result.fourimages[0].Imagess}})" class="imagesbox">
                    <img src="/assets/images/NewsDetail/arrow.jpg" alt="{{detaildata.result.fourimages[0].post_title}}"></div>
                <h4 [innerHTML]="detaildata.result.fourimages[0].Ddate">s</h4>
                <h2 [innerHTML]="detaildata.result.fourimages[0].post_title"></h2>
            </a>
        </div>
        <div class="newsscontent">
            <a href="newsroom/{{detaildata.result.fourimages[1].post_name}}">
                <div style="background:url({{detaildata.result.fourimages[1].Imagess}})" class="imagesbox">
                    <img src="/assets/images/NewsDetail/arrow.jpg" alt="{{detaildata.result.fourimages[1].post_title}}"></div>
                <h4 [innerHTML]="detaildata.result.fourimages[1].Ddate">s</h4>
                <h2 [innerHTML]="detaildata.result.fourimages[1].post_title"></h2>
            </a>
        </div>
        <div class="newsscontent">
            <a href="newsroom/{{detaildata.result.fourimages[2].post_name}}">
                <div style="background:url({{detaildata.result.fourimages[2].Imagess}})" class="imagesbox">
                    <img src="/assets/images/NewsDetail/arrow.jpg" alt="{{detaildata.result.fourimages[2].post_title}}"></div>
                <h4 [innerHTML]="detaildata.result.fourimages[2].Ddate">sss</h4>
                <h2 [innerHTML]="detaildata.result.fourimages[2].post_title"></h2>
            </a>
        </div>
        <div class="newsscontent">
            <a href="newsroom/{{detaildata.result.fourimages[3].post_name}}">
                <div style="background:url({{detaildata.result.fourimages[3].Imagess}})" class="imagesbox">
                    <img src="/assets/images/NewsDetail/arrow.jpg" alt="{{detaildata.result.fourimages[3].post_title}}"></div>
                <h4 [innerHTML]="detaildata.result.fourimages[3].Ddate">ss</h4>
                <h2 [innerHTML]="detaildata.result.fourimages[3].post_title"></h2>
            </a>
        </div>
    </div>
</section>
