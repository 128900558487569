<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/Project_Management/Banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677588230623) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>Project
    <span>management</span>
  </h1>
  <h4>Skills and support to deliver your<br>
    projects successfully
  </h4>
  <div class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about
      IT</a>
  </div>
</header>
<section class="newheadingbox">
  <h2>Experienced <span>Project
      Management Professionals</span>
  </h2>
  <p style="display: none;;">Trust, integrity and collaboration</p>
</section>
<section class="callout-boxes">
  <div>
    <img src="/assets/images/service/ProjectManagement/call-out-1.png"
      alt="Dedicated">
    <h4>Dedicated</h4>
    <p>Our highly trained experts will provide hands-on management</p>
  </div>
  <div>
    <img src="/assets/images/service/ProjectManagement/call-out-2.png"
      alt="Collaboractive">
    <h4>Collaborative</h4>
    <p>They will ensure effective communication among the team</p>
  </div>
  <div>
    <img src="/assets/images/service/ProjectManagement/call-out-3.png"
      alt="Efficient">
    <h4>Efficient</h4>
    <p>Your projects will be completed on time and within budget</p>
  </div>
</section>
<section class="section2 mainredbar">
  <p>
    Our experienced IT project management professionals are certified by the
    Project Management Institute (PMI) and ITSM (IT Service Management) best
    practice frameworks.
    <span><br> They will oversee the success of your project - ensuring that
      it’s completed on time, in scope, and within budget.</span>
  </p>
  <div class="servicebox cols4">
    <div><img src="/assets/images/service/ProjectManagement/icon1.png"
        alt="Initiation">
      <h5>Initiation</h5>
      <div>Setting the goals. The project’s outcome, scope and resources are
        defined, as well as which team members are required to complete it. This
        provides the team with a clear direction and sets stakeholders’
        expectations.</div>
    </div>
    <div><img src="/assets/images/service/ProjectManagement/icon2.png"
        alt="Planning">
      <h5>Planning</h5>
      <div>Determining the steps. The budget, timeline and milestones are
        established, and the responsibilities of each team member are delegated.
        This stage also includes a risk assessment and expectations for team
        communication.</div>
    </div>
    <div><img src="/assets/images/service/ProjectManagement/icon3.png"
        alt="Execution">
      <h5>Execution</h5>
      <div>Making it happen. The steps determined during the planning stage are
        put into action, and the project manager will keep the team on track.
        Progress will be monitored - including quality, risk, costs, and
        communication.</div>
    </div>
    <div><img src="/assets/images/service/ProjectManagement/icon4.png"
        alt="Closing">
      <h5>Closing</h5>
      <div>Handing it over. The project is completed and delivered to the owners,
        and a series of assessments are carried out. These include a project
        closeout report and a review of any learnings to be implemented in
        future projects.</div>
    </div>

  </div>
</section>
<section class="section7">
  <div align="center">
    <img src="/assets/images/service/ProjectManagement/service-icon-1.png"
      alt="Project Recovery Solutions">
    <h3>Project Recovery <span>Solutions</span></h3>
    <div class="peragraph"
      style="font-family: Montserrat !important;">
      Assessment and mediation to get<br> your project back on track
    </div>
    <div class="bbtnsbox"><a
        href="/services/project-management/project-recovery-solutions"
        class="btnsbox">learn more</a></div>
  </div>
  <div align="center">
    <img src="/assets/images/service/ProjectManagement/service-icon-2.png"
      alt="Data Center Project Management">
    <h3>Data Center Project <span>Management</span></h3>
    <div class="peragraph"
      style=" font-family: Montserrat !important;">
      Evaluation and planning to avoid<br> delays and cost increases
    </div>
    <div class="bbtnsbox"><a
        href="/services/project-management/data-center-project-management"
        class="btnsbox">learn more</a></div>
  </div>
</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/Project_Management/footerimage.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677588230507) !important;">
  <div class="logoicon"><img
      src="assets/images/service/digitalmarkting/endurance_icon.png"
      alt="Endurance IT Services Favicon"></div>
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 style="color: #fff;">Deliver your projects successfully</h3>
      <p style="    font-size: 1.1vw!important;">Endurance IT has extensive
        experience of managing projects of all sizes. Get in touch and let us
        make sure your next project is a success.
        <br><br>Not sure exactly what you need? We’ll guide you through it.
      </p>

      <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">GET IN
          TOUCH</a></div>
    </div></div>
</section>
