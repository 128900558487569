<app-kerner-group-header></app-kerner-group-header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-7 col-md-7">
      <div class="heading">
        <img src="../../../assets/images/icon.png"
          alt="Private Cloud solutions ">
        <h2>Elevating Cybersecurity and Efficiency: <span>Our Commitment to
            Excellence</span></h2>

      </div><div class="content">
        <p><strong>At Endurance IT Services, we are dedicated to transforming IT
            challenges into strategic opportunities with our bespoke solutions
            and unparalleled cybersecurity standards.</strong> As the only SSAE
          21 SOC 2 Type II Certified Managed Service Provider in Hampton Roads,
          VA, we assure unmatched security and reliability.</p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-5 col-md-5"><img
        src="../../../assets/images/kerner-group-side.png" alt
        class="sideimage"></div>
  </div>
</section>
<section class="counters row">
  <h3 class="col-md-4">16<span>Years in <br>Hampton Roads</span></h3>
  <h3 class="col-md-4">2500+<span>Projects</span></h3>
  <h3 class="col-md-4"><span>Servicing</span>300+<span>Clients</span></h3>
</section>
<section class="testimonial" align="center">
  <img
    src="https://ik.imagekit.io/hb9nqxxf2/bgass.png?updatedAt=1687543393541" />
  <h3>From Challenge to Triumph: <span>A Client's Journey with Endurance IT
      Services</span></h3>
  <p><strong>“Endurance IT Services has been nothing short of a technological
      boon for The Kerner Group.</strong> Their ability to understand and
    integrate the varied IT needs of our subsidiaries into a cohesive,
    efficient, and secure system has been a cornerstone of our collective
    success. We've seen remarkable improvements in collaboration, operational
    efficiency, and data security, all while maintaining each company's unique
    identity and needs."</p>
  <i>-Amy Hansen, The Kerner Group President/CFO</i>
  <hr>
</section>
<section class="opportunities">
  <div class="header">
    <h3>Overcoming Obstacles and Turning <span><br>Challenges into
        Opportunities</span></h3>
    <p>The Kerner Group faced multifaceted IT challenges that hindered their
      growth and operational efficiency. <strong>Their story is a testament to
        the transformative power of strategic IT solutions tailored to specific
        business needs.</strong></p>
  </div>
  <h3>Why Download the Case Study?</h3>
  <div class="boxes">
    <div class="box">
      <img src="../../../assets/images/1.png" />
      <h4>Unlock Exclusive Insights:</h4>
      <p><strong>Delve into the unique strategies that transformed The Kerner
          Group,</strong> showcasing how bridging the skills gap, enhancing IT
        security, and integrating technologies led to unparalleled
        efficiency.</p>
    </div>
    <div class="box">
      <img src="../../../assets/images/2.png" />
      <h4>Innovative Problem-Solving:</h4>
      <p><strong>Get an inside look at the creative solutions and collaborative
          efforts that solved The Kerner Group’s complex challenges.
        </strong>This section highlights the inventive thinking and teamwork
        that underpin our approach to IT challenges.</p>
    </div>
    <div class="box">
      <img src="../../../assets/images/3.png" />
      <h4>Blueprint for Success:</h4>
      <p><strong>Access a step-by-step guide based on The Kerner Group's
          experience,</strong> illustrating how targeted IT improvements can
        catalyze business growth. Learn from real-world applications that have
        proven to drive transformation and success.</p>
    </div>
  </div>
</section>
<section class="section3">
  <div class="logoicon"><img
      src="assets/images/service/digitalmarkting/endurance_icon.png"
      alt="Endurance IT Services Favicon"></div>
  <h3 style="color:#fff">Ready to unlock the full story and insights?</h3>
  <div  class="bbtnsbox" align="center">
    <a class="btnsbox" data-bs-toggle="modal"
      data-bs-target="#casestudy">Download the Full Case Study</a>
  </div>
  <p><strong>Contact us for more information</strong> on how we can transform
    your IT challenges into success stories.</p>
  <div  class="bbtnsbox" align="center">
    <a href="/contact-us" class="btnsbox"
     >Let's Talk About It</a>
  </div></section>

<script src="https://link.thinkzag.com/js/form_embed.js"></script>

<!-- Modal -->
<div class="modal fade" id="casestudy" tabindex="-1"
  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <iframe
          src="https://link.thinkzag.com/widget/form/Y4WfxLkAq3KxbsxRsXsf"
          style="width:100%;height:80vh;border:none;border-radius:3px"
          id="popup-Y4WfxLkAq3KxbsxRsXsf"
          data-layout="{'id':'POPUP'}"
          data-trigger-type="alwaysShow"
          data-trigger-value
          data-activation-type="alwaysActivated"
          data-activation-value
          data-deactivation-type="neverDeactivate"
          data-deactivation-value
          data-form-name="Download Case Study"
          data-height="417"
          data-layout-iframe-id="popup-Y4WfxLkAq3KxbsxRsXsf"
          data-form-id="Y4WfxLkAq3KxbsxRsXsf"
          title="Download Case Study">
        </iframe>
      </div>
    </div>
  </div>
</div>
