import { Component, OnInit } from '@angular/core';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-google-ads',
  templateUrl: './google-ads.component.html',
  styleUrls: ['./google-ads.component.css']
})
export class GoogleAdsComponent implements OnInit {

  constructor( private MetaService: MetaServiceService) { }

  ngOnInit(): void {this.MetaService.createCanonicalURL();
  }

}
