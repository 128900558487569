<header class="fixedheaders" style="background-image:none !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>
            <app-nav></app-nav>
        </div>
    </nav>
</header>
<section class="goals-boxes">
    <h3>Response Time Goals</h3>
    <div class="boxes">
        <div class="red">
            <p><strong>Emergency / Critical</strong>Response Objective: Within</p>
            <h3><span>1</span>
                /Hour</h3>
            <ul>
                <li>All users on a site unable to work</li>
                <li>Virus Outbreak</li>
                <li>Email server failure</li>
                <li>Server crash</li>
                <li>Network failure</li>
            </ul>
        </div>
        <div class="orange">
            <p><strong>Urgent</strong>Response Objective: Within</p>
            <h3><span>4</span>
                /Hour</h3>
            <ul>
                <li>One user unable to work or all users greatly inconvenienced</li>
                <li>Single virus</li>
                <li>Users machine crashed</li>
                <li>Internet outage*</li>
                <li>Important File unavailable</li>
            </ul>
        </div>
        <div class="blue">
            <p><strong>High</strong>Response Objective: Within</p>
            <h3><span>8</span>
                /Hour</h3>
            <ul>
                <li>One user unable to perform a function </li>
                <li>Experiencing inconvenience</li>
                <li>Application fault</li>
                <li>File unavailable</li>
                <li></li>
            </ul>
        </div>
        <div class="green">
            <p><strong>Normal Service</strong>Response Objective: Within</p>
            <h3><span>16</span>
                /Hour</h3>
            <ul>
                <li>Question or problem that does not affect any users to work</li>
                <li>How do I…?​</li>
                <li>How much would …cost?​</li>
                <li>Please order….</li>
                <li></li>
            </ul>
        </div>
    </div>
</section>
<section class="goals-boxes">
    <h3>Update Goals</h3>
    <div class="boxes">
        <div class="red">
            <p><strong>Emergency / Critical</strong>Update Every</p>
            <h3><span>30</span>
                /Minutes</h3>
            <ul>
                <li>All users on a site unable to work</li>
                <li>Virus Outbreak</li>
                <li>Email server failure</li>
                <li>Server crash</li>
                <li>Network failure</li>
            </ul>
        </div>
        <div class="orange">
            <p><strong>Urgent</strong>Response Objective: Within</p>
            <h3><span>60</span>
                /Minutes</h3>
            <ul>
                <li>One user unable to work or all users greatly inconvenienced</li>
                <li>Single virus</li>
                <li>Users machine crashed</li>
                <li>Internet outage*</li>
                <li>Important File unavailable</li>
            </ul>
        </div>
        <div class="blue">
            <p><strong>High</strong>Response Objective: Within</p>
            <h3><span>2</span>
                /Per day</h3>
            <ul>
                <li>One user unable to perform a function </li>
                <li>Experiencing inconvenience</li>
                <li>Application fault</li>
                <li>File unavailable</li>
                <li></li>
            </ul>
        </div>
        <div class="green">
            <p><strong>Normal Service</strong>Response Objective: Within</p>
            <h3><span></span>
                /AS needed per request</h3>
            <ul>
                <li>Question or problem that does not affect any users to work</li>
                <li>How do I…?​</li>
                <li>How much would …cost?​</li>
                <li>Please order….</li>
                <li></li>
            </ul>
        </div>
    </div>
</section>
<section class="section3">
    <div class="logoicon"><img src="assets/images/service/digitalmarkting/endurance_icon.png" alt="Endurance IT Services Favicon"></div>
    <div>
        <p>Your Dream Partner</p>
        <h3 style="color:#fff">Committed to superior <br>quality and results</h3>           
    </div>
</section>