<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/Compliance_assessments/banner.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677587910603) !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Compliance <span></span></h1>
    <h4>Meet security regulations to remain <br>lawful and win more contracts</h4>
    <div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">GET IN TOUCH</a>
    </div>
</header>
<section class="callout-boxes">
    <div>
        <img alt="Stay Lawful"  src="/assets/images/call-out/ComplianceAssessments/Staylawful.png">
        <h4>Stay Lawful</h4>
        <p>We’ll get you up to speed on the compliance regulations and laws that apply to your industry so you can avoid unnecessary fines or penalties.</p>
    </div>
    <div>
        <img alt="Stay Dependable" src="/assets/images/call-out/ComplianceAssessments/dependable.png">
        <h4>Stay Dependable</h4>
        <p>Your customers trust you with their data, and we help you make sure it’s safe by implementing compliance measures to avoid data breaches.</p>
    </div>
    <div>
        <img alt="Stay Focused" src="/assets/images/call-out/ComplianceAssessments/focused.png">
        <h4>Stay Focused</h4>
        <p>Growing your business is your main priority, so you can leave the rest to us. We’ll make sure that you’re operating within the law.</p>
    </div>
</section>
<section class="section3" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/Compliance_assessments/middle-banner.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677587909387)  !important;">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3>Ensure compliance with security regulations</h3>
            <h5>Assessment and guidance to achieve and maintain compliance</h5>
            <p>From healthcare to government contractors and any business that accepts credit card payments, there are laws and regulations that apply to operating securely online. Your specific industry, and even your particular location, may have different requirements from others.</p>
            <p>Endurance IT can guide you through this by ensuring you’re following protocol and helping you find a solution to any compliance issues you’re facing.
              </p>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6"></div>

    </div>
</section>
<section class="section1">
    <div class="row">
      <h3 class="coloro" style="text-align: center; font-family: MontserratSB!important; font-size: 1.75rem; padding-bottom: 3rem;">
        We have expert knowledge in the following areas of security compliance:
      </h3>
        <div class="gridboxed">
            <div class="borderR">
                <div class="icon"><img alt="CMMC" src="assets/images/service/compliace-assessments/1.png"></div>
                <div class="heading" style="margin-left: 0 !important;">
                    <h3 style="margin-left: 0 !important;">CMMC</h3>
                </div>
                <div  class="peragraph" >
                  Increase your prospects when bidding on government contracts by demonstrating compliance with specific defense industry security protocols.
                </div>
                <div class="buttons"><a href="/services/compliance-assessments/cmmc">FIND OUT MORE</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img alt="GDPR" src="assets/images/service/compliace-assessments/2.png"></div>
                <div class="heading" style="margin-left: 0 !important;">
                    <h3 style="margin-left: 0 !important;">GDPR</h3>
                </div>
                <div  class="peragraph" >
                  Ensure your company is lawfully handling EU-based customer data with this essential security compliance as defined by the EU Commission.
                </div>
                <div class="buttons"><a href="/services/compliance-assessments/gdpr">FIND OUT MORE</a></div>
            </div>
            <div  class="borderR">
                <div class="icon"><img alt="HIPAA" src="assets/images/service/compliace-assessments/3.png"></div>
                <div class="heading" style="margin-left: 0 !important;">
                    <h3 style="margin-left: 0 !important;">HIPAA</h3>
                </div>
                <div  class="peragraph" >
                  Adhere to national standards created to prevent the disclosure of sensitive patient health information without the patient’s knowledge or consent.
                </div>
                <div class="buttons"><a href="/services/compliance-assessments/hipaa">FIND OUT MORE</a></div>
            </div>
            <div >
                <div class="icon"><img alt="PCI" src="assets/images/service/compliace-assessments/4.png"></div>
                <div class="heading" >
                    <h3 style="margin-left: 0 !important;">PCI</h3>

                </div>
                <div  class="peragraph">
                  Process card payments securely by implementing universal data security standards that apply to any company that accepts credit card payments.
                </div>
                <div class="buttons"><a href="/services/compliance-assessments/pci">FIND OUT MORE</a></div>
            </div>
        </div>
    </div>
</section>


<section class="section3" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/Compliance_assessments/footer-banner.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677587909504) !important;">
    <div class="logoicon"><img src="assets/images/service/digitalmarkting/endurance_icon.png" alt="Endurance IT Services Favicon"></div>
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3>Which compliance applies to your industry?</h3>
            <p>Security compliance affects every industry in every location, and our experts can guide you through it. From identifying which regulations or certifications apply to you and your customers, to implementing compliance measures across your business - we have it covered.</p>
            <p>Endurance IT will solve your compliance challenges.</p>
            <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">let’s talk about it</a></div>
        </div>
    </div>
</section>
