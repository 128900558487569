<header style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/soultion.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613646146)">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>The <span>right fit  <br>for any size business</span></h1>
    <h4>Scalable IT Support  and<br>
        Digital Innovation Solutions</h4>
</header>
