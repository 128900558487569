<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Email
    <span> marketing</span>
  </h1>
  <h4>Personalized, direct messaging to maximize engagement</h4>
  <div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about
      IT</a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img alt="Email marketing campaign management"
          src="/assets/images/service/emailmarkting/icons.jpg">
        <h2>
          Email marketing <br>
          <span>campaign management</span>
        </h2>
      </div>
      <div class="content">
        <p>
          The average ROI for email marketing ranges from $36 to $44 for every
          $1 spent. This return on investment beats every other marketing
          platform or strategy, hands down. Not only does email marketing tend
          to have higher conversion rates, emails can also build relationships
          with leads and customers, educate prospects, and overcome objections.
          With email, you are top of mind with your audience - plain and simple.
        </p>
        <h3>10 benefits of email marketing:</h3>
        <ol>
          <li><span>Low cost</span></li>
          <li><span>Reach and re-engage with a warm
              audience</span></li>
          <li><span>Delivers highly targeted messages</span></li>
          <li><span>Drives revenue</span></li>
          <li><span>Easy to implement</span></li>
          <li><span>Simple to measure</span></li>
          <li><span>Convenient to share</span></li>
          <li><span>Reaches a global audience</span></li>
          <li><span>Makes an immediate impact</span></li>
          <li><span>Unparalleled ROI</span></li>

        </ol>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img alt="email marketing"
        src="https://ik.imagekit.io/b0cgaqv3j/service/emailmarkting/Rightside.jpg?updatedAt=1679571024160">
    </div>
  </div>
</section>
<section class="section2 mainredbar">
  <h3><strong>Optimize Your Email Campaign</strong></h3>
  <p>
    To get the most out of an email marketing strategy, your campaigns have to
    be personalized, relevant, and appealing. At Endurance, our email marketing
    specialists are experts at design, segmentation, and automation to ensure
    that your email campaigns deploy smoothly and yield results in the shortest
    time possible.</p>
</section>
<section class="section3">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 style="color: #fff;">Ensure your messages are being received</h3>
      <p>Are your emails getting delivered to the inbox or going straight to the
        spam folder? If you want your campaigns to succeed, your audience has to
        receive the emails!</p>
      <p>
        Do you know your current email sending reputation? Claim your FREE Email
        Deliverability Audit for a comprehensive analysis of your email
        marketing program.
      </p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">Get My Free Audit</a>
      </div>
    </div>
  </div>
</section>
