import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaServiceService } from 'src/app/meta-service.service';




@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})

export class BlogComponent implements OnInit {
  searchTerm: string = '';
  searching: string = '';
  searchCat: string = 'Networking';
  newsRoom: any = [];
  titles: any = "";
  subtitles: any = "";
  breadcrumn: any = "";
  slug: any = {};
  isDivVisible: Number = 1;
  page: number = 1;
  count: number = 0;
  tableSize: number = 9;
  tableSizes: any = [3, 6, 9, 12];
  rand: number = 0;
  BClass: any = '';
  sortOrder: any = 'asc';
  BlogLink: any = "";
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private MetaService: MetaServiceService) { }
  ngOnInit(): void {
    this.getNews();
    this.MetaService.createCanonicalURL();

    this.title.setTitle('Expert Insights & Tips | Endurance IT Services Blog');
    this.meta.updateTag({ name: 'description', content: 'Stay informed with latest tech trends & expert advice on IT solutions. Explore our blog for valuable resources from Endurance IT Services.' });
    this.meta.updateTag({ name: 'author', content: 'Editorial Team' });
    this.meta.updateTag({ name: 'publisher', content: 'Endurance IT Services' });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });

    this.meta.updateTag({ property: 'og:title', content: 'Expert Insights & Tips | Endurance IT Services Blog' });
    this.meta.updateTag({ property: 'og:description', content: 'Stay informed with latest tech trends & expert advice on IT solutions. Explore our blog for valuable resources from Endurance IT Services.' });



  }

  getNews() {
    this.isDivVisible = 0;
    setTimeout(() => {
      this.isDivVisible = 1;
      const surl = this.router.url.split('/');
      this.rand = (Math.random() + 1);
      console.log(surl, 'surl');
      if (surl[2] == 'category') {
        if (surl[3].search('#')) {
          const surl1 = surl[3].split('#');
          console.log(surl1, 'surl1');
          surl[3] = surl1[0];
        }
        const url = '/assets/blog/cat_' + surl[3] + '.json?v=' + this.rand;
        this.http.get(url).subscribe(res => {
          console.log(res, 'HI');
          this.newsRoom = res;
          this.titles = this.newsRoom.result.title;
          this.subtitles = this.newsRoom.result.Details;
          this.breadcrumn = '<a href="/">Home</a> | <a href="/blog">Blog</a>' + this.newsRoom.result.breadCrumn;
          this.BClass = 'blogcat';
          this.searchCat = this.BlogLink = this.newsRoom.result.link;
          this.searchCat = surl[3];
        });
      } else {
        const url = '/assets/blog/blogdetail.json?v=' + this.rand;
        this.http.get(url).subscribe(res => {
          console.log(res, 'HI');
          this.newsRoom = res;
          this.titles = 'Blog';
          this.subtitles = "Welcome to the Endurance IT Services Blog<br> Weekly insights on business, information technology, cybersecurity, and digital innovation.";
          this.breadcrumn = '<a href="/">Home</a> | <strong><a href="/blog">Blog</a></strong>';
          this.BClass = 'blogcat';
          this.searchCat = this.BlogLink = 'blog';

        });
      }
    }, 500);
  }

  sortItems() {
    if (this.sortOrder === 'asc') {
      this.newsRoom.result.blog.sort((a: any, b: any) => b.SortByDate - a.SortByDate);
    } else {
      this.newsRoom.result.blog.sort((a: any, b: any) => a.SortByDate - b.SortByDate);
    }
  }

  search(): void {
    this.searchTerm = this.searching;

  }

  onCategoryChange(event: any) {

    this.searchCat = event.target.value;
    this.getNews();
    if (this.searchCat == 'blog') {
      this.router.navigate(['/blog']);
    } else {
      this.router.navigate(['/blog/category', this.searchCat]);
    }
    // Navigate to a different route based on the selected category


  }

  onTableDataChange(event: any) {
    console.log(event, 'HIsss');
    this.page = event;
    this.getNews();
  }
  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.getNews();
  }

  twitter(tweetText: any, urlToShare: any) {
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(urlToShare)}`, 'twitter-share-dialog', 'width=626,height=436');

  }
  facebook(tweetText: any, urlToShare: any) {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}&quote=${encodeURIComponent(tweetText)}`, 'facebook-share-dialog', 'width=626,height=436');

  }
  linkedin(tweetText: any, urlToShare: any) {
    window.open(`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(urlToShare)}&quote=${encodeURIComponent(tweetText)}`, 'linkedin-share-dialog', 'width=626,height=436');

  }
  mail(tweetText: any, urlToShare: any) {
    window.open(`mailto:?subject=Check%20out%20this%20blog%20post&body=I%20thought%20you%20might%20be%20interested%20in%20this%20blog%20post:%20${encodeURIComponent(urlToShare)}&quote=${encodeURIComponent(tweetText)}`, 'linkedin-share-dialog', 'width=626,height=436');

  }
  printContentFromURL(url: string): void {
    // Create a hidden iframe to load the URL
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);

    // Once the iframe is loaded, trigger the print dialog
    iframe.onload = () => {
      if (iframe.contentWindow) { // Check if contentWindow is not null
        iframe.contentWindow.print();
      } else {
        console.error('contentWindow is null');
      }
    };
  }
}
