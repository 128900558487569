<footer>
    <img style="display:none !important;" src="https://ik.imagekit.io/b0cgaqv3j/accessibility.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677581099262" alt="ADA">
    <div id="footer-menu">
        <div class="col-12" align="center"><a href="/"><img alt="Endurance IT Services" src="https://ik.imagekit.io/b0cgaqv3j/logo-vertical.png?updatedAt=1677581119808"></a></div>
        <div class="row">
            <div class="col-1">
                <h6>About Us</h6>
                <ul>

                    <li><a href="about-us/leadership">Leadership</a></li>
                    <li  style="display: none;"><a href="about-us/our-team">Our Team</a></li>
                    <li><a href="about-us/giving-back">Giving Back</a></li>
                </ul>

                <h6>Solutions</h6>
                <ul>
                    <li><a href="solutions/small-business">Small  Business</a></li>
                    <li><a href="solutions/medium-business">Medium Business</a></li>
                    <li><a href="solutions/enterprise">Enterprise</a></li>
                    <li><a href="solutions/government-contractors">Government  Contractors</a></li>
                </ul>

            </div>
            <div class="col-1">
                <h6>Services</h6>
                <ul>
                    <li><a href="services/infrastructure-services">Infrastructure Services</a></li>
                    <li><a href="services/it-staffing">IT Staffing</a></li>
                    <li><a href="services/project-management">Project Management</a></li>
                    <li><a href="services/managed-services">Managed Services</a></li>
                    <li><a href="services/it-consulting">IT Consulting</a></li>
                    <li><a href="/services/webdesign-and-development">Web Development</a></li>
                    <li><a href="services/digital-marketing">Digital Marketing</a></li>
                </ul>

            </div>

            <div class="col-1 hiddens" style="display: none;">
                <h6>Our Clients</h6>
            </div>
            <div class="col-1 hiddens" style="display: none;">
                <h6>Careers</h6>
            </div>

        </div>
        <div id="social-icons" style="text-align: center;">
            <a href="https://www.facebook.com/profile.php/?id=100063618251696" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
            <a href="https://www.linkedin.com/company/endurance-it-services/" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a>
            <a href="https://www.instagram.com/enduranceitservicesllc/" target="_blank"><i class="fa-brands fa-instagram fa"></i></a>
           </div>
        <div class="col12"><h6>Contact</h6>
                <div>
                    <a href="tel:7573798682">757-379-8682</a><br>
                    <a href="mailto:saleseit@endurance-it.com">saleseit@endurance-it.com</a>
                    <address>
                    Convergence I, 295 Bendix Road,<br>Suite 300. Virginia Beach, VA 23452
                    </address>

                </div></div>

    </div>
    <div class="row" id="reserved-section">
        <div class="col-12" align="center">
            <a href="terms-condition">Terms & conditions&nbsp;&nbsp;&nbsp;</a>
            <a href="privacy-policy">Privacy policy</a>
        </div>
        <div class="col-12">
            @ {{year}} Endurance IT, All Rights Reserved.
        </div>
    </div>
    <div class="col-4">
    </div>

</footer>
