<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Website 
      <span>maintenance 
        <br>and security</span>
  </h1>
  <h4>Security management to eliminate <br>risk of cyber attacks</h4>
  <div class="bbtnsbox">
    <a href="/contact-us" class="btnsbox">let's talk about IT</a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img
          src="/assets/images/service/Website_Maintenance_and_Security/icon.png"
          alt="Specialist website maintenance solutions">
        <h2 style="line-height: 1.2 !important ;">
          Specialist
          <br><span> website maintenance solutions</span>
        </h2>
      </div>
      <div class="content">
        <p>Did you know that the vast majority of cyber attacks happen to small
          and medium businesses? These attacks are so common that they don’t
          make the headlines, but they have devastating effects.</p>
        <p>In fact, they are so disastrous that 60% of small businesses fold
          within six months of a cyber attack, according to Fundera.
          Sadly, small businesses are more vulnerable to attacks because hackers
          view them as easy targets. Unfortunately, they’re usually right.</p>

        <p>With Endurance IT, you’ll be protected from virtually any
          web-based attack. We set you up with the latest technology, monitor
          your web servers, track third-party applications, keep your licenses
          and updates running on the latest versions, and implement Fort
          Knox-level security to ensure your data is safe.</p>
        <p>Your website is an essential part of your business, and it is often
          the most vulnerable. Keep it secure with Endurance – your trusted
          cybersecurity partner.</p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/service/Website_Maintenance_and_Security/Rightside.png?updatedAt=1677585968491"
        alt="Website maintenance and security">
    </div>
  </div>
</section>
<section class="section2 mainredbar">

  <h3>
    <strong>Be proactive with your website safety</strong>

  </h3>
  <p>Too often, businesses wait until
    after a catastrophic cyber strike to think about security.</p>
  <p>
    Whether you’re recovering from an attack or need to implement proactive
    security measures, Endurance can help. </p>
</section>
<section class="section3"
  style="background-image: url(/assets/images/service/Website_Maintenance_and_Security/footercontent.jpg);">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 class="colorw">
        We'll take care of every detail
      </h3><p>
        Running a business is challenging enough without having to constantly
        look over your shoulder to see if there’s a hacker in your rearview
        mirror. When you entrust Endurance with your website maintenance and
        security, you can finally relax and focus on the tasks that move your
        business forward.

      </p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">REQUEST A QUOTE</a>
      </div>
    </div>
  </div>
</section>
