<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services"></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>Branding</h1>
  <h4>Carefully crafted and refined custom brands to make you stand out from the
    crowd</h4><div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">SCHEDULE A
      CONSULTATION</a>
  </div>
</header>
<section class="newheadingbox">
  <h2 class="h2-beforecallouts">
    Leading Brand Design <span>and
    Messaging Services</span>
  </h2>
  <p class="p-beforecallouts">Grow your relationship with new and established
    customers.</p>
</section>
<section class="callout-boxes">
  <div>
    <img src="/assets/images/call-out/Branding/indentify.png"
      alt="Pinpoint your values and tone of voice">
    <h4>Identity</h4>
    <p>Pinpoint your values and tone of voice</p>
  </div>
  <div>
    <img src="/assets/images/call-out/Branding/design.png"
      alt="Express your brand values visually">
    <h4>Design</h4>
    <p>Express your brand values visually</p>
  </div>
  <div>
    <img src="/assets/images/call-out/Branding/personality.png"
      alt="Communicate effectively with your market">
    <h4>Personality </h4>
    <p>Communicate effectively with your market</p>
  </div>
</section>

<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/branding/icon.jpg"
          alt="Find your Market to Message match">
        <h2>Find your <span>message - market match</span></h2>
      </div>
      <div class="content">

        <p>Whether you’re an established brand or just starting out, you need to
          differentiate yourself from the competition. At Endurance, we have a
          foundation of branding expertise that our clients leverage to achieve
          bold brand status.</p>
        <p>When you work with Endurance on your branding strategy and execution,
          you’ll receive a powerful and personalized brand image that will stand the test of time.</p>
        <p>We recognize that the competition is fiercer than ever. The number of
          brands has exploded, and there’s been a massive decentralization of
          communication platforms that can end up diluting your message - making
          it harder to be heard.
        </p>
        <p>Don’t worry, Endurance is here to help.</p>

      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6"><img
        src="https://ik.imagekit.io/b0cgaqv3j/service/branding/Rightside.jpg?updatedAt=1679568811567"
        alt="Lets talk to branding" class="shadow-lg"></div>
  </div>
</section>
<section class="section2 mainredbar" >
  <h3>How we can help:</h3>
  <p>Have a vision of what your brand could be but not sure how to get there? We
    have a solution for that!</p>
  <div class="bulletlist">
    <div class="borderw"><h4>We create context</h4><ul>

        <li >Brand foundation
        </li><li >
          Deep diagnostics</li>
        <li>
          Vision mapping</li>
        <li>
          Engagement strategy
        </li></ul></div>
    <div class>
      <h4>We ensure you leave a lasting impression</h4>
      <ul>
        <li>Iconic identity</li>
        <li>
          Sensory experiences</li>
        <li>
          Consumer insights</li>
        <li>
          Ongoing support</li>
      </ul>
    </div>
  </div>
</section>
<section class="section3">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 style="color: #fff;">We make branding a breeze </h3>
      <p>Using our company-specific approach and winning style guide, we collaborate with you to develop your unique brand identity and design.
        If you’re ready to start your branding journey, trust Endurance IT to make it a success.
        </p>
      <div class="bbtnsbox" ><a href="/contact-us" class="btnsbox">LET’S TALK
          BRANDING</a></div>
    </div></div>
</section>
