<app-homeheader></app-homeheader>
<div class="service-section" align="center">
    <span>
        <strong>From Input to Outcome,</strong> IT is all about you.
    </span>
    <p style="margin-top:10px;">If you're ready to grow and thrive in the digital age, then let us show you how to bring capabilities together to solve real business challenges.
    </p>
</div>
<app-homeslider></app-homeslider>

<div class="service-section" align="center">
    <div class="row innerservice">
        <div class="col-md-3 col-sm-12  d-inline-flex p2">
            <img alt="Managed  Services" src="assets/images/Managed.png" class="p1">
            <div class="p1" align="left">
                <h6>Managed<br> Services</h6>
                <div class="">
                    <a href="/services/managed-services" class="p2">More</a>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-12 d-inline-flex p2">
            <img alt="IT  Staffing" src="assets/images/IT-Staffing.png" class="p1">
            <div class="p1" align="left">
                <h6>IT<br> Staffing</h6>
                <div class="">
                    <a href="/services/it-staffing" class="p2">More</a>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-12 d-inline-flex p2">
            <img alt="IT Consultants Virginia Beach" src="assets/images/IT-Consulting.png" class="p1">
            <div class="p1" align="left">
                <h6>IT <br>Consulting</h6>
                <div class="">
                    <a href="services/it-consulting" class="p2">More</a>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-12 d-inline-flex p2">
            <img alt="Infrastructure  Services" src="assets/images/Infrastructure-Services.png" class="p1">
            <div class="p1" align="left">
                <h6>Infrastructure<br> Services</h6>
                <div class="">
                    <a href="services/infrastructure-services" class="p2">More</a>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-12 d-inline-flex p2" style="display: none !important;">
            <img alt="Digital Recruitment Services" src="assets/images/Digital-Recruitment.png" class="p1">
            <div class="p1" align="left">
                <h6>Digital<br> Recruitment</h6>
                <div class="">
                    <a href="services/digital-recruitment" class="p2">More</a>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-12 d-inline-flex p2">
            <img alt="Digital Marketing" src="assets/images/Digital-Marketing.png" class="p1">
            <div class="p1" align="left">
                <h6>Digital <br>Marketing</h6>
                <div class="">
                    <a href="services/digital-marketing" class="p2">More</a>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-12 d-inline-flex p2">
            <img alt="Application Development" src="assets/images/Application-Development.png" class="p1">
            <div class="p1" align="left">
                <h6>Application <br>Development</h6>
                <div class="">
                    <a href="services/application-development" class="p2">More</a>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-sm-12 d-inline-flex p2">
            <img alt="Web Design and  Development" src="assets/images/Web-Design.png" class="p1">
            <div class="p1" align="left">
                <h6>Web Design and<br> Development</h6>
                <div class="">
                    <a href="/services/webdesign-and-development" class="p2">More</a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="meet-our-experts" align="right" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/meet-our-expert.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677581121835) !Important;">
    <img alt="Endurance IT Services Favicon" src="assets/images/logo-sign.png">
    <div class="contentsboxes">
        <div></div>
        <div align="left">
            <h3>Our 
                People Make It Work
            </h3>
            <p>
                We offer an IT and digital marketing service for every business need. We're proud to offer our clients a team of dedicated professionals who are highly trained experts in their field and ready to offer their skills and support to your business. Our team members value trust, integrity, and collaboration and prioritize the importance of seeking the right answer at every point in the process, every time.
            </p>
            <a href="/about-us/our-team" class="redbtn">Meet Our Experts</a>

        </div>
    </div>
</div>
<div class="news-section" align="center">
    <h2>Latest newsroom
        <span> posts</span>
    </h2>
    <div class="row">
        <img alt="{{ newsRoom.result[0].post_title}}" src="assets/images/newdefault.png" class="col-md-5 newsimgsdefault" style="background: url({{ newsRoom.result[0].Imagess}})">
        <div class="col-md-7" align="left">
            <h2 class="newstitle">
                <a href="/newsroom/{{ newsRoom.result[0].post_name}}" [innerHTML]="newsRoom.result[0].post_title">
                    {{ newsRoom.result[0].post_title}}
                </a>
            </h2>
            <em>{{ newsRoom.result[0].post_date}}</em>
            <p><span [innerHTML]="(newsRoom.result[0].post_content.length>300)? (newsRoom.result[0].post_content | slice:0:300)+'....':(newsRoom.result[0].post_content)"></span> <a href="/newsroom/{{ newsRoom.result[0].post_name}}" > <img style="width: 1.6rem;  margin-left: 0.5rem;" src="/assets/images/blue-arrow.png"></a></p>
        </div>
    </div>
    <hr>
    <div class="row" align="left">
        <div class="boxsnews col-md-3 col-sm-12">
            <em>{{ newsRoom.result[1].post_date}} | {{ newsRoom.result[1].NoBlodTitle}}  </em>
            <br>
            <span>
                <a href="/newsroom/{{ newsRoom.result[1].post_name}}" [innerHTML]="(newsRoom.result[1].post_title.length>45)? (newsRoom.result[1].post_title | slice:0:45)+'....':(newsRoom.result[1].post_title)">
                    <span [innerHTML]="newsRoom.result[1].post_title"></span>
                </a>
            </span>
        </div>
        <div class="boxsnews col-md-3 col-sm-12">
            <em>{{ newsRoom.result[2].post_date}} | {{ newsRoom.result[2].NoBlodTitle}} </em>
            <br>
            <span>
                <a href="/newsroom/{{ newsRoom.result[2].post_name}}" [innerHTML]="(newsRoom.result[2].post_title.length>45)? (newsRoom.result[2].post_title | slice:0:45)+'....':(newsRoom.result[2].post_title)">
                    <span [innerHTML]="newsRoom.result[2].post_title"></span>
                </a>
            </span>
        </div>
        <div class="boxsnews col-md-3 col-sm-12">
            <em>{{ newsRoom.result[3].post_date}} | {{ newsRoom.result[3].NoBlodTitle}}</em>
            <br>
            <span>
                <a href="/newsroom/{{ newsRoom.result[3].post_name}}"  [innerHTML]="(newsRoom.result[3].post_title.length>45)? (newsRoom.result[3].post_title | slice:0:45)+'....':(newsRoom.result[3].post_title)">

                </a>
            </span>
        </div>
        <div class="boxsnews col-md-3 col-sm-12">
            <em>{{ newsRoom.result[4].post_date}} | {{ newsRoom.result[4].NoBlodTitle}}  </em>
            <br>
            <span>
                <a  href="/newsroom/{{ newsRoom.result[4].post_name}}"  [innerHTML]="(newsRoom.result[4].post_title.length>45)? (newsRoom.result[4].post_title | slice:0:45)+'....':(newsRoom.result[4].post_title)">
                    <span [innerHTML]="newsRoom.result[4].post_title"></span>
                </a>
            </span>
        </div>
    </div>
    <div style="padding-top: 3rem;"><a _ngcontent-hhf-c23="" href="/newsroom" class="redbtn">read more news</a></div>
</div>
