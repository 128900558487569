<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/Extra_Folder/ProgrammaticOTT%20advertising.png?updatedAt=1707510030354) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Programmatic/OTT <span>advertising</span>
  </h1>
  <h4>Highly targeted video advertising for maximum impact
  </h4><div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/googleads/googlead-icon.jpg"
          alt="Paid Advertising">
        <h2>The power of OTT media buying
        </h2>
      </div>
      <div class="content">
        <p>
          Short for “Over The Top,” OTT refers to a relatively new form of
          advertising that involves delivering customized and targeted ads to
          consumers across streaming media platforms.</p>
        <p>
          OTT is an alternative to traditional broadcast and cable television
          advertising, which blankets viewers with a single ad and often comes
          at a high cost. </p>
        <p>
          OTT is also different from video streaming ads on platforms like
          YouTube, which doesn’t provide the same control and data insights
          available under the OTT model. Further, OTT ads can be delivered on
          any Internet-enabled device, including phones, smart TVs, and gaming
          consoles.
        </p>
        <h3>The streaming services that leverage the power of OTT advertising
          include:
        </h3>
        <ul style="display: grid; grid-template-columns: 1fr 1fr;">

          <li> Hulu</li>
          <li> HBOmax</li>
          <li>Amazon Prime Video</li>
          <li> Peacock</li>
          <li> Disney+</li>
          <li> AppleTV
          </li>
        </ul>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/OTT/rightside.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677585554805"
        alt="Programmatic OTT Advertising">
    </div>
  </div>
</section>
<section class="section2 mainredbar" >
  <h3>Why is OTT advertising so powerful?</h3>
  <p>To put it simply, two people can stream the same episode or video and be
    served with completely different ads based on their preferences and
    probability of buying your product. </p>
  <p>OTT ads, unlink other forms of advertising, can’t be fast-forwarded (no
    DVRs here!) or skipped. Instead of viewers itching to click the “skip this
    ad” button, like they can on YouTube videos, they must watch your entire ad.
    So, let’s make it entertaining!</p>
  <h3 style="margin-bottom: 0px !important; margin-top: 2rem !important;">
    But, does OTT really work?
  </h3>
  <h4>Consider this statistic from OpenX:</h4>
  <p>While watching OTT content, 40% of viewers paused what they were watching
    to go online and research or buy what was being advertised to them. OTT advertising lets you get the right message in front of the right person
    at the right time. Other advantages include:</p>
  <div class="bulletlist">
    <div class="borderw">
      <ul>
        <li>Target physical addresses using GPS data to serve OTT/CTV ads with
          unmatched precision and scale</li>
        <li>Bring the flexibility of digital targeting to OTT advertising with
          the ability to target users based on keywords, context, and other
          online behaviors</li>
        <li>Measure foot traffic to brick-and-mortar locations, including retail
        </li>
      </ul>
    </div>
    <div class>
      <ul>
        <li>Take advantage of numerous combinations of demographic variables to
          target your ideal audience</li>
        <li>Get up to 96% view rates of your commercials on connected
          devices</li>
        <li>Target connected TV watchers who tend to be more engaged with your
          content and, therefore, more likely to buy</li>
      </ul>
    </div>

  </div>
</section>
<section class="accordian-section">
  <h3>When it comes to OTT advertising, you’ve got 4 options:</h3>
  <div class="accordion" id="accordionPanelsStayOpenExample">
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingOne">
        <button class="accordion-button collapsed" type="button"
          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne"
          aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
          <img src="/assets/images/service/ott/tab1icon.png"
            alt="Geofencing Solution">
          <div>
            <h4>Geofencing</h4>
            <span>Deliver your message everywhere your customers go
            </span>
          </div>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse "
        aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body">
          <section class="section1">
            <div class="row">
              <div class="col-sm-6 col-lg-6 col-md-6">
                <img alt="Geofencing"
                  src="https://ik.imagekit.io/b0cgaqv3j/OTT/tab1leftimage.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677585554199"
                  alt="Geofencing" class="shadow-lg">
              </div>
              <div class="col-sm-6 col-lg-6 col-md-6">

                <div class="content">
                  <p> Geofencing, also known as Location Based Advertising, is
                    the ideal solution for companies that want to target a group
                    of people at a specific event or location with a timely,
                    contextual message that influences their behavior. Simply
                    put, a geofence is an invisible “digital perimeter” that is
                    traced around an event location, building, neighborhood, or
                    even a competitor.
                  </p><p>
                    This digital perimeter gives advertisers the power to target
                    cell phone users to build an audience based on their visit
                    to that particular location during a scheduled timeframe.
                    When people enter the geo-fenced location, they
                    become part of an audience that can be marketing to even
                    after leaving the location.
                  </p><p>
                    Leading with geofencing marketing allows agencies and brands
                    to build comprehensive digital marketing full-funnel
                    strategies that translate into awareness, engagement, leads,
                    and sales. Geofencing is also an effective strategy for
                    achieving reach at a scale with a modest advertising budget.
                  </p>

                </div>
              </div>

            </div>
          </section>
          <section class="section6">
            <h3>Geofencing is:</h3>

            <ul class="columns3box">
              <li>
                <div>
                  <img src="assets/images/service/ott/geo1.png"
                    alt="Highly Precise">
                  <p>Highly Precise</p>
                </div>
                <p>Targeting is based on plat map data from property tax and
                  public land surveying information to maximize the precision of
                  addresses being targeted up to a 90% match rate.
                </p>
              </li>
              <li>
                <div>
                  <img src="assets/images/service/ott/geo2.png"
                    alt="Highly Scalable">
                  <p>Highly Scalable</p>
                </div>
                <p>Up to 1 million physical household and business addresses can
                  be targeted per campaign for exposure to massive audiences at
                  scale.</p>
              </li>
              <li>
                <div>
                  <img src="assets/images/service/ott/geo3.png"
                    alt="Accurate in Foot Traffic Attribution">
                  <p class="mt-1">Accurate in Foot Traffic Attribution</p>
                </div>
                <p>Conversion Zones can be used with geofencing campaigns to
                  track an uplift in foot traffic and offer redemptions to an
                  advertiser’s location.</p>
              </li>
              <li>
                <div>
                  <img src="assets/images/service/ott/geo4.png"
                    alt="Available in multiple creative formats">
                  <p class="mt-1">Available in Multiple Creative Formats</p>
                </div>
                <p>Choose from static ads, video ads, and radio campaigns with
                  flexible creative formatting to fit your brand. </p>
              </li>
              <li>
                <div>
                  <img src="assets/images/service/ott/geo5.png"
                    alt="More precise than IP Targeting">
                  <p class="mt-1">More Precise Than IP Targeting</p>
                </div>
                <p>Addressable geofencing is even more precise than IP address
                  targeting, yielding a 90% match rate. </p>
              </li>
              <li>
                <div>
                  <img src="assets/images/service/ott/geo6.png"
                    alt="Effective Across Industries">
                  <p>Effective Across Industries</p>
                </div>
                <p>According to Intuit, 53% of consumers say they have received
                  an offer via a geofence alert and acted on it.
                </p>
              </li>

            </ul>
            <p class="center f28">The best part about geofencing? It’s easy to
              implement! The only requirement is that cell phone users have
              their Location Services turned on, 90% of which already do.</p>

            <div class="bbtnsbox center">
              <a href="/contact-us" class="btnsbox">Contact us today</a></div>
          </section>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
        <button class="accordion-button collapsed" type="button"
          data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo"
          aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
          <img src="/assets/images/service/ott/tab2icon.png"
            alt="Programatic Audio">
          <div>
            <h4>Programmatic Audio</h4>
            <span>Highly targeted ads powered by automation</span>
          </div>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
        aria-labelledby="panelsStayOpen-headingTwo">
        <div class="accordion-body">
          <section class="section1">
            <div class="row">
              <div class="col-sm-6 col-lg-6 col-md-6">
                <div class="content">
                  <p>Programmatic audio advertising is the automated buying and
                    placing of ads in audio-based content such as podcasts,
                    audio articles, digital radio, and streaming music services.
                    As a result, advertisers get more precise targeting, more
                    accurate measurements, and the ability to retarget
                    audiences.
                  </p>

                  <p>

                    Today, people are bombarded with visual advertising, leading
                    to “banner blindness.” Programmatic audio is an important
                    tool in your marketing mix because it allows brands to reach
                    audiences in a new and innovative way that cuts through the
                    noise and demands attention.
                  </p>
                </div>
              </div>
              <div class="col-sm-6 col-lg-6 col-md-6">
                <img alt="Programmatic audio advertising"
                  src="https://ik.imagekit.io/b0cgaqv3j/OTT/tab2rightimage.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677585554736"
                  alt="Programmatic audio advertising">
              </div>
            </div>
          </section>
          <section class="section4">
            <h3>Audio in Action</h3>
            <h4>Here are six viable audio
              formats
              Endurance can deploy:</h4>
            <ul class="listofservice">
              <li align="center"><img src="/assets/images/service/ott/1.png"
                  alt="Recorded Audio Ads">
                <h4>Recorded Audio Ads</h4>
                <p>
                  Available in standard lengths of 15, 30, and 60 seconds, audio
                  ads come with three placings: pre-roll (before audio content),
                  mid-roll (during a section), and post-roll (after audio
                  content), and can be
                  dynamically inserted.
                </p>
              </li>
              <li align="center">
                <img src="/assets/images/service/ott/2.png"
                  alt="Companion Display Ads">
                <h4>Companion Display Ads</h4>
                <p>
                  Static banners are paired with the audio ad track and
                  displayed in the audio unit while the content is playing.
                </p><p>
                  This adds context to the ad and drives conversions.
                </p>
              </li>
              <li align="center"><img src="/assets/images/service/ott/3.png"
                  alt="Native ads">
                <h4>Native Ads</h4>
                <p>
                  These ads blend seamlessly with content because they match the
                  look and feel of the host’s media source.
                </p>
              </li>
              <li align="center"><img src="/assets/images/service/ott/4.png"
                  alt="Audio ad pods">
                <h4>Audio Ad Pods</h4>
                <p>
                  An audio ad pod is a series of ads played back-to-back. In
                  traditional terms, these were referred to as “ad breaks.”
                </p>
              </li>
              <li align="center"><img src="/assets/images/service/ott/5.png"
                  alt="Rewarded audio">
                <h4>Rewarded Audio</h4>
                <p>
                  These ads are delivered to gamers who listen to a short audio
                  ad
                  track in exchange for a bonus upon completion. </p>
              </li>
              <li align="center"><img src="/assets/images/service/ott/6.png"
                  alt="Voice-activated ads">
                <h4>Voice-Activated Ads</h4>
                <p>
                  Interactive and engaging, these create an experience with
                  specific calls to action that elicit a response.
                </p>
              </li>

            </ul>
            <h4 style="margin-bottom: 2rem;">Ready to try programmatic audio OTT
              advertising?
            </h4>
            <div class="bbtnsbox center">
              <a href="/contact-us" class="btnsbox ">let's talk about
                it</a></div>
          </section>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingThree">
        <button class="accordion-button collapsed" type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
          aria-controls="panelsStayOpen-collapseThree">
          <img src="/assets/images/service/ott/tab3icon.png"
            alt="Programmatic Display Advertisement">
          <div>
            <h4>Programmatic Display Advertising
            </h4>
            <span>Harness the power of real-time bidding to stretch your ad
              budget</span>
          </div>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
        aria-labelledby="panelsStayOpen-headingThree">
        <div class="accordion-body">
          <section class="section1">
            <div class="row">
              <div class="col-sm-6 col-lg-6 col-md-6">
                <img alt="Programmatic Display Ad Campaign"
                  src="https://ik.imagekit.io/b0cgaqv3j/OTT/tab3image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677585554264"
                  alt="Programmatic Display Ad Campaign" class="shadow-lg">
              </div>
              <div class="col-sm-6 col-lg-6 col-md-6">

                <div class="content">
                  <p>Programmatic Display is an automated form of purchasing
                    display advertising inventory that reduces manual work and
                    human guessing, giving you more time to optimize and
                    strategize other parts of your business.</p>
                  <p>This form of advertising works by using Real-Time Bidding
                    (RTB), which means bidding on individual impressions based
                    on their potential value to your business. Instead of paying
                    flat, over-inflated fees based on a CPM (cost per 1,000
                    impressions) model, you pay a dynamic CPM that targets users
                    who are most likely to convert.</p>
                </div>
              </div>

            </div>
          </section>
          <section class="section4">
            <h3>Display Ad benefits:</h3>

            <ul class="columns3box">
              <li>
                <img src="assets/images/service/ott/ad1.png"
                  alt="Visually appealing">
                <p>Visually appealing</p>
              </li>
              <li>
                <img alt="Build brand awareness"
                  src="assets/images/service/ott/ad2.png">
                <p>Build brand awareness</p>
              </li>
              <li>
                <img alt="Highly targeted"
                  src="assets/images/service/ott/ad3.png">
                <p>Highly targeted</p>
              </li>
              <li>
                <img alt="Retargeting capabilities"
                  src="assets/images/service/ott/ad4.png">
                <p>Retargeting capabilities</p>
              </li>
              <li>
                <img alt="Multi-format reach"
                  src="assets/images/service/ott/ad5.png">
                <p>Multi-format reach</p>
              </li>
              <li>
                <img alt="Block competitive ads"
                  src="assets/images/service/ott/ad6.png">
                <p>Block competitive ads</p>
              </li>

            </ul>
            <p class="center f28">Programmatic display ads can be used across
              multiple platforms,
              including social media, to achieve maximum awareness. Want to learn more?</p>
            <div class="bbtnsbox center">
              <a href="/contact-us" class="btnsbox">let's talk about
                it</a></div>
          </section>
        </div>
      </div>
    </div>
    <div class="accordion-item">
      <h2 class="accordion-header" id="panelsStayOpen-headingFour">
        <button class="accordion-button collapsed" type="button"
          data-bs-toggle="collapse"
          data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
          aria-controls="panelsStayOpen-collapseThree">
          <img alt="Video Ads" src="/assets/images/service/ott/tab4icon.png">
          <div>
            <h4>Video Ads</h4>
            <span>Innovate or get left behind
            </span>
          </div>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
        aria-labelledby="panelsStayOpen-headingFour">
        <div class="accordion-body">
          <section class="section1">
            <div class="row">
              <div class="col-sm-6 col-lg-6 col-md-6">
                <div class="content">
                  <p>From silent movies to YouTube videos, motion pictures have
                    captivated audiences for more than a century. If you want to
                    get your message to the masses, video is the ideal medium.
                  </p>
                  <p>The key to success with video ads is to connect with your
                    audience and inspire them to convert. Endurance helps with
                    everything from script writing to video production.</p>
                </div>
              </div>
              <div class="col-sm-6 col-lg-6 col-md-6">
                <img alt="script writing to video production."
                  src="https://ik.imagekit.io/b0cgaqv3j/OTT/tab4image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677585554201">
              </div>
            </div>
          </section>
          <section class="section4">
            <h3>Why Video?</h3>
            <ul class="listofservice">
              <li class="bulletlistr">
                <div><img src="/assets/images/service/ott/1.png">
                  <h4>Your customers are watching</h4>
                </div><p>Did you know…</p>
                <ul>
                  <li>92% of B2B prospects consume online video in their
                    decision-making process</li>
                  <li>4 billion videos are watched on YouTube daily</li>
                  <li>About one-third of shoppers purchase a product after
                    viewing a video ad</li>
                </ul>
              </li>
              <li>
                <div><img src="/assets/images/service/ott/2.png">
                  <h4> Video ads convert</h4></div>
                <p>The biggest names in online marketplaces, including Amazon
                  and eBay, report that adding a video ad to a product page
                  increases the chances of a shopper buying that item by up to
                  35%.</p>
              </li>
              <li>
                <div><img src="/assets/images/service/ott/3.png">
                  <h4>Video is sharable</h4></div>
                <p>When people like your video, they’ll share it! More than 700
                  videos are shared by Twitter users every minute. Ready to go
                  viral for all the right reasons? We can help.
                </p></li>
              <li>
                <div><img src="/assets/images/service/ott/4.png">
                  <h4>Mobile users love video</h4></div>
                <p>Everyone seems attached to their phones, and that means
                  you’ve got a captive audience to share your content with.
                  While
                  desktop users tend to scroll through video ads, 88% of videos
                  on mobile phones are watched to the end when they’re under 30
                  seconds.
                </p></li>
              <li>
                <div><img src="/assets/images/service/ott/5.png">
                  <h4>Video educates, informs, and entertains</h4></div>
                <p>Video can connect with audiences in a way that plain text and
                  static images can’t match. How-to videos, product
                  demonstrations, and entertainment are just a few of the
                  possible ways to use video to sell products and services.
                </p></li>

              <li>
                <div><img src="/assets/images/service/ott/6.png">
                  <h4>Search engines reward video</h4></div>
                <p>Have you ever noticed that videos appear at the top of the
                  Google search
                  results pages? Now that Google owns YouTube, you have
                  unlimited
                  opportunities to attract your target audience by publishing
                  videos on your website, YouTube channel, and other video
                  hosting platforms.</p></li>
              <li>
                <div><img src="/assets/images/service/ott/7.png" alt="Seven">
                  <h4>Video is efficient</h4></div>
                <p>Long blocks of text have their place, but nothing is more
                  efficient than video when sharing a complex topic or engaging
                  all of the senses.
                </p></li>
              <li>
                <div><img src="/assets/images/service/ott/8.png" alt="Eight ">
                  <h4>Video tells a story</h4></div>
                <p>Humans love telling and hearing stories, and video
                  facilitates that process. Use video to build trust and elicit
                  an emotional response.</p></li>
              <li>
                <div><img src="/assets/images/service/ott/9.png" alt="Nine">
                  <h4>Get in-depth analytics</h4></div>
                <p>Video platforms provide you with detailed analytics about
                  your audience’s demographics, watch times, and actions. You
                  can then use this data to make informed decisions about future
                  campaigns.</p></li>
              <li>
                <div><img src="/assets/images/service/ott/10.png" alt="Ten">
                  <h4>What’s Next?</h4></div>
                <p>Video advertising is one of the most versatile and profitable
                  digital marketing tools available today.
                </p>
                <br><p>The next step: Get started!</p>
                <div class="bbtnsbox">
                  <a href="/contact-us" class="btnsbox">let's talk about
                    it</a></div>
              </li>
            </ul>

          </section>
        </div>
      </div>
    </div>
  </div>
</section>
