import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-business',
  templateUrl: './small-business.component.html',
  styleUrls: ['./small-business.component.css']
})
export class SmallBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
