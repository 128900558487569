<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/ProjectManagement/DataCenterProject/banner.jpg?updatedAt=1677586028664) !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Data <span>center <br>project management</span></h1>
    <h4>Evaluation and planning to avoid <br>delays and cost increases</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img alt="Structured, standardised project management" src="/assets/images/service/ProjectManagement/DataCenterProject/service-icon.png">
                <h2>Structured, standardized <br><span>project management</span></h2>

            </div><div class="content">
                <p>Our data center project managers have the specialized skills, knowledge, and tools to oversee your data center infrastructure project. From a complete migration to installing new servers, Endurance can do it all.</p>
                <p>With extensive experience in IT project management, our experts will ensure you avoid delays, stay on budget, and remain focused and efficient.</p>
                </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6"><img src="https://ik.imagekit.io/b0cgaqv3j/service/ProjectManagement/DataCenterProject/rightsideimage.png?updatedAt=1677586032970" alt="Get in Touch"></div>
    </div>
</section>
<section class="section2">

      <h3 style="font-size: 1.5rem !important;
      font-weight: 700 !important;
      font-family: 'Montserrat' !important;">Projects include:</h3>

    <div class="bulletlist">
        <div class="borderw">
            <ul>
            <li>Data center migration</li>
            <li>Data center consolidation</li>
            <li>Building new sites or cabinet rows</li>
            <li>Installing network connectivity</li>
            </ul>
        </div>
        <div>
            <ul>
                <li>Increasing power to a footprint</li>
                <li>Upgrading device components</li>
                <li>Decommissioning assets</li>
                <li>Installing new servers</li></ul></div>
    </div>
    <div style=" display: none;    font-family: 'Montserrat'; line-height: 2;
    font-size: 1.2rem!important;">and more!</div>
</section>
<section class="section3">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3 style="color:#fff">Meet your project requirements on time and within budget</h3>
            <p>Our data center project managers are best-in-class. From consolidating tools to improving efficiency and collaboration, we help you adhere to your KPIs and complete tasks ahead of schedule.</p>
            <p>Ready to learn more?</p>

            <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">GET IN TOUCH</a></div>
        </div></div>
</section>
