<header class="serviceinner">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Infrastructure <span>services</span></h1>
    <h4>Cost-effective digital transformation <br>to accelerate business results</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="newheadingbox">
    <h2>Advanced Digital <span>Technologies and Infrastructure</span></h2>
    <p>Create real change across your organization</p>
</section><section class="callout-boxes">

    <div>
        <img _ngcontent-fqk-c105="" src="/assets/images/call-out/Infrastructure/Cutting-Edge.png" alt="Cutting-edge">
        <h4>Cutting-edge</h4>
        <p>Advanced new age digital technologies to transform your IT</p>
    </div>
    <div>
        <img src="/assets/images/call-out/Infrastructure/Cost-Effective.png" alt="Cost-effective">
        <h4>Cost-effective</h4>
        <p>Streamlined, scalable infrastructure for your growing business</p>
    </div>
    <div>
        <img src="/assets/images/call-out/Infrastructure/Client-Focused.png" alt="Client-focused">
        <h4>Client-focused</h4>
        <p>Customized solutions that work for your unique challenges</p>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="gridboxed">
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/1.png" alt="Networking Solutions"></div>
                <div class="heading">
                    <h3>Networking <span>Solutions</span></h3>
                </div>
                <div class="peragraph">Scalable and secure networking solutions to grow alongside you</div>
                <div class="buttons"><a href="services/infrastructure-services/networking">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/2.png" alt="Compute / Server"></div>
                <div class="heading">
                    <h3>Compute<span>/Server</span></h3>
                </div>
                <div class="peragraph">The perfect server selection for your business that scales as you do</div>
                <div class="buttons"><a href="services/infrastructure-services/computeserver">Learn More</a></div>
            </div>
            <div>
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/3.png" alt="Data Storage"></div>
                <div class="heading">
                    <h3>Data <span>Storage</span></h3>
                </div>
                <div class="peragraph">Leading data storage solutions to improve business performance</div>
                <div class="buttons"><a href="services/infrastructure-services/data-storage">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/4.png" alt="Data Virtualization"></div>
                <div class="heading">
                    <h3>Data <span>Virtualization</span></h3>

                </div>
                <div class="peragraph">A virtual infrastructure to reduce costs and improve efficiency</div>
                <div class="buttons"><a href="services/infrastructure-services/virtualization">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/5.png" alt="Data Center"></div>
                <div class="heading">
                    <h3>Data <span>Center</span></h3>
                </div>
                <div class="peragraph">Seamless data access to streamline your business processes</div>
                <div class="buttons"><a href="services/infrastructure-services/data-center">Learn More</a></div>
            </div>
            <div>
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/6.png" alt="Cloud"></div>
                <div class="heading">
                    <h3>Cloud</h3>
                </div>
                <div class="peragraph">Faster innovation and flexible resources with the Cloud</div>
                <div class="buttons"><a href="services/infrastructure-services/cloud">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/7.png" alt="Cyber Security"></div>
                <div class="heading">
                    <h3>Cyber <span>Security</span></h3>
                </div>
                <div class="peragraph">Secure your data, systems and network with fully managed cybersecurity</div>
                <div class="buttons"><a href="services/infrastructure-services/security">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/8.png" alt="Disaster Recovery"></div>
                <div class="heading">
                    <h3>Disaster<span> Recovery</span> </h3>
                </div>
                <div class="peragraph">Assessment and planning to get you back to business, fast
                </div>
                <div class="buttons"><a href="services/infrastructure-services/disaster-recovery">Learn More</a></div>
            </div>
            <div>
                <div class="icon"><img src="assets/images/service/Infrastructure_Services/9.png" alt="Technology Assessments"></div>
                <div class="heading">
                    <h3>Technology <span>Assessments</span></h3>
                </div>
                <div class="peragraph">A complete technology evaluation to identify any IT issues</div>
                <div class="buttons"><a href="services/infrastructure-services/technology-assessments">Learn More</a></div>
            </div>
        </div>
    </div>
</section>

<section class="section3">
    <div class="logoicon"><img src="assets/images/service/digitalmarkting/endurance_icon.png" alt="Endurance IT Services Favicon"></div>
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3 style="color:#fff">Our Solutions and Services
                are all about you.
            </h3>
            <p>Now more than ever, businesses must address the current challenges of traditional infrastructure and respond to the new age digital transformation requirements. How can they innovate, transform and set new directions for growth by leveraging
                the advanced digital technologies and infrastructure in a cost-effective manner?</p>
            <p> That’s where we come in. Using a digital transformation strategy, we simplify and accelerate business results through innovative, client-focused IT and digital marketing services that create real change and demonstrate real growth.
            </p>
            <div class="bbtnsbox">
                <a href="/contact-us" class="btnsbox">Connect with us</a>
            </div>
        </div>
    </div>
</section>
