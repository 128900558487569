<header>
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <div class="navbar-collapse collapse show" id="mynavbar">
                <ul class="navbar-nav">
                    <li class="nav-item hovermenu">
                        <a class="nav-link" href="/about-us">About Us</a><ul>
                          <li style="display: none;"><a href="about-us/leadership">Leadership</a></li>
                          <li><a href="about-us/leadership">Leadership</a></li>
                          <li style="display: none;"><a href="about-us/our-team">Our Team</a></li>
                          <li><a href="about-us/giving-back">Giving Back</a></li><li><a href="about-us/our-team">Our Team</a></li>
                      </ul>
                    </li>
                    <li class="nav-item hovermenu">
                        <a class="nav-link" href="javascript:void(0)">solutions</a><ul>
                          <li><a href="solutions/small-business">Small  Business</a></li>
                          <li><a href="solutions/medium-business">Medium Business</a></li>
                          <li><a href="solutions/enterprise">Enterprise</a></li>
                          <li><a href="solutions/government-contractors">Government  Contractors</a></li>
                      </ul>
                    </li>
                    <li class="nav-item service">
                        <a class="nav-link " href="javascript:void(0)">Services</a>
                        <app-servicemenu></app-servicemenu>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)">Our Clients</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)">Careers</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)">Contact Us</a>
                    </li>
                </ul>
                <button class="btn" type="button" id="get-support">Get Support</button>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                    <i class="fa fa-bars"></i>
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </div>
    </nav>
    <h1>Our</h1>
    <h4>Management</h4>
</header>
