import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-support-header',
  templateUrl: './support-header.component.html',
  styleUrls: ['./support-header.component.css']
})
export class SupportHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
