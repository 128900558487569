
<header
  style="background-image: none !important; " class="fixedheader ">
  <div class="container">
    <nav class="navbar navbar-expand-sm">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img class="mainlogo"
            src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
            alt="Endurqance IT Services"></a>

        <app-nav></app-nav>
      </div>
    </nav>
    <div [innerHTML]="breadcrumn" class="blogbreadcrumn">{{breadcrumn}}</div>

    <h1 [innerHTML]="titles" class="blogmaintitles"></h1>
    <h4 [innerHTML]="subtitles" class="blogsubtitles">Read about the latest news
      and notes from our team.</h4>
  </div>
</header>
<section class="pt-5 featuredPosts container"
  *ngIf="newsRoom.result.featured.length>1">
  <h3>Featured</h3>
  <div class="row">
    <div class="col-6" *ngFor="let post of newsRoom.result.featured">
      <div class="featuredPostsboxes">
        <img class="mainbannerimage"
          style="background-image: url({{post.featuredImage}});    background-size: cover;"
          src="https://ik.imagekit.io/b0cgaqv3j/defaults_blog.png?updatedAt=1706202176893" />
        <div class="content">
          <h5 class="maincategory"><a (click)="getNews()"
              routerLink="/blog/category/{{post.categories[0].slug}}">{{post.categories[0].name}}</a></h5>
          <h6><a href="blog/{{post.post_name}}">{{post.post_title}}</a></h6>
          <div class="authorsdetails">
            <div class="author"><img src="assets/images/icons/user.svg" alt="editorial team"><span>
                {{post.authorDetails.display_name}}</span></div>
            <div class="calender"><img
                src="assets/images/icons/calendar.svg" alt="calender"><span>
                {{post.PostCreateDate}}</span></div>
          </div>
          <p [innerHTML]="post.smallcontent">Through disaggregation of the
            traditional roles and
            technology involved in telecommunications applications,</p>
          <a class="buttons" href="blog/{{post.post_name}}"
            class="readmore-btn">Read More</a>
          <hr>
          <div class="social-icons">
            <a href><img src="assets/images/icons/linkedin.svg" alt="LinkedIn"></a>
            <a href><img src="assets/images/icons/facebook.svg" alt="Meta"></a>
            <a href><img src="assets/images/icons/twitter.svg"  alt="X"></a>
            <a href><img src="assets/images/icons/email.svg" alt="email"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="blog-list" id="bpost" class="{{BClass}}">
  <h3 class="SeachingData">
    <div class="SearchingBlogs"><span class="fixedspan">
        <span class="sortItemsLabel">Sort by: </span>
        <select class="sortItems" (change)="sortItems()"
          [(ngModel)]="sortOrder">
          <option value="asc">Newest to oldest</option>
          <option value="dec">Oldest to newest</option>
        </select>
        <select [(ngModel)]="searchCat" class="searchbycat"
          (change)="onCategoryChange($event)">
          <option value="blog">Category</option>
          <option *ngFor="let tab of newsRoom.result.categories"
            value="{{tab.slug}}">{{tab.name}}</option>
        </select>
        <input type="text" [(ngModel)]="searching" placeholder="Search"
          class="searchbox"><input type="button" value="search"
          (click)="search()" class="searchbutton">
      </span></div>
  </h3>
  <div class="category-names" style="display: none;">
    <a (click)="getNews()" routerLink="{{BlogLink}}" class="nav-link" id="all"
      class="active">All</a>
    <a (click)="getNews()" *ngFor="let tab of newsRoom.result.categories"
      routerLink="/blog/category/{{tab.slug}}" class="nav-link"
      id="{{tab.slug}}">{{tab.name}}</a>
  </div>
  <section class="boxes">
    <div class="row">
      <div *ngIf="isDivVisible==0" class="spineers"><i
          class="fa fa-spin fa-sync"></i></div>

      <div class="col-4 spinnerend"
        *ngFor="let post of newsRoom.result.blog | filter: searchTerm | paginate : {itemsPerPage: tableSize, currentPage: page,totalItems: count}; let i = index">

        <div class="featuredPostsboxes" *ngIf="isDivVisible==1">
          <img class="mainbannerimage"
            style="background-image: url({{post.featuredImage}});    background-size: cover;"
            src="https://ik.imagekit.io/b0cgaqv3j/defaults_blog.png?updatedAt=1706202176893" />
          <div class="content">
            <h5 class="maincategory"><a (click)="getNews()"
                routerLink="/blog/category/{{post.categories[0].slug}}">{{post.categories[0].name}}</a></h5>
            <h6><a href="blog/{{post.post_name}}">{{post.post_title}}</a></h6>
            <div class="authorsdetails">
              <div class="author"><img style="width: 19px;"
                  src="assets/images/icons/user.svg"  alt="editorial team"><span
                  style="font-size: 0.8rem;">
                  {{post.authorDetails.display_name}}</span></div>
              <div class="calender"><img style="width: 19px;"
                  src="assets/images/icons/calendar.svg"  alt="calender"><span
                  style="font-size: 0.8rem;">
                  {{post.PostCreateDate}}</span></div>
            </div>
            <p [innerHTML]="post.smallcontent">Through disaggregation of the
              traditional roles and
              technology involved in telecommunications applications,</p>
            <a class="buttons" href="blog/{{post.post_name}}"
              class="readmore-btn">Read More</a>
            <hr>

            <div class="social-icons">
              <a
                (click)="linkedin(post.post_title,'https://devendurance.com/blog/'+post.post_name)"><img
                  src="assets/images/icons/linkedin.svg"  alt="LinkedIn"></a>
              <a
                (click)="facebook(post.post_title,'https://devendurance.com/blog/'+post.post_name)"><img
                  src="assets/images/icons/facebook.svg"  alt="Meta"></a>
              <a
                (click)="twitter(post.post_title,'https://devendurance.com/blog/'+post.post_name)"><img
                  src="assets/images/icons/twitter.svg"  alt="X"></a>
              <a
                (click)="mail(post.post_title,'https://devendurance.com/blog/'+post.post_name)"><img
                  src="assets/images/icons/email.svg"  alt="email"></a>
              <a href="/pdf/?b={{post.post_name}}" target="_new"><img
                  src="assets/images/icons/pdf.svg" alt="pdf"></a>
              <a href="/pdf/?b={{post.post_name}}&print=1" target="_new"><img
                  src="assets/images/icons/print.svg"  alt="print"></a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <pagination-controls
    previousLabel="Prev"
    nextLabel="Next"
    (pageChange)="onTableDataChange($event)">
  </pagination-controls>
</section>
