import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-survery-thankyou',
  templateUrl: './survery-thankyou.component.html',
  styleUrls: ['./survery-thankyou.component.css']
})
export class SurveryThankyouComponent implements OnInit {
  clientTesttimonial: any = [];
  rand: number = 0;

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private MetaService: MetaServiceService) { }
  ngOnInit(): void {
    this.rand = (Math.random() + 1);
    const url = '/assets/blog/ClientsTestimonial.json?v=' + this.rand;
    this.http.get(url).subscribe(res => {
      console.log(res, 'HI');
      this.clientTesttimonial = res;

    });
  }



}
