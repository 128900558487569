<header class="serviceinner" >
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Cybersecurity</h1>
    <h4>Secure your data, systems and network <br>with fully managed cybersecurity</h4>
    <div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">GET IN TOUCH</a>
    </div>
</header>
<section class="text-section newheadingbox" align="Center">
    <h2>Advanced cybersecurity <span>solutions</span></h2>
    <p>Scalable, subscription-based Security Operations Center services with packages to suit your requirements and budget, covering Vulnerability Management and Intrusion Detection</p>
 </section>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/Security/icon.png" alt="SOC as a Service">
                <h2>SOC <span>as a Service</span></h2>

            </div>
            <div class="content">
                <p>A managed alternative to hiring, developing and coordinating your own internal security team. Get access to expert cybersecurity knowledge and experience in an effective and affordable package.</p>
                <div _ngcontent-psv-c86="" class="bbtnsbox">
                    <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">learn more</a>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/service/Security/service1.jpg?updatedAt=1677586009341" alt="expert cybersecurity knowledge "></div>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/service/Security/service2.jpg?updatedAt=1677586007777" alt="potential security vulnerabilities"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/Security/service-icon-2.png" alt="Vulnerability Management">
                <h2>Vulnerability <span>Management</span></h2>

            </div>
            <div class="content">
                <p>A scanning and reporting solution that detects any potential security vulnerabilities on your network. A weekly report will be provided including the necessary corrective actions and approximate time frame for fixing.</p>

                <div _ngcontent-psv-c86="" class="bbtnsbox">
                    <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">learn more</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/Security/service-icon-3.png" alt="Intrusion Detection">
                <h2>Intrusion <span>Detection</span></h2>

            </div>
            <div class="content">
                <p>A real-time network monitoring system that flags suspicious activity across your network - both internal and external - and immediately alerts you to it, so the attacker can be disarmed before any damage is done.</p>
                <div _ngcontent-psv-c86="" class="bbtnsbox">
                    <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">learn more</a>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/service/Security/service3.jpg?updatedAt=1677586007003" alt="suspicious activity "></div>
    </div>
</section>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg" src="https://ik.imagekit.io/b0cgaqv3j/service/Security/service4.jpg?updatedAt=1677586009148" alt="Cloud Security "></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/Security/service-icon-4.png" alt="Cloud Security ">
                <h2>Cloud <span> Security </span> </h2>

            </div>
            <div class="content">
                <p>As you move infrastructure, applications, and data to the cloud, don’t forget about cloud security. Security in this environment is just as important as security for your in-house IT systems. Protect your digital assets from hackers, malware, DDoS attacks, and unauthorized access.</p>

                <div _ngcontent-psv-c86="" class="bbtnsbox">
                    <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">learn more</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section2">
    <p style="font-size: 1.75rem!important; font-family: 'Montserrat' !important; font-weight: 400!important;">A cyberattack can irreversibly harm the reputation of your company. With the rising cost of staffing specialized security and cyber-attack recovery, a managed solution will ensure that your business and reputation are safeguarded at an affordable price point.<strong>In addition to the core services above, we offer a full suite of cybersecurity solutions:</strong></p>
    <div class="bulletlist">
        <div class="borderw">
            <img src="/assets/images/service/Security/orange-icon1.png" alt="Penetration Testing">
            <h5>Penetration Testing</h5>
            <div>We deploy hacker simulations attempting to gain access to systems and data in order to uncover potential vulnerabilities and weaknesses.</div>
        </div>
        <div class="borderw">
            <img src="/assets/images/service/Security/orange-icon-2.png" alt="Network Security Audit">
            <h5>Network Security Audit</h5>
            <div>We review your infrastructure and applications to determine security vulnerabilities and risk factors, then provide recommendations for securing your systems.</div>

        </div>
        <div class="borderw">
            <img src="/assets/images/service/Security/orange-icon-3.png" alt="PCI Scanning">
            <h5>PCI Scanning</h5>
            <div>We make sure your business is storing and transmitting credit card data within the law, according to Payment Card Industry (PCI) compliance.</div>

        </div>


    </div>
  </section>
<section class="section3">
    <div class="logoicon"><img src="assets/images/service/digitalmarkting/endurance_icon.png" alt="Endurance IT Services Favicon"></div>
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3 style="color:#fff">How do you get started with cybersecurity?</h3>
            <p>Determining the cybersecurity controls, tools, and frameworks your company needs to function legally and securely can be a minefield. From the NIST Cybersecurity Framework (CSF) to Critical Security Controls from the Center for Internet Security (CIS), it’s difficult to know where to begin. Endurance can help by carrying out a full security audit across your organization, analyzing the results, and offering guidance for the best way to proceed.</p>
            <p>We’ll manage your security concerns, so you don’t have to.</p>
            <div _ngcontent-psv-c86="" class="bbtnsbox">
                <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">LET’S TALK ABOUT IT</a>
            </div>
        </div>
    </div>
</section>
