<header
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/solutions/inner/Medium/Banner.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613799678) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services"></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>Medium <span>business</span></h1>
  <h4>Build your business with expert IT <br>and Digital Marketing support</h4>
  <div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="firstsection row">
  <div class="col-md-6"><img alt="IT and Digital Marketing for medium business"
      src="https://ik.imagekit.io/b0cgaqv3j/solutions/inner/Medium/leftside.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613801900"
      class="shadow-lg"></div>
  <div class="col-md-6"><br><br>
    <div class="row">
      <div class="col-md-2" align="center"><img alt="medium"
          src="assets/images/solutions/inner/Medium/medium.png"></div>
      <div class="col-md-10 heading">
        <h2>IT and Digital Marketing for medium business</h2>
        <h3>The right fit for any size business</h3>
      </div>
    </div>
    <p>We offer a wide variety of solutions and services to complement your IT
      and Marketing teams or provide our own, to help your medium-size business
      with the following:</p>
    <ul class="bulletshow">
      <li>IT Managed
        Services</li>
      <li>IT
        Consulting</li>
      <li>IT
        Staffing</li>
      <li>IT
        Projects</li>
      <li>Digital
        Marketing</li><li>Web/App
        Development</li>
    </ul>
  </div>
</section>
<section class="secondsection ">
  <div class="innericons">
    <div><div class="icons"><img alt="IT Managed Services"
          src="assets/images/solutions/inner/Medium/IT Managed Services.png"></div>
      <h4>IT Managed <br> Services</h4><span><a
          href="/services/managed-services">More</a> </span></div>
    <div><div class="icons"><img alt="consulting"
          src="assets/images/solutions/inner/Medium/consulting.png"></div><h4>IT
        <br> Consulting</h4><span><a href="/services/it-consulting">More</a>
      </span></div>
    <div><div class="icons"><img alt="staffing"
          src="assets/images/solutions/inner/Medium/staffing.png"></div><h4>IT
        <br> Staffing</h4><span><a href="/services/it-staffing">More</a> </span></div>
    <div><div class="icons"><img alt="IT projects"
          src="assets/images/solutions/inner/Medium/IT projects.png"></div><h4>IT
        <br> Projects</h4><span><a href="/services/project-management">More</a>
      </span></div>
    <div><div class="icons"><img alt="digital marketing"
          src="assets/images/solutions/inner/Medium/digital marketing.png"></div><h4>Digital
        <br> Marketing</h4><span><a href="/services/digital-marketing">More</a>
      </span></div>
    <div><div class="icons"><img alt="web developmnt"
          src="assets/images/solutions/inner/Medium/web developmnt.png"></div><h4>Web/App
        <br> Development</h4><span><a href="/services/application-development">More</a>
      </span></div>
  </div>
</section>
<section class="thiredsection">
  <div class="contentwithbutton">
    <div class="innercontent"><div></div>
      <div>
        <h3>Start your growth journey</h3>
        <p>As you grow, we’re ready to be your trusted IT and Digital Marketing
          services partner, and tackle every challenge so you can continue to
          build your business.</p>
        <p>Contact us today to learn what we can offer a medium-sized business.</p>
        <br>
        <div class="contactbbtun"><a _ngcontent-lvn-c33 href="/contact-us"
            class="contactbtn">CONTACT US
            TODAY</a>
        </div>
      </div>
    </div>
  </div>
  <img alt="Medium Business Solutions"
    src="https://ik.imagekit.io/b0cgaqv3j/solutions/inner/Medium/footer-banner.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613800695">
</section>
