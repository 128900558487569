import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ManagementComponent } from './pages/management/management.component';
import { TeamComponent } from './pages/team/team.component';
import { GivingBackComponent } from './pages/giving-back/giving-back.component';
import { ContactComponent } from './pages/contact/contact.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { SolutionsSmallBusinessComponent } from './pages/solutions-small-business/solutions-small-business.component';
import { SolutionsMediumBusinessComponent } from './pages/solutions-medium-business/solutions-medium-business.component';
import { SolutionsEnterpriseComponent } from './pages/solutions-enterprise/solutions-enterprise.component';
import { SolutionsGovernmentContractorComponent } from './pages/solutions-government-contractor/solutions-government-contractor.component';
import { GetSupportComponent } from './pages/get-support/get-support.component';
import { BrandingComponent } from './pages/services/branding/branding.component';
import { DataCenterComponent } from './pages/services/data-center/data-center.component';
import { DigitalMarketingComponent } from './pages/services/digital-marketing/digital-marketing.component';
import { EmailMarketingComponent } from './pages/services/email-marketing/email-marketing.component';
import { GoogleAdsComponent } from './pages/services/google-ads/google-ads.component';
import { OnlineReputationManagementComponent } from './pages/services/online-reputation-management/online-reputation-management.component';
import { PaidAdvertisementComponent } from './pages/services/paid-advertisement/paid-advertisement.component';
import { SocialMediaAdsComponent } from './pages/services/social-media-ads/social-media-ads.component';
import { StorageComponent } from './pages/services/storage/storage.component';
import { SocialMediaMarketingComponent } from './pages/services/social-media-marketing/social-media-marketing.component';
import { ProgrammaticOTTAdvertisementComponent } from './pages/services/programmatic-ottadvertisement/programmatic-ottadvertisement.component';
import { AnalyticsComponent } from './pages/services/analytics/analytics.component';
import { InfrastructureServicesComponent } from './pages/services/infrastructure-services/infrastructure-services.component';
import { NetworkingSolutionsComponent } from './pages/services/networking-solutions/networking-solutions.component';
import { WebDesignAndDevelopmentComponent } from './pages/services/web-design-and-development/web-design-and-development.component';
import { WebsiteDevelopmentComponent } from './pages/services/website-development/website-development.component';
import { UiUxDesignComponent } from './pages/services/ui-ux-design/ui-ux-design.component';
import { WebsiteMaintenanceAndSecurityComponent } from './pages/services/website-maintenance-and-security/website-maintenance-and-security.component';
import { ManagedServicesComponent } from './pages/services/managed-services/managed-services.component';
import { WebBasedProjectManagementComponent } from './pages/services/ManagedServices/web-based-project-management/web-based-project-management.component';
import { DigitalMarketingProjectManagementComponent } from './pages/services/ManagedServices/digital-marketing-project-management/digital-marketing-project-management.component';
import { VirtualizationComponent } from './pages/services/virtualization/virtualization.component';
import { AnalyticsReportingComponent } from './pages/services/analytics-reporting/analytics-reporting.component';
import { DigitalRecruitmentComponent } from './pages/services/digital-recruitment/digital-recruitment.component';
import { SearchEngineOptimizationComponent } from './pages/services/search-engine-optimization/search-engine-optimization.component';
import { StaffingComponent } from './pages/services/staffing/staffing.component';
import { TechnologyAssessmentsComponent } from './pages/services/technology-assessments/technology-assessments.component';
import { DisasterRecoveryComponent } from './pages/services/disaster-recovery/disaster-recovery.component';
import { CloudComponent } from './pages/services/cloud/cloud.component';
import { HybridCloudComponent } from './pages/services/cloud/hybrid-cloud/hybrid-cloud.component';
import { PublicCloudComponent } from './pages/services/cloud/public-cloud/public-cloud.component';
import { CloudConsultingServiceComponent } from './pages/services/cloud/cloud-consulting-service/cloud-consulting-service.component';
import { CloudManagedComponent } from './pages/services/cloud/cloud-managed/cloud-managed.component';
import { CloudHostedComponent } from './pages/services/cloud/cloud-hosted/cloud-hosted.component';
import { EnterpriseCloudComponent } from './pages/services/cloud/enterprise-cloud/enterprise-cloud.component';
import { SmbCloudComponent } from './pages/services/cloud/smb-cloud/smb-cloud.component';
import { PrivateCloudComponent } from './pages/services/cloud/private-cloud/private-cloud.component';
import { ApplicationDevelopmentComponent } from './pages/services/application-development/application-development.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { ServicesComponent } from './pages/services/services.component';
import { LongTermComponent } from './pages/services/itstaffing/long-term/long-term.component';
import { DirectHireComponent } from './pages/services/itstaffing/direct-hire/direct-hire.component';
import { OptionToHireComponent } from './pages/services/itstaffing/option-to-hire/option-to-hire.component';
import { ItConsultingComponent } from './pages/services/it-consulting/it-consulting.component';
import { ItAssessmentComponent } from './pages/services/ItConsulting/it-assessment/it-assessment.component';
import { ItLeadershipComponent } from './pages/services/ItConsulting/it-leadership/it-leadership.component';
import { OutsourcingComponent } from "./pages/services/ItConsulting/outsourcing/outsourcing.component";
import { CmmcComponent } from "./pages/services/compliace-assessments/cmmc/cmmc.component";
import { GdprComponent } from "./pages/services/compliace-assessments/gdpr/gdpr.component";
import { HipaaComponent } from "./pages/services/compliace-assessments/hipaa/hipaa.component";
import { PciComponent } from "./pages/services/compliace-assessments/pci/pci.component";
import { ComputeServerComponent } from './pages/services/compute-server/compute-server.component';
import { CompliaceAssessmentsComponent } from './pages/services/compliace-assessments/compliace-assessments.component';
import { NewsComponent } from './pages/news/news.component';
import { SecurityComponent } from './pages/services/security/security.component';
// import { ProjectManagementComponent } from './pages/Services/project-management/project-management.component';
import { DataCenterProjectManagementComponent } from './pages/services/ProjectManagement/data-center-project-management/data-center-project-management.component';
// import { ProjectRecoverySolutionComponent } from './pages/Services/ProjectManagement/project-recovery-solution/project-recovery-solution.component';
import { OurClientsComponent } from './pages/our-clients/our-clients.component';
import { NewServiceRequestComponent } from './pages/new-service-request/new-service-request.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { StaffingLandingPageComponent } from './LandingPage/staffing-landing-page/staffing-landing-page.component';
import { StaffingLandingPageThankyouComponent } from './LandingPage/staffing-landing-page-thankyou/staffing-landing-page-thankyou.component';
import { HireCloudEngineersComponent } from './LandingPage/hire-cloud-engineers/hire-cloud-engineers.component';
import { HireCloudEngineersThankyouComponent } from './LandingPage/hire-cloud-engineers-thankyou/hire-cloud-engineers-thankyou.component';
import { DefaultLangingComponent } from './LandingPage/default-langing/default-langing.component';
import { DefaultLangingThankyouComponent } from './LandingPage/default-langing-thankyou/default-langing-thankyou.component';
import { HireNetworkEngineersComponent } from './LandingPage/hire-network-engineers/hire-network-engineers.component';
import { HireNetworkEngineerThankyouComponent } from './LandingPage/hire-network-engineer-thankyou/hire-network-engineer-thankyou.component';
import { ItBusinessAnalystsComponent } from './LandingPage/it-business-analysts/it-business-analysts.component';
import { ItBusinessAnalystsThankyouComponent } from './LandingPage/it-business-analysts-thankyou/it-business-analysts-thankyou.component';
import { ManageServiceLandingComponent } from './LandingPage/manage-service-landing/manage-service-landing.component';
import { LManagedServicesComponent } from './LandingPage/lmanaged-services/lmanaged-services.component';
import { KernerGroupComponent } from './CaseStudy/kerner-group/kerner-group.component';
import { ThankyoupageComponent } from './CaseStudy/thankyoupage/thankyoupage.component';

import { OptinFormComponent } from './Testing/optin-form/optin-form.component';
import { SurveysFormComponent } from './Testing/surveys-form/surveys-form.component';
import { JotContactSurveyFormComponent } from './Testing/jot-contact-survey-form/jot-contact-survey-form.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { JotSurveysFormComponent } from './Testing/jot-surveys-form/jot-surveys-form.component';
import { SurveryThankyouComponent } from './Testing/survery-thankyou/survery-thankyou.component';
import { ThankyouComponent } from './pages/new-service-request/thankyou/thankyou.component';
import { EdAdsServeythankyouComponent } from './landingpage2024/ed-ads-serveythankyou/ed-ads-serveythankyou.component';
import { EdAdsThankyoupageComponent } from './landingpage2024/ed-ads-thankyoupage/ed-ads-thankyoupage.component';
import { EdAdsMainComponent } from './landingpage2024/ed-ads-main/ed-ads-main.component';
import { EdAdsSurveyComponent } from './landingpage2024/ed-ads-survey/ed-ads-survey.component';
import { GoalsComponent } from './pages/goals/goals.component';
import { SitemapComponent } from './pages/sitemap/sitemap.component';

const routes: Routes = [
  // { path: '/services', redirectTo: '/services/compliace-assessments/pci', pathMatch: 'full' },
  // Langing Pages Start
  {path: 'slg',component:GoalsComponent},
  { path: 'sitemap', component:SitemapComponent},
  { path: 'get-support/new-service-request/thank-you', component: ThankyouComponent },

  { path: 'ed-ads-survey', component: EdAdsSurveyComponent },
  { path: 'landing/:any', component: DefaultLangingComponent },
  { path: 'landingthx/:any', component: DefaultLangingThankyouComponent },
  { path: 'hire-network-engineers', component: HireNetworkEngineersComponent },
  { path: 'hire-network-engineers-thankyou', component: HireNetworkEngineerThankyouComponent },
  { path: 'it-business-analysts', component: ItBusinessAnalystsComponent },
  { path: 'it-business-analysts-thankyou', component: ItBusinessAnalystsThankyouComponent },
  { path: 'it-staff-augmentation', component: StaffingLandingPageComponent },
  { path: 'it-staff-augmentation-thankyou', component: StaffingLandingPageThankyouComponent },
  { path: 'hire-cloud-engineers', component: HireCloudEngineersComponent },
  { path: 'hire-cloud-engineers-thankyou', component: HireCloudEngineersThankyouComponent },
  { path: 'managed-services-thankyou', component: LManagedServicesComponent },
  { path: 'download-casestudy', component: KernerGroupComponent },
  { path: 'thank-you-for-download-case-study', component: ThankyoupageComponent },
  { path: 'ed-ads-thankyou', component: EdAdsThankyoupageComponent },
  // Langing Pages End
  { path: 'testing/optin-form', component: OptinFormComponent },
  { path: 'testing/survey-form', component: SurveysFormComponent },
  { path: 'testing/jot-survey-form', component: JotSurveysFormComponent },
  { path: 'survey-thankyou', component: SurveryThankyouComponent },
  { path: 'ed-ads-survey-thankyou', component: EdAdsServeythankyouComponent },
  { path: 'ed-managed-services-ads', component: EdAdsMainComponent }, 
  // Langing Pages End
  { path: 'blog', component: BlogComponent },
  { path: 'blog/category/:any', component: BlogComponent },
  { path: 'blog/:any', component: BlogDetailComponent },
  { path: 'survey', component: JotSurveysFormComponent },
  { path: '', component: HomeComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'newsroom/:any', component: NewsDetailComponent },
  { path: 'landing', component: LandingComponent },
  { path: 'get-support/new-service-request', component: NewServiceRequestComponent },
  { path: 'our-clients', component: OurClientsComponent },
  { path: 'about-us', component: AboutComponent },
  { path: 'managed-services-landingpage', component: ManageServiceLandingComponent },
  { path: 'about-us/management', component: ManagementComponent },
  { path: 'about-us/leadership', component: ManagementComponent },
  { path: 'about-us/leadership', redirectTo: '/about-us/management', pathMatch: 'full' },
  { path: 'about-us/our-team', component: TeamComponent },
  { path: 'about-us/giving-back', component: GivingBackComponent },
  { path: 'contact-form-survey', component: ContactComponent },
  { path: 'contact-us', component: JotContactSurveyFormComponent },
  // { path: 'services/project-management', component: ProjectManagementComponent },
  { path: 'services/project-management/data-center-project-management', component: DataCenterProjectManagementComponent },
  // { path: 'services/project-management/project-recovery-solutions', component: ProjectRecoverySolutionComponent },
  { path: 'services/application-development', component: ApplicationDevelopmentComponent },
  { path: 'services/cloud/hybrid-cloud', component: HybridCloudComponent },
  { path: 'services/cloud/public-cloud', component: PublicCloudComponent },
  { path: 'services/cloud/cloud-consulting', component: CloudConsultingServiceComponent },
  { path: 'services/cloud/cloud-managed', component: CloudManagedComponent },
  { path: 'services/cloud/cloud-hosted', component: CloudHostedComponent },
  { path: 'services/cloud/private-cloud', component: PrivateCloudComponent },
  { path: 'services/cloud/enterprise', component: EnterpriseCloudComponent },
  { path: 'services/cloud/smb-cloud', component: SmbCloudComponent },
  { path: 'services/it-staffing', component: StaffingComponent },
  { path: 'services/digital-marketing/seo', component: SearchEngineOptimizationComponent },
  { path: 'services/digital-recruitment', component: DigitalRecruitmentComponent },
  { path: 'services/digital-marketing/analyticsreporting', component: AnalyticsReportingComponent },
  { path: 'services/managed-it-services-virginia', component: ManagedServicesComponent },
  // { path: 'services/project-management/web-based-project-management', component: WebBasedProjectManagementComponent },
  { path: 'services/webdesign-and-development', component: WebDesignAndDevelopmentComponent },
  { path: 'services/webdesign-and-development/ux-ui-design', component: UiUxDesignComponent },
  { path: 'services/webdesign-and-development/website-development', component: WebsiteDevelopmentComponent },
  { path: 'services/webdesign-and-development/website-maintenance-and-security', component: WebsiteMaintenanceAndSecurityComponent },
  { path: 'services/infrastructure-services', component: InfrastructureServicesComponent },
  { path: 'services/infrastructure-services/security', component: SecurityComponent },
  { path: 'services/infrastructure-services/disaster-recovery', component: DisasterRecoveryComponent },
  { path: 'services/infrastructure-services/technology-assessments', component: TechnologyAssessmentsComponent },
  { path: 'services/infrastructure-services/cloud', component: CloudComponent },
  { path: 'services/infrastructure-services/data-center', component: DataCenterComponent },
  { path: 'services/infrastructure-services/networking', component: NetworkingSolutionsComponent },
  { path: 'services/infrastructure-services/data-storage', component: StorageComponent },
  { path: 'services/infrastructure-services/virtualization', component: VirtualizationComponent },
  { path: 'services/infrastructure-services/computeserver', component: ComputeServerComponent },
  { path: 'services/branding', component: BrandingComponent },
  { path: 'services/digital-marketing/paid-advertisement/google-ads', component: GoogleAdsComponent },
  { path: 'services/digital-marketing/paid-advertisement/social-media-ads', component: SocialMediaAdsComponent },
  { path: 'services/digital-marketing', component: DigitalMarketingComponent },
  { path: 'services/digital-marketing/paid-advertisement', component: PaidAdvertisementComponent },
  { path: 'services/digital-marketing/social-media-marketing', component: SocialMediaMarketingComponent },
  { path: 'services/digital-marketing/programmatic-ott-advertisement', component: ProgrammaticOTTAdvertisementComponent },
  { path: 'services/digital-marketing/email-marketing', component: EmailMarketingComponent },
  { path: 'services/digital-marketing/analytics', component: AnalyticsComponent },
  { path: 'services/digital-marketing/online-reputation-management', component: OnlineReputationManagementComponent },
  { path: 'solutions', component: SolutionsComponent },
  { path: 'solutions/small-business', component: SolutionsSmallBusinessComponent },
  { path: 'solutions/medium-business', component: SolutionsMediumBusinessComponent },
  { path: 'solutions/enterprise', component: SolutionsEnterpriseComponent },
  { path: 'solutions/government-contractors', component: SolutionsGovernmentContractorComponent },
  { path: 'get-support', component: GetSupportComponent },
  { path: 'terms-condition', component: TermsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'privacy-policy', component: PrivacypolicyComponent },
  // { path: 'services/it-staffing/long-term', component: LongTermComponent },
  // { path: 'services/it-staffing/direct-hire', component: DirectHireComponent },
  // { path: 'services/it-staffing/option-to-hire', component: OptionToHireComponent },
  { path: 'services/compliance-assessments', component: CompliaceAssessmentsComponent },
  { path: 'services/it-consulting', component: ItConsultingComponent },
  { path: 'services/it-consulting/it-assessments', component: ItAssessmentComponent },
  { path: 'services/it-consulting/it-leadership', component: ItLeadershipComponent },
  { path: 'services/it-consulting/outsourcing', component: OutsourcingComponent },
  { path: 'services/compliance-assessments/cmmc', component: CmmcComponent },
  { path: 'services/compliance-assessments/gdpr', component: GdprComponent },
  { path: 'services/compliance-assessments/hipaa', component: HipaaComponent },
  { path: 'services/compliance-assessments/pci', component: PciComponent },
  { path: 'news', component: NewsComponent },
  { path: 'newsroom', component: NewsComponent },
  { path: '**', component: PageNotFoundComponentComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
