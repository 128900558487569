<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/ProjectManagement/ProjectRecovery/banner.jpg?updatedAt=1677586019638) !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>
        Project <span>
            recovery <br>solutions</span>
    </h1>
    <h4>Assessment and mediation to get <br>your project back on track</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/ProjectManagement/ProjectRecovery/service-icon.png" alt="Project Recovery Solutions">
                <h2>
                    Get back on track <span>on time and within budget </span>
                </h2>
            </div>
            <div class="content">
                <p>When milestones are missed, communication dwindles,  or a project goes over budget - our Project Recovery specialists will turn it around.</p>
                <p> With practiced expertise in assessing and identifying the root causes of issues, they will quickly design a turnaround strategy and implement a solution for success.

                </p>

            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <img  src="https://ik.imagekit.io/b0cgaqv3j/service/ProjectManagement/rightside.jpg?updatedAt=1712144009403" alt="Project Recovery Services" class="shadow-lg">
        </div>
    </div>
</section>
<section class="section2">
    <h3 style="font-size: 28px !important;font-family: MontserratSB !important;">The project recovery process:</h3>
    <div class="serviceicons">
        <div>
            <img src="/assets/images/service/ProjectManagement/ProjectRecovery/1.png" alt="Revisit and redefine goals">
            <h4>Revisit and redefine goals</h4>
        </div>
        <div>
            <img src="/assets/images/service/ProjectManagement/ProjectRecovery/2.png" alt="Establish lines of communication">
            <h4>Establish lines of communication</h4>
        </div>
        <div>
            <img src="/assets/images/service/ProjectManagement/ProjectRecovery/3.png" alt="Assess the current state of the project">
            <h4>Assess the current state of the project</h4>
        </div>
        <div>
            <img src="/assets/images/service/ProjectManagement/ProjectRecovery/4.png" alt="Prioritize all project deliverables">
            <h4>Prioritize all project deliverables</h4>
        </div>
        <div>
            <img src="/assets/images/service/ProjectManagement/ProjectRecovery/5.png" alt="Create new timeline and milestones">
            <h4>Create new timeline and milestones</h4>
        </div>
        <div>
            <img src="/assets/images/service/ProjectManagement/ProjectRecovery/6.png" alt="Manage and mitigate risks">
            <h4>Manage and mitigate risks</h4>
        </div>
        <div>
            <img src="/assets/images/service/ProjectManagement/ProjectRecovery/7.png" alt="Implement training and support">
            <h4>Implement training and support</h4>
        </div>
        <div>
            <img src="/assets/images/service/ProjectManagement/ProjectRecovery/8.png" alt="Bring the budget back in line">
            <h4>Bring the budget back in line</h4>
        </div>

        <!-- comment -->
    </div>
</section>


<section class="section3" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/ProjectManagement/ProjectRecovery/footerimage.jpg?updatedAt=1677586026623) !important;padding: 5vw 10vw !important;">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3>Ready to take back control of your project?</h3>
            <p>Your project recovery specialist will assess issues and risks, mediate communication between all parties, recommend training and support where necessary, and propose a new course of action to ensure your project is successfully completed. </p>
            <p>Sound like something you need?
</p>
<div class="bbtnsbox">
                <a href="/contact-us" class="btnsbox">GET IN TOUCH</a>
            </div>
        </div>
    </div>
</section>
