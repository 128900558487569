<app-management-header></app-management-header>
<div class="row section1" style="margin-top:100px">
  <div class="management-img-left col-3">
    <img alt="Blake White President In Endurance It"
      src="https://ik.imagekit.io/b0cgaqv3j/blake-white.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677581278092"
      alt="Blake White" class="shadow-lg">
  </div>
  <div class="col-9 bio-para-left"><span><strong>Blake</strong> White</span><br>
    <div>President</div>
    <p>Blake founded Endurance lT Services in 2008 with a vision of creating an
      IT services company fanatical about client satistaction. The company also
      prides itself on finding and retaining the top IT talent in the region.
      Blake has over 30 years
      of experience in the IT industry and serves as a board member for
      EQUI-KIDS/EQUI-VETS, an equestrian program for disabled children and our
      armed forces veterans.</p>
    <p>Blake graduated with honors from Old Dominion University with a degree in
      computer science and business management. He loves to spend time outside
      of work with his three kids. Blake is dedicated to the special needs
      community. His oldest daughter
      was born with a rare chromosome disorder, and his family supports several
      causes near and dear to them: EQUI-KIDS Therapeutic Riding Program,
      Children's Hospital of the King's Daughters and Virginia Rush "Thunder"
      Soccer Association for its
      work with special needs children.</p>
  </div>
</div>
<div class="row section2" style="margin-top:100px">

  <div class="col-9 bio-para-right"><span><strong>James
      </strong>Ashmore</span><br>
    <div>Vice President</div>
    <p>James is co-founder of Endurance IT Services. He has over 30 years of
      experience in the IT industry and manages complex infrastructure projects.
      He is a senior enterprise infrastructure architect and experienced with
      strategic planning, design,
      development and management of cost-effective, high-performance technology
      solutions that meet today's challenging business needs.</p>
    <p>James makes sure his teams stay on time and on budget. He will design a
      winning strategy for the client and oversee directors and managers as they
      carry out the plan. He also measures the results of the strategy and
      ensures his team has a clear
      reporting system.</p>
    <p>James is a graduate of Virginia Tech. James and his wife support United
      way and the Joy Fund as well as other charitable organizations in the
      community.</p>
  </div>
  <div class="management-img-right col-3">
    <img alt="James Ashmore Vice President In Endurance It"
      src="https://ik.imagekit.io/b0cgaqv3j/James.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677581122464"
      alt="James" class="shadow-lg">
  </div>
</div>
<div class="management-banner hidden">
  <div align="right"
    style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/management-banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677581120623);">
    <img
      src="https://ik.imagekit.io/b0cgaqv3j/logo-sign.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677581119523"
      alt="Endurance IT Services Favicon">
    <h2>We Value Trust, Integrity<br><span>and Collabration.</span></h2>
  </div>
</div>

<div class="row section3" style="margin-top:100px">
  <div class="management-img-left col-3">
    <img alt="Matt Scott Vice President In Endurance It"
      src="https://ik.imagekit.io/b0cgaqv3j/matt.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677581125468"
      alt="Matt" class="shadow-lg">
  </div>
  <div class="col-9 bio-para-left"><span><strong>Matt </strong>Scott</span><br>
    <div>Vice President</div>
    <p>Matt is an experienced IT Service and Sales professional with 30 years of
      experience working for a variety of organizations from a Fortune 500
      company to a 25-employee small business. He has demonstrated expertise in
      designing, deploying, and
      supporting IT solutions to meet a variety of business challenges.</p>
    <p>Matt lead Endurance to the successful completion of the SOC2 Type 2
      certification and is currently leading Endurance's efforts to achieving
      its ISO9000 certification</p>
    <p>Prior to that Matt was focused on IT Operations with an emphasis on
      operational maturity, customer satisfaction, and employee development. The
      primary focus is delivering an exceptional customer experience. The
      strategies he implements to accomplish
      this revolve around data collection and analytics, metric tracking, root
      cause analysis, corrective/preventative action implementation, proactive
      maintenance, and team empowerment. Matt graduated from James Madison
      University with a bachelor's
      degree specializing in Computer Information Systems and the University of
      Richmond with a Master of Business Administration. He and his wife love
      the outdoors including hiking and camping. Their goal is to visit all the
      National Parks in the
      United States, and then start on the Canadian parks. Matt and his wife
      support the ALS Association and Food Bank, among other community focused
      charities.</p>
  </div>
</div>
<div class="row section4" style="margin-top:100px">

  <div class="col-9 bio-para-right"><span><strong>Tony
      </strong>Aveiro</span><br>
    <div>VP of Marketing </div>
    <p>A high-energy leader in digital transformation and marketing, Tony Aveiro
      is equal parts, marketing strategist and tactician. In other words, Tony
      can come up with award-winning ideas and is not afraid to get his hands
      dirty. </p>
    <p>For the past five years, Tony has served as the Vice President of Digital
      Marketing at Endurance IT Services, where he has spearheaded growth and
      revenue initiatives, and transformed the company’s digital marketing and
      software development practice. </p>
    <p>Tony is a lifelong learner, recently completing the CMO (Chief Marketing
      Officer) Executive Program with a concentration in Digital Transformation
      at Columbia Business School. This program has given Tony additional
      insights into developing new ideas, spearheading organizational growth and
      adapting to the ever-changing business landscape. </p>
    <p>When Tony isn’t behind the screen or meeting with clients, he serves on
      the Board of Directors of Volunteer Hampton Roads, a non-profit
      organization that connects businesses and community organizations with
      volunteers to boost collaboration and quality of life in the area. </p>
  </div>
  <div class="management-img-right col-3">
    <img alt="Tony Aveiro VP of Marketing In Endurance It"
      src="https://ik.imagekit.io/b0cgaqv3j/tony.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677581129513"
      alt="Tony Aveiro" class="shadow-lg">
  </div>
</div>
<div class="row section3" style="margin-top:100px">
  <div class="management-img-left col-3">
    <img alt="Marianne Mele"
      src="https://ik.imagekit.io/b0cgaqv3j/leadership/Marianne_Profile.jpg?updatedAt=1697738596236"
      alt="Matt" class="shadow-lg">
  </div>
  <div class="col-9 bio-para-left"><span><strong>Marianne </strong>Mele
    </span><br>
    <div>Director of Staffing and Recruiting</div>
    <p>Marianne is the driving force behind Endurance’s recruitment strategy.
      Her background includes 30 years of Talent Acquisition and Business
      Development experience in the IT consulting industry.
    </p>
    <p>Marianne has played a pivotal role in building high-performing teams for
      leading companies across diverse industries. From talent sourcing and
      selection to onboarding, she has consistently demonstrated a commitment to
      fostering a culture of excellence within our organization. During her four
      years with Endurance, Marianne’s vision has gone beyond the traditional
      hiring practices. She is committed to creating recruitment processes that
      are transformative, aligning the right people with the right roles to
      foster professional growth.
    </p>
    <p>Marianne is passionate about the Hampton Roads community, serving as an
      Executive Board Member and Events Committee Chairman for the Central
      Business District Association (CBDA). She is also active in United Way and
      EQUI-KIDS/EQUI-VETS. Upon graduating from the University of Pittsburgh,
      Marianne immediately made her home in Virginia Beach. She loves traveling
      the world and going on adventures with her two (now grown) children.
    </p>

  </div>
</div>
<div class="row section4" style="margin-top:100px">

  <div class="col-9 bio-para-right"><span><strong>Clair
      </strong>Ratliff</span><br>
    <div>Director Of Sales</div>
    <p>Clair leads sales efforts, generates new business opportunities, and
      serves as the point of contact for client stakeholders, providing tailored
      services and solutions to help businesses realize their fullest potential.
      Her background includes over 10 years of experience in the IT consulting
      industry.
    </p>
    <p>Creating empowered and engaged client relationships is Clair's passion
      and driving force behind her success in growing Endurance IT’s client
      portfolio. Her collaborative approach, expert insights, and extensive
      knowledge of our innovative solutions has helped organizations expand
      their capabilities and services.
    </p>
    <p>Clair is a graduate of Old Dominion University. Growing up in Hampton
      Roads, local community involvement and giving back is her personal goal.
      When she isn’t focused at the office, she enjoys spending time with her
      husband and two young children.
    </p>
  </div>
  <div class="management-img-right col-3">
    <img alt="Tony Aveiro VP of Marketing In Endurance It"
      src="https://ik.imagekit.io/b0cgaqv3j/leadership/Clair_Profile.jpg?updatedAt=1697738596318"
      alt="Clair Ratliff" class="shadow-lg">
  </div>
</div>
