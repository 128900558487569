<app-ourteam-header></app-ourteam-header>
<div class="main">
  <div class="row">
    <div class="col-6">
      <span><strong>We value trust, integrity </strong> and
        collaboration.</span>
      <p>Every successful relationship is founded on good communication and
        engaging discussions about real needs and experiences. It's these
        discussions that inspire collaboration, strategic planning and a
        data-rich solution that leads to lasting
        organizational change and real business results. That's why we're proud
        to offer a team of experts for all your IT needs. From IT consultants
        and digital marketing experts, we have some of the most talented team in
        the United States</p>
    </div>
    <img src="assets/images/group-photo.jpg" alt="Our Team" class="col-6">
  </div>
  <div class="row">
    <img src="assets/images/group-photo2.jpg" alt="Our Team" class="col-6">
    <div class="col-6">
      <span><strong>We never forget it's all about you.</strong><br>Your goals.
        Your needs.</span>
      <p>Every team member embraces our fanatical approach to customer service.
        In addition to having the right skills, they have the right personality
        and interpersonal skills to deliver best in class customer service.</p>
      <p style="margin-top:20px;">If you've ever experienced the challenges of
        your service provider's rapidly changing staff, then you'll love working
        with us. We're proud to share our 92% staff retention rate and that
        we've been recognized by Inside Business and Best Companies
        Group as one of the Best Places To Work in Hampton Roads and
        Virginia.</p>
      <div>
        <img style="width: 7rem;"
          src="https://ik.imagekit.io/b0cgaqv3j/BestPlacesVA.png?ik-sdk-version=javascript-1.4.3&updatedAt=1678429154780"
          alt="Best place to work">
        <img style="width: 7rem; margin-left: 2rem;"
          src="https://ik.imagekit.io/b0cgaqv3j/logo_inc5000_color.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1678429741352"
          alt="5000 INC">
        <img style="width: 7rem; margin-left: 2rem; display: none;"
          src="https://ik.imagekit.io/b0cgaqv3j/ssae-19-and-type-2_final.png?updatedAt=1690370421787"
          alt="SSAC">
        <img style="width: 7rem; margin-left: 2rem;"
          src="https://ik.imagekit.io/b0cgaqv3j/insidebusiness_new.jpg?updatedAt=1690921937020"
          alt="inside business">
      </div>
    </div>
  </div>
</div>
<div id="team-members">
  <div class="row">
    <div class="col-3 team-card-div" *ngFor="let Team of Teams.result" >
      <div class="teamscontainer">
        <div class=" team-card-hover"
          style="background-image:url('{{Team.Meta.hoverImage}}');">
          <div class="member-name"><strong>{{Team.Meta.fname}} </strong> {{Team.Meta.lname}}</div>
          <div style="color:white;">{{Team.Meta.position}}</div>
          <p class="bio" style="color:#84878a;">
          </p>
          <a class="member-linked" target="_new"
            href="{{Team.Meta.Linkedin}}"><img
              src="assets/images/linkedin.png" alt="Linkedin"></a>
        </div>
        <div class=" team-card"
          style="background-image:url('{{Team.Meta.MainImage}}');">
          <img src="{{Team.Meta.MainImage}}" alt="{{Team.Meta.hoverImage}} {{Team.Meta.hoverImage}}"
            class="forimg">
          <div class="member-name"><strong>{{Team.Meta.fname}}</strong> {{Team.Meta.lname}}</div>
          <div class="member-position">{{Team.Meta.position}}</div>
          <a class="member-linked" target="_new"
            href="{{Team.Meta.Linkedin}}"><img
              src="assets/images/linkedin.png" alt="Linkedin"></a>
        </div>
      </div>
    </div>

























  </div>
</div>
<app-form></app-form>
