<div class="navbar-collapse collapse show" id="mynavbar">
    <ul class="navbar-nav">
        <li class="nav-item hovermenu">
            <a class="nav-link" href="/about-us">About Us</a>
            <ul>
              <li style="display: none;"><a href="about-us/leadership">Leadership</a></li>
              <li><a href="about-us/leadership">Leadership</a></li>
              <li style="display: none;"><a href="about-us/our-team">Our Team</a></li>
              <li><a href="about-us/giving-back">Giving Back</a></li>
              <li><a href="about-us/our-team">Our Team</a></li>
          </ul>
        </li>
        <li class="nav-item hovermenu">
            <a class="nav-link" href="/solutions"  ariaCurrentWhenActive="page"  >Solutions</a><ul>
              <li><a href="solutions/small-business">Small  Business</a></li>
              <li><a href="solutions/medium-business">Medium Business</a></li>
              <li><a href="solutions/enterprise">Enterprise</a></li>
              <li><a href="solutions/government-contractors">Government  Contractors</a></li>
          </ul>
        </li>
        <li class="nav-item service">
            <a class="nav-link servicemenus"   ariaCurrentWhenActive="page"  >Services</a>
        <app-servicemenu></app-servicemenu>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="/our-clients">Our Clients</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" target="_new" href="https://endurance-it.zohorecruit.com/jobs/Careers">Careers</a>
        </li>
        <li  class="nav-item hovermenu"><a class="nav-link" href="#">Resources</a>
          <ul>
            <li><a routerLink="/newsroom">Newsroom</a></li>
            <li ><a href="/blog">Blog</a></li>
            <li><a href="/download-casestudy">Case Study</a></li>


        </ul></li>
        <li class="nav-item">
            <a class="nav-link" href="/contact-us"  ariaCurrentWhenActive="page" >Contact Us</a>
        </li>
        <li class="nav-item monly getsupportmobile">
            <a class="nav-link" href="/get-support"  ariaCurrentWhenActive="page">Get Support</a>
        </li>
    </ul>
    <a  href="/get-support"  ariaCurrentWhenActive="page" class="donly"><button class="btn" type="button" id="get-support">Get Support</button></a>
    <a class="navbar-toggler donly" >
        <i class="fa fa-search"></i>

    </a>
    <a class="monly mobilemenu" >
        <i class="fa fa-list"></i>

    </a>
</div>
