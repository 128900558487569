import { Component, OnInit } from '@angular/core';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-social-media-ads',
  templateUrl: './social-media-ads.component.html',
  styleUrls: ['./social-media-ads.component.css']
})
export class SocialMediaAdsComponent implements OnInit {

  constructor(private MetaService: MetaServiceService) { }

  ngOnInit(): void {this.MetaService.createCanonicalURL();
  }

}
