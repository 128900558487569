<app-ed-ads-header></app-ed-ads-header>
<section class="client-counter">
  <img src="assets/images/ed-ads/ssae21.png" class="donly" />
  <div class="line">
    <h3>16</h3>
    <p>Years in Services</p>
  </div>
  <div class="line">
    <h3>2500+</h3>
    <p>Projects</p>
  </div>
  <div>
    <h3>300+</h3>
    <p>Clients</p>
  </div>
</section>
<div *ngIf="innerWidth<801" class="casestudy-form ">
  <p>Discover how we tranformed</p>
  <h3>The Kerner Group</h3>
  <p class="small">With Managed IT Services and Enhanced Cybersecurity</p>
  <app-case-study-form></app-case-study-form>
  <button class="closebtn">X</button>
</div>
<section class="section1">
  <div class="row">
    <div class="col-sm-5 col-lg-5 col-md-5">
      <img src="assets/images/ed-ads/image.png"
        alt="have a UX or UI need, contact us" class="shadow-lg"></div>
    <div class="col-sm-7 col-lg-7 col-md-7 MainHeadingsPadding">
      <div class="heading">
        <h2>Empower Your Team: <span>Outsource IT Management for Uninterrupted
            Focus on Your Business Goals</span></h2>
      </div>
      <div class="content">
        <p><strong>Proudly the only SSAE 21 SOC 2 Type II certified MSP in
            Hampton Roads, VA, Endurance IT Services</strong> offers the
          assurance of secure and compliant managed IT solutions.</p>
        <p>Endurance IT Services delivers a comprehensive suite of managed IT
          solutions designed to streamline operations, reduce costs, and enhance
          security—all without the complexity of in-house management.</p>
        <p>Our centralized approach provides a single point of contact,
          simplifying management while our predictable billing model eliminates
          financial surprises. </p>
        <p>Whether you're aiming to stay current with evolving technology or
          require adaptable IT services that scale with your growth, Endurance
          is here to support your journey towards efficiency and long-term
          value.</p>
      </div>
    </div>
  </div>
</section>
<section class="services-box">
  <h5>Your Success is Our Mission: <br>
    Tailored IT Solutions to Drive Growth and Innovation </h5>
  <p>With personalized service and strategic support, we exceed your IT
    expectations, boosting growth and enhancing security. </p>
    <div class="boxes donly">
      <div align="center" class="rowfirsts boxicons">
        <img src="assets/images/ed-ads/1.png" />
        <h3>Virtual Solutions</h3>
        <div class="hoverbox">
          <h4>Expert vITM and vCIO Solutions </h4>
        <p>Tailor-made for businesses facing a skills gap or budget constraints,
          our solutions integrate seamlessly with your existing IT team, or
          replace an entire department your enhancing capabilities without the
          overhead.</p></div>
      </div>
      <div align="center" class="rowfirsts boxicons">
        <img src="assets/images/ed-ads/2.png" />
        <h3>Service Desk</h3>
        <div class="hoverbox"> <h4>Responsive
          <br>and Efficient </h4>
        <p>Act as a virtual extension or an alternative to your
          in-house IT service desk. We manage incidents and support requests to
          ensure your operations remain productive without interruption.</p>
      </div></div>
      <div align="center" class="rowfirsts boxicons">
        <img src="assets/images/ed-ads/3.png" />
        <h3>Security Services</h3>
        <div class="hoverbox"><h4>Scalable SOCaaS <br>Solutions</h4>
        <p>Including Intrusion Detection and Vulnerability Management, tailored to
          fit your specific requirements and budget, ensuring robust
          protection.</p></div>
      </div>
      <div align="center" class="rowfirsts boxicons">
        <img src="assets/images/ed-ads/4.png" />
        <h3>Centralized IT Management</h3>
        <div class="hoverbox"><h4>Streamline Operations</h4>
        <p>Simplify your IT management with a single point of contact for all
          IT-related issues. This central approach enhances operational
          efficiency, making IT management hassle-free.</p></div>
      </div>
      <div align="center" class="rowsecond boxicons">
        <img src="assets/images/ed-ads/5.png" />
        <h3>Predictable Monthly Billing and Cost Control</h3>
        <div class="hoverbox"><h4>Cost Reduction
          and Control </h4>
        <p>Our cost-effective service models significantly reduce operational
          expenses. Enjoy predictable billing and avoid unforeseen costs,
          especially during emergencies.</p></div>
      </div>
      <div align="center" class="rowsecond boxicons">
        <img src="assets/images/ed-ads/6.png" />
        <h3>Flexible Services to Support Business Growth</h3>
        <div class="hoverbox"><h4>Scalability and Flexibility</h4>
        <p>Adapt your IT infrastructure to match your business's growth phases
          without the burden of scaling an in-house team. Our flexible services
          adapt as your needs evolve.</p></div>
      </div>
      <div align="center" class="rowsecond boxicons">
        <img src="assets/images/ed-ads/7.png" />
        <h3>Access to the Latest Technological Innovations</h3>
        <div class="hoverbox"><h4>Latest Technology at Your Fingertips</h4>
        <p>Keep your business at the forefront of technology. Our access to
          cutting-edge IT tools and practices gives you a competitive edge.</p></div>
      </div>
      <div align="center" class="rowsecond boxicons">
        <img src="assets/images/ed-ads/8.png" />
        <h3>Seamless System Integration and Long-term Value Creation</h3>
        <div class="hoverbox"><h4>Holistic IT Strategy Integration</h4>
        <p>Our comprehensive IT strategies align perfectly with your business
          operations, boosting efficiency and creating long-term value.</p></div>
      </div>
    </div>
    <div class="boxes monly">
    <div align="center" class="rowfirsts boxicons">
      <img src="assets/images/ed-ads/1.png" />
      <h3>Virtual Solutions</h3>
      <h4>Expert vITM and vCIO Solutions </h4>
      <p>Tailor-made for businesses facing a skills gap or budget constraints,
        our solutions integrate seamlessly with your existing IT team, or
        replace an entire department your enhancing capabilities without the
        overhead.</p>
    </div>
    <div align="center" class="rowfirsts boxicons">
      <img src="assets/images/ed-ads/2.png" />
      <h3>Service Desk</h3>
      <h4>Responsive
        <br>and Efficient </h4>
      <p>Act as a virtual extension or an alternative to your
        in-house IT service desk. We manage incidents and support requests to
        ensure your operations remain productive without interruption.</p>
    </div>
    <div align="center" class="rowfirsts boxicons">
      <img src="assets/images/ed-ads/3.png" />
      <h3>Security Services</h3>
      <h4>Scalable SOCaaS <br>Solutions</h4>
      <p>Including Intrusion Detection and Vulnerability Management, tailored to
        fit your specific requirements and budget, ensuring robust
        protection.</p>
    </div>
    <div align="center" class="rowfirsts boxicons">
      <img src="assets/images/ed-ads/4.png" />
      <h3>Centralized IT Management</h3>
      <h4>Streamline Operations</h4>
      <p>Simplify your IT management with a single point of contact for all
        IT-related issues. This central approach enhances operational
        efficiency, making IT management hassle-free.</p>
    </div>
    <div align="center" class="rowsecond boxicons">
      <img src="assets/images/ed-ads/5.png" />
      <h3>Predictable Monthly Billing and Cost Control</h3>
      <h4>Cost Reduction
        and Control </h4>
      <p>Our cost-effective service models significantly reduce operational
        expenses. Enjoy predictable billing and avoid unforeseen costs,
        especially during emergencies.</p>
    </div>
    <div align="center" class="rowsecond boxicons">
      <img src="assets/images/ed-ads/6.png" />
      <h3>Flexible Services to Support Business Growth</h3>
      <h4>Scalability and Flexibility</h4>
      <p>Adapt your IT infrastructure to match your business's growth phases
        without the burden of scaling an in-house team. Our flexible services
        adapt as your needs evolve.</p>
    </div>
    <div align="center" class="rowsecond boxicons">
      <img src="assets/images/ed-ads/7.png" />
      <h3>Access to the Latest Technological Innovations</h3>
      <h4>Latest Technology at Your Fingertips</h4>
      <p>Keep your business at the forefront of technology. Our access to
        cutting-edge IT tools and practices gives you a competitive edge.</p>
    </div>
    <div align="center" class="rowsecond boxicons">
      <img src="assets/images/ed-ads/8.png" />
      <h3>Seamless System Integration and Long-term Value Creation</h3>
      <h4>Holistic IT Strategy Integration</h4>
      <p>Our comprehensive IT strategies align perfectly with your business
        operations, boosting efficiency and creating long-term value.</p>
    </div>
  </div>

</section>
<section class="vedio">
  <h5>Why Endurance IT? Expert Solutions, Certified Security, and Digital
    Innovation</h5>
  <p>Our Commitment in Action: <strong>Watch the Endurance Story</strong></p>
  <div class="row">
    <div class="col-md-6 col-xs-6 col-lg-6">
      <p><strong>Expertise:</strong> As a leading managed IT service provider in
        Hampton Roads, we specialize in elevating the cybersecurity, information
        technology, and digital innovation for small to medium-sized businesses,
        enterprises, and government contractors.</p>
      <p><strong>Commitment:</strong> As the only SOC 2 Type II certified
        managed IT services provider in Hampton Roads, we uphold the highest
        standards of security and compliance, distinctly setting us apart from
        other providers. </p>
      <p><strong>Innovation: </strong>By focusing on cutting-edge innovation and
        customer success, we empower clients like you to not only keep up with
        but lead in today's fast-evolving technological landscape—enhancing your
        business growth and cybersecurity capabilities. </p>
    </div>
    <div class="col-md-6 col-xs-6 col-lg-6">
      <iframe
        src="https://fast.wistia.net/embed/iframe/inkhus2cor?seo=false&videoFoam=false"
        title="Endurance IT Managed Service Provider in VA Video"
        allow="autoplay; fullscreen" allowtransparency="true" frameborder="0"
        scrolling="no" class="wistia_embed" name="wistia_embed"
        msallowfullscreen width="640" height="338"></iframe>
      <script src="https://fast.wistia.net/assets/external/E-v1.js"
        async></script>
    </div>

  </div>
  <h3>Connect with us today to see how we can transform your IT infrastructure
    and drive your success.</h3>
  <a (click)="openPopup()" class="btnorange">Let's Talk About It</a>

</section>
<section class="section2">
  <img alt="What Our Client Says" src="assets/images/ed-ads/Rectangle.png">
  <div>
    <h3>In Their Words: A Client's Perspective</h3>
    <p><strong>“Endurance IT Services has been nothing short of a technological
        boon for The Kerner Group.</strong> Their ability to understand and
      integrate the varied IT needs of our subsidiaries into a cohesive,
      efficient, and secure system has been a cornerstone of our collective
      success. We've seen remarkable improvements in collaboration, operational
      efficiency, and data security, all while maintaining each company's unique
      identity and needs."</p>
    <i><strong>-Amy Hansen, President/CFO, The Kerner Group</strong></i>
  </div>
</section>
<section class="story">
  <h5>Turning IT Challenges into Opportunities: <span><br>The Kerner Group’s
      Managed IT Services Journey</span></h5>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-6" align="center">
      <img src="assets/images/ed-ads/studycasebook.png">
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6">
      <p><strong>A Story of Strategic Transformation: </strong><br>The Kerner
        Group faced multifaceted IT challenges that hindered their growth and
        operational efficiency. Their journey with Managed IT Services is a
        testament to the transformative power of strategic IT solutions tailored
        to specific business needs.</p>
      <p><strong>Experience Our Impact: </strong><br>Discover how Endurance IT
        Services can transform your IT challenges into advantages. Download our
        detailed case study on The Kerner Group and see how tailored managed IT
        solutions can elevate your business operations just like they did for
        Amy and her team.</p>
      <a class="btnorange" id="downloadcasestudy">Download the Case Study</a>
    </div>
  </div>
</section>
<section class="empower">
  <h3>Empowering Your Team is Easy</h3>
  <img class="donly" src="assets/images/ed-ads/Group.png" />
  <div class="threeboxes">
    <div><img class="monly"
        src="https://ik.imagekit.io/b0cgaqv3j/ed_ads/1.png?updatedAt=1715179480591" /><h3 class="monly">Discovery
        Call</h3>Begin with a simple conversation. Ask questions, share your
      current challenges, and discover how we can support your goals.</div>
    <div><img class="monly"
        src="https://ik.imagekit.io/b0cgaqv3j/ed_ads/2.png?updatedAt=1715179480677" /><h3 class="monly">Discovery
        Call</h3>Following our initial discussion, if needed, one of our experts
      will evaluate your current systems and processes. You'll receive a
      tailored quote designed to meet your specific needs and address your
      unique challenges.</div>
    <div><img class="monly"
        src="https://ik.imagekit.io/b0cgaqv3j/ed_ads/3.png?updatedAt=1715179480817" /><h3 class="monly">Discovery
        Call</h3>Working together, we will develop a comprehensive integration
      plan. Our goal is to ensure a smooth implementation that supports your
      growth and enhances the security of your organization.</div>
  </div>
  <a (click)="openPopup()" class="btnwhite">Get a Free Quote</a>
</section>
<section class="testimonials-box">
  <h3>Here’s What Other Clients Say About Endurance IT</h3>
  <div class="boxes">
    <div class="maintestemonials" *ngFor="let post of clientTesttimonial">
      <img class="quotes" src="assets/images/ed-ads/quote-orange.png" />
      <img class="rating" src="assets/images/ed-ads/rating.png" />
      <p [innerHTML]="post.testimonial.description">SL Nusbaum credits Endurance with providing stability and insightful IT
        management during our leadership transition.</p>
      <h4 [innerHTML]="post.testimonial.name">Virginia Batteen Hawks, Sr. Vice
        President & CFO</h4>
    </div>

  </div>
</section>
<app-popup *ngIf="isPopupOpen" (closePopup)="closePopup()"></app-popup>
