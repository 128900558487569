<meta property="og:title" [content]="dynamicTitle">
<header style="background-image: none !important;" class="fixedheader">
  <div class="container">
    <nav class="navbar navbar-expand-sm">
      <div class="container-fluid">
        <a class="navbar-brand" href="/"><img class="mainlogo"
            src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
            alt="Endurqance IT Services"></a>
        <app-nav></app-nav>
      </div>
    </nav>
    <div class="blogbreadcrumn"><a href="/">Home</a> | <a href="/blog">Blog</a>
      | <a
        href="/blog/category/{{detaildata.result.categories[0].slug}}">{{detaildata.result.categories[0].name}}</a>
      | <strong>{{detaildata.result.post_title}}</strong></div>
    <div class="tags"><a
        href="/blog/category/{{detaildata.result.categories[0].slug}}">{{detaildata.result.categories[0].name}}</a>

      <div class="icons">
        <a (click)="linkedin(detaildata.result.post_title,'https://devendurance.com/blog/'+detaildata.result.post_name)"><img
            src="assets/images/icons/linkedin.svg" alt="LinkedIn"></a>
        <a (click)="facebook(detaildata.result.post_title,'https://devendurance.com/blog/'+detaildata.result.post_name)"><img
            src="assets/images/icons/facebook.svg"  alt="Meta"></a>
        <a (click)="twitter(detaildata.result.post_title,'https://devendurance.com/blog/'+detaildata.result.post_name)"><img
            src="assets/images/icons/twitter.svg" alt="X"></a>
        <a (click)="mail(detaildata.result.post_title,'https://devendurance.com/blog'+detaildata.result.post_name)"><img
            src="assets/images/icons/email.svg"  alt="email"></a>
        <a href="/pdf/?b={{detaildata.result.post_name}}" target="_new"><img
            src="assets/images/icons/pdf.svg" alt="pdf"></a>
        <a href="/pdf/?b={{detaildata.result.post_name}}&print=1"  target="_new"><img  alt="print" src="assets/images/icons/print.svg"></a>
      </div>
    </div>
    <h1 class="headertitle">{{detaildata.result.post_title}}</h1>
    <div class="headerauthor">
      <span><img
          src="assets/images/icons/user.svg"  alt="Editorial Team">Written
        by <a
          href="/blog/{{detaildata.result.post_name}}#AboutAuthor">{{detaildata.result.authorDetails.display_name}}</a></span>
      <span style="border: 0 !important;"><img
          src="assets/images/icons/calendar_w.svg" alt="calendar">
        {{detaildata.result.PostCreateDate}}</span>
      <span style="display: none;">{{detaildata.result.ago}}</span>
    </div>
    <div class="categories headercat">
      <span *ngFor="let category of detaildata.result.categories"><a
          routerLink="/blog/category/{{category.slug}}">{{category.name}}</a></span>
    </div>
  </div>
</header>
<section class="content container">
  <div innerHTML="{{detaildata.result.post_content}}"
    class="contentdatadec"></div>

  <hr class="dark" id="AboutAuthor">
  <section class="author-details">
    <h3>About the Author</h3>
    <div>
      <img
        style="background-image: url('{{detaildata.result.authorDetails.image}}');"
        src="https://ik.imagekit.io/hb9nqxxf2/bgass.png?updatedAt=1687543393541" />
      <div class="content">
        <h5>{{detaildata.result.authorDetails.display_name}}</h5>
        <div class="icons">
          <a target="_blank"
            href="{{detaildata.result.authorDetails.social.linkedin}}"><img
              src="assets/images/icons/linkedin.svg" alt="LinkedIn"></a>
          <a *ngIf="detaildata.result.authorDetails.social.facebook!='#'" target="_blank"
            href="{{detaildata.result.authorDetails.social.facebook}}"><img
              src="assets/images/icons/facebook.svg"  alt="Meta"></a>
          <a *ngIf="detaildata.result.authorDetails.social.twitter!='#'" target="_blank"
            href="{{detaildata.result.authorDetails.social.twitter}}"><img
              src="assets/images/icons/twitter.svg"  alt="X"></a>
          <a target="_blank"
            href="mailto:{{detaildata.result.authorDetails.user_email}}"><img
              src="assets/images/icons/email.svg"  alt="email"></a>
        </div>
        <p [innerHTML]="detaildata.result.authorDetails.bio"></p>
      </div>
    </div>


  </section>
  <hr>
  <section class="contact">
    <h2 style="display: none;">Let's Talk About IT!</h2>
    <h3 style="display: none;"><a href>Contact the Author</a></h3>
    <p style="display: none;">At Endurance IT, we value personal relationships,
      Reach out directly to our Digital Marketing Coordinator, Connor Foutz, to
      enquire about Endurance or our services and get in touch with our
      experts.</p>
    <hr style="display: none;">
    <div class="social-icons">
      <h3>Share This Content, Choose Your Platform!</h3>
      <div class="socialIconsvalue">
      <a (click)="linkedin(detaildata.result.post_title,'https://devendurance.com/blog/'+detaildata.result.post_name)">
        <img
        src="assets/images/icons/linkedin.svg" alt="LinkedIn">
      </a>
    <a (click)="facebook(detaildata.result.post_title,'https://devendurance.com/blog/'+detaildata.result.post_name)">
      <img
        src="assets/images/icons/facebook.svg"  alt="Meta">
      </a>
    <a (click)="twitter(detaildata.result.post_title,'https://devendurance.com/blog/'+detaildata.result.post_name)">
      <img
        src="assets/images/icons/twitter.svg" alt="X">
      </a>
    <a (click)="mail(detaildata.result.post_title,'https://devendurance.com/blog'+detaildata.result.post_name)">
      <img
        src="assets/images/icons/email.svg"  alt="email">
      </a>
        <a href="/pdf/?b={{detaildata.result.post_name}}" target="_new"><img
            src="assets/images/icons/pdf.svg" alt="pdf"></a>
        <a href="/pdf/?b={{detaildata.result.post_name}}&print=1"
          target="_new"><img src="assets/images/icons/print.svg"   alt="print"></a></div>
      </div>

    <hr class="dark">
  </section>
</section>
<section class="related-post container">
  <h2>More Like This</h2>
  <section class="boxes ">
    <div class="row">
      <div class="col-4" *ngFor="let post of newsRoom.result.blog.slice(0, 3)">
        <div class="featuredPostsboxes">

          <img class="mainbannerimage"
            style="background-image: url({{post.postbannerImage}});    background-size: cover; height: auto !important;"
            src="https://ik.imagekit.io/b0cgaqv3j/defaults_blog.png?updatedAt=1706202176893" />
          <div class="content">
            <h5><a
                href="/blog/category/{{post.categories[0].slug}}">{{post.categories[0].name}}</a></h5>
            <h6><a href="blog/{{post.post_name}}">{{post.post_title}}</a></h6>
            <div class="authorsdetails">
              <div class="author"><img src="assets/images/icons/user.svg"  alt="editorial team"><span>
                  {{post.authorDetails.display_name}}</span></div>
              <div class="calender"><img
                  src="assets/images/icons/calendar.svg"  alt="calender"><span>
                  {{post.PostCreateDate}}</span></div>
            </div>
            <p [innerHTML]="post.smallcontent">Through disaggregation of the
              traditional roles and
              technology involved in telecommunications applications,</p>
            <a class="buttons" href="blog/{{post.post_name}}"
              class="readmore-btn">Read More</a>
            <hr>
            <div class="social-icons">
              <a (click)="linkedin(post.post_title,'https://devendurance.com/blog/'+post.post_name)"><img
                src="assets/images/icons/linkedin.svg" alt="LinkedIn"></a>
            <a (click)="facebook(post.post_title,'https://devendurance.com/blog/'+post.post_name)"><img
                src="assets/images/icons/facebook.svg"  alt="Meta"></a>
            <a (click)="twitter(post.post_title,'https://devendurance.com/blog/'+post.post_name)"><img
                src="assets/images/icons/twitter.svg" alt="X"></a>
            <a (click)="mail(post.post_title,'https://devendurance.com/blog'+post.post_name)"><img
                src="assets/images/icons/email.svg"  alt="email"></a>
              <a href="/pdf/?b={{post.post_name}}"
                target="_new"><img src="assets/images/icons/pdf.svg"  alt="pdf"></a>
              <a href="/pdf/?b={{post.post_name}}&print=1" target="_new"><img src="assets/images/icons/print.svg"  alt="print"></a>
            </div>
          </div>
        </div>
      </div></div>
  </section>

</section>
<div class="contactEndurance">
  <h5>Contact Endurance IT Services</h5>
<a target="_blank" href=" /contact-us">Request a Quote</a>
<a target="_blank"  href="https://endurance-it.zohorecruit.com/jobs/Careers">Explore Career Opportunities</a>
<a  target="_blank" href="https://www.endurance-it.com/get-support">Get Client Support</a>

</div>
