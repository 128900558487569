<header class="serviceinner" style="height: 85vh !important;
min-height: 85vh !important;
max-height: 85vh !important;background-image: url(https://ik.imagekit.io/b0cgaqv3j/error.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677581109324);">
  <nav class="navbar navbar-expand-sm">
      <div class="container-fluid">
          <a class="navbar-brand" href="/">
              <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
          </a>
          <app-nav></app-nav>
      </div>
  </nav>
  <h1>
    Thank You for Contacting Endurance IT Services!
  </h1>
  <h4 style="width: 100% !important;">We've received your message and look forward to discussing how we can support your business needs.
    Expect to hear from us soon! For immediate assistance, please reach out to our Director Of Sales, Clair Ratliff at <a href="mailto:clair.ratliff@endurance-it.com">clair.ratliff@endurance-it.com</a> or <a href="tel:+1-757-457-4793">757.457.4793</a></h4>
</header>
<script>
window.scrollTo(0, 0);
</script>
