<header class="fixedheaders" style="background-image:none !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>
            <app-nav></app-nav>
        </div>
    </nav>
</header>
<section class="sitemap">
    <ul class="list">
        <li><a href="/">Home</a></li>
        <li><a href="/about-us">About Us</a>
            <ul class="sub-list">
                <li><a href="/about-us/leadership">Our Leadership</a></li>
                <li><a href="/about-us/giving-back">Giving back</a></li>
                <li><a href="/about-us/our-team">Our Team</a></li>
            </ul>
        </li>
        <li><a href="/solutions">Solutions</a>
            <ul class="sub-list">
                <li><a href="/solutions/small-business">Small business</a></li>
                <li><a href="/solutions/medium-business">Medium business</a></li>
                <li><a href="/solutions/enterprise">Enterprise</a></li>
                <li><a href="/solutions/government-contractors">Government contractors</a></li>
            </ul>
        </li>
        <li><a href="/services">Services</a>
            <ul class="sub-list">
                <li><a href="/services/managed-services">Managed services</a></li>
                <li><a href="/services/it-staffing">IT Staffing</a></li>
                <li><a href="/services/infrastructure-services">Infrastructure Services</a>
                    <ul class="super-sub-list">
                        <li><a href="/services/infrastructure-services/computeserver">Compute/Server</a></li>
                        <li><a href="/services/infrastructure-services/data-storage">Data Storage</a></li>
                        <li><a href="/services/infrastructure-services/networking">Networking Solutions</a></li>
                        <li><a href="/services/infrastructure-services/virtualization">Data Virtualization</a></li>
                        <li><a href="/services/infrastructure-services/data-center">Data Center</a></li>
                        <li><a href="/services/infrastructure-services/security">Cybersecurity</a></li>
                        <li><a href="/services/infrastructure-services/disaster-recovery">Disaster Recovery</a></li>
                        <li><a href="services/infrastructure-services/technology-assessments">Technology Assessments</a></li>
                    </ul>
                </li>
                <li><a href="/services/it-consulting">IT Consulting</a>
                    <ul class="super-sub-list">
                        <li><a href="/services/it-consulting/it-assessments">IT Assessment</a></li>
                        <li><a href="/services/it-consulting/it-leadership">IT Leadership</a></li>
                        <li><a href="/services/it-consulting/outsourcing">Outsourcing</a></li>
                    </ul>
                </li>
                <li><a href="/services/infrastructure-services/cloud">Cloud</a>
                    <ul class="super-sub-list">
                        <li><a href="/services/cloud/public-cloud">Public Cloud</a></li>
                        <li><a href="/services/cloud/private-cloud">Private Cloud</a></li>
                        <li><a href="/services/cloud/hybrid-cloud">Hybrid Cloud</a></li>
                        <li><a href="/services/cloud/cloud-consulting">Cloud Consulting Services</a></li>
                        <li><a href="/services/cloud/cloud-managed">Cloud Managed Services</a></li>
                        <li><a href="/services/cloud/cloud-hosted">Cloud Hosted Services</a></li>
                        <li><a href="/services/cloud/enterprise">Enterprise Cloud</a></li>
                        <li><a href="/services/cloud/smb-cloud">SMB Cloud</a></li>
                    </ul>
                </li>
                <li><a href="/services/project-management">Project Management</a>
                    <ul class="super-sub-list">
                        <li><a href="/services/project-management/project-recovery-solutions">Project Recovery Solutions</a></li>
                        <li><a href="/services/project-management/data-center-project-management">Data Center Project Management</a></li>
                    </ul>
                </li>
                <li><a href="/services/compliance-assessments">Compliance</a>
                    <ul class="super-sub-list">
                        <li><a href="/services/compliance-assessments/cmmc">CMMC</a></li>
                        <li><a href="/services/compliance-assessments/gdpr">GDPR</a></li>
                        <li><a href="/services/compliance-assessments/hipaa">HIPAA</a></li>
                        <li><a href="/services/compliance-assessments/pci">PCI</a></li>
                    </ul>
                </li>
                <li><a href="/services/webdesign-and-development">Web Design and Development</a>
                    <ul class="super-sub-list">
                        <li><a href="/services/webdesign-and-development/ux-ui-design">UX/UI Design</a></li>
                        <li><a href="/services/webdesign-and-development/website-development">Website Development</a></li>
                        <li><a href="/services/webdesign-and-development/website-maintenance-and-security">Website Maintenance and Security</a></li>
                    </ul>
                </li>
                <li><a href="/services/application-development">Application Development</a></li>
                <li><a href="/services/branding">Branding</a></li>
                <li><a href="/services/digital-marketing">Digital Marketing</a>
                    <ul class="super-sub-list">
                        <li><a href="/services/digital-marketing/seo">SEO (Search Engine Optimization)</a></li>
                        <li><a href="/services/digital-marketing/paid-advertisement">Paid Advertisement</a></li>
                        <li><a href="/services/digital-marketing/social-media-marketing">Social Media Marketing</a></li>
                        <li><a href="/services/digital-marketing/programmatic-ott-advertisement">Programmatic/OTT Advertisement</a></li>
                        <li><a href="/services/digital-marketing/email-marketing">Email Marketing</a></li>
                        <li><a href="/services/digital-marketing/analyticsreporting">Analytics</a></li>
                        <li><a href="/services/digital-marketing/online-reputation-management">Online Brand Reputation Management</a></li>
                    </ul>
                </li>
                <li><a href="/services/digital-recruitment">Digital Recruitment</a></li>
            </ul>
        </li>
        <li><a href="/our-clients">Our Clients</a></li>
        <li><a href="https://endurance-it.zohorecruit.com/jobs/Careers">Careers</a></li>
        <li><a>Resources</a>
            <ul class="sub-list">
                <li><a href="/newsroom">Newsroom</a></li>
                <li><a href="/blog">Blog</a></li>
                <li><a href="/download-casestudy">Case Study</a></li>
            </ul>
        </li>
        <li><a href="/contact-us">Contact Us</a></li>
        <li><a href="/get-support">Get Support</a></li>
        <li><a href="/terms-condition">Terms & conditions</a></li>
        <li><a href="/privacy-policy">Privacy policy</a></li>
    </ul>
</section>