<header class="serviceinner">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Outsourcing</h1>
    <h4>An ongoing partnership to accelerate<br> digital transformation</h4>
    <div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img alt="Managed IT, Cloud Services and Service Desk" src="/assets/images/service/Outsourcing/service-icon.png">
                <h2>Managed IT, <span>cloud, and service desk solutions</span></h2>

            </div>
            <div class="content">
                <p>Whether you need to add new skills to your existing team, source specialists for a particular project, or establish a complete IT department, our outsourced solutions will improve organizational efficiency and lower your IT infrastructure costs.</p>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6"><img   class="shadow-lg" alt="IT infrastructure" src="https://ik.imagekit.io/b0cgaqv3j/service/Outsourcing/rightside.png?updatedAt=1677586042268"></div>
    </div>
</section>
<section class="section2" >
    <h3>Our outsourced IT solutions include:</h3>
    <div class="bulletlist">
        <div class="borderw">
            <img alt="Managed IT Services" src="/assets/images/service/Outsourcing/icon1.png">
            <h5>Managed IT <br>Services</h5>
            <div>Improve service and reduce costs with our managed alternatives to in-house IT resources</div>
            <div class="bbtnsbox"><a href="/services/managed-services" class="btnsbox">learn more</a></div>
        </div>
        <div class="borderw">
            <img alt="Cloud Services" src="/assets/images/service/Outsourcing/icon2.png">
            <h5>Cloud <br>Services</h5>
            <div>Public, Private & Hybrid Cloud solutions for flexible resources and faster innovation</div>
            <div class="bbtnsbox"><a href="/services/infrastructure-services/cloud" class="btnsbox">learn more</a></div>

        </div>
        <div class="borderw">
            <img alt="Service Desk" src="/assets/images/service/Outsourcing/icon3.png">
            <h5>Service <br>Desk</h5>
            <div>A virtual extension or alternative to an in-house help desk to manage incidents and support requests</div>
            <div class="bbtnsbox"><a href="/get-support" class="btnsbox">learn more</a></div>

        </div>
        <div class="borderw"><img alt="Performance Management" src="/assets/images/service/Outsourcing/icon4.png">
            <h5>Performance <br>Management</h5>
            <div>Monitor the performance of your infrastructure and allocate appropriate resources to keep your business productive</div>
            <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">learn more</a></div>

        </div>

    </div>
  </section>
<section class="section3">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <br> <br> <br><h3><strong>Get in touch to discuss</strong><span> your IT outsourcing needs.</span></h3>
            <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">let's talk about it</a></div>
            <br> <br> <br></div></div>
</section>
