<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Website
    <span>development</span>
  </h1>
  <h4>Digital experiences that project <br>authority, credibility and
    expertise</h4><div class="bbtnsbox"><a href="/contact-us"
      class="btnsbox">let's talk about IT </a></div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading"
        style="margin-bottom: 1rem; margin-top: 0px !important;">
        <img src="/assets/images/service/Website_Development/icon.png"
          alt="website development solutions">
        <h2 >
          Specialist<span> website development solutions</span>
        </h2>
      </div>
      <div class="content">
        <h3>Both proven and practical</h3>
        <p> Website development goes beyond designing a pretty website that
          makes your customers say “ohh” and “ahh.” </p>
        <p>When it comes to web development, Endurance creates, builds, and
          maintains award-winning websites. Yes, we can make your website look
          beautiful. But our services go beyond what’s on the surface.</p>
          <p> When you hire
          Endurance for your web development project, you’ll obtain a secure,
          high-performing website that looks great and provides a seamless user
          experience.</p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/service/Website_Development/Rightside.jpg?updatedAt=1705444733415"
        alt="Specialist website development solutions">
    </div>
  </div>
</section>
<section class="section2 iconanimation extra mainredbar">
  <h3><strong >
      Let’s Build a Great Website Together</strong></h3>
  <p >Our development team has experience building
    hundreds of world-class websites across a variety of coding languages,
    frameworks, and industries.
  </p></section>
<section class="section6">
  <h3>
    <strong>We help our clients upgrade their<br> digital presence</strong> -
    starting with a stunning<br> website that translates to business growth.
  </h3>
</section>
<section class="section4">
  <div>
    <div class="heading">
      <img src="/assets/images/service/Website_Development/icon1.png"
        alt="Website Development Planning">
      <h2 style="padding-top: 8px;font-size: 1.4rem;">Planning</h2>
    </div>
    <p>It’s no surprise that proper planning is the key to a successful project,
      and that includes web development.</p>
    <p>When we work together, we focus on your business goals and create a
      systematic development plan with clear milestones and deliverables for
      project success.
    </p><p>
      Let’s win together.</p>

  </div>
  <div>
    <div class="heading">
      <img src="/assets/images/service/Website_Development/icon2.png"
        alt="Website development Purpose">
      <h2  style="padding-top: 8px;font-size: 1.4rem;">Purpose</h2>
    </div>
    <p>Before we write a single line of code, we ensure that your purpose is
      aligned with the scope of your web development project. </p>
    <p>The purpose of your website will influence the design, structure,
      technology, and strategic direction of your website. </p>

    <p> No matter what your purpose is, Endurance will build a website that
      delivers maximum functionality on an affordable budget.
    </p>
  </div>
</section>
<section class="section3">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 class="colorw">
        Ready to make your website<br> stand out? <span>Let’s discuss<br> your
          project!</span>
      </h3>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">LET’S TALK ABOUT IT</a>
      </div>
    </div>
  </div>
</section>
