import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.css']
})
export class NewsComponent {
    newsRoom: any = [];
    

    page: number = 1;
    count: number = 0;
    tableSize: number = 8;
    tableSizes: any = [3, 6, 9, 12];



    constructor(private http: HttpClient,private MetaService: MetaServiceService) { }
    ngOnInit(): void {
        this.getNews();
        this.MetaService.createCanonicalURL();
    }
    getNews() {
        const url = '/assets/news/newsdata.json?v=2';
        this.http.get(url).subscribe(res => {
            console.log(res, 'HI');
            this.newsRoom = res;
        });

    }
    onTableDataChange(event: any) {
        this.page = event;
        this.getNews();
    }
    onTableSizeChange(event: any): void {
        this.tableSize = event.target.value;
        this.page = 1;
        this.getNews();
    }
}
