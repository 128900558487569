import { Component } from '@angular/core';

@Component({
  selector: 'app-ed-ads-header',
  templateUrl: './ed-ads-header.component.html',
  styleUrls: ['./ed-ads-header.component.css']
})
export class EdAdsHeaderComponent {
  isPopupOpen = false;

  openPopup(): void {
    this.isPopupOpen = true;
  }

  closePopup(): void {
    this.isPopupOpen = false;
  }
}
