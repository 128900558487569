<app-about-header></app-about-header>
<div class="row about-section">
    <div class="col-5">
        <span>
            <strong>15 years: A celebration</strong>
            in achievement
        </span>
        <p style="margin-top:30px !important;margin-bottom:50px;">
          In 2023, we celebrated our 15th anniversary. From a strategic start-up to a successful, full-service IT and digital marketing agency, we have a lot to be proud of! And with our dedicated client relationships and world-class staff, we are confident in the success of our future.
        </p>
    </div>
    <div class="col-7">
        <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/lf4KUlRyUsI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
    </div>
</div>
<div class="jumbotron jumbotron-fluid row">
    <img src="assets/images/logo-sign.png">
    <div class="col-6">
        <span>
            <strong>Our </strong>
            Mission
        </span>
        <p style="margin-top:30px">
            The mission we serve as Endurance IT Services is
            <strong>
                to enhance lives
                through technology and digital capabilities.
            </strong>
            Guiding us are our core values-being fanatical about client satisfaction, staff retention, investing in our people, and serving our communities.
        </p>
        <p>
            <strong>
                Our vision is to bring innovation and maximizing value for clients, staff, and the
                general community
            </strong> with transformation enabled by technology, marketing, and digital capabilities. Our business and revenue growth will always follow this vision in ways that improve our fanatical commitment to enhancing lives.
        </p>
    </div>
    <div class="col-6" style="padding-left:100px;">
        <span>
            <strong>Core</strong>
            Values:
        </span>
        <ul>
            <li>
                <strong>Client Satisfaction:</strong>
                Be fanatical about client satisfaction like no one else in our marketplace.
            </li>
            <li>
                <strong>Company Culture:</strong>
                Be fanatical about staff retention and invest in our people.
            </li>
            <li>
                <strong>Community Service:</strong>
                Help serve the Hampton Roads community through financial support and volunteerism.
            </li>
        </ul>
    </div>

</div>
<div class="origin-section" align="center" style="display: none;">
    <span>
        <strong>A strategic</strong>
        origin
    </span>
    <p style="margin-top:30px!important;font-size: 1rem;">
        Every great company begins by observing and filling a need. The same is true of us. As longtime IT professionals, we saw a need for a new way of conducting business and knew there was a better way to serve our clients. It's been an exciting journey
        filled with challenges, opportunities, and successes along the way.
    </p>
    <app-timeline></app-timeline>


</div>
<div class="logo-section" align="center">
    <img src="/assets/images/logo-sign.png">
    <br>
    <span>
        <strong>About</strong>
        our logo
    </span>
    <div>Meaning of the Koru</div>
    <p>Endurance's company logo is based upon the ancient Koru symbol which has been used to symbolize growth, strength and harmony for centuries.</p>
</div>
<app-gsh></app-gsh>
<app-form></app-form>
