<app-solution-small-business-header></app-solution-small-business-header>
<section class="firstsection row">
    <div class="col-md-6">
        <img alt="IT and Digital Marketing for small business" src="https://ik.imagekit.io/b0cgaqv3j/solutions/inner/smallbusiness/image1.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677613794685">
    </div>
    <div class="col-md-6">
        <br>

        <div class="row">
            <div class="col-md-2">
                <img alt="small business" src="assets/images/solutions/inner/smallbusiness/SmallB.png">
            </div>
            <div class="col-md-10 heading">
                <h2>IT and Digital Marketing for small business</h2>
                <h3>The right fit for any size business</h3>
            </div>
        </div>
        <p>
            Your commitment to your small business deserves the same level of support from your trusted service
            provider.
        </p>
        <p>
            Whether IT or digital marketing services, our experts will treat you like a partner, not just a
            client.
        </p>
    </div>
</section>
<section class="secondsection ">
    <div class="innericons">
        <div>
            <div class="icons">
                <img  alt="IT Managed Services"  src="assets/images/solutions/inner/smallbusiness/ITManagedServices.png">
            </div>
            <h4>IT Managed
                <br>Services
            </h4>
            <span>
                <a href="/services/managed-services">More</a>
            </span>
        </div>
        <div>
            <div class="icons">
                <img alt="Cyber security" src="assets/images/solutions/inner/smallbusiness/Cybersecurity.png">
            </div>
            <h4>Cybersecurity</h4>
            <span>
                <a href="/services/compliance-assessments">More</a>
            </span>
        </div>
        <div>
            <div class="icons">
                <img  alt="IT Projects"  src="assets/images/solutions/inner/smallbusiness/ITProjects.png">
            </div>
            <h4>IT
                <br> Projects
            </h4>
            <span>
                <a href="/services/project-management">More</a>
            </span>
        </div>
        <div>
            <div class="icons">
                <img  alt="Web Design and  Development"  src="assets/images/solutions/inner/smallbusiness/development.png">
            </div>
            <h4>Web Design and
                <br> Development
            </h4>
            <span>
                <a href="/services/webdesign-and-development">More</a>
            </span>
        </div>
        <div>
            <div class="icons">
                <img   alt="Website  Maintenance"  src="assets/images/solutions/inner/smallbusiness/WebsiteMaintenance.png">
            </div>
            <h4>Website
                <br> Maintenance
            </h4>
            <span>
                <a href="/services/webdesign-and-development/website-maintenance-and-security">More</a>
            </span>
        </div>
        <div>
            <div class="icons">
                <img alt="Digital Marketing" src="assets/images/solutions/inner/smallbusiness/DigitalMarketing.png">
            </div>
            <h4>Digital
                <br>Marketing
            </h4>
            <span>
                <a href="/services/digital-marketing">More</a>
            </span>
        </div>
    </div>
</section>
<section class="thiredsection ">
    <div class="contentwithbutton">
        <div class="innercontent">
            <div></div>
            <div style="padding-left: 6rem;">
                <h3>Grow your business
                </h3>
                
                <p>
                    We value communication, trust, accountability, and speaking in plain English, not in complex
                    languages
                    and
                    terminology. Whether you need to troubleshoot a business challenge or need ongoing maintenance,
                    we’re
                    here
                    for you.
                </p>
                <br>
                <div class="contactbbtun">
                    <a _ngcontent-lvn-c33="" href="/contact-us" class="contactbtn">
                        CONTACT US
                        TODAY
                    </a>
                </div>
            </div>
        </div>
    </div>
    <img alt="Grow your business" src="https://ik.imagekit.io/b0cgaqv3j/solutions/inner/smallbusiness/image2.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677613794310">
</section>

