import { Component, OnInit } from '@angular/core';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {

  constructor(private MetaService: MetaServiceService) { }

  ngOnInit(): void {
    this.MetaService.createCanonicalURL();
  }

}
