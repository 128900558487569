import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {
  SEO: any = [];
  constructor(private http: HttpClient, private title: Title, private meta: Meta,private MetaService: MetaServiceService) { }

  ngOnInit(): void {
    this.MetaService.createCanonicalURL();
    const url = '/assets/seo/management.json';
    this.http.get(url).subscribe(seodata => {
      
      this.SEO = seodata;
      console.log(this.SEO[0]);
      this.title.setTitle(this.SEO.meta_title);
      this.meta.updateTag({ name: 'description', content: this.SEO.meta_dec });
      this.meta.updateTag({ name: 'keyword', content: this.SEO.keyword });
    });
  }

}