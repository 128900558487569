import { Component } from '@angular/core';

@Component({
  selector: 'app-kerner-group-header',
  templateUrl: './kerner-group-header.component.html',
  styleUrls: ['./kerner-group-header.component.css']
})
export class KernerGroupHeaderComponent {

}
