import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-get-support',
  templateUrl: './get-support.component.html',
  styleUrls: ['./get-support.component.css']
})
export class GetSupportComponent implements OnInit {
  toDisplay = true;
  Results: any = [];
  SEO: any = [];
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta,private MetaService: MetaServiceService) { }

  ngOnInit(): void {
    this.MetaService.createCanonicalURL();
    const url = '/assets/seo/get-support.json';
        this.http.get(url).subscribe(seodata => {

            this.SEO = seodata;
            console.log(this.SEO[0]);
            this.title.setTitle(this.SEO.meta_title);
            this.meta.updateTag({ name: 'description', content: this.SEO.meta_dec });
            this.meta.updateTag({ name: 'keyword', content: this.SEO.keyword });
        });
  }

}
