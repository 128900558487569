<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
      <div class="container-fluid">
          <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

          <app-nav></app-nav>
      </div>
  </nav>
  <h1>Networking <br><span> solutions</span></h1>
  <h4>Scalable and secure networking solutions <br>to grow alongside you</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
      <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
  </div>
</header>
<section class="section1">
  <div class="row">
      <div class="col-sm-6 col-lg-6 col-md-6">
          <div class="heading">
              <img src="/assets/images/service/Networking_Solutions/icon.png" alt="Stable network design and deployment">
              <h2>Stable network design <br><span>and deployment</span></h2>

          </div>
          <div class="content">
              <p>At Endurance, we offer stability and flexibility with network design, network deployment and wireless network deployment. We deliver scalable and secure IP networks which can be customized as your business needs change.</p>
          </div>
      </div>
      <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg" src="/assets/images/NetWorkingSolution_Rightside.jpg" alt="network design and deployment"></div>
  </div>
</section>
<section class="section2">
  <h3>Our networking solutions for planning, design, implementation and operation:</h3>
  <div class="bulletlist">
      <div class="borderw">
          <img src="/assets/images/service/Networking_Solutions/1.png" alt="Campus switching and routing for voice, video and data">
          <div>Campus switching and routing for voice, video and data</div>

      </div>
      <div class="borderw"><img src="/assets/images/service/Networking_Solutions/2.png" alt="Wireless LAN solutions">
          <div>Wireless LAN solutions</div>
      </div><div class="borderw"><img src="/assets/images/service/Networking_Solutions/3.png" alt="VPN and network security solutions">
          <div>VPN and network security solutions</div>
      </div><div class="borderw"><img src="/assets/images/service/Networking_Solutions/4.png" alt="LAN infrastructure audit and analysis">
          <div>LAN infrastructure audit and analysis</div>
      </div>
      <div><img src="/assets/images/service/Networking_Solutions/5.png" alt="WAN traffic audit and shaping">
          <div>WAN traffic audit and shaping</div>
      </div>
      <div><img src="/assets/images/service/Networking_Solutions/6.png" alt="IP telephony">
          <div>IP telephony</div>
      </div>
  </div>
</section>
<section class="section3" style="background-image: url(/assets/images/networking_footer.jpg);">
  <div class="row">
      <div class="col-sm-6 col-lg-6 col-md-6"></div>
      <div class="col-sm-6 col-lg-6 col-md-6">
          <h3><strong>Our team of experts has the experience </strong>and knowledge to design, deploy and support network infrastructure projects with security as the top priority.<br> <br>If you have a networking need, get in touch.</h3>
          <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">let's talk about it</a></div>
      </div></div>
</section>
