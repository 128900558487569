import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {
  constructor(private router: Router,private MetaService: MetaServiceService) {


  }
  ngOnInit() {
    this.MetaService.createCanonicalURL();
    console.log(this.router);
    this.router.navigate(['/']);
}
}
