import { Component } from '@angular/core';

@Component({
  selector: 'app-it-business-analysts-thankyou',
  templateUrl: './it-business-analysts-thankyou.component.html',
  styleUrls: ['./it-business-analysts-thankyou.component.css']
})
export class ItBusinessAnalystsThankyouComponent {

}
