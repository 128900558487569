<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/Digital_Recruitment/Banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1678377462381);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Digital

    <span>
      recruitment
    </span>
  </h1>
  <h4>
    Targeted campaigns to identify <br>and engage top tier candidates
  </h4><div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="newheadingbox">
  <h2 class="h2-beforecallouts">
    Digital Recruiting
    <span>Done For You</span>
  </h2>
  <p class="p-beforecallouts" style="display: none;">Strategize, attract,
    engage, evaluate</p>
</section>
<section class="callout-boxes">
  <div>
    <img src="/assets/images/call-out/DigitalRecruitment/Identity.png"
      alt="Identify">
    <h4>Identify</h4>
    <p>We determine your goals and launch the social campaign</p>
  </div>
  <div>
    <img src="/assets/images/call-out/DigitalRecruitment/ERngage.png"
      alt="Engage">
    <h4>Engage</h4>
    <p>We identify and approach the best potential candidates</p>
  </div>
  <div>
    <img src="/assets/images/call-out/DigitalRecruitment/Evaluate.png"
      alt="Evaluate">
    <h4>Evaluate </h4>
    <p>We conduct background checks and refer candidates to you</p>
  </div>
</section>

<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/digitalrecruitment/icon.jpg"
          alt="Digital recruiting done for you" style="margin-top: -1px;">
        <h2 style="display: initial !important; padding-top: 1rem;">
          Digital recruiting <span>made easy</span>
        </h2>
      </div>
      <div class="content">
        <h3>Strategize, attract, engage, evaluate</h3>
        <p>
          At Endurance, we recognize that the 21st-century hiring environment
          can be aggressive and competitive. That's why we began offering
          standalone digital recruitment services to help our clients who were
          struggling to recruit highly qualified, new talent. Using a
          multi-touch recruiting strategy, we actively attract, engage, and
          deliver top-tier candidates for our client’s open roles—and we can do
          it for you, too.</p>
        <p>
          If your hiring strategy isn’t consistently and successfully delivering
          best-in-class candidates that fit your roles and organization
          requirements, we can help!
        </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/service/Digital_Recruitment/Rightside.jpg?updatedAt=1679571567121"
        alt="Digital Recruitment Services">
    </div>
  </div>
</section>
<section class="section2 mainredbar">
  <h3><strong>What is Digital Recruitment Marketing?</strong></h3>
  <p style="font-size: 20px!important;">Digital recruiting is our proprietary
    candidate/lead generation solution that identifies, engages, and vets job
    candidates for the corporate hiring process by leveraging
    industry-appropriate social media platforms. Our digital recruitment
    campaigns offer the following benefits:</p><!-- comment -->
  <div class="serviceicons cols2 bulletlist">
    <div>
      <ul>
        <li>Reach a wider pool of qualified candidates</li>
        <li>Strategically produce highly targeted results</li>
        <li>Send timely and effective messages to the right audience</li>
      </ul>
    </div>
    <div class="noborder">
      <ul>
        <li>Highly effective use of your marketing budget, HR resources, and
          time
        </li>
        <li>
          Quickly deliver qualified, pre-screened candidates for short-term,
          long-term, and permanent hires
        </li>
      </ul>
    </div>
    <!-- comment -->
    <!-- comment -->
  </div>
</section>
<section class="section4 alignc">
  <h3 class="coloro" style="margin-bottom:1rem; font-size: 28px !important;">Follow our lead</h3>
  <p class="para" style="font-size: 20px !important;">
    Endurance IT has been one of the leading staffing and recruiting providers
    in Hampton Roads for the past 15 years. Over the last 5 years, we have
    successfully deployed several digital recruiting funnels to identify,
    attract, engage, and evaluate qualified candidates for our clients. This has
    allowed us to help our clients meet their staffing needs while growing our
    staffing practice.
  </p>
  <h4
    style="color: #1c345c!important; margin-bottom: 1rem; font-size: 1.4rem !important;font-weight: 700 !important;font-family: MontserratSB;">
    Today, we are using our framework to help clients in different
    industries attract candidates for the following positions:

  </h4>
  <div class="cols2 bulletlistr para">
    <div>
      <ul>
        <li>Accountants, bookkeepers, and admin support</li>
        <li>Construction and building maintenance staff</li>
        <li>Nursing professionals (NP, RN, CRN, LPN, etc.)</li>
        <li>Caregivers/assisted living staff </li>
        <li>Kitchen/dining staff (cooks, dishwashers, hostesses, servers,
          etc.)</li>
      </ul>
    </div>
    <div>
      <ul>
        <li>IT support (Project Managers, Desktop Engineers, CIOs, etc.)</li>
        <li>Marketing management, coordinators, and executives</li>
        <li>Application developers</li>
        <li>Web designers/developers</li>
      </ul>
    </div>
  </div>
  <h4
    style="color: #1c345c!important; margin-bottom: 1rem; font-size: 1.4rem !important;font-weight: 700 !important;font-family: MontserratSB;">
    If you don’t see your staffing need on this list, contact us. We can still
    help!
  </h4>
</section>
<section class="section5">
  <div class="cols2">
    <div>
      <div class="heading"><img
          src="/assets/images/service/digitalrecruitment/partnership.png"
          alt="Strategic partnerships for better hiring outcomes">
        <h3>Strategic partnerships for better hiring outcomes</h3></div>
      <p class="pera">
        We support partnerships with clients of all sizes and can scale our
        services to meet your business needs. Whether you’re interested in an
        augmented team model for a set period of time, or permanent hire
        support, we’re a proven resource for you.

      </p></div>
    <div><div class="heading"><img
          src="/assets/images/service/digitalrecruitment/like.png"
          alt="A winning strategy that makes recruiting more successful">
        <h3>A winning strategy that makes recruiting more successful</h3>
      </div>
      <p class="pera">
        Unlike other recruiters or headhunters, we apply an affordable and
        practical multi-touch recruiting strategy. This includes inbound and
        outbound digital marketing efforts that target both active and passive
        candidates. We become an extension of your HR and marketing teams at a
        fraction of the
        cost of average recruitment agencies.</p>
    </div>
  </div>
</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/Digital_Recruitment/footercontent.jpg?updatedAt=1679571565356);">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3 style="color: #fff;">Step into the future of hiring with Endurance IT</h3>
        <p>
          Our digital recruitment services are designed to match you with the
          ideal candidates efficiently, reducing your time-to-hire and enhancing
          your team’s capabilities. Aligned to your unique business needs, our
          tailored solutions ensure you attract and retain industry-leading
          professionals.</p><p>
          Revolutionize your approach and build the team that leads your
          business to new heights.</p>

      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">ELEVATE NOW</a>
      </div>
    </div>
  </div>
</section>
