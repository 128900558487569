<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Public
    <span>cloud</span>
  </h1>
  <h4>On-demand resources to reduce IT costs</h4><div _ngcontent-psv-c86
    class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/cloud/cloudmain/1.png"
          alt="Public Cloud Solutions">
        <h2>
          Public cloud solutions <span>for any size business</span>
        </h2>
      </div>
      <div class="content">
        <p>Endurance delivers a scalable and efficient digital transformation
          that combines the best solutions from today’s leading infrastructure
          partners. From small organizations to global leaders, you now have
          access to enterprise-level infrastructure without massive capital
          outlays.</p>
        <h3> Write Your Own Rules</h3><p>

          A relatively new concept, the public cloud provides businesses with a
          way to access and utilize resources on demand and only pay for what
          they use.
        </p>
        <p>
          By selecting from multiple vendors and solutions, you can pick and
          choose the right tools for your organization. This increases developer
          efficiency and decreases overall IT costs.</p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/service/cloud/public-cloud/rightslide.jpg?updatedAt=1679470523499"
        alt="Endurance Cloud Migration Services" class="shadow-lg">
    </div>
  </div>
</section>
<section class="section2 mainredbar">
  <h3>Cutting edge tools and technology</h3>
  <p>By partnering with top cloud providers and database technology platforms,
    your cloud transition is set up for success.
  </p><p><strong>Endurance provides:</strong></p>
  <div class="serviceicons">
    <div>
      <img src="/assets/images/service/cloud/public-cloud/serv1.png"
        alt="Data protection">

      <div>Data protection</div>
    </div>
    <div>
      <img src="/assets/images/service/cloud/public-cloud/serv2.png"
        alt="Backup and recovery solutions">

      <div>Backup and recovery solutions</div>
    </div>
    <!-- comment -->
    <div>
      <img src="/assets/images/service/cloud/public-cloud/serv3.png"
        alt="Virtual technologies">

      <div>Virtual technologies</div>
    </div>
    <div>
      <img src="/assets/images/service/cloud/public-cloud/serv4.png"
        alt="Troubleshooting">

      <div>Troubleshooting</div>
    </div><div class="noborder">
      <img src="/assets/images/service/cloud/public-cloud/serv5.png"
        alt="Consulting">

      <div>Consulting</div>
    </div>
    <!-- comment -->
  </div>
</section>
<section class="section4 graybar">
  <h3>Cloud Migration Services</h3>
  <p style="font-size: 20px !important;">Transitioning to the cloud can boost your productivity and keep you
    competitive in today’s fast-paced world.</p>

  <p style="font-size: 20px !important;">Every business transitions at a different pace, and Endurace custom tailors
    solutions to fit your needs in an organized migration effort.</p>
  <ul class="listofservice">
    <li><h4>Audit and goal setting:</h4><p>We look at your entire technology
        infrastructure and uncover your short and long-term goals.</p></li>

    <li><h4>Plan the transition:</h4><p>We put together a plan that balances
        timeframes, budgets, risks, and advantages. </p></li>

    <li><h4>Test migration options:</h4><p>A tiered approach to cloud migration
        eases transitions and minimizes downtime.</p></li>

    <li><h4>Complete the conversion:</h4><p>We transfer production workloads to
        your new environment in a way that fits your business needs. </p></li>
  </ul>
</section>
<section class="section5 graybar">
  <div class="enlogoicon"><img class
      src="assets/images/service/cloud/public-cloud/endurance.png"
      alt="Endurance IT Services Favicon"></div>
  <h3>Why Choose Endurance for Public Cloud Solutions?</h3>
  <p style="font-size: 20px !important;">Endurance has been serving Hampton Roads businesses since 2009, making us a
    trusted technology provider. </p>

  <h4 class="subheading">Whether you rely on us for full IT infrastructure or for building
      upon your existing team, we offer:</h4>
  <div class="bulletlistr">
    <ul>
      <li>Longstanding strategic partnerships with premier vendors</li>
      <li>Custom solutions tailored to your exact business needs</li>
      <li>An experienced team of technical experts fanatic about<br> customer
        service</li>
      <li>24/7 technical support (remote and on-site)</li>
    </ul>

  </div>
</section>

<section class="section3">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>Reap the Benefits of Endurance Cloud Migration Services</h3>
      <p>Modernize your infrastructure by processing large amounts of data,
        increasing your storage capacity, and upgrading your information
        protection capabilities. Our cloud migration services offer your
        business all of that - and more!
      </p><p>
        Let’s connect to build a public cloud solution that’s ideal for your
        digital transformation strategy. </p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">CONNECT WITH US</a>
      </div>
    </div>
  </div>
</section>
