<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/gdpr/header-banner.png?updatedAt=1679565592684s) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"><img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services"></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>GDPR</h1>
  <h4>Ensure your company is lawfully <br>handling EU-based customer
    data</h4><div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">Get in touch</a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img alt="What is GDPR?"
          src="/assets/images/service/compliace-assessments/gdpr/icon.png">
        <h2>What is GDPR?</h2>

      </div><div class="content">

        <p>The General Data Protection Regulation (GDPR) is a set of
          standardized data protection laws regulating how companies handle the
          personal information of any individual within the EU they interact
          with. It outlines a framework for storing and using any personal data
          that can directly or indirectly identify a living person, such as
          their name, location, online username, IP address, or cookie
          identifiers.
        </p>
        <p>From the individual’s point of view, GDPR makes it easier to
          understand how their data is being used by a company, and raise any
          complaints if they feel it has been misused.
        </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6"><img class="shadow-lg"
        alt="General Data Protection Regulation (GDPR)"
        style="height: auto !important;"
        src="https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/gdpr/Rightside.png?updatedAt=16795655914592"></div>
  </div>
</section>
<section class="section2 mainredbar" >

  <h3><strong>Do I need to be GDPR compliant?</strong></h3><p>If you interact
    with individuals in the EU, whether they are customers, prospects or
    suppliers, then yes.</p>

</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/gdpr/footer-banner.png?updatedAt=1679565586034) !important;">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>Don’t risk non-compliance</h3>
      <p>Protect your customers’ data and avoid costly fines with our
        comprehensive GDPR compliance solutions.</p>
      <p>Endurance will guide you through the complexities of data protection
        regulations so you can safeguard your bottom line.</p>
      <p>Take action now - Your customers’ trust and your business’s future
        depend on it!</p>
      <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">GET COMPLIANT
        </a></div>
    </div></div>
</section>
