import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class MetaServiceService {

  constructor(@Inject(DOCUMENT) private doc: Document) { }
  createCanonicalURL() {
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    if (this.doc.URL == 'https://www.endurance-it.com/') {
      link.setAttribute('href', 'https://www.endurance-it.com');
    } else {
      link.setAttribute('href', this.doc.URL);
    }

    // const SC: string ='';
    // this.SC = '<script type="application/ld+json">{"@context": "https://schema.org","@type": "NewsArticle","mainEntityOfPage": {"@type": "WebPage","@id": "https://www.endurance-it.com/newsroom"},"headline": "Read about the latest news and notes from our team","image": "https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456","author": {"@type": "Organization","name": "Endurance IT Services","url": "https://www.endurance-it.com/"},"publisher": {"@type": "Organization","name": "Endurance IT Services","logo": {"@type": "ImageObject","url": "https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"}},"datePublished": ""}</script>';
    // console.log('----------------------',this.doc);
    // this.doc.head.appendChild(this.SC);

  }
}
