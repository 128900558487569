<header class="serviceinner"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/pci/header-banner.png?updatedAt=1679565584600) !important;">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>PCI</h1>
  <h4>Process card payments securely <br>with universal data security
    standards</h4>
  <div _ngcontent-psv-c86 class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">Get in touch</a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img alt="What is PCI?"
          src="/assets/images/service/compliace-assessments/pci/icon.png">
        <h2>What is PCI?</h2>
      </div>
      <div class="content">
        <p>
          The Payment Card Industry Data Security Standard (PCI DSS) is an
          information security standard mandated by credit card companies to
          help ensure the security of credit card transactions. It ensures that
          any company that accepts, transmits, or stores the private data of
          cardholders is compliant with specific technical and operational
          requirements.
        </p>
        <p>
          While not a law, companies that are not PCI compliant leave themselves
          open to costly cyber attacks and data breaches - with penalties
          ranging from $5,000 to $500,000.
        </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img class="shadow-lg"
        alt="Payment Card Industry Data Security Standard (PCI DSS)"
        src="https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/pci/Rightside.png?updatedAt=1679565585336">
    </div>
  </div>
</section>
<section class="section2 mainredbar">
  <h3>
    <strong>
      Do I need to be PCI compliant?
    </strong></h3>
    <p>Simply put, any company that handles credit card payments needs to be
    PCI compliant to protect both their customers’ data and their own
    reputation.</p>

</section>
<section class="section3"
  style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/compliace-assessments/pci/footer-banner.png?updatedAt=1679565586156) !important;">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>Take a proactive approach to data security</h3>
      <p>Protect your business from costly data breaches and potential legal
        liabilities by achieving PCI compliance.</p>
      <p>Endurance understands that this is a crucial step toward safeguarding
        your customers’ sensitive information. That’s why we provide
        personalized support, so you can be confident in your compliance.</p>
      <p>Don’t wait for a security breach to happen. Your peace of mind and your
        customers’ trust are just a click away!</p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">GET COMPLIANT</a>
      </div>
    </div>
  </div>
</section>
