<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/contact/banner.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677617746710);">
  <nav class="navbar navbar-expand-sm">
      <div class="container-fluid">
          <a class="navbar-brand" href="/">
              <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1679916736866" alt="Endurqance IT Services">
          </a>
          <app-nav></app-nav>
      </div>
  </nav>
  <h1>
      Survey Endurance
  </h1>
  <h4></h4>
</header>
<section>
  <div><iframe src="https://link.thinkzag.com/widget/survey/qYCT7oK01qrUnU0YD6Wj" style="border:none;width:100%;" scrolling="no" id="qYCT7oK01qrUnU0YD6Wj" title="Survey main Endurance"></iframe>
    <script src="https://link.thinkzag.com/js/form_embed.js"></script></div>
</section>
