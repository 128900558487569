<header style="background-image: url(assets/images/news/banner.jpg) !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Newsroom</h1>
    <h4>Read about the latest news, events, and updates from our team!</h4>
</header>
<section class="section1"><div class="row">
        <img alt="{{ newsRoom.result[0].post_title}}" src="assets/images/newdefault.png" class="col-md-5 newsimgsdefault" style="background: url({{ newsRoom.result[0].Imagess}})" >
        <div class="col-md-7" align="left">
            <h2 class="newstitle">
                <a href="/newsroom/{{ newsRoom.result[0].post_name}}" [innerHTML]="newsRoom.result[0].post_title">
                    {{ newsRoom.result[0].post_title}}
                </a>
            </h2>
            <span class="dates">{{ newsRoom.result[0].post_date}} | News</span>
            <p [innerHTML]="(newsRoom.result[0].Maincontent.length>1000)? (newsRoom.result[0].Maincontent | slice:0:1002)+'....':(newsRoom.result[0].Maincontent)">{{ newsRoom.result[0].Maincontent}}</p>
            <a class="readmorebtn" href="/newsroom/{{ newsRoom.result[0].post_name}}">read more</a>
        </div>
    </div></section>
<section class="section2">
    <h3>Find more  <span>stories</span></h3>
    <div class="newsbox4">
        <div class="newsscontent" *ngFor="let post of newsRoom.result | paginate : {itemsPerPage: tableSize, currentPage: page,totalItems: count}; let i = index">
            <a href="newsroom/{{post.post_name}}">
                <div style="background:url({{post.Imagess}})" class="imagesbox"><img alt="arrow" src="/assets/images/NewsDetail/arrow.jpg"></div>
                <h4 [innerHTML]="post.Ddate">s</h4>
                <h2 [innerHTML]="post.post_title"></h2>
            </a>
        </div>


    </div>
    <pagination-controls
      previousLabel="Prev"
      nextLabel="Next"
      (pageChange)="onTableDataChange($event)"
    >
    </pagination-controls>
</section>
