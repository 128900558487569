<header style="background-image: url('../../../assets/images/kerner-group-banner.png');">
    <div class="bgdhomheader monly">ss</div>
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" routerLink="/" ariaCurrentWhenActive="page" ><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>
            <app-nav></app-nav>

        </div>
    </nav>
    <h1>Securing IT, Unifying Technology, & Amplifying Success</h1>
    <h4>Discover How We Transformed The Kerner Group with Tailored IT Solutions and Enhanced Cybersecurity</h4>
    <a class="buttonboxes" data-bs-toggle="modal" data-bs-target="#casestudy"  style="color: #fff !important;">Download the Case Study Here</a>
    <div class="bannerbottom">
        <div class="innerbannerbottom">
            <!-- <div class="columns1">
                <p>Recent Accolades</p>
            </div>
            <div class="columns2"><img alt="Botton Arrow" src="/assets/images/bottom-arrow.png"><br>Scroll</div> -->
            <div class="columns3 row">
                <div class="icon icon2 col-md-3"><img alt="Best place to work" src="/assets/images/icn/best.png"></div>
                <div class="icon icon3 col-md-3"><img alt="Listed in 5000 INC" src="/assets/images/icn/inc.png"></div>
                <div class="icon icon4 col-md-3"><img alt="Inside Business" src="/assets/images/icn/insidebusiness.png"></div>
                <div class="icon icon1 col-md-3"><img alt="SSAE19" src="/assets/images/icn/SSAE19.png"></div>
            </div>
        </div>
    </div>
</header>
