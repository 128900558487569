import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './include/header/header.component';
import { FooterComponent } from './include/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ManagementComponent } from './pages/management/management.component';
import { TeamComponent } from './pages/team/team.component';
import { HomesliderComponent } from './include/part/homeslider/homeslider.component';
import { PageNotFoundComponentComponent } from './page-not-found-component/page-not-found-component.component';
import { FormComponent } from './include/part/team/form/form.component';
import { GshComponent } from './include/part/gsh/gsh.component';
import { TimelineComponent } from './include/part/timeline/timeline.component';
import { HomeheaderComponent } from './include/part/homeheader/homeheader.component';
import { ServicemenuComponent } from './include/part/servicemenu/servicemenu.component';
import { OurteamHeaderComponent } from './include/ourteam-header/ourteam-header.component';
import { ManagementHeaderComponent } from './include/management-header/management-header.component';
import { AboutHeaderComponent } from './include/about-header/about-header.component';
import { SolutionsComponent } from './pages/solutions/solutions.component';
import { SmallBusinessComponent } from './pages/small-business/small-business.component';
import { SupportComponent } from './pages/support/support.component';
import { GivingBackComponent } from './pages/giving-back/giving-back.component';
import { GivingBackHeaderComponent } from './include/giving-back-header/giving-back-header.component';
import { SolutionsHeaderComponent } from './include/solutions-header/solutions-header.component';
import { SmallBusinessHeaderComponent } from './include/small-business-header/small-business-header.component';
import { SupportHeaderComponent } from './include/support-header/support-header.component';
import { ErrorHeaderComponent } from './include/error-header/error-header.component';
import { ContactComponent } from './pages/contact/contact.component';
import { NavComponent } from './include/part/nav/nav.component';
import { SolutionsSmallBusinessComponent } from './pages/solutions-small-business/solutions-small-business.component';
import { SolutionsMediumBusinessComponent } from './pages/solutions-medium-business/solutions-medium-business.component';
import { SolutionsEnterpriseComponent } from './pages/solutions-enterprise/solutions-enterprise.component';
import { SolutionsGovernmentContractorComponent } from './pages/solutions-government-contractor/solutions-government-contractor.component';
import { SolutionSmallBusinessHeaderComponent } from './include/solution-small-business-header/solution-small-business-header.component';
import { SolutionGovernmentContractorsHeaderComponent } from './include/solution-government-contractors-header/solution-government-contractors-header.component';
import { GetSupportComponent } from './pages/get-support/get-support.component';
import { GetSupportHeaderComponent } from './include/get-support-header/get-support-header.component';
import { StorageComponent } from './pages/services/storage/storage.component';
import { DataCenterComponent } from './pages/services/data-center/data-center.component';
import { DigitalMarketingComponent } from './pages/services/digital-marketing/digital-marketing.component';
import { PaidAdvertisementComponent } from './pages/services/paid-advertisement/paid-advertisement.component';
import { GoogleAdsComponent } from './pages/services/google-ads/google-ads.component';
import { SocialMediaAdsComponent } from './pages/services/social-media-ads/social-media-ads.component';
import { EmailMarketingComponent } from './pages/services/email-marketing/email-marketing.component';
import { OnlineReputationManagementComponent } from './pages/services/online-reputation-management/online-reputation-management.component';
import { BrandingComponent } from './pages/services/branding/branding.component';
import { SocialMediaMarketingComponent } from './pages/services/social-media-marketing/social-media-marketing.component';
import { ProgrammaticOTTAdvertisementComponent } from './pages/services/programmatic-ottadvertisement/programmatic-ottadvertisement.component';
import { AnalyticsComponent } from './pages/services/analytics/analytics.component';
import { InfrastructureServicesComponent } from './pages/services/infrastructure-services/infrastructure-services.component';
import { NetworkingSolutionsComponent } from './pages/services/networking-solutions/networking-solutions.component';
import { WebDesignAndDevelopmentComponent } from './pages/services/web-design-and-development/web-design-and-development.component';
import { WebsiteDevelopmentComponent } from './pages/services/website-development/website-development.component';
import { UiUxDesignComponent } from './pages/services/ui-ux-design/ui-ux-design.component';
import { WebsiteMaintenanceAndSecurityComponent } from './pages/services/website-maintenance-and-security/website-maintenance-and-security.component';
import { ManagedServicesComponent } from './pages/services/managed-services/managed-services.component';
import { WebBasedProjectManagementComponent } from './pages/services/ManagedServices/web-based-project-management/web-based-project-management.component';
import { DigitalMarketingProjectManagementComponent } from './pages/services/ManagedServices/digital-marketing-project-management/digital-marketing-project-management.component';
import { ITStaffingComponent } from './pages/services/itstaffing/itstaffing.component';
import { LongTermComponent } from './pages/services/itstaffing/long-term/long-term.component';
import { DirectHireComponent } from './pages/services/itstaffing/direct-hire/direct-hire.component';
import { OptionToHireComponent } from './pages/services/itstaffing/option-to-hire/option-to-hire.component';
import { ItConsultingComponent } from './pages/services/it-consulting/it-consulting.component';
import { ItAssessmentComponent } from './pages/services/ItConsulting/it-assessment/it-assessment.component';
import { ItLeadershipComponent } from './pages/services/ItConsulting/it-leadership/it-leadership.component';
import { OutsourcingComponent } from './pages/services/ItConsulting/outsourcing/outsourcing.component';
import { ApplicationDevelopmentComponent } from './pages/services/application-development/application-development.component';
import { DigitalRecruitmentComponent } from './pages/services/digital-recruitment/digital-recruitment.component';
import { CompliaceAssessmentsComponent } from './pages/services/compliace-assessments/compliace-assessments.component';
import { DisasterRecoveryComponent } from './pages/services/disaster-recovery/disaster-recovery.component';
import { TechnologyAssessmentsComponent } from './pages/services/technology-assessments/technology-assessments.component';
import { StaffingComponent } from './pages/services/staffing/staffing.component';
import { SearchEngineOptimizationComponent } from './pages/services/search-engine-optimization/search-engine-optimization.component';
import { AnalyticsReportingComponent } from './pages/services/analytics-reporting/analytics-reporting.component';
import { VirtualizationComponent } from './pages/services/virtualization/virtualization.component';
import { ServicesComponent } from './pages/services/services.component';
import { CloudComponent } from './pages/services/cloud/cloud.component';
import { PublicCloudComponent } from './pages/services/cloud/public-cloud/public-cloud.component';
import { CloudConsultingServiceComponent } from './pages/services/cloud/cloud-consulting-service/cloud-consulting-service.component';
import { CloudManagedComponent } from './pages/services/cloud/cloud-managed/cloud-managed.component';
import { CloudHostedComponent } from './pages/services/cloud/cloud-hosted/cloud-hosted.component';
import { HybridCloudComponent } from './pages/services/cloud/hybrid-cloud/hybrid-cloud.component';
import { PrivateCloudComponent } from './pages/services/cloud/private-cloud/private-cloud.component';
import { EnterpriseCloudComponent } from './pages/services/cloud/enterprise-cloud/enterprise-cloud.component';
import { SmbCloudComponent } from './pages/services/cloud/smb-cloud/smb-cloud.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { NewsDetailComponent } from './pages/news-detail/news-detail.component';
import { MobileFooterComponent } from './include/footer/mobile-footer/mobile-footer.component';
import { CmmcComponent } from './pages/services/compliace-assessments/cmmc/cmmc.component';
import { GdprComponent } from './pages/services/compliace-assessments/gdpr/gdpr.component';
import { HipaaComponent } from './pages/services/compliace-assessments/hipaa/hipaa.component';
import { PciComponent } from './pages/services/compliace-assessments/pci/pci.component';
import { ComputeServerComponent } from './pages/services/compute-server/compute-server.component';
import { NewsComponent } from './pages/news/news.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SecurityComponent } from './pages/services/security/security.component';
import { ProjectManagementComponent } from './pages/services/project-management/project-management.component';
import { ProjectRecoverySolutionComponent } from './pages/services/ProjectManagement/project-recovery-solution/project-recovery-solution.component';
import { DataCenterProjectManagementComponent } from './pages/services/ProjectManagement/data-center-project-management/data-center-project-management.component';
import { OurClientsComponent } from './pages/our-clients/our-clients.component';
import { NewServiceRequestComponent } from './pages/new-service-request/new-service-request.component';
import { LandingComponent } from './pages/landing/landing.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
import { StaffingLandingPageComponent } from './LandingPage/staffing-landing-page/staffing-landing-page.component';
import { StaffingLandingPageThankyouComponent } from './LandingPage/staffing-landing-page-thankyou/staffing-landing-page-thankyou.component';
import { HireCloudEngineersComponent } from './LandingPage/hire-cloud-engineers/hire-cloud-engineers.component';
import { HireCloudEngineersThankyouComponent } from './LandingPage/hire-cloud-engineers-thankyou/hire-cloud-engineers-thankyou.component';
import { DefaultLangingComponent } from './LandingPage/default-langing/default-langing.component';
import { DefaultLangingThankyouComponent } from './LandingPage/default-langing-thankyou/default-langing-thankyou.component';
import { HireNetworkEngineersComponent } from './LandingPage/hire-network-engineers/hire-network-engineers.component';
import { HireNetworkEngineerThankyouComponent } from './LandingPage/hire-network-engineer-thankyou/hire-network-engineer-thankyou.component';
import { ItBusinessAnalystsComponent } from './LandingPage/it-business-analysts/it-business-analysts.component';
import { ItBusinessAnalystsThankyouComponent } from './LandingPage/it-business-analysts-thankyou/it-business-analysts-thankyou.component';
import { ManageServiceLandingComponent } from './LandingPage/manage-service-landing/manage-service-landing.component';
import { KernerGroupComponent } from './CaseStudy/kerner-group/kerner-group.component';
import { KernerGroupHeaderComponent } from './CaseStudy/kerner-group-header/kerner-group-header.component';
import { ThankyoupageComponent } from './CaseStudy/thankyoupage/thankyoupage.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { FilterPipe } from './pipes/filter.pipe';
import { OptinFormComponent } from './Testing/optin-form/optin-form.component';
import { SurveysFormComponent } from './Testing/surveys-form/surveys-form.component';
import { EdAdsServeythankyouComponent } from './landingpage2024/ed-ads-serveythankyou/ed-ads-serveythankyou.component';
import { JotContactSurveyFormComponent } from './Testing/jot-contact-survey-form/jot-contact-survey-form.component';
import { JotSurveysFormComponent } from './Testing/jot-surveys-form/jot-surveys-form.component';
import { SurveryThankyouComponent } from './Testing/survery-thankyou/survery-thankyou.component';
import { ThankyouComponent } from './pages/new-service-request/thankyou/thankyou.component';
import {EdAdsThankyoupageComponent}from './landingpage2024/ed-ads-thankyoupage/ed-ads-thankyoupage.component';
import { EdAdsHeaderComponent } from './landingpage2024/templates/ed-ads-header/ed-ads-header.component';
import { CaseStudyFormComponent } from './landingpage2024/templates/case-study-form/case-study-form.component';
import { from } from 'rxjs';
import { EdAdsMainComponent } from './landingpage2024/ed-ads-main/ed-ads-main.component';
import { ContactFormComponent } from './landingpage2024/templates/contact-form/contact-form.component';
import { PopupComponent } from './landingpage2024/templates/popup/popup.component';
import { EdAdsSurveyComponent } from './landingpage2024/ed-ads-survey/ed-ads-survey.component';
import { GoalsComponent } from './pages/goals/goals.component';
import { SitemapComponent} from './pages/sitemap/sitemap.component';
@NgModule({
  declarations: [
    GoalsComponent,SitemapComponent,
    EdAdsSurveyComponent,
    ThankyouComponent,
    SurveryThankyouComponent,
    OptinFormComponent,
    EdAdsHeaderComponent,
    ContactFormComponent,
    FilterPipe,
    PopupComponent,
    CaseStudyFormComponent,
    EdAdsServeythankyouComponent,
    EdAdsThankyoupageComponent,
    SurveysFormComponent,
    JotSurveysFormComponent,
    JotContactSurveyFormComponent,
    AppComponent,
    EdAdsMainComponent,
    HireNetworkEngineersComponent,
    HireNetworkEngineerThankyouComponent,
    ItBusinessAnalystsComponent,
    ItBusinessAnalystsThankyouComponent,
    DataCenterProjectManagementComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ManageServiceLandingComponent,
    AboutComponent,
    ManagementComponent,
    TeamComponent,
    HomesliderComponent,
    PageNotFoundComponentComponent,
    FormComponent,
    GshComponent,
    TimelineComponent,
    HomeheaderComponent,
    ServicemenuComponent,
    OurteamHeaderComponent,
    ManagementHeaderComponent,
    AboutHeaderComponent,
    SolutionsComponent,
    SmallBusinessComponent,
    SupportComponent,
    GivingBackComponent,
    GivingBackHeaderComponent,
    SolutionsHeaderComponent,
    SmallBusinessHeaderComponent,
    SupportHeaderComponent,
    ErrorHeaderComponent,
    ContactComponent,
    NavComponent,
    SolutionsSmallBusinessComponent,
    SolutionsMediumBusinessComponent,
    SolutionsEnterpriseComponent,
    SolutionsGovernmentContractorComponent,
    SolutionSmallBusinessHeaderComponent,
    SolutionGovernmentContractorsHeaderComponent,
    GetSupportComponent,
    GetSupportHeaderComponent,
    StorageComponent,
    DataCenterComponent,
    DigitalMarketingComponent,
    PaidAdvertisementComponent,
    GoogleAdsComponent,
    SocialMediaAdsComponent,
    EmailMarketingComponent,
    OnlineReputationManagementComponent,
    BrandingComponent,
    SocialMediaMarketingComponent,
    ProgrammaticOTTAdvertisementComponent,
    AnalyticsComponent,
    InfrastructureServicesComponent,
    NetworkingSolutionsComponent,
    WebDesignAndDevelopmentComponent,
    WebsiteDevelopmentComponent,
    UiUxDesignComponent,
    WebsiteMaintenanceAndSecurityComponent,
    ManagedServicesComponent,
    WebBasedProjectManagementComponent,
    DigitalMarketingProjectManagementComponent,
    ITStaffingComponent,
    LongTermComponent,
    DirectHireComponent,
    OptionToHireComponent,
    ItConsultingComponent,
    ItAssessmentComponent,
    ItLeadershipComponent,
    OutsourcingComponent,
    ApplicationDevelopmentComponent,
    DigitalRecruitmentComponent,
    CompliaceAssessmentsComponent,
    DisasterRecoveryComponent,
    TechnologyAssessmentsComponent,
    StaffingComponent,
    SearchEngineOptimizationComponent,
    AnalyticsReportingComponent,
    VirtualizationComponent,
    ServicesComponent,
    CloudComponent,
    PublicCloudComponent,
    CloudConsultingServiceComponent,
    CloudManagedComponent,
    CloudHostedComponent,
    HybridCloudComponent,
    PrivateCloudComponent,
    EnterpriseCloudComponent,
    SmbCloudComponent,
    TermsComponent,
    PrivacypolicyComponent,
    NewsDetailComponent,
    MobileFooterComponent,
    CmmcComponent,
    GdprComponent,
    HipaaComponent,
    PciComponent,
    ComputeServerComponent,
    NewsComponent,
    SecurityComponent,
    ProjectManagementComponent,
    ProjectRecoverySolutionComponent,
    OurClientsComponent,
    NewServiceRequestComponent,
    LandingComponent,
    ThankYouComponent,
    StaffingLandingPageComponent,
    StaffingLandingPageThankyouComponent,
    HireCloudEngineersComponent,
    HireCloudEngineersThankyouComponent,
    DefaultLangingComponent,
    DefaultLangingThankyouComponent,
    KernerGroupComponent,
    KernerGroupHeaderComponent,
    ThankyoupageComponent,
    BlogComponent,
    BlogDetailComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
