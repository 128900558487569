<header class="serviceinner"
  style="        height: 85vh !important;
  min-height: 85vh !important;
  max-height: 85vh !important; background-image: url(https://ik.imagekit.io/b0cgaqv3j/error.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1677581109324);">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1 style="text-align: center;">
    Thank You For Your Request!
  </h1>
  <h4 style="width: 100% !important;margin-bottom: 2rem;  text-align: center;">You can access the complete case study by clicking the button below.
  </h4>
 <div style="text-align: center;"><a href="/assets/images/The_Kerner_Group_Case_Study_Download.pdf"
    download="casestudy.pdf" class="orangebtn"
    style="color:white;background-color:#d74b34;padding: 0.8rem 2.5rem;border-radius: 3rem;">Download
    Your Full Case Study Now</a></div>
    <div style="text-align: center;">
      <h4 style="color:#fff; padding-top: 2rem;">Need Immediate Assistance?</h4>
      <p style="color:#fff;">
Our sales team is ready to assist. <strong>Call us directly at <a href="tel:+1-757-457-4793">(757) 457-4793</a></strong></p>

    </div>
</header>

