<header>
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"
        ><img
          class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services"
      /></a>

      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    From Input to Outcome,
    <span><br>our solutions and services are all about you</span>
  </h1>
  <h4>
    We simplify and accelerate business results through innovative,
    client-focused IT and Digital Marketing services that create real change and
    demonstrate real growth.
  </h4>
</header>
<section class="solution-first">
  <div class="solutionboxs">
    <div class="solutionboxs1 solutionboxsxx ManagedServices-boxes">
      <a href="/services/managed-services">
        <div class="hover">
          <div class="withover">
            <div>
              <img alt="Managed Services" src="/assets/images/service/1.png" />
            </div>
            <div class="heading">Managed <br />Services</div>
            <div class="headingc">
              Improve service and reduce costs with our managed alternatives
            </div>
          </div>
        </div>
        <img alt="Managed Services" src="/assets/images/service/bg1.png" />
        <div class="withoutover">
          <div>
            <img alt="Managed Services" src="/assets/images/service/1.png" />
          </div>
          <div>Managed <br />Services</div>
        </div>
      </a>
    </div>
    <div class="solutionboxs1 solutionboxsxx  ITStaffing-boxes">
      <a href="/services/it-staffing">
        <div class="hover">
          <div class="withover">
            <div>
              <img alt="IT Staffing" src="/assets/images/service/2.png" />
            </div>
            <div class="heading">IT Staffing</div>
            <div class="headingc">
              Specialist resources to overcome skills gaps and reduce hiring
              costs
            </div>
          </div>
        </div>
        <img alt="IT Staffing Solutions" src="/assets/images/service/bg2.png" />
        <div class="withoutover">
          <div>
            <img alt="IT Staffing" src="/assets/images/service/2.png" />
          </div>
          <div>IT Staffing</div>
        </div>
      </a>
    </div>
    <div class="solutionboxs4 solutionboxsxx  InfrastructureServiceProvider-boxes">
      <a href="/services/infrastructure-services">
        <div class="hover">
          <div class="withover">
            <div>
              <img
                alt="Infrastructure Service Provider"
                src="/assets/images/service/8.png"
              />
            </div>
            <div class="heading">Infrastructure <br />Services</div>
            <div class="headingc">
              Cost-effective digital transformation to accelerate business
              results
            </div>
          </div>
        </div>
        <img
          alt="Infrastructure Service Provider"
          src="/assets/images/service/bg8.png"
        />
        <div class="withoutover">
          <div>
            <img
              alt="Infrastructure Service Provider"
              src="/assets/images/service/8.png"
            />
          </div>
          <div>Infrastructure <br />Services</div>
        </div>
      </a>
    </div>
    <div class="solutionboxs3 solutionboxsxx  ITConsulting-boxes">
      <a href="/services/it-consulting">
        <div class="hover">
          <div class="withover">
            <div>
              <img alt="IT Consulting" src="/assets/images/service/7.png" />
            </div>
            <div class="heading">IT <br />Consulting</div>
            <div class="headingc">
              Achieve your business goals through digital transformation
            </div>
          </div>
        </div>
        <img alt="IT Consulting" src="/assets/images/service/bg7.png" />
        <div class="withoutover">
          <div>
            <img alt="IT Consulting" src="/assets/images/service/7.png" />
          </div>
          <div>IT Consulting</div>
        </div>
      </a>
    </div>
    <div class="solutionboxs1 solutionboxsxx  WebDesignDevelopment-boxes">
      <a href="/services/webdesign-and-development">
        <div class="hover">
          <div class="withover">
            <div>
              <img
                alt="Web Design & Development"
                src="/assets/images/service/4.png"
              />
            </div>
            <div class="heading">Web Design & <br />Development</div>
            <div class="headingc">
              Improved online presence to increase engagement and sales
            </div>
          </div>
        </div>
        <img
          alt="Web Design & Development"
          src="/assets/images/service/bg4.png"
        />
        <div class="withoutover">
          <div>
            <img
              alt="Web Design & Development"
              src="/assets/images/service/4.png"
            />
          </div>
          <div>Web Design & <br />Development</div>
        </div>
      </a>
    </div>
    <div class="solutionboxs1 solutionboxsxx  ApplicationDevelopment-boxes">
      <a href="/services/application-development">
        <div class="hover">
          <div class="withover">
            <div>
              <img
                alt="Application Development"
                src="/assets/images/service/3.png"
              />
            </div>
            <div class="heading">Application <br />Development</div>
            <div class="headingc">
              User-friendly app creation to give your customers what they need
            </div>
          </div>
        </div>
        <img
          alt="Application Development"
          src="/assets/images/service/bg3.png"
        />
        <div class="withoutover">
          <div>
            <img
              alt="Application Development"
              src="/assets/images/service/3.png"
            />
          </div>
          <div>Application <br />Development</div>
        </div>
      </a>
    </div>
    <div class="solutionboxs2 solutionboxsxx  DigitalMarketing-boxes">
      <a href="/services/digital-marketing">
        <div class="hover">
          <div class="withover">
            <div>
              <img
                alt="Digital  Marketing"
                src="/assets/images/service/6.png"
              />
            </div>
            <div class="heading">Digital <br />Marketing</div>
            <div class="headingc">
              Boost traffic, generate leads, and increase sales
            </div>
          </div>
        </div>
        <img alt="Digital Marketing" src="/assets/images/service/bg6.png" />
        <div class="withoutover">
          <div>
            <img alt="Digital  Marketing" src="/assets/images/service/6.png" />
          </div>
          <div>Digital <br />Marketing</div>
        </div>
      </a>
    </div>
    <div class="solutionboxs1 solutionboxsxx  DigitalRecruitment-boxes">
      <a href="/services/digital-recruitment">
        <div class="hover">
          <div class="withover">
            <div>
              <img
                alt="Digital Recruitment"
                src="/assets/images/service/5.png"
              />
            </div>
            <div class="heading">Digital <br />Recruitment</div>
            <div class="headingc">
              Targeted campaigns to identify and engage top tier candidates
            </div>
          </div>
        </div>
        <img alt="Digital Recruitment" src="/assets/images/service/bg5.png" />
        <div class="withoutover">
          <div>
            <img alt="Digital Recruitment" src="/assets/images/service/5.png" />
          </div>
          <div>Digital <br />Recruitment</div>
        </div>
      </a>
    </div>
  </div>
</section>
<section class="counter-section-main">
  <section class="wow fadeIn animated">
    <div class="container">
      <div class="row">
        <!-- counter -->
        <div
          class="col-md-3 col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten animated"
          data-wow-duration="300ms"
          style="
            visibility: visible;
            animation-duration: 300ms;
            animation-name: fadeInUp;
          "
        >
          <span id="anim-number-pizza" class="counter-number"></span>
          <span
            class="timer counter alt-font appear"
            data-to="300"
            data-speed="9000"
            >2500</span
          >
          <p class="counter-title">Projects Delivered</p>
        </div>
        <!-- end counter -->
        <!-- counter -->
        <div
          class="col-md-3 col-sm-6 bottom-margin text-center counter-section wow fadeInUp sm-margin-bottom-ten animated"
          data-wow-duration="600ms"
          style="
            visibility: visible;
            animation-duration: 600ms;
            animation-name: fadeInUp;
          "
        >
          <span
            class="timer counter alt-font appear"
            data-to="300"
            data-speed="10000"
            >300</span
          >
          <span class="counter-title">Clients</span>
        </div>
        <!-- end counter -->
        <!-- counter -->
        <div
          class="col-md-3 col-sm-6 bottom-margin-small text-center counter-section wow fadeInUp xs-margin-bottom-ten animated"
          data-wow-duration="900ms"
          style="
            visibility: visible;
            animation-duration: 900ms;
            animation-name: fadeInUp;
          "
        >
          <span
            class="timer counter alt-font appear"
            data-to="120"
            data-speed="11000"
            >110</span
          >
          <span class="counter-title">Technology & Marketing Experts</span>
        </div>
        <!-- end counter -->
        <!-- counter -->
        <div
          class="col-md-3 col-sm-6 text-center counter-section wow fadeInUp animated"
          data-wow-duration="1200ms"
          style="
            visibility: visible;
            animation-duration: 1200ms;
            animation-name: fadeInUp;
          "
        >
          <span
            class="timer counter alt-font appear"
            data-to="15"
            data-speed="12000"
            >16</span
          >
          <span class="counter-title">Years Of Client Satisfaction</span>
        </div>
        <!-- end counter -->
      </div>
    </div>
  </section>
</section>
<section class="solution-second">
  <div class="logos">
    <img src="/assets/images/givenback/smalllogo2x.png" alt="Giving Back" />
  </div>
  <div class="contentwithbutton">
    <div class="innercontent row">
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <h2>Getting <span>Started</span></h2>
        <p>
          Our Digital Marketing Services help you reach a larger audience than
          traditional methods, and let you target the prospects who are most
          likely to buy your product or service. More cost-effective than
          traditional advertising, and enabling success measurements on a daily
          basis, digital marketing allows the flexibility to quickly pivot based
          on campaign or project results.
        </p>
        <p>
          Our IT staffing capabilities and solutions give you options like
          scaling your workforce to match demand without the costs associated
          with employee turnover. This flexibility keeps your workforce adaptable
          and nimble, allowing you to quickly respond and scale to meet change.
        </p>
        <p>
          If you’re ready to grow and thrive in the digital age, then let us
          show you how to bring capabilities together to solve real business
          challenges. From IT support to web/app development, we leverage digital transformation to produce unprecedented results that directly benefit you.
        </p>
      </div>
    </div>
  </div>
  <img
    alt="Digital Marketing Services"
    src="https://ik.imagekit.io/b0cgaqv3j/service/Group-199.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677585938870"
    class="donly footerbanner"
  />
</section>

<app-form></app-form>
