import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import {
  FormsModule,
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-hire-network-engineers',
  templateUrl: './hire-network-engineers.component.html',
  styleUrls: ['./hire-network-engineers.component.css'],
})
export class HireNetworkEngineersComponent implements OnInit {
  toDisplay = true;
  Results: any = [];
  SEO: any = [];
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private MetaService: MetaServiceService
  ) {}
  ngOnInit(): void {
    this.MetaService.createCanonicalURL();
    const url = '/assets/seo/hire-network-engineers.json';
    this.http.get(url).subscribe((seodata) => {
      this.SEO = seodata;
      console.log(this.SEO[0]);
      this.title.setTitle(this.SEO.meta_title);
      this.meta.updateTag({ name: 'description', content: this.SEO.meta_dec });
      this.meta.updateTag({ name: 'keyword', content: this.SEO.keyword });
    });
  }
  logForm(value: any) {
    this.toDisplay = false;
    const surl = this.router.url;
    console.log(value);
    const url = '/wp/mail/hirenetworkengineers.php';
    const headers = {
      Authorization: 'Bearer my-token',
      'My-Custom-Header': 'foobar',
    };
    const body = { data: value, url: surl };
    this.http.post<any>(url, body, { headers }).subscribe((data) => {
      this.Results = data;
      this.toDisplay = true;
      if (this.Results.flag == 1) {
        this.router.navigate(['/hire-network-engineers-thankyou']).then(() => {
          location.reload();
        });
      }
    });
  }
}
