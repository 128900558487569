import { Component } from '@angular/core';

@Component({
  selector: 'app-hire-network-engineer-thankyou',
  templateUrl: './hire-network-engineer-thankyou.component.html',
  styleUrls: ['./hire-network-engineer-thankyou.component.css']
})
export class HireNetworkEngineerThankyouComponent {

}
