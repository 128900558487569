<header class="serviceinner">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>UX/UI <span>design</span></h1>
    <h4>Enhanced user experience <br>to increase website conversions</h4><div class="bbtnsbox"><a href="/contact-us" class="btnsbox">let's talk about IT </a></div>
</header>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/UXUIDesign/UXUIDesign.png" alt="Expert UX/UI design">
                <h2>Expert UX/UI design for a <span>richer online experience</span></h2>

            </div><div class="content">
                <p>What if you could create a digital experience for your audience that is inclusive, intuitive, and delightful? Welcome to the world of UX/UI. From your website to your mobile app, our designers create products that enhance and facilitate all things digital.</p>
                <p>The user interface (UI) deals with the visual design. This includes layout, icons, buttons, information architecture, colors, typography, animations, and illustrations.</p>
                <p>Similarly, the user experience (UX) encompasses all interaction with your digital assets. We look at user intent, consumer behavior, and cultural trends to predict and analyze what users want.</p>
                <p>Leave it to the experts. Endurance has what it takes to create inviting experiences for your visitors and customers.</p>
                <p>That’s where Endurance comes in. We have what it takes to create experiences that surprise and delight your visitors and customers.</p>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6"><img src="https://ik.imagekit.io/b0cgaqv3j/service/UXUIDesign/Rightside.png?updatedAt=1677585977199" alt="have a UX or UI need, contact us" class="shadow-lg"></div>
    </div>
</section>
<section class="section2" style="margin-top: 0 !important;">

    <h3><strong>The Endurance IT UX/UI workflow:   </strong> </h3>
    <div class="serviceicons">
        <div><img src="/assets/images/service/UXUIDesign/service1.png" alt="Research">
            <h5>Research</h5>
            <div>We leave no stone unturned as we get to know your brand, audience, capabilities, and  competition.
            </div></div>
        <div><img src="/assets/images/service/UXUIDesign/service2.png" alt="Strategy">
            <h5>Strategy</h5>
            <div>Empathy is our key driver in designing an interface and experience that sparks joy and drives goals.
            </div></div><!-- comment -->
        <div><img src="/assets/images/service/UXUIDesign/service3.png" alt="Design">
            <h5>Design</h5>
            <div>Whether you need us beginning to end, or just to move your project across the finish line, we can help.</div>
          </div>
        <div class="noborder"><img src="/assets/images/service/UXUIDesign/service4.png" alt="Development">
            <h5>Development</h5>
            <div>Our expert team of developers can bring any vision into functional reality. Put us to the test - we’re up to the challenge!
            </div></div><!-- comment -->


    </div>
</section>
<section class="section3">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <h3>
              <strong>We take a strategic approach to <br>UX and UI</strong>
            </h3>
            <p>Our goal is to create a user experience that goes beyond the four corners of the screen.</p>

            <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">BOOK MY CONSULTATION</a></div>
        </div></div>
</section>
