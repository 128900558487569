<header class="serviceinner">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>
        Compute/<span>Server</span>
    </h1>
    <h4>The perfect server selection for <br>your business that scales as you do</h4>
    <div class="bbtnsbox">
        <a  href="/contact-us" class="btnsbox">Get in touch</a>
    </div>
</header>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img alt="Stable and reliable Compute/Server solutions" src="/assets/images/service/Computeserver/icon.png">
                <h2>
                    Stable and reliable <span>Compute/Server solutions</span>
                </h2>
            </div>
            <div class="content">

                <p>Implementing an appropriate computing environment is critical to running any business, and that’s where we excel. We guarantee server selections that are the right size for your business. We help our customers build enterprise solutions with mainstream server and blade manufacturers, including HP Enterprise, IBM, Dell and more.</p><!-- comment -->
                <br>
                <div class="bbtnsbox" style="display: none;">
                    <a href="/contact-us" class="btnsbox">let's talk about it</a>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <img alt="Stable and reliable Compute/Server solutions" src="https://ik.imagekit.io/b0cgaqv3j/service/Computeserver/rightside.jpg?updatedAt=1679469218498">
        </div>
    </div>
</section>
<section class="section2    mainredbar">
    <h3><strong>A scalable server solution</strong></h3>
    <p>We build stable and reliable computing systems covering hardware, firmware, and system software from the top manufacturers. Our solutions are designed to scale from small to enterprise environments, so they can seamlessly grow alongside your business. You choose the levels of performance, availability and expandability from the most comprehensive industry-standard servers - and we’ll guide you through the process. Futureproof systems to suit your growing needs.    </p>
</section>
<section class="section4 " style="display: none;">
    <h3>Endurance’s team of IT experts creates an environment that is:</h3>
    <ul class="columns3box">
        <li>
            <div>
                <img src="assets/images/service/ApplicationDevelopment/1.png" alt="Web Based Applications  Development">
                <span>Web Based
                    <br>
                    Applications
                </span>
            </div>
            <p>
                Our talented, dedicated, and hard-working team of developers can create user-friendly web applications with mobile-first interfaces. Whether you need an e-commerce platform or a business web portal that works on different devices or browsers, Endurance got it covered.
            </p>
        </li>
        <li>
            <div>
                <img src="assets/images/service/ApplicationDevelopment/2.png" alt="Mobile Applications Development">
                <span>Mobile <br>
                    Applications</span>
            </div>
            <p>
                We develop custom native and cross-platform mobile apps, for both iOS and Android platforms, that are both sustainable and scalable. This means that system updates will not disrupt your business operations and all of your device’s features will be upgraded to their newest version.
            </p>
        </li>

        <li>
            <div>
                <img src="assets/images/service/ApplicationDevelopment/3.png" alt="Desktop Applications Development">
                <span>Desktop<br>
                    Applications</span>
            </div>

            <p>
                Desktop app development is a piece of software capable of running offline. This development service is known for being user-friendly, stable, and responsive, making it a go-to solution for many clients.</p>

        </li>
        <li>
            <div>
                <img src="assets/images/service/ApplicationDevelopment/4.png">
                <span>Windows<br> Based <br>
                    Applications</span>
            </div>
            <p>
                Windows application development is based on a flexible model of navigation structures, navigation elements, and system-level features that let us partner with you to build a beautiful, functional, polished app that will meet your business goals and needs. </p>
        </li>
        <li>
            <div>
                <img src="assets/images/service/ApplicationDevelopment/5.png">
                <span>
                    Intranet<br> SharePoint <br>
                    Applications
                </span>
            </div>
            <p>
                We develop custom native and cross-platform mobile apps, for both iOS and Android platforms, that are both sustainable and scalable. This means that system updates will not disrupt your business operations and all of your device’s features will be upgraded to their newest version. </p>
        </li>
        <li>
            <div>
                <img src="assets/images/service/ApplicationDevelopment/6.png">
                <span>Custom <br>
                    APIs</span>
            </div>
            <p>
                Desktop app development is a piece of software capable of running offline. This development service is known for being user-friendly, stable, and responsive, making it a go-to solution for many clients. </p>
        </li>
    </ul>
</section>

<section class="section3">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6 bulletlist">
            <h3>How do I choose the right Compute / Server solution?</h3>
            <p>As with all our business solutions, we’ll recommend a computing solution that’s ideal for your company and its growing needs. Implementing the right solution at the right scale for your current business will not only cut unnecessary costs, it will free your staff to better support your enterprise network and end-user community.
            </p><p>
                We work with all major operating systems to provide server solutions     for business:
            </p>
            <ul class="listofdata sizebigs">
                <li>Windows Servers </li>
                <li>VMware</li>
                <li>vSphere</li>
                <li>Linux (all supported variants)</li>
            </ul>
            <p>Endurance IT can help you find your perfect Compute / Server solution.</p>

            <div class="bbtnsbox">
                <a href="/contact-us" class="btnsbox">let’s talk about it</a>
            </div>
        </div>
    </div>
</section>
