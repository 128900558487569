<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/digitalmarkting/banner.jpg?updatedAt=1679569026563) !important;">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/"><img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services"></a>

            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Digital
        <span>marketing</span>
    </h1>
    <h4>Boost traffic, generate leads, and increase sales</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="newheadingbox">
    <h2>
        Leading Digital Marketing
        <span> Campaign Solutions</span>
    </h2>
    <p class="p-beforecallouts">Target new customers wherever they are online</p>
</section>
<section class="callout-boxes">
    <div>
        <img  src="/assets/images/call-out/DigitalMarketing/seo.png" alt="Generate organic and local traffic through website optimization">
        <h4>SEO</h4>
        <p>Generate organic and local traffic through website optimization</p>
    </div>
    <div>
        <img src="/assets/images/call-out/DigitalMarketing/paid.png" alt="Get an instant inbound leads boost with PPC and social ads">
        <h4>Paid</h4>
        <p>Get an instant inbound leads boost with PPC and social ads</p>
    </div>
    <div>
        <img src="/assets/images/call-out/DigitalMarketing/more.png" alt="Go further with email, OTT, and online reputation management">
        <h4>& More</h4>
        <p>Go further with email, OTT, and online reputation management</p>
    </div>
</section>

<section class="section1">
    <div class="row">
        <div class="gridboxed">
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/digitalmarkting/1.png" alt="SEO"></div>
                <div class="heading">
                    <h3>SEO <span><br>(Organic and Local)</span></h3>
                </div>
                <div class="peragraph" >Improved search visibility for more trust, more traffic, and more leads</div>
                <div class="buttons"><a href="services/digital-marketing/seo">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/digitalmarkting/2.png" alt="Paid Advertisements"></div>
                <div class="heading">
                    <h3>Paid <span><br>Advertisements</span></h3>
                </div>
                <div class="peragraph" >Reach new customers and increase conversions quickly</div>
                <div class="buttons"><a href="services/digital-marketing/paid-advertisement">Learn More</a></div>
            </div>
            <div>
                <div class="icon"><img src="assets/images/service/digitalmarkting/3.png" alt="Social Media Marketing"></div>
                <div class="heading">
                    <h3>Social <span><br>Media Marketing</span></h3> 
                </div>
                <div class="peragraph" >Target potential customers and increase brand awareness</div>
                <div class="buttons"><a href="services/digital-marketing/social-media-marketing">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/digitalmarkting/4.png" alt="Programmatic/ OTT Advertisements "></div>
                <div class="heading">
                    <h3>Programmatic/OTT <span><br>Advertisements</span></h3>
                </div>
                <div class="peragraph" >Highly targeted video advertising for maximum impact</div>
                <div class="buttons"><a href="services/digital-marketing/programmatic-ott-advertisement">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/digitalmarkting/5.png" alt="Email Marketing"></div>
                <div class="heading">
                    <h3>Email <span><br>Marketing</span></h3>
                </div>
                <div class="peragraph" >Personalized direct messaging to maximize engagement</div>
                <div class="buttons"><a href="services/digital-marketing/email-marketing">Learn More</a></div>
            </div>
            <div>
                <div class="icon"><img src="assets/images/service/digitalmarkting/6.png" alt="Analytics"></div>
                <div class="heading">
                    <h3>Analytics</h3>
                </div>
                <div class="peragraph" >In-depth campaign data to optimize your marketing investment</div>
                <div class="buttons"><a href="services/digital-marketing/analyticsreporting">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/digitalmarkting/7.png" alt="Branding"></div>
                <div class="heading">
                    <h3>Branding</h3>
                </div>
                <div class="peragraph" >Define your values and stand out among competitors</div>
                <div class="buttons"><a href="services/branding">Learn More</a></div>
            </div>
            <div class="borderR">
                <div class="icon"><img src="assets/images/service/digitalmarkting/8.png" alt="Digital Recruitment "></div>
                <div class="heading">
                    <h3>Digital <span><br>Recruitment</span></h3>
                </div>
                <div class="peragraph" >Targeted campaigns to identify and engage top tier candidates</div>
                <div class="buttons"><a href="services/digital-recruitment">Learn More</a></div>
            </div>
            <div>
                <div class="icon"><img src="assets/images/service/digitalmarkting/9.png" alt="Online brand reputation Management "></div>
                <div class="heading">
                    <h3>Online Brand <span><br>Reputation Management</span>
</h3>
                </div>
                <div class="peragraph" >Build trust with positive reviews and social media referrals</div>
                <div class="buttons"><a href="/services/digital-marketing/online-reputation-management">Learn More</a></div>
            </div>
        </div>
    </div>
</section>

<section class="section3" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/Extra_Folder/digital-marketing.png?updatedAt=1707510030587) !important;">
    <div class="logoicon"><img src="assets/images/service/digitalmarkting/endurance_icon.png" alt="Endurance IT Services Favicon"></div>
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
          <h3>Stay competitive in today’s digital world</h3>
            <p>Digital marketing is essential to survival in today’s ultra-competitive environments.
              To get attention for your brand and grow your business, digital marketing is a necessity, not a luxury.</p>
            <p>At Endurance, our team of skilled digital marketers and expert analysts design and implement award-winning strategies to put your company on top.
            </p>
            <p>We help businesses of all sizes become leaders in their industry.
              Our methods combine cutting-edge strategies with proven best practices to hit the ground running and scale rapidly.
</p>
            <div class="bbtnsbox"><a href="/contact-us" class="btnsbox">LEARN MORE</a></div>
        </div>
    </div>
</section>
