<header class="serviceinner">
  <nav class="navbar navbar-expand-sm">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">
        <img class="mainlogo"
          src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456"
          alt="Endurqance IT Services">
      </a>
      <app-nav></app-nav>
    </div>
  </nav>
  <h1>
    Cloud hosted
      <br>
      <span>services</span>
  </h1>
  <h4>Reduce downtime and eliminate data loss</h4><div _ngcontent-psv-c86
    class="bbtnsbox">
    <a _ngcontent-psv-c86 href="/contact-us" class="btnsbox">let's talk about IT
    </a>
  </div>
</header>
<section class="section1">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6">
      <div class="heading">
        <img src="/assets/images/service/cloud/CloudHosted/icon.jpg"
          alt="Cloud Hosted Solutions">
        <h2>Secure turnkey <span>cloud solutions</span>
        </h2>
      </div>
      <div class="content">
        <p>Avoid becoming a headline. With Endurance in your corner, you’ll have
          a custom-tailored cloud hosted solution that utilizes the latest in
          enterprise-class technology. Pay only for what you need and get
          premium, white glove service and support.</p>
        <h3>Cloud Managed Data Protection</h3>
        <p>
          As more and more sensitive data moves through the cloud, both through
          internal colleagues and external collaborators, the risk of data
          breach and loss exponentially increases.
        </p>
        <p>
          Relying solely on cloud applications for security leaves organizations
          vulnerable to malicious attacks. In addition, outside vendors have
          limited liability, which is a huge risk for a business.
        </p>
        <p>
          Cloud managed data protection enables you to control how data is
          disseminated, have ubiquitous accessibility, and gain insights into
          security intelligence to detect potential breaches.
        </p>
      </div>
    </div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <img
        src="https://ik.imagekit.io/b0cgaqv3j/service/cloud/CloudHosted/right.jpg?updatedAt=1679470536643"
        alt="Secure turnkey cloud solutions" class="shadow-lg">
    </div>
  </div>
</section>
<section class="section2 iconanimation bulletlist bulletlistbox mainredbar">
  <h3>
    <strong>Cloud managed backup</strong>
  </h3>
  <div class="bulletlist">
    <ul>
      <li>
        In today’s knowledge-based world, data can be one of an
        enterprise’s most valuable assets that creates a moat of competitive
        advantages. Robust protection and backup is not optional.</li>
      <li>Without the oversight of cloud hosted services, a combination of
        human error, system failures, and malware can interfere with backup
        capabilities.</li>
      <li>Reduce the risk of downtime and data loss with premier cloud
        hosted services from Endurance.</li>
    </ul></div>
</section>
<section class="section4 bulletlistr">
  <div>
    <div>
      <img src="assets/images/service/cloud/cloudmain/3.png"
        alt="Cloud solutions">
      <h3>Cloud Disaster Recovery</h3>
    </div>
    <p>Disaster recovery doesn’t have to be expensive, complex, or unreliable.
      Unfortunately, traditional disaster recovery is exactly that, with a
      single data center failure wreaking havoc on an entire organization.</p>
    <p>Endurance applies solutions like VMware cloud disaster recovery to ensure
      the highest level of business continuity. This streamlined process takes
      advantage of cloud economics to address issues related to:</p>
    <ul>
      <li>Ransomware attacks</li>
      <li>Power outages</li>
      <li>Natural disasters</li>
    </ul>
    <p>At Endurance, we manage your protection policies and settings in a way
      that
      makes the most sense for your business. Our regular compliance checks
      ensure that everything is working as it should be, so you can sleep better
      at
      night.
    </p><p>
      With cloud disaster recovery, your data will be secure, and operations can
      continue normally in the event of a disaster – all without having to learn
      a new tool.</p>
  </div>
  <div class="bulletlistr">
    <div>
      <img src="assets/images/service/cloud/CloudHosted/ser2.png"
        alt="Custom, right-sized solutions">
      <h3>Custom, right-sized solutions</h3>
    </div>
    <p> You already know the power of cloud - data backup, fast disaster
      recovery, and flexible hosting.
    </p><p>
      Our services are delivered through Tier 3 data centers, one of the
      highest-performing data centers available, with an ideal balance of:
    </p><ul>
      <li>99.982% uptime with N+1 redundancy</li>
      <li>Fault tolerance to handle disruptions (both planned and
        unplanned)</li>
      <li>Budget-friendly service costs</li>
    </ul>
    <p>Monthly consumption-based pricing means you don’t have to drain your IT
      budget with hardware and software purchases. You also have the ability to
      scale your costs up and down as your needs fluctuate. </p>
  </div>
</section>
<section class="section5 graybar">
  <h3>Cloud Hosted Infrastructure</h3>
  <p style="font-size: 20px !important;">
    Ride the wave of cloud technology with cloud hosting that uses multiple
    servers to share, load, and maximize uptime.
  </p>
  <p style="font-size: 20px !important;">
    Dedicated cloud servers can be configured to achieve optimal security,
    performance, and control that’s compatible with the needs of your business.
  </p>
  <h4>Infrastructure services include:</h4>
  <ul class="columns3box">
    <li>
      <img class="bubbleicon"
        src="assets/images/service/cloud/CloudHosted/1.png"
        alt="Cloud storage">
      <h4 class="bluetitle">
        Cloud Storage
      </h4>
    </li>
    <li>
      <img class="bubbleicon"
        src="assets/images/service/cloud/CloudHosted/2.png"
        alt="Cloud desktops">
      <h4 class="bluetitle">
        Cloud Desktops
      </h4>
    </li>
    <li>
      <img class="bubbleicon"
        src="assets/images/service/cloud/CloudHosted/3.png"
        alt="Cloud computing">
      <h4 class="bluetitle">
        Cloud Computing
      </h4>
    </li>
    <li>
      <img class="bubbleicon"
        src="assets/images/service/cloud/CloudHosted/4.png" alt="SaaS">
      <h4 class="bluetitle">
        SaaS
      </h4>
    </li>

  </ul>
</section>
<section class="section3">
  <div class="row">
    <div class="col-sm-6 col-lg-6 col-md-6"></div>
    <div class="col-sm-6 col-lg-6 col-md-6">
      <h3>Your organization deserves a turnkey solution</h3>
      <p>
        Endurance is a trusted cloud hosted services provider, offering
        management of backups, troubleshooting, regular testing, and data
        recovery.</p>
      <p>
        Let’s connect to build a solution that keeps your digital transformation
        initiatives moving forward. </p>
      <div class="bbtnsbox">
        <a href="/contact-us" class="btnsbox">CONNECT WITH US</a>
      </div>
    </div>
  </div>
</section>
