<header class="serviceinner" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/disaster-recovery/banner.jpg?updatedAt=1679471256504);">
    <nav class="navbar navbar-expand-sm">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">
                <img class="mainlogo" src="https://ik.imagekit.io/b0cgaqv3j/logo.png?updatedAt=1677581117456" alt="Endurqance IT Services">
            </a>
            <app-nav></app-nav>
        </div>
    </nav>
    <h1>Disaster
        <span>recovery</span>
    </h1>
    <h4>Assessment and planning to get<br>you back to business, fast</h4><div _ngcontent-psv-c86="" class="bbtnsbox">
        <a _ngcontent-psv-c86="" href="/contact-us" class="btnsbox">let's talk about IT </a>
    </div>
</header>
<section class="section1">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6">
            <div class="heading">
                <img src="/assets/images/service/disaster-recovery/icon.png" alt="Disaster Recovery">
                <h2>Analysis and guidance for <span>effective disaster recovery</span>
                </h2>
            </div>
            <div class="content">
                <p>
                    Digital transformation is about being more agile in response to uncertainty. Businesses understand cloud strategies harbor varied opportunities and challenges through the hybrid cloud, multi-cloud, IaaS, PaaS, and SaaS while also sharing commonalities for digital transformation journeys. Some commonalities include that all businesses are seeking ways to increase agile responsiveness, secure assets, and democratize access to vital data, applications, and workloads.
                </p>
                <p>
                    Businesses are turning to disaster recovery companies for solutions. What they’re finding is many of these companies are struggling to keep pace with the varied needs of disaster recovery across customized cloud strategies. An example might be the needs of backup and SaaS access encompassing several cloud providers (multi-cloud) with varying services and approaches. The same is true for specific needs that stretch across hybrid cloud (public, colocation, and private) where workload spreads can shift based on access, costs, and specific security needs. How do you navigate these complexities and challenges successfully? Invest in a partner you trust. Let Endurance IT provide cost-effective disaster recovery and business continuity services before disaster strikes.
                </p>
            </div>
        </div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <img src="https://ik.imagekit.io/b0cgaqv3j/service/disaster-recovery/Rightside.png?updatedAt=1679471255649" alt="Cloud Security Solutions" class="shadow-lg">
        </div>
    </div>
</section>
<section class="section2 mainredbar">
  <h3>
    <strong>Endurance’s Disaster Recovery Services</strong>
</h3>
    <p style="    font-size: 1rem;">
        <strong>What would you do if a hurricane, fire, water main break or other natural disaster impacted your critical IT systems?</strong>
        Would your computer network systems come back up or would your business be “in the dark”? Without a plan in place, many businesses would be crippled after a major disaster.
        In fact, a study by Meta Group, an investment firm, found 94 percent of companies that suffer a catastrophic loss of data would not survive another two years in business.
    </p>

    <div class="serviceicons cols2 bulletlist">


        <div>
            <ul>
                <li>Disaster business impact analysis</li>
                <li> Risk assessment</li>
                <li> Customized solution based on your needs</li>
            </ul>   </div>
        <div class="noborder">
            <ul>
                <li>Scheduled testing of recovery plans</li>
                <li> Designed to fit your specific budget</li>
            </ul> </div>
        <!-- comment -->
    </div>
</section>
<section class="section4" style="padding: 3vw 10vw !important;
text-align: center;
">
    <h3 class="coloro" style="margin-bottom: 2rem; font-family: MontserratEB !important;"> Backing Up Your Data Is Only Part Of The Equation.</h3>
    <div class="cols2 bulletlistr">
        <div>
            <ul class="para" style=" padding-top:0.5rem !important;">
                <li>What happens if the servers are destroyed or data is inaccessible?</li>
                <li>
                    What are the costs to your organization while technology is down?
                </li>
                <li>How long will it take you to recover?</li>
            </ul>
        </div>
        <div><ul  class="para" style="padding-left: 1rem; padding-top:0.5rem;">
            <li>Do you have the skills on hand to rebuild?</li>
            <li>Do you have a current disaster recovery plan?</li>
            <li>Have you tested the rebuilt system?</li></ul>
        </div>
    </div>
</section>
<section class="section3" style="background-image: url(https://ik.imagekit.io/b0cgaqv3j/service/disaster-recovery/footercontent.jpg?updatedAt=1679471254100);">
    <div class="row">
        <div class="col-sm-6 col-lg-6 col-md-6"></div>
        <div class="col-sm-6 col-lg-6 col-md-6">
            <p>
                At Endurance IT, we look at your mission critical systems, identify risks, and determine impact if disaster occurs. We explore recovery times for each system and develop multiple strategies with risks, and costs to align with your budget and, risk tolerance. A sound disaster recovery or business continuity solution must be predictable, testable, documented and communicated. Let Endurance IT consultants guide you through the process and build the best plan for your business.
            </p>
            <p>
                To discuss a Disaster Recovery solution for your company, get in touch.
            </p>
            <div class="bbtnsbox">
                <a href="/contact-us" class="btnsbox">let's talk about it</a>
            </div>
        </div>
    </div>
</section>
