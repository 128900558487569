import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { FormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MetaServiceService } from 'src/app/meta-service.service';

@Component({
  selector: 'app-jot-surveys-form',
  templateUrl: './jot-surveys-form.component.html',
  styleUrls: ['./jot-surveys-form.component.css']
})
export class JotSurveysFormComponent {
  toDisplay = true;
  NewResults: any = [];
  Results: any = [];
  SurveyQus: any = [];
  SEO: any = [];
  values: any = [];
  IsShowButton = false;
  ButtonText: String = 'Next';
  defaultChecked: string = 'option1';
  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private title: Title, private meta: Meta, private MetaService: MetaServiceService) { }

  ngOnInit(): void {
    this.SurveyQus.step1 = [
      {
        id: 1,
        name: 'd1',
        checked: false
      },
      {
        id: 2,
        name: 'd2',
        checked: false
      },
      {
        id: 3,
        name: 'd3',
        checked: false
      },
      {
        id: 4,
        name: 'd4',
        checked: false
      },
      {
        id: 5,
        name: 'd5',
        checked: false
      },
    ];

    this.MetaService.createCanonicalURL();
    const url = '/assets/seo/contact-us.json';
    this.http.get(url).subscribe(seodata => {

      this.SEO = seodata;
      console.log(this.SEO[0]);
      this.title.setTitle(this.SEO.meta_title);
      this.meta.updateTag({ name: 'description', content: this.SEO.meta_dec });
      this.meta.updateTag({ name: 'keyword', content: this.SEO.keyword });
    });
    this.StepUp();

  }
  StepSet(stepname: any, stepvalue: String, type: any) {

    const Nurl = '/wp/mail/Survey/formsDetails.php';
    const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
    const body = { Step: stepname, value: stepvalue, buttontype: type };
    this.http.post<any>(Nurl, body, { headers }).subscribe(data => {
      //window.location.reload();
    });
  }


  StepUp() {
    const urls = '/wp/mail/Survey/steps.php';
    this.http.get(urls).subscribe(Result => {
      this.Results = (Result);
      console.log(this.Results.current_step);
      if (this.Results.totalsteps == this.Results.current_step) {
        this.ButtonText = 'Submit';
      }
      if (this.Results.current_step == 0) {
        this.router.navigate(['/contact-us']);
      }
      if (this.Results.totalsteps < this.Results.current_step) {
        this.router.navigate(['/thank-you']);
      }

    });

  }
  logForm(value: any) {

    const surl = this.router.url;
    const url = '/wp/mail/Survey/index.php';
    const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar' };
    const body = { data: value, url: surl };
    this.http.post<any>(url, body, { headers }).subscribe(data => {
      this.Results = data;
      this.NewResults = data;
      this.toDisplay = true;
      this.StepUp();
      if (this.NewResults.flag == 1) {
        window.location.reload();
      }
    });
  }

}
