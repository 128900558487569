import { Component, OnInit } from '@angular/core';
import { MetaServiceService } from 'src/app/meta-service.service';
@Component({
  selector: 'app-web-based-project-management',
  templateUrl: './web-based-project-management.component.html',
  styleUrls: ['./web-based-project-management.component.css']
})
export class WebBasedProjectManagementComponent implements OnInit {

  constructor(private MetaService: MetaServiceService) { }

  ngOnInit(): void {this.MetaService.createCanonicalURL();
  }

}
